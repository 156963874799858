// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../rescript/lib/es6/curry.js";
import * as Generic$ApiTypes from "../utils/Generic.bs.js";
import * as DeccoAdapter$ApiTypes from "../utils/DeccoAdapter.bs.js";
import * as TiktokCodecs$ApiTypes from "../TiktokCodecs.bs.js";
import * as InstagramCodecs$ApiTypes from "../InstagramCodecs.bs.js";

var fromJson = Curry._2(Generic$ApiTypes.Decode.oneOf, Generic$ApiTypes.Decode.deccoVariantWithPayload("Instagram", InstagramCodecs$ApiTypes.ContentType.fromJson, (function (payload) {
            return {
                    TAG: /* Instagram */0,
                    _0: payload
                  };
          })), [Generic$ApiTypes.Decode.deccoVariantWithPayload("Tiktok", TiktokCodecs$ApiTypes.ContentType.fromJson, (function (payload) {
              return {
                      TAG: /* Tiktok */1,
                      _0: payload
                    };
            }))]);

function toJson(source) {
  if (source.TAG === /* Instagram */0) {
    return Generic$ApiTypes.Encode.deccoVariantWithPayload(source._0, "Instagram", InstagramCodecs$ApiTypes.ContentType.toJson);
  } else {
    return Generic$ApiTypes.Encode.deccoVariantWithPayload(source._0, "Tiktok", TiktokCodecs$ApiTypes.ContentType.toJson);
  }
}

var include = DeccoAdapter$ApiTypes.Make({
      toJson: toJson,
      fromJson: fromJson
    });

var t_encode = include.t_encode;

var t_decode = include.t_decode;

var SocialNetworkSource_codec = include.codec;

var SocialNetworkSource = {
  fromJson: fromJson,
  toJson: toJson,
  t_encode: t_encode,
  t_decode: t_decode,
  codec: SocialNetworkSource_codec
};

var socialNetworkSource_encode = t_encode;

var socialNetworkSource_decode = t_decode;

export {
  SocialNetworkSource ,
  socialNetworkSource_encode ,
  socialNetworkSource_decode ,
}
/* fromJson Not a pure module */

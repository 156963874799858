// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../rescript/lib/es6/curry.js";
import * as Decco from "../../../../decco/lib/es6_global/src/Decco.bs.js";
import * as Js_dict from "../../../../rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../../rescript/lib/es6/js_json.js";
import * as Belt_Array from "../../../../rescript/lib/es6/belt_Array.js";
import * as Belt_Float from "../../../../rescript/lib/es6/belt_Float.js";
import * as Belt_Option from "../../../../rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "../../../../rescript/lib/es6/belt_Result.js";
import * as Caml_format from "../../../../rescript/lib/es6/caml_format.js";
import * as Json__Decode from "../../../../rescript-json/lib/es6_global/src/Json__Decode.bs.js";
import * as Generic$ApiTypes from "./utils/Generic.bs.js";

function entry_encode(v) {
  return Js_dict.fromArray([
              [
                "facet",
                Decco.stringToJson(v.facet)
              ],
              [
                "index",
                Decco.intToJson(v.index)
              ]
            ]);
}

function entry_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var facet = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "facet"), null));
  if (facet.TAG === /* Ok */0) {
    var index = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "index"), null));
    if (index.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                facet: facet._0,
                index: index._0
              }
            };
    }
    var e = index._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".index" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = facet._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".facet" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var entry_fromJson = Curry._3(Generic$ApiTypes.Decode.map2, Curry._2(Generic$ApiTypes.Decode.field, "facet", Generic$ApiTypes.Decode.string), Curry._2(Generic$ApiTypes.Decode.field, "index", Generic$ApiTypes.Decode.$$int), (function (facet, index) {
        return {
                facet: facet,
                index: index
              };
      }));

function entry_toJson(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, [
              [
                "facet",
                Curry._1(Generic$ApiTypes.Encode.string, param.facet)
              ],
              [
                "index",
                Curry._1(Generic$ApiTypes.Encode.$$int, param.index)
              ]
            ]);
}

function t_encode(v) {
  return Js_dict.fromArray([
              [
                "entries",
                Decco.arrayToJson(entry_encode, v.entries)
              ],
              [
                "minPrice",
                Decco.optionToJson(Decco.floatToJson, v.minPrice)
              ],
              [
                "maxPrice",
                Decco.optionToJson(Decco.floatToJson, v.maxPrice)
              ]
            ]);
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var entries = Decco.arrayFromJson(entry_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "entries"), null));
  if (entries.TAG === /* Ok */0) {
    var minPrice = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "minPrice"), null));
    if (minPrice.TAG === /* Ok */0) {
      var maxPrice = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "maxPrice"), null));
      if (maxPrice.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  entries: entries._0,
                  minPrice: minPrice._0,
                  maxPrice: maxPrice._0
                }
              };
      }
      var e = maxPrice._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".maxPrice" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = minPrice._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".minPrice" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = entries._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".entries" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

var fromJson = Json__Decode.map3(Json__Decode.field("entries", Json__Decode.array(entry_fromJson)), Json__Decode.field("minPrice", Json__Decode.option(Json__Decode.$$float)), Json__Decode.field("maxPrice", Json__Decode.option(Json__Decode.$$float)), (function (entries, minPrice, maxPrice) {
        return {
                entries: entries,
                minPrice: minPrice,
                maxPrice: maxPrice
              };
      }));

function toJson(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, [
              [
                "entries",
                Curry._2(Generic$ApiTypes.Encode.array, param.entries, entry_toJson)
              ],
              [
                "minPrice",
                Generic$ApiTypes.Encode.nullable(param.minPrice, Generic$ApiTypes.Encode.$$float)
              ],
              [
                "maxPrice",
                Generic$ApiTypes.Encode.nullable(param.maxPrice, Generic$ApiTypes.Encode.$$float)
              ]
            ]);
}

var abstract_encode = Decco.stringToJson;

var abstract_decode = Decco.stringFromJson;

function t_decode$1(json) {
  return Belt_Result.flatMap(Decco.stringFromJson(json), (function (string) {
                var entries = Belt_Array.keepMap(string.split(","), (function (each) {
                        var match = each.split(";");
                        if (match.length !== 2) {
                          return {
                                  facet: decodeURIComponent(each),
                                  index: -1
                                };
                        }
                        var key = match[0];
                        var index = match[1];
                        return {
                                facet: decodeURIComponent(key),
                                index: Caml_format.int_of_string(index)
                              };
                      }));
                var extractedMinPrice = Belt_Array.reduce(entries, undefined, (function (acc, entry) {
                        var match = entry.facet.split("=");
                        if (match.length !== 2) {
                          return acc;
                        }
                        var match$1 = match[0];
                        if (match$1 === "minprice") {
                          return Belt_Float.fromString(match[1]);
                        } else {
                          return acc;
                        }
                      }));
                var extractedMaxPrice = Belt_Array.reduce(entries, undefined, (function (acc, entry) {
                        var match = entry.facet.split("=");
                        if (match.length !== 2) {
                          return acc;
                        }
                        var match$1 = match[0];
                        if (match$1 === "maxprice") {
                          return Belt_Float.fromString(match[1]);
                        } else {
                          return acc;
                        }
                      }));
                var entries$1 = Belt_Array.keepMap(entries, (function (entry) {
                        var match = entry.index;
                        if (match !== -1) {
                          return entry;
                        }
                        
                      }));
                return {
                        TAG: /* Ok */0,
                        _0: {
                          entries: entries$1,
                          minPrice: extractedMinPrice,
                          maxPrice: extractedMaxPrice
                        }
                      };
              }));
}

function encode(a) {
  return JSON.stringify(Decco.stringToJson(a.join(","))).replace(/^\"(.*)\"$/, "$1");
}

function t_encode$1(a) {
  return encode(Belt_Array.concat(Belt_Array.map(a.entries, (function (param) {
                        return encodeURIComponent(param.facet) + ";" + String(param.index);
                      })), Belt_Array.keepMap([
                      Belt_Option.map(a.minPrice, (function (minPrice) {
                              return encodeURIComponent("minprice") + "=" + String(minPrice);
                            })),
                      Belt_Option.map(a.maxPrice, (function (maxPrice) {
                              return encodeURIComponent("maxprice") + "=" + String(maxPrice);
                            }))
                    ], (function (a) {
                        return a;
                      }))));
}

function toStringOfIndex(a) {
  return encode(Belt_Array.map(a.entries, (function (param) {
                    return String(param.index);
                  })));
}

var codec = [
  t_decode$1,
  t_encode$1
];

function safeDecode(filters) {
  try {
    var result = t_decode$1(filters);
    if (result.TAG === /* Ok */0) {
      return result._0;
    } else {
      return ;
    }
  }
  catch (exn){
    return ;
  }
}

var fromString = t_decode$1;

var toString = t_encode$1;

export {
  entry_encode ,
  entry_decode ,
  entry_fromJson ,
  entry_toJson ,
  t_encode ,
  t_decode ,
  fromJson ,
  toJson ,
  abstract_encode ,
  abstract_decode ,
  toStringOfIndex ,
  codec ,
  fromString ,
  toString ,
  safeDecode ,
}
/* entry_fromJson Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import { lazy } from "@loadable/component";
let component = lazy(() => import('./Slider.bs.js'));
;
function Slider_Lazy(Props) {
  var fallbackOpt = Props.fallback;
  var fallback = fallbackOpt !== undefined ? Caml_option.valFromOption(fallbackOpt) : null;
  var element = React.createElement(component, Props);
  return React.createElement(React.Suspense, {
    children: element,
    fallback: fallback
  });
}
Slider_Lazy.displayName = "Slider_Lazy";
var preload = component.preload;
var make = Slider_Lazy;
export { make, preload };
/*  Not a pure module */
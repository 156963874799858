// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as LocalStorage$Showside from "../utils/LocalStorage.bs.js";
import * as ConfigurationProvider$Showside from "./ConfigurationProvider.bs.js";
function t_toString(value) {
  if (value.TAG === /* Product */0) {
    return "P" + value._0.id;
  } else {
    return "P" + value._0;
  }
}
function t_fromString(string) {
  if (string.startsWith("P")) {
    return {
      TAG: /* ProductId */1,
      _0: string.replace(/^P/, "")
    };
  }
}
function toString(array) {
  return Belt_Array.map(array, t_toString).join(",");
}
function fromString(string) {
  return Belt_Array.keepMap(Belt_Array.map(string.split(","), t_fromString), function (a) {
    return a;
  });
}
function contains(array, value) {
  return Belt_Array.some(array, function (v) {
    return t_toString(v) === t_toString(value);
  });
}
var $$Array = {
  toString: toString,
  fromString: fromString,
  contains: contains
};
var Value = {
  t_toString: t_toString,
  t_fromString: t_fromString,
  $$Array: $$Array,
  toString: t_toString,
  fromString: t_fromString
};
var empty_value = [];
function empty_add(param) {}
function empty_remove(param) {}
var empty = {
  value: empty_value,
  enabled: false,
  add: empty_add,
  remove: empty_remove
};
var emptyGroup = {
  history: empty,
  favorites: empty
};
function getCurrentValue(key) {
  return Belt_Option.getWithDefault(Belt_Option.map(LocalStorage$Showside.get(key), fromString), []);
}
function useStorage(enabled, key) {
  var match = React.useState(function () {
    if (enabled) {
      return getCurrentValue(key);
    } else {
      return [];
    }
  });
  var setCurrentValue = match[1];
  var setCurrentValue$1 = function (fn) {
    Curry._1(setCurrentValue, function (param) {
      if (!enabled) {
        return [];
      }
      var currentValue = getCurrentValue(key);
      var nextValue = Curry._1(fn, currentValue);
      if (nextValue.length !== 0) {
        LocalStorage$Showside.set(key, toString(nextValue));
      } else {
        LocalStorage$Showside.$$delete(key);
      }
      return nextValue;
    });
  };
  var add = function (value) {
    if (enabled) {
      return setCurrentValue$1(function (currentValue) {
        return Belt_Array.concat([value], Belt_Array.keepWithIndex(Belt_Array.keep(currentValue, function (v) {
          return t_toString(v) !== t_toString(value);
        }), function (p, i) {
          return i < 99;
        }));
      });
    }
  };
  var remove = function (value) {
    if (enabled) {
      return setCurrentValue$1(function (currentValue) {
        return Belt_Array.keep(currentValue, function (v) {
          return t_toString(v) !== t_toString(value);
        });
      });
    }
  };
  return {
    value: enabled ? match[0] : [],
    enabled: enabled,
    add: add,
    remove: remove
  };
}
var context = React.createContext(emptyGroup);
var provider = context.Provider;
function UserSavedProductsProvider(Props) {
  var children = Props.children;
  var match = ConfigurationProvider$Showside.use(undefined).config;
  var accountId = match.accountId;
  var match$1 = match.settings;
  var history = useStorage(match$1.historyEnabled, {
    NAME: "history",
    VAL: accountId
  });
  var favorites = useStorage(match$1.favoritesEnabled, {
    NAME: "favorites",
    VAL: accountId
  });
  return React.createElement(provider, {
    value: {
      history: history,
      favorites: favorites
    },
    children: children
  });
}
UserSavedProductsProvider.displayName = "UserSavedProductsProvider";
function use(param) {
  return React.useContext(context);
}
var make = UserSavedProductsProvider;
export { Value, empty, emptyGroup, getCurrentValue, useStorage, context, provider, make, use };
/* context Not a pure module */
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Shopify$Showside from "../utils/Shopify.bs.js";
function getValues(t) {
  if (typeof t === "number") {
    return {
      currency: undefined,
      country: Shopify$Showside.getCountry(undefined)
    };
  } else if (t.TAG === /* Currency */0) {
    return {
      currency: t._0,
      country: Shopify$Showside.getCountry(undefined)
    };
  } else {
    return {
      currency: undefined,
      country: t._0
    };
  }
}
function toString(value) {
  if (typeof value === "number") {
    return "";
  } else {
    return value._0;
  }
}
export { getValues, toString };
/* Shopify-Showside Not a pure module */
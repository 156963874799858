// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "../../../../node_modules/rescript/lib/es6/belt_Result.js";
import * as Json__Decode from "../../../../node_modules/rescript-json/lib/es6_global/src/Json__Decode.bs.js";
import * as Product$Showside from "../types/Product.bs.js";
import * as Currency$Showside from "./Currency.bs.js";
import * as Account_OverridesCodecs$ApiTypes from "../../../../node_modules/api-types/lib/es6_global/src/Account/Account_OverridesCodecs.bs.js";
function getAccountProperty(account, accountValue, configId, property, decoder) {
  var override = Belt_Option.flatMap(Belt_Option.map(Belt_Option.map(Account_OverridesCodecs$ApiTypes.Overrides.getByConfigIdAndProperty(Belt_Option.getWithDefault(account.overrides, []), configId, property), function (item) {
    return item.value;
  }), function (__x) {
    return Json__Decode.decodeValue(__x, decoder);
  }), function (__x) {
    return Belt_Result.getWithDefault(__x, undefined);
  });
  if (override !== undefined) {
    return override;
  } else {
    return accountValue;
  }
}
function getProductName(product, variant) {
  var color = Product$Showside.Variant.getOptionValue(variant, "Color");
  var productColor = color !== undefined ? " - " + color + "" : "";
  var size = Product$Showside.Variant.getOptionValue(variant, "Size");
  var productSize = size !== undefined ? " / " + size + "" : "";
  return "" + product.name + "" + productColor + "" + productSize + "";
}
function paramsFromProduct(product, variant, category) {
  var categoryName = Belt_Option.mapWithDefault(category, "", function (category) {
    return category.title;
  });
  var ids = [product.id];
  var price = variant.price;
  var tmp;
  tmp = typeof price === "number" ? undefined : price.TAG === /* Regular */0 ? price._0 : price._0.price;
  var parsed = Belt_Option.flatMap(tmp, Currency$Showside.parse);
  var value = Belt_Option.mapWithDefault(parsed, 0.0, function (parsed) {
    return parsed.value;
  });
  var currency = product.currency;
  return {
    ids: ids,
    value: value,
    name: getProductName(product, variant),
    currency: currency,
    categoryName: categoryName
  };
}
function trackViewContent(account, configId, product, variant, category, param) {
  var enabled = Belt_Option.getWithDefault(Belt_Option.flatMap(account, function (account) {
    return getAccountProperty(account, account.facebookPixelViewItemEnabled, configId, "facebookPixelViewItemEnabled", Json__Decode.nullable(Json__Decode.bool));
  }), true);
  var match = paramsFromProduct(product, variant, category);
  var match$1 = window.fbq;
  if (match$1 !== undefined && enabled) {
    return match$1("track", "ViewContent", {
      content_type: "product_group",
      content_ids: match.ids,
      value: match.value,
      content_name: match.name,
      currency: match.currency,
      content_category: match.categoryName
    });
  }
}
function trackAddToCart(account, configId, product, variant, category, quantity, param) {
  var enabled = Belt_Option.getWithDefault(Belt_Option.flatMap(account, function (account) {
    return getAccountProperty(account, account.facebookPixelAddToCartEnabled, configId, "facebookPixelAddToCartEnabled", Json__Decode.nullable(Json__Decode.bool));
  }), true);
  var match = paramsFromProduct(product, variant, category);
  var match$1 = window.fbq;
  if (match$1 !== undefined && enabled) {
    return match$1("track", "AddToCart", {
      content_type: "product_group",
      content_ids: match.ids,
      value: match.value,
      num_items: quantity,
      content_name: match.name,
      currency: match.currency,
      content_category: match.categoryName
    });
  }
}
function trackPageView(account, configId, param) {
  var enabled = Belt_Option.getWithDefault(Belt_Option.flatMap(account, function (account) {
    return getAccountProperty(account, account.facebookPixelPageViewEnabled, configId, "facebookPixelPageViewEnabled", Json__Decode.nullable(Json__Decode.bool));
  }), true);
  var match = window.fbq;
  if (match !== undefined && enabled) {
    return match("track", "PageView", undefined);
  }
}
var injectScript = function injectScript(tagId) {
  !function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', tagId);
};
function init(account, configId) {
  var tagId = getAccountProperty(account, account.facebookPixelId, configId, "facebookPixelId", Json__Decode.nullable(Json__Decode.string));
  var match = window.fbq;
  if (tagId !== undefined && !(match !== undefined || tagId === "")) {
    injectScript(tagId);
  }
  trackPageView(account, configId, undefined);
}
export { getAccountProperty, getProductName, paramsFromProduct, trackViewContent, trackAddToCart, trackPageView, injectScript, init };
/* Json__Decode Not a pure module */
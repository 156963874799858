// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "../../../../rescript/lib/es6/belt_Array.js";
import * as Json__Decode from "../../../../rescript-json/lib/es6_global/src/Json__Decode.bs.js";
import * as CTAButtons$ApiTypes from "./CTAButtons.bs.js";
import * as DeccoAdapter$ApiTypes from "./utils/DeccoAdapter.bs.js";

var fromJson = Json__Decode.andThen(Json__Decode.string, (function (str) {
        var parts = Belt_Array.keepMap(str.split(CTAButtons$ApiTypes.separator), (function (part) {
                switch (part) {
                  case "add_to_cart" :
                      return "add_to_cart";
                  case "custom_action" :
                      return "custom_action";
                  case "open_link" :
                      return "open_link";
                  default:
                    return ;
                }
              }));
        var match = Belt_Array.get(parts, 0);
        var match$1 = Belt_Array.get(parts, 1);
        var match$2 = Belt_Array.get(parts, 2);
        if (match !== undefined) {
          if (match$1 !== undefined) {
            return Json__Decode.succeed([
                        match$1,
                        match,
                        match$2
                      ]);
          } else if (match$2 !== undefined) {
            return Json__Decode.fail("CTAButtons '" + str + "' is not supported.");
          } else {
            return Json__Decode.succeed([
                        match,
                        undefined,
                        undefined
                      ]);
          }
        } else {
          return Json__Decode.fail("CTAButtons '" + str + "' is not supported.");
        }
      }));

var include = DeccoAdapter$ApiTypes.Make({
      toJson: CTAButtons$ApiTypes.t_encode,
      fromJson: fromJson
    });

var toJson = CTAButtons$ApiTypes.t_encode;

var t_encode = include.t_encode;

var t_decode = include.t_decode;

var codec = include.codec;

export {
  toJson ,
  fromJson ,
  t_encode ,
  t_decode ,
  codec ,
}
/* fromJson Not a pure module */

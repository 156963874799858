// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../rescript/lib/es6/curry.js";
import * as Belt_Option from "../../../../../rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "../../../../../rescript/lib/es6/belt_Result.js";
import * as Caml_option from "../../../../../rescript/lib/es6/caml_option.js";
import * as Json__Decode from "../../../../../rescript-json/lib/es6_global/src/Json__Decode.bs.js";
import * as Generic$ApiTypes from "../utils/Generic.bs.js";
import * as LegacyShowside_Styling$ApiTypes from "./LegacyShowside_Styling.bs.js";

function toJson(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, [
              [
                "fontFamily",
                Generic$ApiTypes.Encode.nullable(Belt_Option.flatMap(param.fontFamily, LegacyShowside_Styling$ApiTypes.FontFamily.value), Generic$ApiTypes.Encode.string)
              ],
              [
                "primaryColor",
                Curry._1(Generic$ApiTypes.Encode.string, LegacyShowside_Styling$ApiTypes.Color.value(param.primaryColor))
              ],
              [
                "secondaryColor",
                Curry._1(Generic$ApiTypes.Encode.string, LegacyShowside_Styling$ApiTypes.Color.value(param.secondaryColor))
              ],
              [
                "tertiaryColor",
                Curry._1(Generic$ApiTypes.Encode.string, LegacyShowside_Styling$ApiTypes.Color.value(param.tertiaryColor))
              ],
              [
                "accentColor",
                Curry._1(Generic$ApiTypes.Encode.string, LegacyShowside_Styling$ApiTypes.Color.value(param.accentColor))
              ],
              [
                "backgroundColor",
                Curry._1(Generic$ApiTypes.Encode.string, LegacyShowside_Styling$ApiTypes.Color.value(param.backgroundColor))
              ],
              [
                "categoryCardPlaceholderColor",
                Curry._1(Generic$ApiTypes.Encode.string, LegacyShowside_Styling$ApiTypes.Color.value(param.categoryCardPlaceholderColor))
              ],
              [
                "productCardPlaceholderColor",
                Curry._1(Generic$ApiTypes.Encode.string, LegacyShowside_Styling$ApiTypes.Color.value(param.productCardPlaceholderColor))
              ],
              [
                "drawerMainColor",
                Curry._1(Generic$ApiTypes.Encode.string, LegacyShowside_Styling$ApiTypes.Color.value(param.drawerMainColor))
              ],
              [
                "drawerLabelColor",
                Curry._1(Generic$ApiTypes.Encode.string, LegacyShowside_Styling$ApiTypes.Color.value(param.drawerLabelColor))
              ],
              [
                "productPriceDiscountBackgroundColor",
                Curry._1(Generic$ApiTypes.Encode.string, LegacyShowside_Styling$ApiTypes.Color.value(param.productPriceDiscountBackgroundColor))
              ],
              [
                "productPriceDiscountLabelColor",
                Curry._1(Generic$ApiTypes.Encode.string, LegacyShowside_Styling$ApiTypes.Color.value(param.productPriceDiscountLabelColor))
              ],
              [
                "productPromoBackgroundColor",
                Curry._1(Generic$ApiTypes.Encode.string, LegacyShowside_Styling$ApiTypes.Color.value(param.productPromoBackgroundColor))
              ],
              [
                "productPromoLabelColor",
                Curry._1(Generic$ApiTypes.Encode.string, LegacyShowside_Styling$ApiTypes.Color.value(param.productPromoLabelColor))
              ],
              [
                "roundedEdges",
                Curry._1(Generic$ApiTypes.Encode.string, LegacyShowside_Styling$ApiTypes.Px.value(param.roundedEdges))
              ],
              [
                "columnGutter",
                Curry._1(Generic$ApiTypes.Encode.string, LegacyShowside_Styling$ApiTypes.Px.value(param.columnGutter))
              ],
              [
                "rowGutter",
                Curry._1(Generic$ApiTypes.Encode.string, LegacyShowside_Styling$ApiTypes.Px.value(param.rowGutter))
              ]
            ]);
}

var fromJson = Json__Decode.andMap(Json__Decode.andMap(Json__Decode.andMap(Json__Decode.andMap(Json__Decode.andMap(Json__Decode.andMap(Json__Decode.andMap(Json__Decode.andMap(Json__Decode.andMap(Json__Decode.andMap(Json__Decode.andMap(Json__Decode.andMap(Json__Decode.andMap(Json__Decode.andMap(Json__Decode.andMap(Json__Decode.andMap(Json__Decode.andMap(Json__Decode.succeed(function (fontFamily, primaryColor, secondaryColor, tertiaryColor, accentColor, backgroundColor, categoryCardPlaceholderColor, productCardPlaceholderColor, drawerMainColor, drawerLabelColor, productPriceDiscountBackgroundColor, productPriceDiscountLabelColor, productPromoBackgroundColor, productPromoLabelColor, roundedEdges, columnGutter, rowGutter) {
                                                                          return {
                                                                                  fontFamily: fontFamily,
                                                                                  primaryColor: primaryColor,
                                                                                  secondaryColor: secondaryColor,
                                                                                  tertiaryColor: tertiaryColor,
                                                                                  accentColor: accentColor,
                                                                                  backgroundColor: backgroundColor,
                                                                                  categoryCardPlaceholderColor: categoryCardPlaceholderColor,
                                                                                  productCardPlaceholderColor: productCardPlaceholderColor,
                                                                                  drawerMainColor: drawerMainColor,
                                                                                  drawerLabelColor: drawerLabelColor,
                                                                                  productPriceDiscountBackgroundColor: productPriceDiscountBackgroundColor,
                                                                                  productPriceDiscountLabelColor: productPriceDiscountLabelColor,
                                                                                  productPromoBackgroundColor: productPromoBackgroundColor,
                                                                                  productPromoLabelColor: productPromoLabelColor,
                                                                                  roundedEdges: roundedEdges,
                                                                                  columnGutter: columnGutter,
                                                                                  rowGutter: rowGutter
                                                                                };
                                                                        }), Json__Decode.map(Json__Decode.field("fontFamily", Json__Decode.nullable(Json__Decode.string)), (function (v) {
                                                                            return Belt_Result.mapWithDefault(LegacyShowside_Styling$ApiTypes.FontFamily.make(v), undefined, (function (r) {
                                                                                          return Caml_option.some(r);
                                                                                        }));
                                                                          }))), Json__Decode.map(Json__Decode.field("primaryColor", Json__Decode.string), (function (v) {
                                                                        return Belt_Result.getWithDefault(LegacyShowside_Styling$ApiTypes.Color.make(v), LegacyShowside_Styling$ApiTypes.Color.$$default);
                                                                      }))), Json__Decode.map(Json__Decode.field("secondaryColor", Json__Decode.string), (function (v) {
                                                                    return Belt_Result.getWithDefault(LegacyShowside_Styling$ApiTypes.Color.make(v), LegacyShowside_Styling$ApiTypes.Color.$$default);
                                                                  }))), Json__Decode.map(Json__Decode.field("tertiaryColor", Json__Decode.string), (function (v) {
                                                                return Belt_Result.getWithDefault(LegacyShowside_Styling$ApiTypes.Color.make(v), LegacyShowside_Styling$ApiTypes.Color.$$default);
                                                              }))), Json__Decode.map(Json__Decode.field("accentColor", Json__Decode.string), (function (v) {
                                                            return Belt_Result.getWithDefault(LegacyShowside_Styling$ApiTypes.Color.make(v), LegacyShowside_Styling$ApiTypes.Color.$$default);
                                                          }))), Json__Decode.map(Json__Decode.field("backgroundColor", Json__Decode.string), (function (v) {
                                                        return Belt_Result.getWithDefault(LegacyShowside_Styling$ApiTypes.Color.make(v), LegacyShowside_Styling$ApiTypes.Color.$$default);
                                                      }))), Json__Decode.map(Json__Decode.field("categoryCardPlaceholderColor", Json__Decode.string), (function (v) {
                                                    return Belt_Result.getWithDefault(LegacyShowside_Styling$ApiTypes.Color.make(v), LegacyShowside_Styling$ApiTypes.Color.$$default);
                                                  }))), Json__Decode.map(Json__Decode.field("productCardPlaceholderColor", Json__Decode.string), (function (v) {
                                                return Belt_Result.getWithDefault(LegacyShowside_Styling$ApiTypes.Color.make(v), LegacyShowside_Styling$ApiTypes.Color.$$default);
                                              }))), Json__Decode.map(Json__Decode.field("drawerMainColor", Json__Decode.string), (function (v) {
                                            return Belt_Result.getWithDefault(LegacyShowside_Styling$ApiTypes.Color.make(v), LegacyShowside_Styling$ApiTypes.Color.$$default);
                                          }))), Json__Decode.map(Json__Decode.field("drawerLabelColor", Json__Decode.string), (function (v) {
                                        return Belt_Result.getWithDefault(LegacyShowside_Styling$ApiTypes.Color.make(v), LegacyShowside_Styling$ApiTypes.Color.$$default);
                                      }))), Json__Decode.map(Json__Decode.field("productPriceDiscountBackgroundColor", Json__Decode.string), (function (v) {
                                    return Belt_Result.getWithDefault(LegacyShowside_Styling$ApiTypes.Color.make(v), LegacyShowside_Styling$ApiTypes.Color.$$default);
                                  }))), Json__Decode.map(Json__Decode.field("productPriceDiscountLabelColor", Json__Decode.string), (function (v) {
                                return Belt_Result.getWithDefault(LegacyShowside_Styling$ApiTypes.Color.make(v), LegacyShowside_Styling$ApiTypes.Color.$$default);
                              }))), Json__Decode.map(Json__Decode.field("productPromoBackgroundColor", Json__Decode.string), (function (v) {
                            return Belt_Result.getWithDefault(LegacyShowside_Styling$ApiTypes.Color.make(v), LegacyShowside_Styling$ApiTypes.Color.$$default);
                          }))), Json__Decode.map(Json__Decode.field("productPromoLabelColor", Json__Decode.string), (function (v) {
                        return Belt_Result.getWithDefault(LegacyShowside_Styling$ApiTypes.Color.make(v), LegacyShowside_Styling$ApiTypes.Color.$$default);
                      }))), Json__Decode.map(Json__Decode.field("roundedEdges", Json__Decode.string), (function (v) {
                    return Belt_Result.getWithDefault(LegacyShowside_Styling$ApiTypes.Px.make(v), LegacyShowside_Styling$ApiTypes.Px.$$default);
                  }))), Json__Decode.map(Json__Decode.field("columnGutter", Json__Decode.string), (function (v) {
                return Belt_Result.getWithDefault(LegacyShowside_Styling$ApiTypes.Px.make(v), LegacyShowside_Styling$ApiTypes.Px.$$default);
              }))), Json__Decode.map(Json__Decode.field("rowGutter", Json__Decode.string), (function (v) {
            return Belt_Result.getWithDefault(LegacyShowside_Styling$ApiTypes.Px.make(v), LegacyShowside_Styling$ApiTypes.Px.$$default);
          })));

export {
  toJson ,
  fromJson ,
}
/* fromJson Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import loadable from "@loadable/component";
let component = loadable(() => import('./SectionApp.bs.js'));
;
function SectionApp_Lazy(Props) {
  return React.createElement(component, Props);
}
SectionApp_Lazy.displayName = "SectionApp_Lazy";
var make = SectionApp_Lazy;
export { make };
/*  Not a pure module */
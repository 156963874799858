(function (global, factory) {
  typeof exports === 'object' && typeof module !== 'undefined' ? factory(exports) :
  typeof define === 'function' && define.amd ? define(['exports'], factory) :
  (global = global || self, factory(global.transform = {}));
}(this, function (exports) { 'use strict';

  // List of transforms for which the value has to be numeral. Default px substitution for these
  // transforms will be skipped.
  const transformsWithNumeralValues = ['scale', 'scale3d', 'scaleX', 'scaleY', 'scaleZ'];
  /**
   * Returns a object representation of the transform string supplied. It parses the string and
   * converts them to an object.
   * @param transformString String containing the transforms
   */
  const parse = (transformString) => {
      const transforms = transformString.trim().split(/\) |\)/);
      // Handle "initial", "inherit", "unset".
      if (transforms.length === 1) {
          return {
              [transforms[0]]: true,
          };
      }
      /**
       * Converts string values to number when the unit is pixel or parsable. Returns a string when the
       * unit is not pixel or not parsable.
       * @param value Value of a transform
       */
      const parsePixelValues = (value) => {
          if (value.endsWith('px'))
              return parseFloat(value);
          if (!Number.isNaN(Number(value)))
              return Number(value);
          return value;
      };
      return transforms.reduce((acc, transform) => {
          if (!transform)
              return acc;
          const [name, transformValue] = transform.split('(');
          const valueArray = transformValue.split(',');
          const values = valueArray.map((val) => {
              return parsePixelValues(val.endsWith(')') ? val.replace(')', '') : val.trim());
          });
          const value = values.length === 1 ? values[0] : values;
          return {
              ...acc, ...{ [name]: value },
          };
      }, {});
  };
  /**
   * Returns the transform string constructed from the transform object supplied.
   * @param transformObject Object containing the transforms
   */
  const stringify = (transformObject) => {
      const resolveUnits = (key, val) => {
          if (typeof val === 'number' && !transformsWithNumeralValues.includes(key)) {
              return `${val}px`;
          }
          return val.toString();
      };
      return Object.keys(transformObject).reduce((acc, key) => {
          const value = transformObject[key];
          if (value === true)
              return key;
          if (value === false)
              return acc;
          if (Array.isArray(value)) {
              return `${acc} ${key}(${value.map((val) => resolveUnits(key, val)).join(', ')})`;
          }
          return `${acc} ${key}(${resolveUnits(key, value)})`;
      }, '')
          .trim();
  };

  exports.parse = parse;
  exports.stringify = stringify;

  Object.defineProperty(exports, '__esModule', { value: true });

}));

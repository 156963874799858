// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "../../../../decco/lib/es6_global/src/Decco.bs.js";
import * as Js_json from "../../../../rescript/lib/es6/js_json.js";
import * as Belt_Array from "../../../../rescript/lib/es6/belt_Array.js";

var separator = " + ";

function t_encode(input) {
  var secondary = input[1];
  var primary = input[0];
  if (secondary !== undefined) {
    var tertiary = input[2];
    if (tertiary !== undefined) {
      return secondary + separator + primary + separator + tertiary;
    } else {
      return secondary + separator + primary;
    }
  }
  var secondary$1 = input[2];
  if (secondary$1 !== undefined) {
    return secondary$1 + separator + primary;
  } else {
    return primary;
  }
}

function t_decode(json) {
  var str = Js_json.classify(json);
  if (typeof str === "number") {
    return Decco.error(undefined, "Trying to decode a field that is not a string to CTAButtons", json);
  }
  if (str.TAG !== /* JSONString */0) {
    return Decco.error(undefined, "Trying to decode a field that is not a string to CTAButtons", json);
  }
  var str$1 = str._0;
  var parts = Belt_Array.keepMap(str$1.split(separator), (function (part) {
          switch (part) {
            case "add_to_cart" :
                return "add_to_cart";
            case "custom_action" :
                return "custom_action";
            case "open_link" :
                return "open_link";
            default:
              return ;
          }
        }));
  var match = Belt_Array.get(parts, 0);
  var match$1 = Belt_Array.get(parts, 1);
  var match$2 = Belt_Array.get(parts, 2);
  if (match !== undefined) {
    if (match$1 !== undefined) {
      return {
              TAG: /* Ok */0,
              _0: [
                match$1,
                match,
                match$2
              ]
            };
    }
    if (match$2 === undefined) {
      return {
              TAG: /* Ok */0,
              _0: [
                match,
                undefined,
                undefined
              ]
            };
    }
    
  }
  return Decco.error(undefined, "CTAButtons '" + str$1 + "' is not supported.", json);
}

export {
  separator ,
  t_encode ,
  t_decode ,
}
/* No side effect */

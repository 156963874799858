// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../rescript/lib/es6/curry.js";
import * as Belt_Array from "../../../../rescript/lib/es6/belt_Array.js";
import * as PostmsgRpc from "postmsg-rpc";

var options = {
  postMessage: (function (data, origin) {
      window.parent.postMessage(data, origin);
    })
};

function expose1(funcName, impl) {
  return PostmsgRpc.expose(funcName, impl, options);
}

function expose0(funcName, impl) {
  return PostmsgRpc.expose(funcName, (function (param) {
                return Curry._1(impl, undefined);
              }), options);
}

function expose2(funcName, impl) {
  return PostmsgRpc.expose(funcName, (function (param) {
                return Curry._2(impl, param[0], param[1]);
              }), options);
}

function expose3(funcName, impl) {
  return PostmsgRpc.expose(funcName, (function (param) {
                return Curry._3(impl, param[0], param[1], param[2]);
              }), options);
}

function caller1(funcName) {
  var fn1 = PostmsgRpc.caller(funcName, options);
  return function (a) {
    return fn1(a);
  };
}

function caller0(funcName) {
  var fn1 = caller1(funcName);
  return function (param) {
    return Curry._1(fn1, []);
  };
}

function caller2(funcName) {
  var fn1 = caller1(funcName);
  return function (a, b) {
    return Curry._1(fn1, [
                a,
                b
              ]);
  };
}

function caller3(funcName) {
  var fn1 = caller1(funcName);
  return function (a, b, c) {
    return Curry._1(fn1, [
                a,
                b,
                c
              ]);
  };
}

var ParentWindow = {
  options: options,
  expose1: expose1,
  expose0: expose0,
  expose2: expose2,
  expose3: expose3,
  caller1: caller1,
  caller0: caller0,
  caller2: caller2,
  caller3: caller3
};

var options$1 = {
  postMessage: (function (data, origin) {
      Belt_Array.map(Array.prototype.slice.call(document.querySelectorAll("iframe")), (function (el) {
              el.contentWindow.postMessage(data, origin);
            }));
    })
};

function expose1$1(funcName, impl) {
  return PostmsgRpc.expose(funcName, impl, options$1);
}

function expose0$1(funcName, impl) {
  return PostmsgRpc.expose(funcName, (function (param) {
                return Curry._1(impl, undefined);
              }), options$1);
}

function expose2$1(funcName, impl) {
  return PostmsgRpc.expose(funcName, (function (param) {
                return Curry._2(impl, param[0], param[1]);
              }), options$1);
}

function expose3$1(funcName, impl) {
  return PostmsgRpc.expose(funcName, (function (param) {
                return Curry._3(impl, param[0], param[1], param[2]);
              }), options$1);
}

function caller1$1(funcName) {
  var fn1 = PostmsgRpc.caller(funcName, options$1);
  return function (a) {
    return fn1(a);
  };
}

function caller0$1(funcName) {
  var fn1 = caller1$1(funcName);
  return function (param) {
    return Curry._1(fn1, []);
  };
}

function caller2$1(funcName) {
  var fn1 = caller1$1(funcName);
  return function (a, b) {
    return Curry._1(fn1, [
                a,
                b
              ]);
  };
}

function caller3$1(funcName) {
  var fn1 = caller1$1(funcName);
  return function (a, b, c) {
    return Curry._1(fn1, [
                a,
                b,
                c
              ]);
  };
}

var ChildIFrames = {
  options: options$1,
  expose1: expose1$1,
  expose0: expose0$1,
  expose2: expose2$1,
  expose3: expose3$1,
  caller1: caller1$1,
  caller0: caller0$1,
  caller2: caller2$1,
  caller3: caller3$1
};

var options$2 = {
  postMessage: (function (data, origin) {
      window.opener.postMessage(data, origin);
    })
};

function expose1$2(funcName, impl) {
  return PostmsgRpc.expose(funcName, impl, options$2);
}

function expose0$2(funcName, impl) {
  return PostmsgRpc.expose(funcName, (function (param) {
                return Curry._1(impl, undefined);
              }), options$2);
}

function expose2$2(funcName, impl) {
  return PostmsgRpc.expose(funcName, (function (param) {
                return Curry._2(impl, param[0], param[1]);
              }), options$2);
}

function expose3$2(funcName, impl) {
  return PostmsgRpc.expose(funcName, (function (param) {
                return Curry._3(impl, param[0], param[1], param[2]);
              }), options$2);
}

function caller1$2(funcName) {
  var fn1 = PostmsgRpc.caller(funcName, options$2);
  return function (a) {
    return fn1(a);
  };
}

function caller0$2(funcName) {
  var fn1 = caller1$2(funcName);
  return function (param) {
    return Curry._1(fn1, []);
  };
}

function caller2$2(funcName) {
  var fn1 = caller1$2(funcName);
  return function (a, b) {
    return Curry._1(fn1, [
                a,
                b
              ]);
  };
}

function caller3$2(funcName) {
  var fn1 = caller1$2(funcName);
  return function (a, b, c) {
    return Curry._1(fn1, [
                a,
                b,
                c
              ]);
  };
}

var OpenerWindow = {
  options: options$2,
  expose1: expose1$2,
  expose0: expose0$2,
  expose2: expose2$2,
  expose3: expose3$2,
  caller1: caller1$2,
  caller0: caller0$2,
  caller2: caller2$2,
  caller3: caller3$2
};

export {
  ParentWindow ,
  ChildIFrames ,
  OpenerWindow ,
}
/* postmsg-rpc Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as IdFunctor$ApiTypes from "../IdFunctor.bs.js";

var ProductId = IdFunctor$ApiTypes.Make({});

var ProductVariantId = IdFunctor$ApiTypes.Make({});

var CategoryId = IdFunctor$ApiTypes.Make({});

export {
  ProductId ,
  ProductVariantId ,
  CategoryId ,
}
/* ProductId Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Breakpoint2$Showside from "../utils/Breakpoint2.bs.js";
import * as LegacyShowside_Breakpoint2$ApiTypes from "../../../../node_modules/api-types/lib/es6_global/src/LegacyShowside/LegacyShowside_Breakpoint2.bs.js";
function use(windowWidth) {
  return React.useMemo(function () {
    var breakpoint = Breakpoint2$Showside.calc(windowWidth);
    return {
      isMobile: breakpoint <= /* Sm */1,
      breakpoint: breakpoint
    };
  }, [windowWidth]);
}
var toString = LegacyShowside_Breakpoint2$ApiTypes.toString;
var fromString = LegacyShowside_Breakpoint2$ApiTypes.fromString;
export { toString, fromString, use };
/* react Not a pure module */
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as SWR from "../../../../node_modules/rescript-swr/lib/es6_global/src/SWR.bs.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as NavRPC from "../../../../node_modules/rescript-rpc/lib/es6_global/src/NavRPC.bs.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as RouterProvider$Showside from "../context/RouterProvider.bs.js";
import * as ConfigurationProvider$Showside from "../context/ConfigurationProvider.bs.js";
function EditorMethods(Props) {
  var match = ConfigurationProvider$Showside.use(undefined);
  var config = match.config;
  var match$1 = RouterProvider$Showside.useRouter(undefined);
  var goTo = match$1.goTo;
  var route = match$1.route;
  NavRPC.IFrame.useCommandReceiver(undefined, /* RefreshConfig */2, function (param) {
    return Promise.all(Belt_Array.map(Belt_Array.keep(Array.from(SWR.Cache.cache.keys()), function (key) {
      return key.includes("/api/get_config");
    }), SWR.revalidate));
  }, []);
  NavRPC.IFrame.useCommandReceiver(undefined, /* Open */3, function (param) {
    if (typeof route === "number") {
      return Curry._2(goTo, undefined, {
        TAG: /* Home */0,
        _0: {
          homeType: /* Index */0,
          filters: undefined,
          sort: undefined
        }
      });
    }
  }, [config.id]);
  React.useEffect(function () {
    NavRPC.IFrame.sendCommand0(undefined, /* Ready */0);
    return function (param) {
      NavRPC.IFrame.sendCommand0(undefined, /* Destroyed */1);
    };
  }, [config.id]);
  return null;
}
EditorMethods.displayName = "EditorMethods";
var make = EditorMethods;
export { make };
/* SWR Not a pure module */
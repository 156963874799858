// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Belt_Int from "../../../../node_modules/rescript/lib/es6/belt_Int.js";
import * as Belt_Float from "../../../../node_modules/rescript/lib/es6/belt_Float.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
function waitForWebEngage(retryOpt, param) {
  var retry = retryOpt !== undefined ? retryOpt : 0;
  var onReady = Belt_Option.map(window.webengage, function (prim0, prim1) {
    prim0.onReady(prim1);
  });
  return new Promise(function (resolve, param) {
    var match = window.webengage;
    var match$1 = retry < 50;
    if (match !== undefined && onReady !== undefined) {
      var webengage = Caml_option.valFromOption(match);
      return Curry._1(onReady, function (param) {
        resolve(webengage);
      });
    }
    if (match$1) {
      setTimeout(function (param) {
        waitForWebEngage(retry + 1 | 0, undefined).then(function (webengage) {
          resolve(webengage);
        });
      }, 100);
      return;
    }
  });
}
function getEventName(eventName) {
  return eventName;
}
function toCategoryDetails(category, productCount, products, param) {
  var match = category.card;
  var tmp;
  switch (match.TAG | 0) {
    case /* ImageLinkCard */0:
      tmp = match._0.imageUrl;
      break;
    case /* VideoLinkCard */1:
      tmp = match._0.fallbackImageUrl;
      break;
    case /* ImageCard */2:
      tmp = match._0.imageUrl;
      break;
    case /* VideoCard */3:
      tmp = match._0.fallbackImageUrl;
      break;
    case /* MedialessCard */4:
    case /* MedialessLinkCard */5:
      tmp = undefined;
      break;
    case /* ImageDisplayCard */6:
      tmp = match._0.imageUrl;
      break;
    case /* VideoDisplayCard */7:
      tmp = match._0.fallbackImageUrl;
      break;
  }
  return {
    Id: Belt_Int.fromString(category.id),
    Image: tmp,
    "Product Count": Belt_Option.getWithDefault(productCount, products.length),
    Source: "maker",
    Title: category.title
  };
}
function toProductDetails(product, variant) {
  var match = variant.media;
  var tmp;
  switch (match.TAG | 0) {
    case /* Image */0:
      tmp = match._0.url;
      break;
    case /* Video */1:
      tmp = match._0.posterUrl;
      break;
    case /* IframeEmbed */2:
      tmp = undefined;
      break;
  }
  var price = variant.price;
  var tmp$1;
  tmp$1 = typeof price === "number" ? 0.0 : price.TAG === /* Regular */0 ? Belt_Option.getWithDefault(Belt_Float.fromString(price._0), 0.0) : Belt_Option.getWithDefault(Belt_Float.fromString(price._0.price), 0.0);
  return {
    Available: variant.availability !== /* OutOfStock */0,
    Currency: product.currency,
    ID: product.id,
    "Variant Id": variant.id,
    Image: tmp,
    Price: tmp$1,
    Source: "maker",
    Title: product.name,
    "Total Variants": product.otherVariants.length + 1 | 0
  };
}
function track(eventName, category, product, variant, productsOpt, productCount, param) {
  var products = productsOpt !== undefined ? productsOpt : [];
  var $$event = eventName === "Product Viewed" ? product !== undefined && variant !== undefined ? {
    NAME: "Product Viewed",
    VAL: toProductDetails(product, variant)
  } : undefined : eventName === "Add Size and Fit" ? product !== undefined && variant !== undefined ? {
    NAME: "Add Size and Fit",
    VAL: toProductDetails(product, variant)
  } : undefined : category !== undefined ? {
    NAME: "Category Viewed",
    VAL: toCategoryDetails(category, productCount, products, undefined)
  } : undefined;
  waitForWebEngage(undefined, undefined).then(function (webengage) {
    if ($$event === undefined) {
      return;
    }
    var variant = $$event.NAME;
    if (variant === "Product Viewed") {
      webengage.track("Product Viewed", $$event.VAL);
    } else if (variant === "Add Size and Fit") {
      webengage.track("Add Size and Fit", $$event.VAL);
    } else {
      webengage.track("Category Viewed", $$event.VAL);
    }
  });
}
export { waitForWebEngage, getEventName, toCategoryDetails, toProductDetails, track };
/* No side effect */
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../rescript/lib/es6/curry.js";
import * as Generic$ApiTypes from "../utils/Generic.bs.js";
import * as DeccoAdapter$ApiTypes from "../utils/DeccoAdapter.bs.js";
import * as Tree_NodeBreakpointCodecs$ApiTypes from "./Tree_NodeBreakpointCodecs.bs.js";

function source_toJson(source) {
  return Generic$ApiTypes.Encode.deccoVariantWithPayload(source._0, "Manual", (function (__x) {
                return Curry._2(Generic$ApiTypes.Encode.array, __x, Tree_NodeBreakpointCodecs$ApiTypes.NodeBreakpoint.node_encode);
              }));
}

var source_fromJson = Curry._2(Generic$ApiTypes.Decode.oneOf, Generic$ApiTypes.Decode.deccoVariantWithPayload("Manual", Curry._1(Generic$ApiTypes.Decode.array, Tree_NodeBreakpointCodecs$ApiTypes.NodeBreakpoint.node_decode), (function (payload) {
            return /* Manual */{
                    _0: payload
                  };
          })), []);

function displayType_toJson(displayType) {
  return Generic$ApiTypes.Encode.simpleVariant([
              [
                "InlineDisplay",
                /* InlineDisplay */0
              ],
              [
                "SliderDisplay",
                /* SliderDisplay */1
              ]
            ], displayType);
}

var displayType_fromJson = Curry._2(Generic$ApiTypes.Decode.oneOf, Generic$ApiTypes.Decode.simpleVariant([[
            "InlineDisplay",
            /* InlineDisplay */0
          ]]), [Generic$ApiTypes.Decode.simpleVariant([[
              "SliderDisplay",
              /* SliderDisplay */1
            ]])]);

function toJson(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, [
              [
                "source",
                source_toJson(param.source)
              ],
              [
                "displayType",
                displayType_toJson(param.displayType)
              ],
              [
                "cardWidth",
                Generic$ApiTypes.Encode.nullable(param.cardWidth, Generic$ApiTypes.Encode.$$int)
              ],
              [
                "cardHeight",
                Generic$ApiTypes.Encode.nullable(param.cardHeight, Generic$ApiTypes.Encode.$$int)
              ],
              [
                "startRowOffset",
                Generic$ApiTypes.Encode.nullable(param.startRowOffset, Generic$ApiTypes.Encode.$$int)
              ]
            ]);
}

var fromJson = Curry._6(Generic$ApiTypes.Decode.map5, Curry._2(Generic$ApiTypes.Decode.field, "source", source_fromJson), Generic$ApiTypes.Decode.fieldWithDefault("displayType", displayType_fromJson, /* InlineDisplay */0), Generic$ApiTypes.Decode.fieldWithDefault("cardWidth", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.$$int), undefined), Generic$ApiTypes.Decode.fieldWithDefault("cardHeight", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.$$int), undefined), Generic$ApiTypes.Decode.fieldWithDefault("startRowOffset", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.$$int), undefined), (function (source, displayType, cardWidth, cardHeight, startRowOffset) {
        return {
                source: source,
                displayType: displayType,
                cardWidth: cardWidth,
                cardHeight: cardHeight,
                startRowOffset: startRowOffset
              };
      }));

var include = DeccoAdapter$ApiTypes.Make({
      toJson: toJson,
      fromJson: fromJson
    });

var TaggedMedia_t_encode = include.t_encode;

var TaggedMedia_t_decode = include.t_decode;

var TaggedMedia_codec = include.codec;

var TaggedMedia = {
  source_toJson: source_toJson,
  source_fromJson: source_fromJson,
  displayType_toJson: displayType_toJson,
  displayType_fromJson: displayType_fromJson,
  toJson: toJson,
  fromJson: fromJson,
  t_encode: TaggedMedia_t_encode,
  t_decode: TaggedMedia_t_decode,
  codec: TaggedMedia_codec
};

export {
  TaggedMedia ,
}
/* source_fromJson Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Shopify$Showside from "../utils/Shopify.bs.js";
import * as StoreCart$Showside from "../utils/StoreCart.bs.js";
import * as StoreCartTypes$Showside from "../utils/StoreCartTypes.bs.js";
import * as GoogleAnalytics$Showside from "../utils/GoogleAnalytics.bs.js";
import * as ConfigurationProvider$Showside from "./ConfigurationProvider.bs.js";
function initialValue_mutateCart(param, param$1) {
  return Promise.resolve(undefined);
}
function initialValue_onUpdateCart(category, param, param$1, param$2) {
  return Promise.resolve(undefined);
}
var initialValue = {
  cart: StoreCartTypes$Showside.empty,
  mutateCart: initialValue_mutateCart,
  showCartDrawer: false,
  setShowCartDrawer: undefined,
  onUpdateCart: initialValue_onUpdateCart,
  storeCheckoutLink: undefined
};
var context = React.createContext(initialValue);
var provider = context.Provider;
function CartContextProvider(Props) {
  var children = Props.children;
  var match = ConfigurationProvider$Showside.use(undefined);
  var match$1 = match.config;
  var account = match$1.transitiveAccount;
  var match$2 = match$1.settings;
  var gaVersion = match$2.gaVersion;
  var gaTrackingId = match$2.gaTrackingId;
  var miniCartEnabled = match$2.cart;
  var checkoutLink = match$2.checkoutLink;
  var match$3 = Shopify$Showside.getShop(undefined);
  var cartSource = match$3 !== undefined ? /* CartSourceShopify */2 : miniCartEnabled ? /* CartSourceLocalStorage */1 : /* CartSourceNoop */0;
  var useStoreCart = StoreCart$Showside.use(cartSource);
  var match$4 = Curry._1(useStoreCart.hook, undefined);
  var cart = Belt_Option.getWithDefault(match$4.data, StoreCartTypes$Showside.empty);
  var match$5 = React.useState(function () {
    return false;
  });
  var setShowCartDrawer = match$5[1];
  var match$6 = React.useState(function () {});
  var setClientId = match$6[1];
  var storeCheckoutLink = Shopify$Showside.getCheckoutLink(cart, match$6[0], match$1.transitiveStore, checkoutLink);
  var updateCart = function (category, product, variant, quantity) {
    Curry._1(setShowCartDrawer, function (param) {
      return true;
    });
    return Curry._2(useStoreCart.updateCart, undefined, function (__x) {
      return StoreCart$Showside.addOrUpdateInCart(__x, product, variant, category, quantity);
    });
  };
  var value_mutateCart = useStoreCart.mutateCart;
  var value_showCartDrawer = match$5[0];
  var value_setShowCartDrawer = miniCartEnabled ? setShowCartDrawer : undefined;
  var value = {
    cart: cart,
    mutateCart: value_mutateCart,
    showCartDrawer: value_showCartDrawer,
    setShowCartDrawer: value_setShowCartDrawer,
    onUpdateCart: updateCart,
    storeCheckoutLink: storeCheckoutLink
  };
  React.useEffect(function () {
    if (account !== undefined) {
      Curry._4(GoogleAnalytics$Showside.Gtag.Linker.getClientId, gaTrackingId, gaVersion, checkoutLink, account).then(function (result) {
        Curry._1(setClientId, function (param) {
          return result;
        });
      });
    }
  }, [account]);
  return React.createElement(provider, {
    value: value,
    children: children
  });
}
CartContextProvider.displayName = "CartContextProvider";
function useCart(param) {
  return React.useContext(context);
}
function useCartProductIds(param) {
  var match = React.useContext(context);
  var mutateCart = match.mutateCart;
  var cart = match.cart;
  React.useEffect(function () {
    Curry._2(mutateCart, function (a) {
      return a;
    }, true);
  }, []);
  var productIds = Belt_Array.map(cart.items, function (i) {
    return i.productId;
  });
  var variantIds = Belt_Array.map(cart.items, function (i) {
    return i.variantId;
  });
  return [productIds, variantIds];
}
var make = CartContextProvider;
export { initialValue, context, provider, make, useCart, useCartProductIds };
/* context Not a pure module */
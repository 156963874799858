// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../rescript/lib/es6/curry.js";
import * as Json__Decode from "../../../../rescript-json/lib/es6_global/src/Json__Decode.bs.js";
import * as Generic$ApiTypes from "./utils/Generic.bs.js";
import * as DeccoAdapter$ApiTypes from "./utils/DeccoAdapter.bs.js";

function toJson(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, [
              [
                "id",
                Curry._1(Generic$ApiTypes.Encode.string, param.id)
              ],
              [
                "x",
                Curry._1(Generic$ApiTypes.Encode.$$int, param.x)
              ],
              [
                "y",
                Curry._1(Generic$ApiTypes.Encode.$$int, param.y)
              ],
              [
                "google_feed_id",
                Curry._1(Generic$ApiTypes.Encode.string, param.productId)
              ],
              [
                "variantId",
                Generic$ApiTypes.Encode.nullable(param.variantId, Generic$ApiTypes.Encode.string)
              ],
              [
                "visible",
                Generic$ApiTypes.Encode.nullable(param.visible, Generic$ApiTypes.Encode.bool)
              ]
            ]);
}

var fromJson = Json__Decode.map6(Json__Decode.field("id", Json__Decode.string), Json__Decode.field("x", Json__Decode.$$int), Json__Decode.field("y", Json__Decode.$$int), Json__Decode.field("google_feed_id", Json__Decode.string), Json__Decode.field("variantId", Json__Decode.nullable(Json__Decode.string)), Json__Decode.field("visible", Json__Decode.nullable(Json__Decode.bool)), (function (id, x, y, productId, variantId, visible) {
        return {
                id: id,
                x: x,
                y: y,
                productId: productId,
                variantId: variantId,
                visible: visible
              };
      }));

var include = DeccoAdapter$ApiTypes.Make({
      toJson: toJson,
      fromJson: fromJson
    });

var t_encode = include.t_encode;

var t_decode = include.t_decode;

var codec = include.codec;

export {
  toJson ,
  fromJson ,
  t_encode ,
  t_decode ,
  codec ,
}
/* fromJson Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as UseSafeTimeout from "../../../../node_modules/@makerinc/use-safe-timeout/lib/es6_global/src/UseSafeTimeout.bs.js";
import * as LCPContext$Showside from "../context/LCPContext.bs.js";
function ConditionalRender$When(Props) {
  var condition = Props.condition;
  var children = Props.children;
  var fallbackOpt = Props.fallback;
  var fallback = fallbackOpt !== undefined ? Caml_option.valFromOption(fallbackOpt) : null;
  if (condition) {
    return children;
  } else {
    return fallback;
  }
}
ConditionalRender$When.displayName = "ConditionalRender.When";
var When = {
  make: ConditionalRender$When
};
function ConditionalRender$WhenSome(Props) {
  var children = Props.children;
  return Belt_Option.getWithDefault(children, null);
}
ConditionalRender$WhenSome.displayName = "ConditionalRender.WhenSome";
var WhenSome = {
  make: ConditionalRender$WhenSome
};
function ConditionalRender$OptionMap(Props) {
  var option = Props.option;
  var conditionOpt = Props.condition;
  var children = Props.children;
  var fallbackOpt = Props.fallback;
  var condition = conditionOpt !== undefined ? conditionOpt : function (param) {
    return true;
  };
  var fallback = fallbackOpt !== undefined ? Caml_option.valFromOption(fallbackOpt) : null;
  if (option === undefined) {
    return fallback;
  }
  var value = Caml_option.valFromOption(option);
  if (Curry._1(condition, value)) {
    return Curry._1(children, value);
  } else {
    return fallback;
  }
}
ConditionalRender$OptionMap.displayName = "ConditionalRender.OptionMap";
var OptionMap = {
  make: ConditionalRender$OptionMap
};
function ConditionalRender$RenderAfterLCP(Props) {
  var children = Props.children;
  var fallbackElementOpt = Props.fallbackElement;
  var fallbackElement = fallbackElementOpt !== undefined ? Caml_option.valFromOption(fallbackElementOpt) : null;
  var match = LCPContext$Showside.use(undefined);
  return React.createElement(ConditionalRender$When, {
    condition: match.isMainImageRendered,
    children: children,
    fallback: fallbackElement
  });
}
ConditionalRender$RenderAfterLCP.displayName = "ConditionalRender.RenderAfterLCP";
var RenderAfterLCP = {
  make: ConditionalRender$RenderAfterLCP
};
function ConditionalRender$Delayed(Props) {
  var delayMs = Props.delayMs;
  var children = Props.children;
  var match = React.useState(function () {
    return false;
  });
  var setCanRender = match[1];
  var match$1 = UseSafeTimeout.useSafeTimeout(undefined, undefined);
  match$1.setTimeout(function (param) {
    Curry._1(setCanRender, function (param) {
      return true;
    });
  }, delayMs);
  if (match[0]) {
    return children;
  } else {
    return null;
  }
}
ConditionalRender$Delayed.displayName = "ConditionalRender.Delayed";
var Delayed = {
  make: ConditionalRender$Delayed
};
export { When, WhenSome, OptionMap, RenderAfterLCP, Delayed };
/* react Not a pure module */
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Rollbar from "../../../../node_modules/rescript-rollbar/lib/es6_global/src/Rollbar.bs.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as RollbarInstance$Showside from "./RollbarInstance.bs.js";
var fromRescriptWithNewMessage = Rollbar.$$Error.fromRescriptWithNewMessage;
class _ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorInfo: null
    };
  }
  componentDidCatch(error, errorInfo) {
    // If we detect that the error is a rescript exception, we try to extract information out of it,
    // and create a new error object with it's RE_EXN_ID as the message.
    if (typeof error.RE_EXN_ID == "string" && typeof error.Error == "object") {
      error = fromRescriptWithNewMessage(error, error.RE_EXN_ID);
    }
    // Do nothing if the error is thrown by parcel script loader.
    // This error usually indicates network issues.
    if (error.message.includes("Failed to fetch dynamically imported module:") || error.message.includes("Cannot find module") || error.message.includes("Could not resolve bundle with id")) {
      return;
    }
    this.setState({
      error,
      errorInfo
    });
    if (this.props.onError) {
      this.props.onError(error, errorInfo);
    }
  }
  render() {
    if (this.state.error) {
      return this.props.renderFallback ? this.props.renderFallback(this.state.error, this.state.errorInfo) : null;
    } else {
      return this.props.children;
    }
  }
}
;
function ErrorBoundary$RawErrorBoundary(Props) {
  return React.createElement(_ErrorBoundary, Props);
}
ErrorBoundary$RawErrorBoundary.displayName = "ErrorBoundary.RawErrorBoundary";
var RawErrorBoundary = {
  make: ErrorBoundary$RawErrorBoundary
};
function noneIfEmpty(str) {
  if (str !== "") {
    return str;
  }
}
function ErrorBoundary$ReportOnce(Props) {
  var error = Props.error;
  var redirect = Props.redirect;
  var componentStack = Props.componentStack;
  var message = Props.message;
  React.useEffect(function () {
    RollbarInstance$Showside.critical2(error, {
      message: message,
      componentStack: componentStack
    });
    var timeout = redirect !== undefined ? Caml_option.some(setTimeout(function (param) {
      Curry._1(redirect, undefined);
    }, 0)) : undefined;
    return function (param) {
      Belt_Option.forEach(timeout, function (id) {
        clearTimeout(id);
      });
    };
  }, []);
  return null;
}
ErrorBoundary$ReportOnce.displayName = "ErrorBoundary.ReportOnce";
var ReportOnce = {
  make: ErrorBoundary$ReportOnce
};
function ErrorBoundary(Props) {
  var componentName = Props.componentName;
  var redirect = Props.redirect;
  var children = Props.children;
  var renderFallback = function (internalError, info) {
    var cause = Rollbar.$$Error.fromRescript(internalError);
    var rootCause = Rollbar.$$Error.getRootCause(cause);
    var rootCauseName = rootCause.name;
    var rootCauseMessage = rootCause.message;
    var culprit = Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(Belt_Option.map(Belt_Option.map(Belt_Option.flatMap(Belt_Option.map(Belt_Option.map(info.componentStack, function (__x) {
      return __x.replace(/\n/g, "");
    }), function (__x) {
      return __x.split(" in ");
    }), function (__x) {
      return Belt_Array.get(__x, 1);
    }), function (__x) {
      return __x.replace(/\(([^\)]+)\)/g, "");
    }), function (prim) {
      return prim.trim();
    }), noneIfEmpty), function (culprit) {
      return "<" + culprit + "> crashed, " + componentName + " caught";
    }), componentName + " caught a crash");
    var rootCauseMessage$1 = rootCauseName !== undefined ? rootCauseMessage !== undefined ? "RC: " + rootCauseName + " " + rootCauseMessage : "RC: " + rootCauseName : rootCauseMessage !== undefined ? "RC: " + rootCauseMessage : undefined;
    var message = Belt_Array.keepMap([culprit, rootCauseMessage$1], function (a) {
      return a;
    }).join(", ");
    var error = Rollbar.$$Error.fromStringWithCause(message, rootCause);
    var tmp = {
      error: error,
      componentStack: info.componentStack,
      message: message
    };
    if (redirect !== undefined) {
      tmp.redirect = Caml_option.valFromOption(redirect);
    }
    return React.createElement(ErrorBoundary$ReportOnce, tmp);
  };
  return React.createElement(ErrorBoundary$RawErrorBoundary, {
    children: children,
    renderFallback: renderFallback
  });
}
ErrorBoundary.displayName = "ErrorBoundary";
var make = ErrorBoundary;
export { fromRescriptWithNewMessage, RawErrorBoundary, noneIfEmpty, ReportOnce, make };
/*  Not a pure module */
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../rescript/lib/es6/curry.js";
import * as Json__Decode from "../../../../../rescript-json/lib/es6_global/src/Json__Decode.bs.js";
import * as Json__Encode from "../../../../../rescript-json/lib/es6_global/src/Json__Encode.bs.js";
import * as Generic$ApiTypes from "../utils/Generic.bs.js";
import * as DeccoAdapter$ApiTypes from "../utils/DeccoAdapter.bs.js";

function entry_encode(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, [
              [
                "id",
                Curry._1(Generic$ApiTypes.Encode.string, param.id)
              ],
              [
                "productId",
                Curry._1(Generic$ApiTypes.Encode.string, param.productId)
              ],
              [
                "variantId",
                Generic$ApiTypes.Encode.nullable(param.variantId, Generic$ApiTypes.Encode.string)
              ]
            ]);
}

var entry_v1_decode = Curry._2(Generic$ApiTypes.Decode.andThen, Generic$ApiTypes.Decode.$$default(Generic$ApiTypes.Decode.string, ""), (function (entry) {
        return Curry._1(Generic$ApiTypes.Decode.succeed, {
                    id: entry,
                    productId: entry,
                    variantId: undefined
                  });
      }));

var entry_decode = Json__Decode.map3(Json__Decode.field("id", Json__Decode.string), Json__Decode.field("productId", Json__Decode.string), Json__Decode.field("variantId", Json__Decode.option(Json__Decode.string)), (function (id, productId, variantId) {
        return {
                id: id,
                productId: productId,
                variantId: variantId
              };
      }));

var entry_decode$1 = Json__Decode.oneOf(entry_decode, [entry_v1_decode]);

function toJson(arr) {
  return Json__Encode.array(arr, entry_encode);
}

var fromJson = Json__Decode.array(Json__Decode.oneOf(entry_decode$1, [entry_v1_decode]));

var include = DeccoAdapter$ApiTypes.Make({
      toJson: toJson,
      fromJson: fromJson
    });

var TaggedProducts_t_encode = include.t_encode;

var TaggedProducts_t_decode = include.t_decode;

var TaggedProducts_codec = include.codec;

var TaggedProducts = {
  entry_encode: entry_encode,
  entry_v1_decode: entry_v1_decode,
  entry_decode: entry_decode$1,
  toJson: toJson,
  fromJson: fromJson,
  t_encode: TaggedProducts_t_encode,
  t_decode: TaggedProducts_t_decode,
  codec: TaggedProducts_codec
};

export {
  TaggedProducts ,
}
/* entry_v1_decode Not a pure module */

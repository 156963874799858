// Generated by ReScript, PLEASE EDIT WITH CARE

import * as FacebookPixel$Showside from "./FacebookPixel.bs.js";
var injectScript = function injectScript(tagId) {
  !function (e) {
    if (!window.pintrk) {
      window.pintrk = function () {
        window.pintrk.queue.push(Array.prototype.slice.call(arguments));
      };
      var n = window.pintrk;
      n.queue = [], n.version = "3.0";
      var t = document.createElement("script");
      t.async = !0, t.src = e;
      var r = document.getElementsByTagName("script")[0];
      r.parentNode.insertBefore(t, r);
    }
  }("https://s.pinimg.com/ct/core.js");
  pintrk('load', tagId);
  pintrk('page');
};
function init(account) {
  var tagId = account.pinterestTagId;
  var match = window.pintrk;
  if (tagId !== undefined && match === undefined) {
    return injectScript(tagId);
  }
}
function track(options, $$event) {
  var pintrk = window.pintrk;
  if (pintrk !== undefined) {
    return pintrk("track", $$event, options);
  }
}
function trackPageView(param) {
  track(undefined, "pagevisit");
}
function trackViewContent(category, product, variant, param) {
  track(undefined, "pagevisit");
  track(undefined, "viewcategory");
}
function trackAddToCart(category, product, variant, quantity, param) {
  var match = FacebookPixel$Showside.paramsFromProduct(product, variant, category);
  track(undefined, "pagevisit");
  track({
    value: match.value,
    order_quantity: quantity,
    currency: match.currency
  }, "addtocart");
}
export { injectScript, init, track, trackPageView, trackViewContent, trackAddToCart };
/* FacebookPixel-Showside Not a pure module */
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as $$Promise from "../../../../node_modules/@ryyppy/rescript-promise/lib/es6_global/src/Promise.bs.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Product$Showside from "../types/Product.bs.js";
import * as Category$Showside from "../types/Category.bs.js";
import * as URLUtils$Showside from "../utils/URLUtils.bs.js";
import * as UseTracking$Showside from "./UseTracking.bs.js";
import * as RouterProvider$Showside from "../context/RouterProvider.bs.js";
import * as GoogleAnalytics$Showside from "../utils/GoogleAnalytics.bs.js";
import * as RollbarInstance$Showside from "../utils/RollbarInstance.bs.js";
import * as ViewportContext$Showside from "../context/ViewportContext.bs.js";
import * as EntrypointContext$Showside from "../context/EntrypointContext.bs.js";
import * as ConfigurationProvider$Showside from "../context/ConfigurationProvider.bs.js";
import * as ExternalIntegrationProvider$Showside from "../context/ExternalIntegrationProvider.bs.js";
import * as PictureInPicture2SessionStorage$Showside from "../utils/PictureInPicture2SessionStorage.bs.js";
function useRouteManipulation(param) {
  var match = RouterProvider$Showside.useRouter(undefined);
  var listPageType = match.listPageType;
  var reset = match.reset;
  var goTo = match.goTo;
  var back = match.back;
  var route = match.route;
  var match$1 = EntrypointContext$Showside.use(undefined);
  var trackEvent = match$1.trackEvent;
  var match$2 = ViewportContext$Showside.use(undefined);
  var isMobile = match$2.isMobile;
  var match$3 = ConfigurationProvider$Showside.use(undefined);
  var config = match$3.config;
  var account = config.transitiveAccount;
  var match$4 = ExternalIntegrationProvider$Showside.use(undefined);
  var externalOnOpenLink = match$4.onOpenLink;
  var routeAsString = RouterProvider$Showside.toString(route);
  var onSmartCardClick = React.useCallback(function (category) {
    var match = category.displayPriority;
    Curry._2(goTo, undefined, {
      TAG: /* ListPage */1,
      _0: {
        listPageType: {
          TAG: /* Category */0,
          _0: category.id
        },
        viewPLP: match ? false : true,
        filters: undefined,
        sort: undefined
      }
    });
  }, [goTo]);
  var onOverlayProductClick = React.useCallback(function (category) {
    return function (parentCategory, product, variant) {
      return Curry._2(goTo, undefined, {
        TAG: /* QuickView */3,
        _0: {
          mediaId: category.id,
          mediaParentId: Belt_Option.map(parentCategory, function (c) {
            return c.id;
          }),
          albumId: undefined,
          productId: product.id,
          relatedMediaProductIds: undefined,
          relatedMediaCategoryId: undefined,
          variantId: Belt_Option.map(variant, function (variant) {
            return variant.id;
          }),
          panelClosed: undefined,
          zoomedPhotoIndex: undefined,
          time: undefined
        }
      });
    };
  }, [goTo]);
  var backInCategoryPath = function (param) {
    var parent = Category$Showside.getCategoryParentById(config.categories, Belt_Option.mapWithDefault(listPageType, "", function (id) {
      if (typeof id === "number" || id.TAG !== /* Category */0) {
        return "";
      } else {
        return id._0;
      }
    }));
    if (typeof route !== "number" && route.TAG === /* ListPage */1) {
      var listPageRoute = route._0;
      if (listPageRoute.viewPLP) {
        return Curry._2(goTo, undefined, {
          TAG: /* ListPage */1,
          _0: {
            listPageType: listPageRoute.listPageType,
            viewPLP: false,
            filters: undefined,
            sort: undefined
          }
        });
      }
      if (parent !== undefined) {
        return Curry._2(goTo, undefined, {
          TAG: /* ListPage */1,
          _0: {
            listPageType: {
              TAG: /* Category */0,
              _0: parent.id
            },
            viewPLP: false,
            filters: undefined,
            sort: undefined
          }
        });
      }
    }
    var isHome;
    isHome = typeof route === "number" || route.TAG !== /* Home */0 ? false : true;
    if (isHome) {
      Curry.app(trackEvent, ["back button", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined]);
    }
    Curry._1(back, undefined);
  };
  var onCloseNavigation = function (param) {
    Curry.app(trackEvent, ["exit click", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined]);
    Curry._1(reset, undefined);
  };
  var getLinkerParam = function (param) {
    return new Promise(function (resolve, reject) {
      var timeout = setTimeout(function (param) {
        resolve(undefined);
      }, 500);
      $$Promise.$$catch(GoogleAnalytics$Showside.getLinkerParam(config.settings.gaTrackingId, config.settings.gaVersion, config.settings.checkoutLink, config.transitiveAccount, undefined), function (param) {
        return Promise.resolve(undefined);
      }).then(function (linkerParam) {
        clearTimeout(timeout);
        resolve(linkerParam);
      });
    }).then(function (linkerParam) {
      var match = config.settings.gaTrackingId;
      if (match !== undefined && linkerParam !== undefined) {
        return linkerParam;
      }
    });
  };
  var appendQueryParams = function (url) {
    var transformedUrl = URLUtils$Showside.maybeMakeUrl(URLUtils$Showside.resolveRelativePath(URLUtils$Showside.addProtocolIfMissing(url)));
    if (transformedUrl === undefined) {
      return Promise.resolve(url);
    }
    var url$1 = Caml_option.valFromOption(transformedUrl);
    var params = url$1.searchParams;
    var urlHasUtmParams = params.has("utm_source");
    var existingUtmParams = Belt_Array.keep(Array.from(new URL(window.location.href).searchParams.entries()), function (param) {
      return param[0].includes("utm_");
    });
    var hasExistingUtmParams = existingUtmParams.length !== 0;
    var match = config.settings.mkrBio;
    if (hasExistingUtmParams) {
      if (urlHasUtmParams) {} else {
        Belt_Array.forEach(existingUtmParams, function (param) {
          params.append(param[0], param[1]);
        });
      }
    } else if (match !== undefined && !urlHasUtmParams) {
      params.append("utm_medium", "referral");
      params.append("utm_source", "mkr.bio");
      params.append("utm_campaign", "mkr.bio");
    }
    return getLinkerParam(undefined).then(function (linkerParam) {
      if (linkerParam !== undefined) {
        params.append(linkerParam[0], linkerParam[1]);
      }
      return url$1.href;
    });
  };
  var redirectToLink = function (openLinkInNewTabOpt, url) {
    var openLinkInNewTab = openLinkInNewTabOpt !== undefined ? openLinkInNewTabOpt : false;
    appendQueryParams(url).then(function (url) {
      if (openLinkInNewTab) {
        window.open(url, "_blank");
      } else {
        window.top.location.href = url;
      }
    });
  };
  var onOpenLink = function (category, index, product, variant, param) {
    var variant$1 = Belt_Option.getWithDefault(variant, product.firstVariant);
    UseTracking$Showside.trackRetargeting(account, config.id, category, product, variant$1, isMobile, index, undefined, 1, undefined, undefined, "select_item");
    PictureInPicture2SessionStorage$Showside.storeLastCategory(route);
    var tmp;
    tmp = typeof route === "number" || route.TAG !== /* QuickView */3 ? undefined : route._0.mediaId;
    Curry.app(trackEvent, ["open link", undefined, undefined, product, variant$1, undefined, undefined, undefined, tmp, undefined, undefined, undefined, undefined, undefined, undefined]);
    if (externalOnOpenLink !== undefined) {
      return Curry._1(externalOnOpenLink, Product$Showside.toLegacyDTO(product, variant$1));
    }
    var match = variant$1.link;
    var match$1 = product.link;
    if (match !== undefined) {
      return redirectToLink(undefined, match);
    } else if (match$1 !== undefined) {
      return redirectToLink(undefined, match$1);
    } else {
      RollbarInstance$Showside.error1(RollbarInstance$Showside.makeError(true, "Couldn't open link because neither product " + product.id + " nor variant " + variant$1.id + " have a link."));
      return;
    }
  };
  return React.useMemo(function () {
    return {
      back: backInCategoryPath,
      onCloseNavigation: onCloseNavigation,
      onOpenLink: onOpenLink,
      onSmartCardClick: onSmartCardClick,
      onOverlayProductClick: onOverlayProductClick,
      redirectToLink: redirectToLink,
      getLinkerParam: getLinkerParam
    };
  }, [routeAsString, trackEvent]);
}
export { useRouteManipulation };
/* react Not a pure module */
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RPC from "./RPC.bs.js";
import * as Curry from "../../../../rescript/lib/es6/curry.js";
import * as React from "react";

function fromString(kind) {
  switch (kind) {
    case "category-card" :
        return "category-card";
    case "clp-quick-categories" :
        return "clp-quick-categories";
    case "first-card" :
        return "first-card";
    case "menubar" :
        return "menubar";
    case "menubar-item" :
        return "menubar-item";
    case "navbar-item" :
        return "navbar-item";
    case "quickview-card" :
        return "quickview-card";
    case "section" :
        return "section";
    case "section-title" :
        return "section-title";
    default:
      return "unknown";
  }
}

function toString(kind) {
  return kind;
}

var Kind = {
  fromString: fromString,
  toString: toString
};

function fromString$1(theme) {
  switch (theme) {
    case "blue" :
        return "blue";
    case "purple" :
        return "purple";
    default:
      return "blue";
  }
}

function toString$1(theme) {
  return theme;
}

var Theme = {
  fromString: fromString$1,
  toString: toString$1
};

function toString$2(command) {
  switch (command) {
    case /* Ready */0 :
        return "SmartNav.In.ready";
    case /* Destroyed */1 :
        return "SmartNav.In.destroyed";
    case /* RefreshConfig */2 :
        return "SmartNav.In.refreshConfig";
    case /* Open */3 :
        return "SmartNav.In.open";
    case /* ScrollTo */4 :
        return "SmartNav.In.scrollTo";
    case /* FocusOnSection */5 :
        return "SmartNav.In.focusOnSection";
    case /* FocusOnCard */6 :
        return "SmartNav.In.focusOnCard";
    case /* Select */7 :
        return "SmartNav.In.select";
    case /* Unselect */8 :
        return "SmartNav.In.unselect";
    case /* Hover */9 :
        return "SmartNav.In.hover";
    case /* Unhover */10 :
        return "SmartNav.In.unhover";
    case /* InspectOn */11 :
        return "SmartNav.In.inspectModeOn";
    case /* InspectOff */12 :
        return "SmartNav.In.inspectModeOff";
    
  }
}

var IncomingCommand = {
  toString: toString$2
};

function toString$3(command) {
  switch (command) {
    case /* Ready */0 :
        return "SmartNav.Out.ready";
    case /* Destroyed */1 :
        return "SmartNav.Out.destroyed";
    case /* RefreshConfig */2 :
        return "SmartNav.Out.refreshConfig";
    case /* Open */3 :
        return "SmartNav.Out.open";
    case /* ScrollTo */4 :
        return "SmartNav.Out.scrollTo";
    case /* FocusOnSection */5 :
        return "SmartNav.Out.focusOnSection";
    case /* FocusOnCard */6 :
        return "SmartNav.Out.focusOnCard";
    case /* Select */7 :
        return "SmartNav.Out.select";
    case /* Unselect */8 :
        return "SmartNav.Out.unselect";
    case /* Hover */9 :
        return "SmartNav.Out.hover";
    case /* Unhover */10 :
        return "SmartNav.Out.unhover";
    case /* InspectOn */11 :
        return "SmartNav.Out.inspectModeOn";
    case /* InspectOff */12 :
        return "SmartNav.Out.inspectModeOff";
    
  }
}

var OutgoingCommand = {
  toString: toString$3
};

var InternalCommandDoNotUse = {
  IncomingCommand: IncomingCommand,
  OutgoingCommand: OutgoingCommand
};

function fromDomRect(rect) {
  var top = rect.top;
  var bottom = rect.bottom;
  var left = rect.left;
  var right = rect.right;
  var width = rect.width;
  var height = rect.height;
  return {
          top: top,
          bottom: bottom,
          left: left,
          right: right,
          width: width,
          height: height
        };
}

var Rect = {
  fromDomRect: fromDomRect
};

var Item = {};

var Types = {
  Kind: Kind,
  Theme: Theme,
  InternalCommandDoNotUse: InternalCommandDoNotUse,
  Rect: Rect,
  Item: Item
};

var OutCommand = {
  toString: toString$3
};

var InCommand = {
  toString: toString$2
};

function useCommandReceiver(debugOpt, command, fn, dependencies) {
  var debug = debugOpt !== undefined ? debugOpt : false;
  React.useEffect((function () {
          var command$1 = toString$2(command);
          var func = function (arg) {
            if (debug) {
              console.log("[IFrame] recieved command " + command$1, arg);
            }
            return Curry._1(fn, arg);
          };
          var register = RPC.ParentWindow.expose1(command$1, func);
          return (function (param) {
                    Curry._1(register.close, undefined);
                  });
        }), dependencies);
}

function sendCommand0(debugOpt, command) {
  var debug = debugOpt !== undefined ? debugOpt : false;
  if (debug) {
    console.log("[IFrame] send command: ", toString$3(command));
  }
  RPC.ParentWindow.caller0(toString$3(command))(undefined);
}

function sendCommand1(debugOpt, command, arg) {
  var debug = debugOpt !== undefined ? debugOpt : false;
  if (debug) {
    console.log("[IFrame] send command: ", toString$3(command), arg);
  }
  RPC.ParentWindow.caller1(toString$3(command))(arg);
}

function sendCommand2(debugOpt, command, arg1, arg2) {
  var debug = debugOpt !== undefined ? debugOpt : false;
  if (debug) {
    console.log("[IFrame] send command: ", toString$3(command), arg1, arg2);
  }
  RPC.ParentWindow.caller2(toString$3(command))(arg1, arg2);
}

var IFrame = {
  OutCommand: OutCommand,
  InCommand: InCommand,
  useCommandReceiver: useCommandReceiver,
  sendCommand0: sendCommand0,
  sendCommand1: sendCommand1,
  sendCommand2: sendCommand2
};

var OutCommand$1 = {
  toString: toString$2
};

var InCommand$1 = {
  toString: toString$3
};

function useCommandReceiver$1(debugOpt, command, fn, dependencies) {
  var debug = debugOpt !== undefined ? debugOpt : false;
  React.useEffect((function () {
          var command$1 = toString$3(command);
          var func = function (arg) {
            if (debug) {
              console.log("[ParentWindow] recieved command " + command$1, arg);
            }
            return Curry._1(fn, arg);
          };
          var register = RPC.ParentWindow.expose1(command$1, func);
          return (function (param) {
                    Curry._1(register.close, undefined);
                  });
        }), dependencies);
}

function sendCommand0$1(debugOpt, command) {
  var debug = debugOpt !== undefined ? debugOpt : false;
  if (debug) {
    console.log("[ParentWindow] send command: ", toString$2(command));
  }
  RPC.ChildIFrames.caller0(toString$2(command))(undefined);
}

function sendCommand1$1(debugOpt, command, arg) {
  var debug = debugOpt !== undefined ? debugOpt : false;
  if (debug) {
    console.log("[ParentWindow] send command: ", toString$2(command), arg);
  }
  RPC.ChildIFrames.caller1(toString$2(command))(arg);
}

function sendCommand2$1(debugOpt, command, arg1, arg2) {
  var debug = debugOpt !== undefined ? debugOpt : false;
  if (debug) {
    console.log("[ParentWindow] send command: ", toString$2(command), arg1, arg2);
  }
  RPC.ChildIFrames.caller2(toString$2(command))(arg1, arg2);
}

var ParentWindow = {
  OutCommand: OutCommand$1,
  InCommand: InCommand$1,
  useCommandReceiver: useCommandReceiver$1,
  sendCommand0: sendCommand0$1,
  sendCommand1: sendCommand1$1,
  sendCommand2: sendCommand2$1
};

export {
  Types ,
  IFrame ,
  ParentWindow ,
}
/* RPC Not a pure module */

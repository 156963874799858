// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json__Encode from "../../../../../rescript-json/lib/es6_global/src/Json__Encode.bs.js";
import * as Store_ProductSortOrderDecoders$ApiTypes from "./Store_ProductSortOrderDecoders.bs.js";

function toJson(sortOrder) {
  switch (sortOrder) {
    case /* Default */0 :
        return Json__Encode.array(["Default"], Json__Encode.string);
    case /* Featured */1 :
        return Json__Encode.array(["Featured"], Json__Encode.string);
    case /* BestSellers */2 :
        return Json__Encode.array(["BestSellers"], Json__Encode.string);
    case /* Newest */3 :
        return Json__Encode.array(["Newest"], Json__Encode.string);
    case /* HighestPrice */4 :
        return Json__Encode.array(["HighestPrice"], Json__Encode.string);
    case /* LowestPrice */5 :
        return Json__Encode.array(["LowestPrice"], Json__Encode.string);
    case /* HighestDiscount */6 :
        return Json__Encode.array(["HighestDiscount"], Json__Encode.string);
    
  }
}

var fromJson = Store_ProductSortOrderDecoders$ApiTypes.ProductSortOrder.fromJson;

var all = Store_ProductSortOrderDecoders$ApiTypes.ProductSortOrder.all;

var fromString = Store_ProductSortOrderDecoders$ApiTypes.ProductSortOrder.fromString;

function toString(sort) {
  switch (sort) {
    case /* Default */0 :
        return "default";
    case /* Featured */1 :
        return "featured";
    case /* BestSellers */2 :
        return "best_sellers";
    case /* Newest */3 :
        return "newest";
    case /* HighestPrice */4 :
        return "highest_price";
    case /* LowestPrice */5 :
        return "lowest_price";
    case /* HighestDiscount */6 :
        return "highest_discount";
    
  }
}

var ProductSortOrder = {
  toJson: toJson,
  fromJson: fromJson,
  all: all,
  fromString: fromString,
  toString: toString
};

export {
  ProductSortOrder ,
}
/* Json__Encode Not a pure module */

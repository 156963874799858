// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Webapi__Dom__Document from "../../../../node_modules/rescript-webapi/lib/es6_global/src/Webapi/Dom/Webapi__Dom__Document.bs.js";
function postMessage(name, data) {
  window.parent.postMessage(name + ("-" + (data + ("-iframeid=" + window.iframeid))), "*");
}
function isEmbed(param) {
  return Belt_Option.isSome(window.parentUrl == null ? undefined : Caml_option.some(window.parentUrl));
}
function getUrl(param) {
  return Belt_Option.getWithDefault(window.parentUrl == null ? undefined : Caml_option.some(window.parentUrl), window.location.href);
}
function getTitle(param) {
  return Belt_Option.getWithDefault(Belt_Option.map(Webapi__Dom__Document.asHtmlDocument(document), function (prim) {
    return prim.title;
  }), "");
}
var excludedDomainsFromHistoryBack = ["rue21.com", "pratapsons.com", "pratapsonsglobal.com", "culpritunderwear.com", "goodearth.in", "whisperinghomes.com"];
function supportsHistoryBack(param) {
  var partial_arg = getUrl(undefined);
  return !Belt_Array.some(excludedDomainsFromHistoryBack, function (param) {
    return partial_arg.includes(param);
  });
}
export { postMessage, isEmbed, getUrl, getTitle, excludedDomainsFromHistoryBack, supportsHistoryBack };
/* Webapi__Dom__Document Not a pure module */
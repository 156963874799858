// Generated by ReScript, PLEASE EDIT WITH CARE

import * as IdFunctor$ApiTypes from "./IdFunctor.bs.js";

var Id = IdFunctor$ApiTypes.Make({});

var t_decode = Id.t_decode;

var t_encode = Id.t_encode;

var toJson = Id.toJson;

var fromJson = Id.fromJson;

var toString = Id.toString;

var fromString = Id.fromString;

export {
  Id ,
  t_decode ,
  t_encode ,
  toJson ,
  fromJson ,
  toString ,
  fromString ,
}
/* Id Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as LegacyShowside_Settings$ApiTypes from "./LegacyShowside_Settings.bs.js";

var empty_categories = [];

var empty = {
  id: "",
  categories: empty_categories,
  settings: LegacyShowside_Settings$ApiTypes.empty,
  transitiveStoreId: undefined,
  transitiveAccount: undefined,
  transitiveStore: undefined,
  accountId: undefined,
  lastModifiedAt: 0.0
};

var Breakpoint2;

export {
  Breakpoint2 ,
  empty ,
}
/* LegacyShowside_Settings-ApiTypes Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "../../../../../rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../../rescript/lib/es6/belt_Option.js";

function make(color) {
  if (/^#(([0-9a-fA-F]{2}){3}|([0-9a-fA-F]){3})$/.test(color)) {
    return {
            TAG: /* Ok */0,
            _0: color
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: "Invalid value for color: " + color
          };
  }
}

function value(color) {
  return color;
}

var Color = {
  $$default: "#ffffff",
  make: make,
  value: value
};

function make$1(pxString) {
  if (/^([0-9]+)px$/.test(pxString)) {
    return {
            TAG: /* Ok */0,
            _0: pxString
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: "Invalid value for Px: " + pxString
          };
  }
}

function value$1(px) {
  return px;
}

var Px = {
  $$default: "#ffffff",
  make: make$1,
  value: value$1
};

function make$2(fontFamilyString) {
  return Belt_Option.mapWithDefault(fontFamilyString, {
              TAG: /* Ok */0,
              _0: undefined
            }, (function (fontFamilyString) {
                if (/^[a-z0-9\-\"\'\,\s\;]+$/i.test(fontFamilyString)) {
                  return {
                          TAG: /* Ok */0,
                          _0: fontFamilyString
                        };
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: "Invalid value for font-family: " + fontFamilyString
                        };
                }
              }));
}

function value$2(fontFamily) {
  var fallback = "sans-serif";
  return Belt_Option.flatMap(fontFamily, (function (fontFamily) {
                return Belt_Option.map(Belt_Array.get(fontFamily.split(";"), 0), (function (fontFamily) {
                              return Belt_Array.concat(Belt_Array.keep(Belt_Array.keepMap(fontFamily.split(/\,[\s]/), (function (s) {
                                                      return s;
                                                    })), (function (s) {
                                                  return s !== fallback;
                                                })), [fallback]).join(",");
                            }));
              }));
}

var FontFamily = {
  $$default: undefined,
  make: make$2,
  value: value$2
};

export {
  Color ,
  Px ,
  FontFamily ,
}
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../rescript/lib/es6/curry.js";
import * as Decco from "../../../../../decco/lib/es6_global/src/Decco.bs.js";
import * as Js_dict from "../../../../../rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../../../rescript/lib/es6/js_json.js";
import * as Js_array from "../../../../../rescript/lib/es6/js_array.js";
import * as Belt_Array from "../../../../../rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../../rescript/lib/es6/belt_Option.js";
import * as StoreId$ApiTypes from "./StoreId.bs.js";
import * as PageMeta$ApiTypes from "../PageMeta.bs.js";

function t_encode(v) {
  switch (v) {
    case /* ShopifyOkendoReviews */0 :
        return ["ShopifyOkendoReviews"];
    case /* ShopifyYotpoReviews */1 :
        return ["ShopifyYotpoReviews"];
    case /* ShopifyLooxReviews */2 :
        return ["ShopifyLooxReviews"];
    case /* ShopifyJudgeMeReviews */3 :
        return ["ShopifyJudgeMeReviews"];
    
  }
}

function t_decode(v) {
  var jsonArr = Js_json.classify(v);
  if (typeof jsonArr === "number") {
    return Decco.error(undefined, "Not a variant", v);
  }
  if (jsonArr.TAG !== /* JSONArray */3) {
    return Decco.error(undefined, "Not a variant", v);
  }
  var jsonArr$1 = jsonArr._0;
  if (jsonArr$1.length === 0) {
    return Decco.error(undefined, "Expected variant, found empty array", v);
  }
  var tagged = Js_array.map(Js_json.classify, jsonArr$1);
  var match = Belt_Array.getExn(tagged, 0);
  if (typeof match !== "number" && match.TAG === /* JSONString */0) {
    switch (match._0) {
      case "ShopifyJudgeMeReviews" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* ShopifyJudgeMeReviews */3
                  };
          }
      case "ShopifyLooxReviews" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* ShopifyLooxReviews */2
                  };
          }
      case "ShopifyOkendoReviews" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* ShopifyOkendoReviews */0
                  };
          }
      case "ShopifyYotpoReviews" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* ShopifyYotpoReviews */1
                  };
          }
      default:
        
    }
  }
  return Decco.error(undefined, "Invalid variant constructor", Belt_Array.getExn(jsonArr$1, 0));
}

var ReviewDataSource = {
  t_encode: t_encode,
  t_decode: t_decode
};

function embeddedApp_encode(v) {
  switch (v) {
    case /* PagesAppEmbed */0 :
        return ["PagesAppEmbed"];
    case /* NavAppEmbed */1 :
        return ["NavAppEmbed"];
    case /* NavAppDevEmbed */2 :
        return ["NavAppDevEmbed"];
    case /* BioAppEmbed */3 :
        return ["BioAppEmbed"];
    case /* BioAppDevEmbed */4 :
        return ["BioAppDevEmbed"];
    case /* NoEmbed */5 :
        return ["NoEmbed"];
    
  }
}

function embeddedApp_decode(v) {
  var jsonArr = Js_json.classify(v);
  if (typeof jsonArr === "number") {
    return Decco.error(undefined, "Not a variant", v);
  }
  if (jsonArr.TAG !== /* JSONArray */3) {
    return Decco.error(undefined, "Not a variant", v);
  }
  var jsonArr$1 = jsonArr._0;
  if (jsonArr$1.length === 0) {
    return Decco.error(undefined, "Expected variant, found empty array", v);
  }
  var tagged = Js_array.map(Js_json.classify, jsonArr$1);
  var match = Belt_Array.getExn(tagged, 0);
  if (typeof match !== "number" && match.TAG === /* JSONString */0) {
    switch (match._0) {
      case "BioAppDevEmbed" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* BioAppDevEmbed */4
                  };
          }
      case "BioAppEmbed" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* BioAppEmbed */3
                  };
          }
      case "NavAppDevEmbed" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* NavAppDevEmbed */2
                  };
          }
      case "NavAppEmbed" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* NavAppEmbed */1
                  };
          }
      case "NoEmbed" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* NoEmbed */5
                  };
          }
      case "PagesAppEmbed" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* PagesAppEmbed */0
                  };
          }
      default:
        
    }
  }
  return Decco.error(undefined, "Invalid variant constructor", Belt_Array.getExn(jsonArr$1, 0));
}

function t_encode$1(v) {
  if (v) {
    return ["Uninstalled"];
  } else {
    return ["Active"];
  }
}

function t_decode$1(v) {
  var jsonArr = Js_json.classify(v);
  if (typeof jsonArr === "number") {
    return Decco.error(undefined, "Not a variant", v);
  }
  if (jsonArr.TAG !== /* JSONArray */3) {
    return Decco.error(undefined, "Not a variant", v);
  }
  var jsonArr$1 = jsonArr._0;
  if (jsonArr$1.length === 0) {
    return Decco.error(undefined, "Expected variant, found empty array", v);
  }
  var tagged = Js_array.map(Js_json.classify, jsonArr$1);
  var match = Belt_Array.getExn(tagged, 0);
  if (typeof match !== "number" && match.TAG === /* JSONString */0) {
    switch (match._0) {
      case "Active" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* Active */0
                  };
          }
      case "Uninstalled" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* Uninstalled */1
                  };
          }
      default:
        
    }
  }
  return Decco.error(undefined, "Invalid variant constructor", Belt_Array.getExn(jsonArr$1, 0));
}

function fromString(str) {
  switch (str) {
    case "active" :
        return /* Active */0;
    case "uninstalled" :
        return /* Uninstalled */1;
    default:
      return /* Active */0;
  }
}

function toString(sort) {
  if (sort) {
    return "uninstalled";
  } else {
    return "active";
  }
}

var Status = {
  t_encode: t_encode$1,
  t_decode: t_decode$1,
  fromString: fromString,
  toString: toString
};

var currentShopifyScopes = [
  "read_products",
  "read_inventory",
  "read_locations",
  "read_metaobjects",
  "read_markets",
  "read_themes",
  "read_publications",
  "write_script_tags",
  "read_script_tags",
  "read_product_listings",
  "unauthenticated_read_checkouts",
  "unauthenticated_read_content",
  "unauthenticated_read_customers",
  "unauthenticated_read_customer_tags",
  "unauthenticated_read_product_tags",
  "unauthenticated_read_selling_plans",
  "unauthenticated_read_product_listings"
];

function t_encode$2(v) {
  return Js_dict.fromArray([
              [
                "storeId",
                Decco.stringToJson(v.storeId)
              ],
              [
                "platform",
                Decco.optionToJson(Decco.stringToJson, v.platform)
              ],
              [
                "platformToken",
                Decco.optionToJson(Decco.stringToJson, v.platformToken)
              ],
              [
                "platformUrl",
                Decco.optionToJson(Decco.stringToJson, v.platformUrl)
              ],
              [
                "reviewDataSource",
                Decco.optionToJson(t_encode, v.reviewDataSource)
              ],
              [
                "tagFacetExtractorRegex",
                Decco.optionToJson(Decco.stringToJson, v.tagFacetExtractorRegex)
              ],
              [
                "embeddedApp",
                embeddedApp_encode(v.embeddedApp)
              ],
              [
                "shopifyStorefrontAccessToken",
                Decco.optionToJson(Decco.stringToJson, v.shopifyStorefrontAccessToken)
              ],
              [
                "status",
                t_encode$1(v.status)
              ],
              [
                "shopifyScopes",
                Decco.arrayToJson(Decco.stringToJson, v.shopifyScopes)
              ]
            ]);
}

function t_decode$2(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var storeId = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "storeId"), null));
  if (storeId.TAG === /* Ok */0) {
    var platform = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "platform"), null));
    if (platform.TAG === /* Ok */0) {
      var platformToken = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "platformToken"), null));
      if (platformToken.TAG === /* Ok */0) {
        var platformUrl = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "platformUrl"), null));
        if (platformUrl.TAG === /* Ok */0) {
          var reviewDataSource = Decco.optionFromJson(t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "reviewDataSource"), null));
          if (reviewDataSource.TAG === /* Ok */0) {
            var tagFacetExtractorRegex = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "tagFacetExtractorRegex"), null));
            if (tagFacetExtractorRegex.TAG === /* Ok */0) {
              var embeddedApp = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict$1, "embeddedApp"), embeddedApp_decode), {
                    TAG: /* Ok */0,
                    _0: /* NavAppEmbed */1
                  });
              if (embeddedApp.TAG === /* Ok */0) {
                var shopifyStorefrontAccessToken = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "shopifyStorefrontAccessToken"), null));
                if (shopifyStorefrontAccessToken.TAG === /* Ok */0) {
                  var status = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict$1, "status"), t_decode$1), {
                        TAG: /* Ok */0,
                        _0: /* Active */0
                      });
                  if (status.TAG === /* Ok */0) {
                    var shopifyScopes = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict$1, "shopifyScopes"), (function (param) {
                                return Decco.arrayFromJson(Decco.stringFromJson, param);
                              })), {
                          TAG: /* Ok */0,
                          _0: []
                        });
                    if (shopifyScopes.TAG === /* Ok */0) {
                      return {
                              TAG: /* Ok */0,
                              _0: {
                                storeId: storeId._0,
                                platform: platform._0,
                                platformToken: platformToken._0,
                                platformUrl: platformUrl._0,
                                reviewDataSource: reviewDataSource._0,
                                tagFacetExtractorRegex: tagFacetExtractorRegex._0,
                                embeddedApp: embeddedApp._0,
                                shopifyStorefrontAccessToken: shopifyStorefrontAccessToken._0,
                                status: status._0,
                                shopifyScopes: shopifyScopes._0
                              }
                            };
                    }
                    var e = shopifyScopes._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".shopifyScopes" + e.path,
                              message: e.message,
                              value: e.value
                            }
                          };
                  }
                  var e$1 = status._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".status" + e$1.path,
                            message: e$1.message,
                            value: e$1.value
                          }
                        };
                }
                var e$2 = shopifyStorefrontAccessToken._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".shopifyStorefrontAccessToken" + e$2.path,
                          message: e$2.message,
                          value: e$2.value
                        }
                      };
              }
              var e$3 = embeddedApp._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".embeddedApp" + e$3.path,
                        message: e$3.message,
                        value: e$3.value
                      }
                    };
            }
            var e$4 = tagFacetExtractorRegex._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".tagFacetExtractorRegex" + e$4.path,
                      message: e$4.message,
                      value: e$4.value
                    }
                  };
          }
          var e$5 = reviewDataSource._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".reviewDataSource" + e$5.path,
                    message: e$5.message,
                    value: e$5.value
                  }
                };
        }
        var e$6 = platformUrl._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".platformUrl" + e$6.path,
                  message: e$6.message,
                  value: e$6.value
                }
              };
      }
      var e$7 = platformToken._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".platformToken" + e$7.path,
                message: e$7.message,
                value: e$7.value
              }
            };
    }
    var e$8 = platform._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".platform" + e$8.path,
              message: e$8.message,
              value: e$8.value
            }
          };
  }
  var e$9 = storeId._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".storeId" + e$9.path,
            message: e$9.message,
            value: e$9.value
          }
        };
}

var codec = [
  t_encode$2,
  t_decode$2
];

function t_encode$3(v) {
  return Js_dict.fromArray([
              [
                "id",
                Decco.stringToJson(v.id)
              ],
              [
                "platform",
                Decco.optionToJson(Decco.stringToJson, v.platform)
              ],
              [
                "platformUrl",
                Decco.optionToJson(Decco.stringToJson, v.platformUrl)
              ],
              [
                "shopifyStorefrontAccessToken",
                Decco.optionToJson(Decco.stringToJson, v.shopifyStorefrontAccessToken)
              ],
              [
                "shopifyScopes",
                Decco.arrayToJson(Decco.stringToJson, v.shopifyScopes)
              ]
            ]);
}

function t_decode$3(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var id = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "id"), null));
  if (id.TAG === /* Ok */0) {
    var platform = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "platform"), null));
    if (platform.TAG === /* Ok */0) {
      var platformUrl = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "platformUrl"), null));
      if (platformUrl.TAG === /* Ok */0) {
        var shopifyStorefrontAccessToken = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "shopifyStorefrontAccessToken"), null));
        if (shopifyStorefrontAccessToken.TAG === /* Ok */0) {
          var shopifyScopes = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict$1, "shopifyScopes"), (function (param) {
                      return Decco.arrayFromJson(Decco.stringFromJson, param);
                    })), {
                TAG: /* Ok */0,
                _0: []
              });
          if (shopifyScopes.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      id: id._0,
                      platform: platform._0,
                      platformUrl: platformUrl._0,
                      shopifyStorefrontAccessToken: shopifyStorefrontAccessToken._0,
                      shopifyScopes: shopifyScopes._0
                    }
                  };
          }
          var e = shopifyScopes._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".shopifyScopes" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = shopifyStorefrontAccessToken._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".shopifyStorefrontAccessToken" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = platformUrl._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".platformUrl" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = platform._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".platform" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = id._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".id" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

var codec$1 = [
  t_encode$3,
  t_decode$3
];

var Public = {
  t_encode: t_encode$3,
  t_decode: t_decode$3,
  codec: codec$1,
  toJson: t_encode$3,
  fromJson: t_decode$3
};

function change_encode(v) {
  if (v.TAG === /* ReviewDataSource */0) {
    return [
            "ReviewDataSource",
            Decco.optionToJson(t_encode, v._0)
          ];
  } else {
    return [
            "Status",
            t_encode$1(v._0)
          ];
  }
}

function change_decode(v) {
  var jsonArr = Js_json.classify(v);
  if (typeof jsonArr === "number") {
    return Decco.error(undefined, "Not a variant", v);
  }
  if (jsonArr.TAG !== /* JSONArray */3) {
    return Decco.error(undefined, "Not a variant", v);
  }
  var jsonArr$1 = jsonArr._0;
  if (jsonArr$1.length === 0) {
    return Decco.error(undefined, "Expected variant, found empty array", v);
  }
  var tagged = Js_array.map(Js_json.classify, jsonArr$1);
  var match = Belt_Array.getExn(tagged, 0);
  if (typeof match !== "number" && match.TAG === /* JSONString */0) {
    switch (match._0) {
      case "ReviewDataSource" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0 = Decco.optionFromJson(t_decode, Belt_Array.getExn(jsonArr$1, 1));
          if (v0.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* ReviewDataSource */0,
                      _0: v0._0
                    }
                  };
          }
          var e = v0._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
      case "Status" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$1 = t_decode$1(Belt_Array.getExn(jsonArr$1, 1));
          if (v0$1.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* Status */1,
                      _0: v0$1._0
                    }
                  };
          }
          var e$1 = v0$1._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
      default:
        
    }
  }
  return Decco.error(undefined, "Invalid variant constructor", Belt_Array.getExn(jsonArr$1, 0));
}

function t_encode$4(v) {
  return Decco.arrayToJson(change_encode, v);
}

function t_decode$4(v) {
  return Decco.arrayFromJson(change_decode, v);
}

var codec$2 = [
  t_encode$4,
  t_decode$4
];

var Patch = {
  change_encode: change_encode,
  change_decode: change_decode,
  t_encode: t_encode$4,
  t_decode: t_decode$4,
  codec: codec$2,
  toJson: t_encode$4,
  fromJson: t_decode$4
};

function t_encode$5(v) {
  switch (v) {
    case /* Default */0 :
        return ["Default"];
    case /* Featured */1 :
        return ["Featured"];
    case /* BestSellers */2 :
        return ["BestSellers"];
    case /* Newest */3 :
        return ["Newest"];
    case /* HighestPrice */4 :
        return ["HighestPrice"];
    case /* LowestPrice */5 :
        return ["LowestPrice"];
    case /* HighestDiscount */6 :
        return ["HighestDiscount"];
    
  }
}

function t_decode$5(v) {
  var jsonArr = Js_json.classify(v);
  if (typeof jsonArr === "number") {
    return Decco.error(undefined, "Not a variant", v);
  }
  if (jsonArr.TAG !== /* JSONArray */3) {
    return Decco.error(undefined, "Not a variant", v);
  }
  var jsonArr$1 = jsonArr._0;
  if (jsonArr$1.length === 0) {
    return Decco.error(undefined, "Expected variant, found empty array", v);
  }
  var tagged = Js_array.map(Js_json.classify, jsonArr$1);
  var match = Belt_Array.getExn(tagged, 0);
  if (typeof match !== "number" && match.TAG === /* JSONString */0) {
    switch (match._0) {
      case "BestSellers" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* BestSellers */2
                  };
          }
      case "Default" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* Default */0
                  };
          }
      case "Featured" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* Featured */1
                  };
          }
      case "HighestDiscount" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* HighestDiscount */6
                  };
          }
      case "HighestPrice" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* HighestPrice */4
                  };
          }
      case "LowestPrice" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* LowestPrice */5
                  };
          }
      case "Newest" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* Newest */3
                  };
          }
      default:
        
    }
  }
  return Decco.error(undefined, "Invalid variant constructor", Belt_Array.getExn(jsonArr$1, 0));
}

var all = [
  /* Default */0,
  /* Featured */1,
  /* BestSellers */2,
  /* Newest */3,
  /* HighestPrice */4,
  /* LowestPrice */5,
  /* HighestDiscount */6
];

function fromString$1(str) {
  switch (str) {
    case "best_sellers" :
        return /* BestSellers */2;
    case "default" :
        return /* Default */0;
    case "featured" :
        return /* Featured */1;
    case "highest_discount" :
        return /* HighestDiscount */6;
    case "highest_price" :
        return /* HighestPrice */4;
    case "lowest_price" :
        return /* LowestPrice */5;
    case "newest" :
        return /* Newest */3;
    default:
      return /* Default */0;
  }
}

function toString$1(sort) {
  switch (sort) {
    case /* Default */0 :
        return "default";
    case /* Featured */1 :
        return "featured";
    case /* BestSellers */2 :
        return "best_sellers";
    case /* Newest */3 :
        return "newest";
    case /* HighestPrice */4 :
        return "highest_price";
    case /* LowestPrice */5 :
        return "lowest_price";
    case /* HighestDiscount */6 :
        return "highest_discount";
    
  }
}

var ProductSortOrder = {
  t_encode: t_encode$5,
  t_decode: t_decode$5,
  all: all,
  fromString: fromString$1,
  toString: toString$1
};

function t_encode$6(v) {
  return Js_dict.fromArray([
              [
                "url",
                Decco.stringToJson(v.url)
              ],
              [
                "size",
                Decco.optionToJson((function (param) {
                        return [
                                Decco.intToJson(param[0]),
                                Decco.intToJson(param[1])
                              ];
                      }), v.size)
              ],
              [
                "altText",
                Decco.optionToJson(Decco.stringToJson, v.altText)
              ]
            ]);
}

function t_decode$6(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var url = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "url"), null));
  if (url.TAG === /* Ok */0) {
    var size = Decco.optionFromJson((function (json) {
            var match = Js_json.classify(json);
            if (typeof match === "number") {
              return Decco.error(undefined, "Not a tuple", json);
            }
            if (match.TAG !== /* JSONArray */3) {
              return Decco.error(undefined, "Not a tuple", json);
            }
            var match$1 = match._0;
            if (match$1.length !== 2) {
              return Decco.error(undefined, "Incorrect cardinality", json);
            }
            var v0 = match$1[0];
            var v1 = match$1[1];
            var match$2 = Decco.intFromJson(v0);
            var match$3 = Decco.intFromJson(v1);
            if (match$2.TAG === /* Ok */0) {
              if (match$3.TAG === /* Ok */0) {
                return {
                        TAG: /* Ok */0,
                        _0: [
                          match$2._0,
                          match$3._0
                        ]
                      };
              }
              var e = match$3._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: "[1]" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = match$2._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: "[0]" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "size"), null));
    if (size.TAG === /* Ok */0) {
      var altText = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "altText"), null));
      if (altText.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  url: url._0,
                  size: size._0,
                  altText: altText._0
                }
              };
      }
      var e = altText._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".altText" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = size._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".size" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = url._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".url" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

var $$Image = {
  t_encode: t_encode$6,
  t_decode: t_decode$6
};

function t_encode$7(v) {
  var param = v.size;
  return Js_dict.fromArray([
              [
                "mimeType",
                Decco.stringToJson(v.mimeType)
              ],
              [
                "size",
                [
                  Decco.intToJson(param[0]),
                  Decco.intToJson(param[1])
                ]
              ],
              [
                "url",
                Decco.stringToJson(v.url)
              ]
            ]);
}

function t_decode$7(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var mimeType = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "mimeType"), null));
  if (mimeType.TAG === /* Ok */0) {
    var json = Belt_Option.getWithDefault(Js_dict.get(dict$1, "size"), null);
    var match = Js_json.classify(json);
    var size;
    if (typeof match === "number" || match.TAG !== /* JSONArray */3) {
      size = Decco.error(undefined, "Not a tuple", json);
    } else {
      var match$1 = match._0;
      if (match$1.length !== 2) {
        size = Decco.error(undefined, "Incorrect cardinality", json);
      } else {
        var v0 = match$1[0];
        var v1 = match$1[1];
        var match$2 = Decco.intFromJson(v0);
        var match$3 = Decco.intFromJson(v1);
        if (match$2.TAG === /* Ok */0) {
          if (match$3.TAG === /* Ok */0) {
            size = {
              TAG: /* Ok */0,
              _0: [
                match$2._0,
                match$3._0
              ]
            };
          } else {
            var e = match$3._0;
            size = {
              TAG: /* Error */1,
              _0: {
                path: "[1]" + e.path,
                message: e.message,
                value: e.value
              }
            };
          }
        } else {
          var e$1 = match$2._0;
          size = {
            TAG: /* Error */1,
            _0: {
              path: "[0]" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
        }
      }
    }
    if (size.TAG === /* Ok */0) {
      var url = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "url"), null));
      if (url.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  mimeType: mimeType._0,
                  size: size._0,
                  url: url._0
                }
              };
      }
      var e$2 = url._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".url" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = size._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".size" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = mimeType._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".mimeType" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

var VideoSource = {
  t_encode: t_encode$7,
  t_decode: t_decode$7
};

function t_encode$8(v) {
  return Js_dict.fromArray([
              [
                "poster",
                Decco.optionToJson(Decco.stringToJson, v.poster)
              ],
              [
                "sources",
                Decco.arrayToJson(t_encode$7, v.sources)
              ]
            ]);
}

function t_decode$8(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var poster = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "poster"), null));
  if (poster.TAG === /* Ok */0) {
    var sources = Decco.arrayFromJson(t_decode$7, Belt_Option.getWithDefault(Js_dict.get(dict$1, "sources"), null));
    if (sources.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                poster: poster._0,
                sources: sources._0
              }
            };
    }
    var e = sources._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".sources" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = poster._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".poster" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var Video = {
  t_encode: t_encode$8,
  t_decode: t_decode$8
};

function t_encode$9(v) {
  if (v.TAG === /* TypeImage */0) {
    return [
            "TypeImage",
            t_encode$6(v._0)
          ];
  } else {
    return [
            "TypeVideo",
            t_encode$8(v._0)
          ];
  }
}

function t_decode$9(v) {
  var jsonArr = Js_json.classify(v);
  if (typeof jsonArr === "number") {
    return Decco.error(undefined, "Not a variant", v);
  }
  if (jsonArr.TAG !== /* JSONArray */3) {
    return Decco.error(undefined, "Not a variant", v);
  }
  var jsonArr$1 = jsonArr._0;
  if (jsonArr$1.length === 0) {
    return Decco.error(undefined, "Expected variant, found empty array", v);
  }
  var tagged = Js_array.map(Js_json.classify, jsonArr$1);
  var match = Belt_Array.getExn(tagged, 0);
  if (typeof match !== "number" && match.TAG === /* JSONString */0) {
    switch (match._0) {
      case "TypeImage" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0 = t_decode$6(Belt_Array.getExn(jsonArr$1, 1));
          if (v0.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* TypeImage */0,
                      _0: v0._0
                    }
                  };
          }
          var e = v0._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
      case "TypeVideo" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$1 = t_decode$8(Belt_Array.getExn(jsonArr$1, 1));
          if (v0$1.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* TypeVideo */1,
                      _0: v0$1._0
                    }
                  };
          }
          var e$1 = v0$1._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
      default:
        
    }
  }
  return Decco.error(undefined, "Invalid variant constructor", Belt_Array.getExn(jsonArr$1, 0));
}

var Media = {
  t_encode: t_encode$9,
  t_decode: t_decode$9
};

function t_encode$10(v) {
  return Js_dict.fromArray([
              [
                "page",
                Curry._1(PageMeta$ApiTypes.t_encode, v.page)
              ],
              [
                "media",
                Decco.arrayToJson(t_encode$9, v.media)
              ]
            ]);
}

function t_decode$10(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var page = Curry._1(PageMeta$ApiTypes.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "page"), null));
  if (page.TAG === /* Ok */0) {
    var media = Decco.arrayFromJson(t_decode$9, Belt_Option.getWithDefault(Js_dict.get(dict$1, "media"), null));
    if (media.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                page: page._0,
                media: media._0
              }
            };
    }
    var e = media._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".media" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = page._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".page" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var $$MediaList = {
  t_encode: t_encode$10,
  t_decode: t_decode$10
};

function t_encode$11(v) {
  return Js_dict.fromArray([
              [
                "name",
                Decco.stringToJson(v.name)
              ],
              [
                "contentUrl",
                Decco.stringToJson(v.contentUrl)
              ],
              [
                "thumbnailUrl",
                Decco.stringToJson(v.thumbnailUrl)
              ]
            ]);
}

function t_decode$11(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var name = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "name"), null));
  if (name.TAG === /* Ok */0) {
    var contentUrl = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "contentUrl"), null));
    if (contentUrl.TAG === /* Ok */0) {
      var thumbnailUrl = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "thumbnailUrl"), null));
      if (thumbnailUrl.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  name: name._0,
                  contentUrl: contentUrl._0,
                  thumbnailUrl: thumbnailUrl._0
                }
              };
      }
      var e = thumbnailUrl._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".thumbnailUrl" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = contentUrl._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".contentUrl" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = name._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".name" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

var $$Audio = {
  t_encode: t_encode$11,
  t_decode: t_decode$11
};

function t_encode$12(v) {
  return Js_dict.fromArray([
              [
                "count",
                Decco.intToJson(v.count)
              ],
              [
                "average",
                Decco.floatToJson(v.average)
              ]
            ]);
}

function t_decode$12(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var count = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "count"), null));
  if (count.TAG === /* Ok */0) {
    var average = Decco.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "average"), null));
    if (average.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                count: count._0,
                average: average._0
              }
            };
    }
    var e = average._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".average" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = count._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".count" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var ProductReviewSummary = {
  t_encode: t_encode$12,
  t_decode: t_decode$12
};

function t_encode$13(v) {
  return Js_dict.fromArray([
              [
                "amount",
                Decco.stringToJson(v.amount)
              ],
              [
                "currencyCode",
                Decco.stringToJson(v.currencyCode)
              ]
            ]);
}

function t_decode$13(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var amount = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "amount"), null));
  if (amount.TAG === /* Ok */0) {
    var currencyCode = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "currencyCode"), null));
    if (currencyCode.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                amount: amount._0,
                currencyCode: currencyCode._0
              }
            };
    }
    var e = currencyCode._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".currencyCode" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = amount._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".amount" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var Money = {
  t_encode: t_encode$13,
  t_decode: t_decode$13
};

function t_encode$14(v) {
  return Js_dict.fromArray([
              [
                "unitName",
                Decco.stringToJson(v.unitName)
              ],
              [
                "value",
                Decco.floatToJson(v.value)
              ]
            ]);
}

function t_decode$14(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var unitName = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "unitName"), null));
  if (unitName.TAG === /* Ok */0) {
    var value = Decco.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "value"), null));
    if (value.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                unitName: unitName._0,
                value: value._0
              }
            };
    }
    var e = value._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".value" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = unitName._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".unitName" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var PhysicalQuantity = {
  t_encode: t_encode$14,
  t_decode: t_decode$14
};

function t_encode$15(v) {
  return Js_dict.fromArray([
              [
                "value",
                Decco.floatToJson(v.value)
              ],
              [
                "scaleMin",
                Decco.floatToJson(v.scaleMin)
              ],
              [
                "scaleMax",
                Decco.floatToJson(v.scaleMax)
              ]
            ]);
}

function t_decode$15(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var value = Decco.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "value"), null));
  if (value.TAG === /* Ok */0) {
    var scaleMin = Decco.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "scaleMin"), null));
    if (scaleMin.TAG === /* Ok */0) {
      var scaleMax = Decco.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "scaleMax"), null));
      if (scaleMax.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  value: value._0,
                  scaleMin: scaleMin._0,
                  scaleMax: scaleMax._0
                }
              };
      }
      var e = scaleMax._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".scaleMax" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = scaleMin._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".scaleMin" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = value._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".value" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

var Rating = {
  t_encode: t_encode$15,
  t_decode: t_decode$15
};

function t_encode$16(v) {
  return Js_dict.fromArray([
              [
                "text",
                Decco.stringToJson(v.text)
              ],
              [
                "url",
                Decco.stringToJson(v.url)
              ]
            ]);
}

function t_decode$16(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var text = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "text"), null));
  if (text.TAG === /* Ok */0) {
    var url = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "url"), null));
    if (url.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                text: text._0,
                url: url._0
              }
            };
    }
    var e = url._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".url" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = text._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".text" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var Link = {
  t_encode: t_encode$16,
  t_decode: t_decode$16
};

function t_encode$17(v) {
  if (typeof v === "number") {
    return ["Unsupported"];
  }
  switch (v.TAG | 0) {
    case /* MultiLineTextField */0 :
        return [
                "MultiLineTextField",
                Decco.stringToJson(v._0)
              ];
    case /* SingleLineTextField */1 :
        return [
                "SingleLineTextField",
                Decco.stringToJson(v._0)
              ];
    case /* DecimalField */2 :
        return [
                "DecimalField",
                Decco.floatToJson(v._0)
              ];
    case /* IntegerField */3 :
        return [
                "IntegerField",
                Decco.floatToJson(v._0)
              ];
    case /* Dimension */4 :
        return [
                "Dimension",
                t_encode$14(v._0)
              ];
    case /* Date */5 :
        return [
                "Date",
                Decco.stringToJson(v._0)
              ];
    case /* DateTime */6 :
        return [
                "DateTime",
                Decco.stringToJson(v._0)
              ];
    case /* Color */7 :
        return [
                "Color",
                Decco.stringToJson(v._0)
              ];
    case /* Boolean */8 :
        return [
                "Boolean",
                Decco.boolToJson(v._0)
              ];
    case /* Json */9 :
        return [
                "Json",
                v._0
              ];
    case /* Money */10 :
        return [
                "Money",
                t_encode$13(v._0)
              ];
    case /* Volume */11 :
        return [
                "Volume",
                t_encode$14(v._0)
              ];
    case /* Weight */12 :
        return [
                "Weight",
                t_encode$14(v._0)
              ];
    case /* Url */13 :
        return [
                "Url",
                Decco.stringToJson(v._0)
              ];
    case /* Rating */14 :
        return [
                "Rating",
                Decco.stringToJson(v._0)
              ];
    case /* FileReference */15 :
        return [
                "FileReference",
                t_encode$9(v._0)
              ];
    case /* Audio */16 :
        return [
                "Audio",
                t_encode$11(v._0)
              ];
    case /* ListColor */17 :
        return [
                "ListColor",
                Decco.arrayToJson(Decco.stringToJson, v._0)
              ];
    case /* ListDimension */18 :
        return [
                "ListDimension",
                Decco.arrayToJson(t_encode$14, v._0)
              ];
    case /* ListLink */19 :
        return [
                "ListLink",
                Decco.arrayToJson(t_encode$16, v._0)
              ];
    case /* ListRating */20 :
        return [
                "ListRating",
                Decco.arrayToJson(t_encode$15, v._0)
              ];
    case /* ListSingleLineTextField */21 :
        return [
                "ListSingleLineTextField",
                Decco.arrayToJson(Decco.stringToJson, v._0)
              ];
    case /* ListUrl */22 :
        return [
                "ListUrl",
                Decco.arrayToJson(Decco.stringToJson, v._0)
              ];
    case /* ListVolume */23 :
        return [
                "ListVolume",
                Decco.arrayToJson(t_encode$14, v._0)
              ];
    case /* ListWeight */24 :
        return [
                "ListWeight",
                Decco.arrayToJson(t_encode$14, v._0)
              ];
    case /* ListNumberDecimal */25 :
        return [
                "ListNumberDecimal",
                Decco.arrayToJson(Decco.floatToJson, v._0)
              ];
    case /* ListNumberInteger */26 :
        return [
                "ListNumberInteger",
                Decco.arrayToJson(Decco.floatToJson, v._0)
              ];
    case /* ListProductReference */27 :
        return [
                "ListProductReference",
                Decco.arrayToJson(StoreId$ApiTypes.ProductId.t_encode, v._0)
              ];
    
  }
}

function t_decode$17(v) {
  var jsonArr = Js_json.classify(v);
  if (typeof jsonArr === "number") {
    return Decco.error(undefined, "Not a variant", v);
  }
  if (jsonArr.TAG !== /* JSONArray */3) {
    return Decco.error(undefined, "Not a variant", v);
  }
  var jsonArr$1 = jsonArr._0;
  if (jsonArr$1.length === 0) {
    return Decco.error(undefined, "Expected variant, found empty array", v);
  }
  var tagged = Js_array.map(Js_json.classify, jsonArr$1);
  var match = Belt_Array.getExn(tagged, 0);
  if (typeof match !== "number" && match.TAG === /* JSONString */0) {
    switch (match._0) {
      case "Audio" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0 = t_decode$11(Belt_Array.getExn(jsonArr$1, 1));
          if (v0.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* Audio */16,
                      _0: v0._0
                    }
                  };
          }
          var e = v0._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
      case "Boolean" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$1 = Decco.boolFromJson(Belt_Array.getExn(jsonArr$1, 1));
          if (v0$1.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* Boolean */8,
                      _0: v0$1._0
                    }
                  };
          }
          var e$1 = v0$1._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
      case "Color" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$2 = Decco.stringFromJson(Belt_Array.getExn(jsonArr$1, 1));
          if (v0$2.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* Color */7,
                      _0: v0$2._0
                    }
                  };
          }
          var e$2 = v0$2._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
      case "Date" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$3 = Decco.stringFromJson(Belt_Array.getExn(jsonArr$1, 1));
          if (v0$3.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* Date */5,
                      _0: v0$3._0
                    }
                  };
          }
          var e$3 = v0$3._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$3.path,
                    message: e$3.message,
                    value: e$3.value
                  }
                };
      case "DateTime" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$4 = Decco.stringFromJson(Belt_Array.getExn(jsonArr$1, 1));
          if (v0$4.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* DateTime */6,
                      _0: v0$4._0
                    }
                  };
          }
          var e$4 = v0$4._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$4.path,
                    message: e$4.message,
                    value: e$4.value
                  }
                };
      case "DecimalField" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$5 = Decco.floatFromJson(Belt_Array.getExn(jsonArr$1, 1));
          if (v0$5.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* DecimalField */2,
                      _0: v0$5._0
                    }
                  };
          }
          var e$5 = v0$5._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$5.path,
                    message: e$5.message,
                    value: e$5.value
                  }
                };
      case "Dimension" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$6 = t_decode$14(Belt_Array.getExn(jsonArr$1, 1));
          if (v0$6.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* Dimension */4,
                      _0: v0$6._0
                    }
                  };
          }
          var e$6 = v0$6._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$6.path,
                    message: e$6.message,
                    value: e$6.value
                  }
                };
      case "FileReference" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$7 = t_decode$9(Belt_Array.getExn(jsonArr$1, 1));
          if (v0$7.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* FileReference */15,
                      _0: v0$7._0
                    }
                  };
          }
          var e$7 = v0$7._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$7.path,
                    message: e$7.message,
                    value: e$7.value
                  }
                };
      case "IntegerField" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$8 = Decco.floatFromJson(Belt_Array.getExn(jsonArr$1, 1));
          if (v0$8.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* IntegerField */3,
                      _0: v0$8._0
                    }
                  };
          }
          var e$8 = v0$8._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$8.path,
                    message: e$8.message,
                    value: e$8.value
                  }
                };
      case "Json" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$9 = {
            TAG: /* Ok */0,
            _0: Belt_Array.getExn(jsonArr$1, 1)
          };
          if (v0$9.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* Json */9,
                      _0: v0$9._0
                    }
                  };
          }
          var e$9 = v0$9._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$9.path,
                    message: e$9.message,
                    value: e$9.value
                  }
                };
      case "ListColor" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$10 = Decco.arrayFromJson(Decco.stringFromJson, Belt_Array.getExn(jsonArr$1, 1));
          if (v0$10.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* ListColor */17,
                      _0: v0$10._0
                    }
                  };
          }
          var e$10 = v0$10._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$10.path,
                    message: e$10.message,
                    value: e$10.value
                  }
                };
      case "ListDimension" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$11 = Decco.arrayFromJson(t_decode$14, Belt_Array.getExn(jsonArr$1, 1));
          if (v0$11.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* ListDimension */18,
                      _0: v0$11._0
                    }
                  };
          }
          var e$11 = v0$11._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$11.path,
                    message: e$11.message,
                    value: e$11.value
                  }
                };
      case "ListLink" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$12 = Decco.arrayFromJson(t_decode$16, Belt_Array.getExn(jsonArr$1, 1));
          if (v0$12.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* ListLink */19,
                      _0: v0$12._0
                    }
                  };
          }
          var e$12 = v0$12._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$12.path,
                    message: e$12.message,
                    value: e$12.value
                  }
                };
      case "ListNumberDecimal" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$13 = Decco.arrayFromJson(Decco.floatFromJson, Belt_Array.getExn(jsonArr$1, 1));
          if (v0$13.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* ListNumberDecimal */25,
                      _0: v0$13._0
                    }
                  };
          }
          var e$13 = v0$13._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$13.path,
                    message: e$13.message,
                    value: e$13.value
                  }
                };
      case "ListNumberInteger" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$14 = Decco.arrayFromJson(Decco.floatFromJson, Belt_Array.getExn(jsonArr$1, 1));
          if (v0$14.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* ListNumberInteger */26,
                      _0: v0$14._0
                    }
                  };
          }
          var e$14 = v0$14._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$14.path,
                    message: e$14.message,
                    value: e$14.value
                  }
                };
      case "ListProductReference" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$15 = Decco.arrayFromJson(StoreId$ApiTypes.ProductId.t_decode, Belt_Array.getExn(jsonArr$1, 1));
          if (v0$15.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* ListProductReference */27,
                      _0: v0$15._0
                    }
                  };
          }
          var e$15 = v0$15._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$15.path,
                    message: e$15.message,
                    value: e$15.value
                  }
                };
      case "ListRating" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$16 = Decco.arrayFromJson(t_decode$15, Belt_Array.getExn(jsonArr$1, 1));
          if (v0$16.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* ListRating */20,
                      _0: v0$16._0
                    }
                  };
          }
          var e$16 = v0$16._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$16.path,
                    message: e$16.message,
                    value: e$16.value
                  }
                };
      case "ListSingleLineTextField" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$17 = Decco.arrayFromJson(Decco.stringFromJson, Belt_Array.getExn(jsonArr$1, 1));
          if (v0$17.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* ListSingleLineTextField */21,
                      _0: v0$17._0
                    }
                  };
          }
          var e$17 = v0$17._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$17.path,
                    message: e$17.message,
                    value: e$17.value
                  }
                };
      case "ListUrl" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$18 = Decco.arrayFromJson(Decco.stringFromJson, Belt_Array.getExn(jsonArr$1, 1));
          if (v0$18.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* ListUrl */22,
                      _0: v0$18._0
                    }
                  };
          }
          var e$18 = v0$18._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$18.path,
                    message: e$18.message,
                    value: e$18.value
                  }
                };
      case "ListVolume" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$19 = Decco.arrayFromJson(t_decode$14, Belt_Array.getExn(jsonArr$1, 1));
          if (v0$19.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* ListVolume */23,
                      _0: v0$19._0
                    }
                  };
          }
          var e$19 = v0$19._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$19.path,
                    message: e$19.message,
                    value: e$19.value
                  }
                };
      case "ListWeight" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$20 = Decco.arrayFromJson(t_decode$14, Belt_Array.getExn(jsonArr$1, 1));
          if (v0$20.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* ListWeight */24,
                      _0: v0$20._0
                    }
                  };
          }
          var e$20 = v0$20._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$20.path,
                    message: e$20.message,
                    value: e$20.value
                  }
                };
      case "Money" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$21 = t_decode$13(Belt_Array.getExn(jsonArr$1, 1));
          if (v0$21.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* Money */10,
                      _0: v0$21._0
                    }
                  };
          }
          var e$21 = v0$21._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$21.path,
                    message: e$21.message,
                    value: e$21.value
                  }
                };
      case "MultiLineTextField" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$22 = Decco.stringFromJson(Belt_Array.getExn(jsonArr$1, 1));
          if (v0$22.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* MultiLineTextField */0,
                      _0: v0$22._0
                    }
                  };
          }
          var e$22 = v0$22._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$22.path,
                    message: e$22.message,
                    value: e$22.value
                  }
                };
      case "Rating" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$23 = Decco.stringFromJson(Belt_Array.getExn(jsonArr$1, 1));
          if (v0$23.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* Rating */14,
                      _0: v0$23._0
                    }
                  };
          }
          var e$23 = v0$23._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$23.path,
                    message: e$23.message,
                    value: e$23.value
                  }
                };
      case "SingleLineTextField" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$24 = Decco.stringFromJson(Belt_Array.getExn(jsonArr$1, 1));
          if (v0$24.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* SingleLineTextField */1,
                      _0: v0$24._0
                    }
                  };
          }
          var e$24 = v0$24._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$24.path,
                    message: e$24.message,
                    value: e$24.value
                  }
                };
      case "Unsupported" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* Unsupported */0
                  };
          }
      case "Url" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$25 = Decco.stringFromJson(Belt_Array.getExn(jsonArr$1, 1));
          if (v0$25.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* Url */13,
                      _0: v0$25._0
                    }
                  };
          }
          var e$25 = v0$25._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$25.path,
                    message: e$25.message,
                    value: e$25.value
                  }
                };
      case "Volume" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$26 = t_decode$14(Belt_Array.getExn(jsonArr$1, 1));
          if (v0$26.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* Volume */11,
                      _0: v0$26._0
                    }
                  };
          }
          var e$26 = v0$26._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$26.path,
                    message: e$26.message,
                    value: e$26.value
                  }
                };
      case "Weight" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$27 = t_decode$14(Belt_Array.getExn(jsonArr$1, 1));
          if (v0$27.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* Weight */12,
                      _0: v0$27._0
                    }
                  };
          }
          var e$27 = v0$27._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$27.path,
                    message: e$27.message,
                    value: e$27.value
                  }
                };
      default:
        
    }
  }
  return Decco.error(undefined, "Invalid variant constructor", Belt_Array.getExn(jsonArr$1, 0));
}

var Metadata = {
  t_encode: t_encode$17,
  t_decode: t_decode$17
};

function t_encode$18(v) {
  return Js_dict.fromArray([
              [
                "id",
                Decco.stringToJson(v.id)
              ],
              [
                "name",
                Decco.stringToJson(v.name)
              ],
              [
                "values",
                Decco.arrayToJson(Decco.stringToJson, v.values)
              ]
            ]);
}

function t_decode$18(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var id = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "id"), null));
  if (id.TAG === /* Ok */0) {
    var name = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "name"), null));
    if (name.TAG === /* Ok */0) {
      var values = Decco.arrayFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "values"), null));
      if (values.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  id: id._0,
                  name: name._0,
                  values: values._0
                }
              };
      }
      var e = values._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".values" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = name._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".name" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = id._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".id" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

var $$Option = {
  t_encode: t_encode$18,
  t_decode: t_decode$18
};

function t_encode$19(v) {
  return Js_dict.fromArray([[
                "quantity",
                Decco.optionToJson(Decco.intToJson, v.quantity)
              ]]);
}

function t_decode$19(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var quantity = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict._0, "quantity"), null));
  if (quantity.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              quantity: quantity._0
            }
          };
  }
  var e = quantity._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".quantity" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var Inventory = {
  t_encode: t_encode$19,
  t_decode: t_decode$19
};

function t_encode$20(v) {
  return Js_dict.fromArray([
              [
                "id",
                Decco.stringToJson(v.id)
              ],
              [
                "name",
                Decco.optionToJson(Decco.stringToJson, v.name)
              ],
              [
                "description",
                Decco.optionToJson(Decco.stringToJson, v.description)
              ],
              [
                "price",
                Decco.optionToJson(Decco.stringToJson, v.price)
              ],
              [
                "price2",
                Decco.optionToJson(Decco.stringToJson, v.price2)
              ],
              [
                "compareAtPrice",
                Decco.optionToJson(Decco.stringToJson, v.compareAtPrice)
              ],
              [
                "compareAtPrice2",
                Decco.optionToJson(Decco.stringToJson, v.compareAtPrice2)
              ],
              [
                "sku",
                Decco.optionToJson(Decco.stringToJson, v.sku)
              ],
              [
                "imageId",
                Decco.optionToJson(Decco.stringToJson, v.imageId)
              ],
              [
                "mediaIndex",
                Decco.optionToJson(Decco.intToJson, v.mediaIndex)
              ],
              [
                "mediaIndexesSourcedFromVariant",
                Decco.optionToJson((function (param) {
                        return Decco.arrayToJson(Decco.intToJson, param);
                      }), v.mediaIndexesSourcedFromVariant)
              ],
              [
                "option1",
                Decco.optionToJson(Decco.stringToJson, v.option1)
              ],
              [
                "option2",
                Decco.optionToJson(Decco.stringToJson, v.option2)
              ],
              [
                "option3",
                Decco.optionToJson(Decco.stringToJson, v.option3)
              ],
              [
                "inventory",
                t_encode$19(v.inventory)
              ],
              [
                "inventory2",
                t_encode$19(v.inventory2)
              ],
              [
                "link",
                Decco.optionToJson(Decco.stringToJson, v.link)
              ],
              [
                "customLabels",
                Decco.arrayToJson((function (param) {
                        return Decco.optionToJson(Decco.stringToJson, param);
                      }), v.customLabels)
              ],
              [
                "brand",
                Decco.optionToJson(Decco.stringToJson, v.brand)
              ],
              [
                "mpn",
                Decco.optionToJson(Decco.stringToJson, v.mpn)
              ],
              [
                "promoText",
                Decco.optionToJson(Decco.stringToJson, v.promoText)
              ],
              [
                "metadata",
                Decco.dictToJson(t_encode$17, v.metadata)
              ]
            ]);
}

function t_decode$20(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var id = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "id"), null));
  if (id.TAG === /* Ok */0) {
    var name = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "name"), null));
    if (name.TAG === /* Ok */0) {
      var description = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "description"), null));
      if (description.TAG === /* Ok */0) {
        var price = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "price"), null));
        if (price.TAG === /* Ok */0) {
          var price2 = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "price2"), null));
          if (price2.TAG === /* Ok */0) {
            var compareAtPrice = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "compareAtPrice"), null));
            if (compareAtPrice.TAG === /* Ok */0) {
              var compareAtPrice2 = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "compareAtPrice2"), null));
              if (compareAtPrice2.TAG === /* Ok */0) {
                var sku = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "sku"), null));
                if (sku.TAG === /* Ok */0) {
                  var imageId = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "imageId"), null));
                  if (imageId.TAG === /* Ok */0) {
                    var mediaIndex = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "mediaIndex"), null));
                    if (mediaIndex.TAG === /* Ok */0) {
                      var mediaIndexesSourcedFromVariant = Decco.optionFromJson((function (param) {
                              return Decco.arrayFromJson(Decco.intFromJson, param);
                            }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "mediaIndexesSourcedFromVariant"), null));
                      if (mediaIndexesSourcedFromVariant.TAG === /* Ok */0) {
                        var option1 = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "option1"), null));
                        if (option1.TAG === /* Ok */0) {
                          var option2 = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "option2"), null));
                          if (option2.TAG === /* Ok */0) {
                            var option3 = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "option3"), null));
                            if (option3.TAG === /* Ok */0) {
                              var inventory = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict$1, "inventory"), t_decode$19), {
                                    TAG: /* Ok */0,
                                    _0: {
                                      quantity: undefined
                                    }
                                  });
                              if (inventory.TAG === /* Ok */0) {
                                var inventory2 = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict$1, "inventory2"), t_decode$19), {
                                      TAG: /* Ok */0,
                                      _0: {
                                        quantity: undefined
                                      }
                                    });
                                if (inventory2.TAG === /* Ok */0) {
                                  var link = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "link"), null));
                                  if (link.TAG === /* Ok */0) {
                                    var customLabels = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict$1, "customLabels"), (function (param) {
                                                return Decco.arrayFromJson((function (param) {
                                                              return Decco.optionFromJson(Decco.stringFromJson, param);
                                                            }), param);
                                              })), {
                                          TAG: /* Ok */0,
                                          _0: []
                                        });
                                    if (customLabels.TAG === /* Ok */0) {
                                      var brand = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "brand"), null));
                                      if (brand.TAG === /* Ok */0) {
                                        var mpn = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "mpn"), null));
                                        if (mpn.TAG === /* Ok */0) {
                                          var promoText = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "promoText"), null));
                                          if (promoText.TAG === /* Ok */0) {
                                            var metadata = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict$1, "metadata"), (function (param) {
                                                        return Decco.dictFromJson(t_decode$17, param);
                                                      })), {
                                                  TAG: /* Ok */0,
                                                  _0: {}
                                                });
                                            if (metadata.TAG === /* Ok */0) {
                                              return {
                                                      TAG: /* Ok */0,
                                                      _0: {
                                                        id: id._0,
                                                        name: name._0,
                                                        description: description._0,
                                                        price: price._0,
                                                        price2: price2._0,
                                                        compareAtPrice: compareAtPrice._0,
                                                        compareAtPrice2: compareAtPrice2._0,
                                                        sku: sku._0,
                                                        imageId: imageId._0,
                                                        mediaIndex: mediaIndex._0,
                                                        mediaIndexesSourcedFromVariant: mediaIndexesSourcedFromVariant._0,
                                                        option1: option1._0,
                                                        option2: option2._0,
                                                        option3: option3._0,
                                                        inventory: inventory._0,
                                                        inventory2: inventory2._0,
                                                        link: link._0,
                                                        customLabels: customLabels._0,
                                                        brand: brand._0,
                                                        mpn: mpn._0,
                                                        promoText: promoText._0,
                                                        metadata: metadata._0
                                                      }
                                                    };
                                            }
                                            var e = metadata._0;
                                            return {
                                                    TAG: /* Error */1,
                                                    _0: {
                                                      path: ".metadata" + e.path,
                                                      message: e.message,
                                                      value: e.value
                                                    }
                                                  };
                                          }
                                          var e$1 = promoText._0;
                                          return {
                                                  TAG: /* Error */1,
                                                  _0: {
                                                    path: ".promoText" + e$1.path,
                                                    message: e$1.message,
                                                    value: e$1.value
                                                  }
                                                };
                                        }
                                        var e$2 = mpn._0;
                                        return {
                                                TAG: /* Error */1,
                                                _0: {
                                                  path: ".mpn" + e$2.path,
                                                  message: e$2.message,
                                                  value: e$2.value
                                                }
                                              };
                                      }
                                      var e$3 = brand._0;
                                      return {
                                              TAG: /* Error */1,
                                              _0: {
                                                path: ".brand" + e$3.path,
                                                message: e$3.message,
                                                value: e$3.value
                                              }
                                            };
                                    }
                                    var e$4 = customLabels._0;
                                    return {
                                            TAG: /* Error */1,
                                            _0: {
                                              path: ".customLabels" + e$4.path,
                                              message: e$4.message,
                                              value: e$4.value
                                            }
                                          };
                                  }
                                  var e$5 = link._0;
                                  return {
                                          TAG: /* Error */1,
                                          _0: {
                                            path: ".link" + e$5.path,
                                            message: e$5.message,
                                            value: e$5.value
                                          }
                                        };
                                }
                                var e$6 = inventory2._0;
                                return {
                                        TAG: /* Error */1,
                                        _0: {
                                          path: ".inventory2" + e$6.path,
                                          message: e$6.message,
                                          value: e$6.value
                                        }
                                      };
                              }
                              var e$7 = inventory._0;
                              return {
                                      TAG: /* Error */1,
                                      _0: {
                                        path: ".inventory" + e$7.path,
                                        message: e$7.message,
                                        value: e$7.value
                                      }
                                    };
                            }
                            var e$8 = option3._0;
                            return {
                                    TAG: /* Error */1,
                                    _0: {
                                      path: ".option3" + e$8.path,
                                      message: e$8.message,
                                      value: e$8.value
                                    }
                                  };
                          }
                          var e$9 = option2._0;
                          return {
                                  TAG: /* Error */1,
                                  _0: {
                                    path: ".option2" + e$9.path,
                                    message: e$9.message,
                                    value: e$9.value
                                  }
                                };
                        }
                        var e$10 = option1._0;
                        return {
                                TAG: /* Error */1,
                                _0: {
                                  path: ".option1" + e$10.path,
                                  message: e$10.message,
                                  value: e$10.value
                                }
                              };
                      }
                      var e$11 = mediaIndexesSourcedFromVariant._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".mediaIndexesSourcedFromVariant" + e$11.path,
                                message: e$11.message,
                                value: e$11.value
                              }
                            };
                    }
                    var e$12 = mediaIndex._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".mediaIndex" + e$12.path,
                              message: e$12.message,
                              value: e$12.value
                            }
                          };
                  }
                  var e$13 = imageId._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".imageId" + e$13.path,
                            message: e$13.message,
                            value: e$13.value
                          }
                        };
                }
                var e$14 = sku._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".sku" + e$14.path,
                          message: e$14.message,
                          value: e$14.value
                        }
                      };
              }
              var e$15 = compareAtPrice2._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".compareAtPrice2" + e$15.path,
                        message: e$15.message,
                        value: e$15.value
                      }
                    };
            }
            var e$16 = compareAtPrice._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".compareAtPrice" + e$16.path,
                      message: e$16.message,
                      value: e$16.value
                    }
                  };
          }
          var e$17 = price2._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".price2" + e$17.path,
                    message: e$17.message,
                    value: e$17.value
                  }
                };
        }
        var e$18 = price._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".price" + e$18.path,
                  message: e$18.message,
                  value: e$18.value
                }
              };
      }
      var e$19 = description._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".description" + e$19.path,
                message: e$19.message,
                value: e$19.value
              }
            };
    }
    var e$20 = name._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".name" + e$20.path,
              message: e$20.message,
              value: e$20.value
            }
          };
  }
  var e$21 = id._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".id" + e$21.path,
            message: e$21.message,
            value: e$21.value
          }
        };
}

function optionsToDict(variant, options) {
  var dict = {};
  var values = [
    variant.option1,
    variant.option2,
    variant.option3
  ];
  Belt_Array.forEachWithIndex(values, (function (i, value) {
          var key = Belt_Option.map(Belt_Array.get(options, i), (function (o) {
                  return o.name.toLowerCase();
                }));
          if (key !== undefined && value !== undefined) {
            dict[key] = value;
            return ;
          }
          
        }));
  return dict;
}

function isInStock(v) {
  return Belt_Option.getWithDefault(v.inventory.quantity, 0) > 0;
}

var Variant = {
  t_encode: t_encode$20,
  t_decode: t_decode$20,
  optionsToDict: optionsToDict,
  isInStock: isInStock
};

function t_encode$21(v) {
  return Decco.arrayToJson(t_encode$20, v);
}

function t_decode$21(v) {
  return Decco.arrayFromJson(t_decode$20, v);
}

var codec$3 = [
  t_encode$21,
  t_decode$21
];

var Variants = {
  t_encode: t_encode$21,
  t_decode: t_decode$21,
  codec: codec$3,
  toJson: t_encode$21,
  fromJson: t_decode$21
};

function t_encode$22(v) {
  return Js_dict.fromArray([
              [
                "v",
                Decco.intToJson(v.v)
              ],
              [
                "id",
                Curry._1(StoreId$ApiTypes.ProductId.t_encode, v.id)
              ],
              [
                "name",
                Decco.optionToJson(Decco.stringToJson, v.name)
              ],
              [
                "description",
                Decco.optionToJson(Decco.stringToJson, v.description)
              ],
              [
                "currency",
                Decco.optionToJson(Decco.stringToJson, v.currency)
              ],
              [
                "currency2",
                Decco.optionToJson(Decco.stringToJson, v.currency2)
              ],
              [
                "productType",
                Decco.optionToJson(Decco.stringToJson, v.productType)
              ],
              [
                "categoryId",
                Decco.optionToJson(Decco.stringToJson, v.categoryId)
              ],
              [
                "images",
                Decco.arrayToJson(t_encode$6, v.images)
              ],
              [
                "media",
                Decco.arrayToJson(t_encode$9, v.media)
              ],
              [
                "variants",
                Decco.arrayToJson(t_encode$20, v.variants)
              ],
              [
                "options",
                Decco.arrayToJson(t_encode$18, v.options)
              ],
              [
                "link",
                Decco.optionToJson(Decco.stringToJson, v.link)
              ],
              [
                "tags",
                Decco.arrayToJson(Decco.stringToJson, v.tags)
              ],
              [
                "metadata",
                Decco.dictToJson(t_encode$17, v.metadata)
              ],
              [
                "createdAt",
                Decco.optionToJson(Decco.floatToJson, v.createdAt)
              ],
              [
                "bestSellerRank",
                Decco.optionToJson(Decco.floatToJson, v.bestSellerRank)
              ],
              [
                "featuredRank",
                Decco.optionToJson(Decco.floatToJson, v.featuredRank)
              ],
              [
                "reviewSummary",
                Decco.optionToJson(t_encode$12, v.reviewSummary)
              ],
              [
                "promoText",
                Decco.optionToJson(Decco.stringToJson, v.promoText)
              ],
              [
                "lastModifiedAt",
                Decco.optionToJson(Decco.floatToJson, v.lastModifiedAt)
              ],
              [
                "lastImportedAt",
                Decco.optionToJson(Decco.floatToJson, v.lastImportedAt)
              ],
              [
                "deletedAt",
                Decco.optionToJson(Decco.floatToJson, v.deletedAt)
              ],
              [
                "market",
                Decco.optionToJson(Decco.stringToJson, v.market)
              ],
              [
                "alternativeMarkets",
                Decco.dictToJson((function (v) {
                        return v;
                      }), v.alternativeMarkets)
              ]
            ]);
}

function t_decode$22(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var v$1 = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict$1, "v"), Decco.intFromJson), {
        TAG: /* Ok */0,
        _0: 0
      });
  if (v$1.TAG === /* Ok */0) {
    var id = Curry._1(StoreId$ApiTypes.ProductId.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "id"), null));
    if (id.TAG === /* Ok */0) {
      var name = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "name"), null));
      if (name.TAG === /* Ok */0) {
        var description = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "description"), null));
        if (description.TAG === /* Ok */0) {
          var currency = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "currency"), null));
          if (currency.TAG === /* Ok */0) {
            var currency2 = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "currency2"), null));
            if (currency2.TAG === /* Ok */0) {
              var productType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "productType"), null));
              if (productType.TAG === /* Ok */0) {
                var categoryId = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "categoryId"), null));
                if (categoryId.TAG === /* Ok */0) {
                  var images = Decco.arrayFromJson(t_decode$6, Belt_Option.getWithDefault(Js_dict.get(dict$1, "images"), null));
                  if (images.TAG === /* Ok */0) {
                    var media = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict$1, "media"), (function (param) {
                                return Decco.arrayFromJson(t_decode$9, param);
                              })), {
                          TAG: /* Ok */0,
                          _0: []
                        });
                    if (media.TAG === /* Ok */0) {
                      var variants = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict$1, "variants"), (function (param) {
                                  return Decco.arrayFromJson(t_decode$20, param);
                                })), {
                            TAG: /* Ok */0,
                            _0: []
                          });
                      if (variants.TAG === /* Ok */0) {
                        var options = Decco.arrayFromJson(t_decode$18, Belt_Option.getWithDefault(Js_dict.get(dict$1, "options"), null));
                        if (options.TAG === /* Ok */0) {
                          var link = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "link"), null));
                          if (link.TAG === /* Ok */0) {
                            var tags = Decco.arrayFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "tags"), null));
                            if (tags.TAG === /* Ok */0) {
                              var metadata = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict$1, "metadata"), (function (param) {
                                          return Decco.dictFromJson(t_decode$17, param);
                                        })), {
                                    TAG: /* Ok */0,
                                    _0: {}
                                  });
                              if (metadata.TAG === /* Ok */0) {
                                var createdAt = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "createdAt"), null));
                                if (createdAt.TAG === /* Ok */0) {
                                  var bestSellerRank = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "bestSellerRank"), null));
                                  if (bestSellerRank.TAG === /* Ok */0) {
                                    var featuredRank = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "featuredRank"), null));
                                    if (featuredRank.TAG === /* Ok */0) {
                                      var reviewSummary = Decco.optionFromJson(t_decode$12, Belt_Option.getWithDefault(Js_dict.get(dict$1, "reviewSummary"), null));
                                      if (reviewSummary.TAG === /* Ok */0) {
                                        var promoText = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "promoText"), null));
                                        if (promoText.TAG === /* Ok */0) {
                                          var lastModifiedAt = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "lastModifiedAt"), null));
                                          if (lastModifiedAt.TAG === /* Ok */0) {
                                            var lastImportedAt = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "lastImportedAt"), null));
                                            if (lastImportedAt.TAG === /* Ok */0) {
                                              var deletedAt = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "deletedAt"), null));
                                              if (deletedAt.TAG === /* Ok */0) {
                                                var market = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "market"), null));
                                                if (market.TAG === /* Ok */0) {
                                                  var alternativeMarkets = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict$1, "alternativeMarkets"), (function (param) {
                                                              return Decco.dictFromJson((function (v) {
                                                                            return {
                                                                                    TAG: /* Ok */0,
                                                                                    _0: v
                                                                                  };
                                                                          }), param);
                                                            })), {
                                                        TAG: /* Ok */0,
                                                        _0: {}
                                                      });
                                                  if (alternativeMarkets.TAG === /* Ok */0) {
                                                    return {
                                                            TAG: /* Ok */0,
                                                            _0: {
                                                              v: v$1._0,
                                                              id: id._0,
                                                              name: name._0,
                                                              description: description._0,
                                                              currency: currency._0,
                                                              currency2: currency2._0,
                                                              productType: productType._0,
                                                              categoryId: categoryId._0,
                                                              images: images._0,
                                                              media: media._0,
                                                              variants: variants._0,
                                                              options: options._0,
                                                              link: link._0,
                                                              tags: tags._0,
                                                              metadata: metadata._0,
                                                              createdAt: createdAt._0,
                                                              bestSellerRank: bestSellerRank._0,
                                                              featuredRank: featuredRank._0,
                                                              reviewSummary: reviewSummary._0,
                                                              promoText: promoText._0,
                                                              lastModifiedAt: lastModifiedAt._0,
                                                              lastImportedAt: lastImportedAt._0,
                                                              deletedAt: deletedAt._0,
                                                              market: market._0,
                                                              alternativeMarkets: alternativeMarkets._0
                                                            }
                                                          };
                                                  }
                                                  var e = alternativeMarkets._0;
                                                  return {
                                                          TAG: /* Error */1,
                                                          _0: {
                                                            path: ".alternativeMarkets" + e.path,
                                                            message: e.message,
                                                            value: e.value
                                                          }
                                                        };
                                                }
                                                var e$1 = market._0;
                                                return {
                                                        TAG: /* Error */1,
                                                        _0: {
                                                          path: ".market" + e$1.path,
                                                          message: e$1.message,
                                                          value: e$1.value
                                                        }
                                                      };
                                              }
                                              var e$2 = deletedAt._0;
                                              return {
                                                      TAG: /* Error */1,
                                                      _0: {
                                                        path: ".deletedAt" + e$2.path,
                                                        message: e$2.message,
                                                        value: e$2.value
                                                      }
                                                    };
                                            }
                                            var e$3 = lastImportedAt._0;
                                            return {
                                                    TAG: /* Error */1,
                                                    _0: {
                                                      path: ".lastImportedAt" + e$3.path,
                                                      message: e$3.message,
                                                      value: e$3.value
                                                    }
                                                  };
                                          }
                                          var e$4 = lastModifiedAt._0;
                                          return {
                                                  TAG: /* Error */1,
                                                  _0: {
                                                    path: ".lastModifiedAt" + e$4.path,
                                                    message: e$4.message,
                                                    value: e$4.value
                                                  }
                                                };
                                        }
                                        var e$5 = promoText._0;
                                        return {
                                                TAG: /* Error */1,
                                                _0: {
                                                  path: ".promoText" + e$5.path,
                                                  message: e$5.message,
                                                  value: e$5.value
                                                }
                                              };
                                      }
                                      var e$6 = reviewSummary._0;
                                      return {
                                              TAG: /* Error */1,
                                              _0: {
                                                path: ".reviewSummary" + e$6.path,
                                                message: e$6.message,
                                                value: e$6.value
                                              }
                                            };
                                    }
                                    var e$7 = featuredRank._0;
                                    return {
                                            TAG: /* Error */1,
                                            _0: {
                                              path: ".featuredRank" + e$7.path,
                                              message: e$7.message,
                                              value: e$7.value
                                            }
                                          };
                                  }
                                  var e$8 = bestSellerRank._0;
                                  return {
                                          TAG: /* Error */1,
                                          _0: {
                                            path: ".bestSellerRank" + e$8.path,
                                            message: e$8.message,
                                            value: e$8.value
                                          }
                                        };
                                }
                                var e$9 = createdAt._0;
                                return {
                                        TAG: /* Error */1,
                                        _0: {
                                          path: ".createdAt" + e$9.path,
                                          message: e$9.message,
                                          value: e$9.value
                                        }
                                      };
                              }
                              var e$10 = metadata._0;
                              return {
                                      TAG: /* Error */1,
                                      _0: {
                                        path: ".metadata" + e$10.path,
                                        message: e$10.message,
                                        value: e$10.value
                                      }
                                    };
                            }
                            var e$11 = tags._0;
                            return {
                                    TAG: /* Error */1,
                                    _0: {
                                      path: ".tags" + e$11.path,
                                      message: e$11.message,
                                      value: e$11.value
                                    }
                                  };
                          }
                          var e$12 = link._0;
                          return {
                                  TAG: /* Error */1,
                                  _0: {
                                    path: ".link" + e$12.path,
                                    message: e$12.message,
                                    value: e$12.value
                                  }
                                };
                        }
                        var e$13 = options._0;
                        return {
                                TAG: /* Error */1,
                                _0: {
                                  path: ".options" + e$13.path,
                                  message: e$13.message,
                                  value: e$13.value
                                }
                              };
                      }
                      var e$14 = variants._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".variants" + e$14.path,
                                message: e$14.message,
                                value: e$14.value
                              }
                            };
                    }
                    var e$15 = media._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".media" + e$15.path,
                              message: e$15.message,
                              value: e$15.value
                            }
                          };
                  }
                  var e$16 = images._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".images" + e$16.path,
                            message: e$16.message,
                            value: e$16.value
                          }
                        };
                }
                var e$17 = categoryId._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".categoryId" + e$17.path,
                          message: e$17.message,
                          value: e$17.value
                        }
                      };
              }
              var e$18 = productType._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".productType" + e$18.path,
                        message: e$18.message,
                        value: e$18.value
                      }
                    };
            }
            var e$19 = currency2._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".currency2" + e$19.path,
                      message: e$19.message,
                      value: e$19.value
                    }
                  };
          }
          var e$20 = currency._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".currency" + e$20.path,
                    message: e$20.message,
                    value: e$20.value
                  }
                };
        }
        var e$21 = description._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".description" + e$21.path,
                  message: e$21.message,
                  value: e$21.value
                }
              };
      }
      var e$22 = name._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".name" + e$22.path,
                message: e$22.message,
                value: e$22.value
              }
            };
    }
    var e$23 = id._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".id" + e$23.path,
              message: e$23.message,
              value: e$23.value
            }
          };
  }
  var e$24 = v$1._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".v" + e$24.path,
            message: e$24.message,
            value: e$24.value
          }
        };
}

var codec$4 = [
  t_encode$22,
  t_decode$22
];

var Product = {
  $$Option: $$Option,
  Inventory: Inventory,
  Variant: Variant,
  Variants: Variants,
  t_encode: t_encode$22,
  t_decode: t_decode$22,
  codec: codec$4,
  toJson: t_encode$22,
  fromJson: t_decode$22
};

function t_encode$23(v) {
  return Js_dict.fromArray([
              [
                "id",
                Curry._1(StoreId$ApiTypes.ProductId.t_encode, v.id)
              ],
              [
                "lastModifiedAt",
                Decco.optionToJson(Decco.floatToJson, v.lastModifiedAt)
              ],
              [
                "details",
                Decco.optionToJson(t_encode$22, v.details)
              ]
            ]);
}

function t_decode$23(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var id = Curry._1(StoreId$ApiTypes.ProductId.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "id"), null));
  if (id.TAG === /* Ok */0) {
    var lastModifiedAt = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "lastModifiedAt"), null));
    if (lastModifiedAt.TAG === /* Ok */0) {
      var details = Decco.optionFromJson(t_decode$22, Belt_Option.getWithDefault(Js_dict.get(dict$1, "details"), null));
      if (details.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  id: id._0,
                  lastModifiedAt: lastModifiedAt._0,
                  details: details._0
                }
              };
      }
      var e = details._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".details" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = lastModifiedAt._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".lastModifiedAt" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = id._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".id" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

var codec$5 = [
  t_encode$23,
  t_decode$23
];

var CategoryProductEntry = {
  t_encode: t_encode$23,
  t_decode: t_decode$23,
  codec: codec$5,
  toJson: t_encode$23,
  fromJson: t_decode$23
};

function t_encode$24(v) {
  return Decco.arrayToJson(t_encode$23, v);
}

function t_decode$24(v) {
  return Decco.arrayFromJson(t_decode$23, v);
}

var codec$6 = [
  t_encode$24,
  t_decode$24
];

function t_encode$25(v) {
  return Js_dict.fromArray([
              [
                "page",
                Curry._1(PageMeta$ApiTypes.t_encode, v.page)
              ],
              [
                "entries",
                Decco.arrayToJson(t_encode$23, v.entries)
              ]
            ]);
}

function t_decode$25(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var page = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict$1, "page"), PageMeta$ApiTypes.t_decode), {
        TAG: /* Ok */0,
        _0: PageMeta$ApiTypes.$$default
      });
  if (page.TAG === /* Ok */0) {
    var v$1 = Belt_Option.getWithDefault(Js_dict.get(dict$1, "entries"), null);
    var entries = Decco.arrayFromJson(t_decode$23, v$1);
    if (entries.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                page: page._0,
                entries: entries._0
              }
            };
    }
    var e = entries._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".entries" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = page._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".page" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var codec$7 = [
  t_encode$25,
  t_decode$25
];

var Page = {
  t_encode: t_encode$25,
  t_decode: t_decode$25,
  codec: codec$7,
  toJson: t_encode$25,
  fromJson: t_decode$25
};

var CategoryProductEntries = {
  t_encode: t_encode$24,
  t_decode: t_decode$24,
  codec: codec$6,
  toJson: t_encode$24,
  fromJson: t_decode$24,
  Page: Page
};

function t_encode$26(v) {
  return Decco.arrayToJson(Decco.stringToJson, v);
}

function t_decode$26(v) {
  return Decco.arrayFromJson(Decco.stringFromJson, v);
}

function t_encode$27(v) {
  return Js_dict.fromArray([
              [
                "page",
                Curry._1(PageMeta$ApiTypes.t_encode, v.page)
              ],
              [
                "facets",
                Decco.arrayToJson(Decco.stringToJson, v.facets)
              ]
            ]);
}

function t_decode$27(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var page = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict$1, "page"), PageMeta$ApiTypes.t_decode), {
        TAG: /* Ok */0,
        _0: PageMeta$ApiTypes.$$default
      });
  if (page.TAG === /* Ok */0) {
    var v$1 = Belt_Option.getWithDefault(Js_dict.get(dict$1, "facets"), null);
    var facets = Decco.arrayFromJson(Decco.stringFromJson, v$1);
    if (facets.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                page: page._0,
                facets: facets._0
              }
            };
    }
    var e = facets._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".facets" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = page._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".page" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var codec$8 = [
  t_encode$27,
  t_decode$27
];

var Page$1 = {
  t_encode: t_encode$27,
  t_decode: t_decode$27,
  codec: codec$8,
  toJson: t_encode$27,
  fromJson: t_decode$27
};

var Facets = {
  t_encode: t_encode$26,
  t_decode: t_decode$26,
  Page: Page$1
};

function t_encode$28(v) {
  return Js_dict.fromArray([
              [
                "minPrice",
                Decco.intToJson(v.minPrice)
              ],
              [
                "maxPrice",
                Decco.intToJson(v.maxPrice)
              ],
              [
                "facets",
                Decco.arrayToJson((function (param) {
                        return [
                                Decco.stringToJson(param[0]),
                                Decco.intToJson(param[1])
                              ];
                      }), v.facets)
              ]
            ]);
}

function t_decode$28(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var minPrice = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "minPrice"), null));
  if (minPrice.TAG === /* Ok */0) {
    var maxPrice = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "maxPrice"), null));
    if (maxPrice.TAG === /* Ok */0) {
      var facets = Decco.arrayFromJson((function (json) {
              var match = Js_json.classify(json);
              if (typeof match === "number") {
                return Decco.error(undefined, "Not a tuple", json);
              }
              if (match.TAG !== /* JSONArray */3) {
                return Decco.error(undefined, "Not a tuple", json);
              }
              var match$1 = match._0;
              if (match$1.length !== 2) {
                return Decco.error(undefined, "Incorrect cardinality", json);
              }
              var v0 = match$1[0];
              var v1 = match$1[1];
              var match$2 = Decco.stringFromJson(v0);
              var match$3 = Decco.intFromJson(v1);
              if (match$2.TAG === /* Ok */0) {
                if (match$3.TAG === /* Ok */0) {
                  return {
                          TAG: /* Ok */0,
                          _0: [
                            match$2._0,
                            match$3._0
                          ]
                        };
                }
                var e = match$3._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: "[1]" + e.path,
                          message: e.message,
                          value: e.value
                        }
                      };
              }
              var e$1 = match$2._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: "[0]" + e$1.path,
                        message: e$1.message,
                        value: e$1.value
                      }
                    };
            }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "facets"), null));
      if (facets.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  minPrice: minPrice._0,
                  maxPrice: maxPrice._0,
                  facets: facets._0
                }
              };
      }
      var e = facets._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".facets" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = maxPrice._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".maxPrice" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = minPrice._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".minPrice" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

var empty_facets = [];

var empty = {
  minPrice: 0,
  maxPrice: 0,
  facets: empty_facets
};

var ProductFilters2 = {
  t_encode: t_encode$28,
  t_decode: t_decode$28,
  empty: empty
};

function t_encode$29(v) {
  return Js_dict.fromArray([
              [
                "minPrice",
                Decco.intToJson(v.minPrice)
              ],
              [
                "maxPrice",
                Decco.intToJson(v.maxPrice)
              ],
              [
                "size",
                Decco.arrayToJson(Decco.stringToJson, v.size)
              ],
              [
                "color",
                Decco.arrayToJson(Decco.stringToJson, v.color)
              ],
              [
                "pattern",
                Decco.arrayToJson(Decco.stringToJson, v.pattern)
              ],
              [
                "material",
                Decco.arrayToJson(Decco.stringToJson, v.material)
              ],
              [
                "tag",
                Decco.arrayToJson(Decco.stringToJson, v.tag)
              ],
              [
                "availability",
                Decco.arrayToJson(Decco.stringToJson, v.availability)
              ],
              [
                "price",
                Decco.arrayToJson(Decco.stringToJson, v.price)
              ],
              [
                "fabric",
                Decco.arrayToJson(Decco.stringToJson, v.fabric)
              ],
              [
                "sleeves",
                Decco.arrayToJson(Decco.stringToJson, v.sleeves)
              ],
              [
                "colour",
                Decco.arrayToJson(Decco.stringToJson, v.colour)
              ],
              [
                "neckline",
                Decco.arrayToJson(Decco.stringToJson, v.neckline)
              ],
              [
                "occasion",
                Decco.arrayToJson(Decco.stringToJson, v.occasion)
              ],
              [
                "type_",
                Decco.arrayToJson(Decco.stringToJson, v.type_)
              ],
              [
                "technique",
                Decco.arrayToJson(Decco.stringToJson, v.technique)
              ],
              [
                "style",
                Decco.arrayToJson(Decco.stringToJson, v.style)
              ]
            ]);
}

function t_decode$29(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var minPrice = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "minPrice"), null));
  if (minPrice.TAG === /* Ok */0) {
    var maxPrice = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "maxPrice"), null));
    if (maxPrice.TAG === /* Ok */0) {
      var size = Decco.arrayFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "size"), null));
      if (size.TAG === /* Ok */0) {
        var color = Decco.arrayFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "color"), null));
        if (color.TAG === /* Ok */0) {
          var pattern = Decco.arrayFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "pattern"), null));
          if (pattern.TAG === /* Ok */0) {
            var material = Decco.arrayFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "material"), null));
            if (material.TAG === /* Ok */0) {
              var tag = Decco.arrayFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "tag"), null));
              if (tag.TAG === /* Ok */0) {
                var availability = Decco.arrayFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "availability"), null));
                if (availability.TAG === /* Ok */0) {
                  var price = Decco.arrayFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "price"), null));
                  if (price.TAG === /* Ok */0) {
                    var fabric = Decco.arrayFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "fabric"), null));
                    if (fabric.TAG === /* Ok */0) {
                      var sleeves = Decco.arrayFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "sleeves"), null));
                      if (sleeves.TAG === /* Ok */0) {
                        var colour = Decco.arrayFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "colour"), null));
                        if (colour.TAG === /* Ok */0) {
                          var neckline = Decco.arrayFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "neckline"), null));
                          if (neckline.TAG === /* Ok */0) {
                            var occasion = Decco.arrayFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "occasion"), null));
                            if (occasion.TAG === /* Ok */0) {
                              var type_ = Decco.arrayFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "type_"), null));
                              if (type_.TAG === /* Ok */0) {
                                var technique = Decco.arrayFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "technique"), null));
                                if (technique.TAG === /* Ok */0) {
                                  var style = Decco.arrayFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "style"), null));
                                  if (style.TAG === /* Ok */0) {
                                    return {
                                            TAG: /* Ok */0,
                                            _0: {
                                              minPrice: minPrice._0,
                                              maxPrice: maxPrice._0,
                                              size: size._0,
                                              color: color._0,
                                              pattern: pattern._0,
                                              material: material._0,
                                              tag: tag._0,
                                              availability: availability._0,
                                              price: price._0,
                                              fabric: fabric._0,
                                              sleeves: sleeves._0,
                                              colour: colour._0,
                                              neckline: neckline._0,
                                              occasion: occasion._0,
                                              type_: type_._0,
                                              technique: technique._0,
                                              style: style._0
                                            }
                                          };
                                  }
                                  var e = style._0;
                                  return {
                                          TAG: /* Error */1,
                                          _0: {
                                            path: ".style" + e.path,
                                            message: e.message,
                                            value: e.value
                                          }
                                        };
                                }
                                var e$1 = technique._0;
                                return {
                                        TAG: /* Error */1,
                                        _0: {
                                          path: ".technique" + e$1.path,
                                          message: e$1.message,
                                          value: e$1.value
                                        }
                                      };
                              }
                              var e$2 = type_._0;
                              return {
                                      TAG: /* Error */1,
                                      _0: {
                                        path: ".type_" + e$2.path,
                                        message: e$2.message,
                                        value: e$2.value
                                      }
                                    };
                            }
                            var e$3 = occasion._0;
                            return {
                                    TAG: /* Error */1,
                                    _0: {
                                      path: ".occasion" + e$3.path,
                                      message: e$3.message,
                                      value: e$3.value
                                    }
                                  };
                          }
                          var e$4 = neckline._0;
                          return {
                                  TAG: /* Error */1,
                                  _0: {
                                    path: ".neckline" + e$4.path,
                                    message: e$4.message,
                                    value: e$4.value
                                  }
                                };
                        }
                        var e$5 = colour._0;
                        return {
                                TAG: /* Error */1,
                                _0: {
                                  path: ".colour" + e$5.path,
                                  message: e$5.message,
                                  value: e$5.value
                                }
                              };
                      }
                      var e$6 = sleeves._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".sleeves" + e$6.path,
                                message: e$6.message,
                                value: e$6.value
                              }
                            };
                    }
                    var e$7 = fabric._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".fabric" + e$7.path,
                              message: e$7.message,
                              value: e$7.value
                            }
                          };
                  }
                  var e$8 = price._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".price" + e$8.path,
                            message: e$8.message,
                            value: e$8.value
                          }
                        };
                }
                var e$9 = availability._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".availability" + e$9.path,
                          message: e$9.message,
                          value: e$9.value
                        }
                      };
              }
              var e$10 = tag._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".tag" + e$10.path,
                        message: e$10.message,
                        value: e$10.value
                      }
                    };
            }
            var e$11 = material._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".material" + e$11.path,
                      message: e$11.message,
                      value: e$11.value
                    }
                  };
          }
          var e$12 = pattern._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".pattern" + e$12.path,
                    message: e$12.message,
                    value: e$12.value
                  }
                };
        }
        var e$13 = color._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".color" + e$13.path,
                  message: e$13.message,
                  value: e$13.value
                }
              };
      }
      var e$14 = size._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".size" + e$14.path,
                message: e$14.message,
                value: e$14.value
              }
            };
    }
    var e$15 = maxPrice._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".maxPrice" + e$15.path,
              message: e$15.message,
              value: e$15.value
            }
          };
  }
  var e$16 = minPrice._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".minPrice" + e$16.path,
            message: e$16.message,
            value: e$16.value
          }
        };
}

var empty_size = [];

var empty_color = [];

var empty_pattern = [];

var empty_material = [];

var empty_tag = [];

var empty_availability = [];

var empty_price = [];

var empty_fabric = [];

var empty_sleeves = [];

var empty_colour = [];

var empty_neckline = [];

var empty_occasion = [];

var empty_type_ = [];

var empty_technique = [];

var empty_style = [];

var empty$1 = {
  minPrice: 0,
  maxPrice: 0,
  size: empty_size,
  color: empty_color,
  pattern: empty_pattern,
  material: empty_material,
  tag: empty_tag,
  availability: empty_availability,
  price: empty_price,
  fabric: empty_fabric,
  sleeves: empty_sleeves,
  colour: empty_colour,
  neckline: empty_neckline,
  occasion: empty_occasion,
  type_: empty_type_,
  technique: empty_technique,
  style: empty_style
};

function t_encode$30(v) {
  return Js_dict.fromArray([
              [
                "size",
                Decco.dictToJson(Decco.intToJson, v.size)
              ],
              [
                "color",
                Decco.dictToJson(Decco.intToJson, v.color)
              ],
              [
                "pattern",
                Decco.dictToJson(Decco.intToJson, v.pattern)
              ],
              [
                "material",
                Decco.dictToJson(Decco.intToJson, v.material)
              ],
              [
                "availability",
                Decco.dictToJson(Decco.intToJson, v.availability)
              ],
              [
                "price",
                Decco.dictToJson(Decco.intToJson, v.price)
              ],
              [
                "tag",
                Decco.dictToJson(Decco.intToJson, v.tag)
              ],
              [
                "fabric",
                Decco.dictToJson(Decco.intToJson, v.fabric)
              ],
              [
                "sleeves",
                Decco.dictToJson(Decco.intToJson, v.sleeves)
              ],
              [
                "colour",
                Decco.dictToJson(Decco.intToJson, v.colour)
              ],
              [
                "neckline",
                Decco.dictToJson(Decco.intToJson, v.neckline)
              ],
              [
                "occasion",
                Decco.dictToJson(Decco.intToJson, v.occasion)
              ],
              [
                "type_",
                Decco.dictToJson(Decco.intToJson, v.type_)
              ],
              [
                "technique",
                Decco.dictToJson(Decco.intToJson, v.technique)
              ],
              [
                "style",
                Decco.dictToJson(Decco.intToJson, v.style)
              ]
            ]);
}

function t_decode$30(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var size = Decco.dictFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "size"), null));
  if (size.TAG === /* Ok */0) {
    var color = Decco.dictFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "color"), null));
    if (color.TAG === /* Ok */0) {
      var pattern = Decco.dictFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "pattern"), null));
      if (pattern.TAG === /* Ok */0) {
        var material = Decco.dictFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "material"), null));
        if (material.TAG === /* Ok */0) {
          var availability = Decco.dictFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "availability"), null));
          if (availability.TAG === /* Ok */0) {
            var price = Decco.dictFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "price"), null));
            if (price.TAG === /* Ok */0) {
              var tag = Decco.dictFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "tag"), null));
              if (tag.TAG === /* Ok */0) {
                var fabric = Decco.dictFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "fabric"), null));
                if (fabric.TAG === /* Ok */0) {
                  var sleeves = Decco.dictFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "sleeves"), null));
                  if (sleeves.TAG === /* Ok */0) {
                    var colour = Decco.dictFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "colour"), null));
                    if (colour.TAG === /* Ok */0) {
                      var neckline = Decco.dictFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "neckline"), null));
                      if (neckline.TAG === /* Ok */0) {
                        var occasion = Decco.dictFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "occasion"), null));
                        if (occasion.TAG === /* Ok */0) {
                          var type_ = Decco.dictFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "type_"), null));
                          if (type_.TAG === /* Ok */0) {
                            var technique = Decco.dictFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "technique"), null));
                            if (technique.TAG === /* Ok */0) {
                              var style = Decco.dictFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "style"), null));
                              if (style.TAG === /* Ok */0) {
                                return {
                                        TAG: /* Ok */0,
                                        _0: {
                                          size: size._0,
                                          color: color._0,
                                          pattern: pattern._0,
                                          material: material._0,
                                          availability: availability._0,
                                          price: price._0,
                                          tag: tag._0,
                                          fabric: fabric._0,
                                          sleeves: sleeves._0,
                                          colour: colour._0,
                                          neckline: neckline._0,
                                          occasion: occasion._0,
                                          type_: type_._0,
                                          technique: technique._0,
                                          style: style._0
                                        }
                                      };
                              }
                              var e = style._0;
                              return {
                                      TAG: /* Error */1,
                                      _0: {
                                        path: ".style" + e.path,
                                        message: e.message,
                                        value: e.value
                                      }
                                    };
                            }
                            var e$1 = technique._0;
                            return {
                                    TAG: /* Error */1,
                                    _0: {
                                      path: ".technique" + e$1.path,
                                      message: e$1.message,
                                      value: e$1.value
                                    }
                                  };
                          }
                          var e$2 = type_._0;
                          return {
                                  TAG: /* Error */1,
                                  _0: {
                                    path: ".type_" + e$2.path,
                                    message: e$2.message,
                                    value: e$2.value
                                  }
                                };
                        }
                        var e$3 = occasion._0;
                        return {
                                TAG: /* Error */1,
                                _0: {
                                  path: ".occasion" + e$3.path,
                                  message: e$3.message,
                                  value: e$3.value
                                }
                              };
                      }
                      var e$4 = neckline._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".neckline" + e$4.path,
                                message: e$4.message,
                                value: e$4.value
                              }
                            };
                    }
                    var e$5 = colour._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".colour" + e$5.path,
                              message: e$5.message,
                              value: e$5.value
                            }
                          };
                  }
                  var e$6 = sleeves._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".sleeves" + e$6.path,
                            message: e$6.message,
                            value: e$6.value
                          }
                        };
                }
                var e$7 = fabric._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".fabric" + e$7.path,
                          message: e$7.message,
                          value: e$7.value
                        }
                      };
              }
              var e$8 = tag._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".tag" + e$8.path,
                        message: e$8.message,
                        value: e$8.value
                      }
                    };
            }
            var e$9 = price._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".price" + e$9.path,
                      message: e$9.message,
                      value: e$9.value
                    }
                  };
          }
          var e$10 = availability._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".availability" + e$10.path,
                    message: e$10.message,
                    value: e$10.value
                  }
                };
        }
        var e$11 = material._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".material" + e$11.path,
                  message: e$11.message,
                  value: e$11.value
                }
              };
      }
      var e$12 = pattern._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".pattern" + e$12.path,
                message: e$12.message,
                value: e$12.value
              }
            };
    }
    var e$13 = color._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".color" + e$13.path,
              message: e$13.message,
              value: e$13.value
            }
          };
  }
  var e$14 = size._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".size" + e$14.path,
            message: e$14.message,
            value: e$14.value
          }
        };
}

var Counts = {
  t_encode: t_encode$30,
  t_decode: t_decode$30
};

var ProductFilters = {
  t_encode: t_encode$29,
  t_decode: t_decode$29,
  empty: empty$1,
  Counts: Counts
};

function t_encode$31(v) {
  return Js_dict.fromArray([
              [
                "id",
                Curry._1(StoreId$ApiTypes.CategoryId.t_encode, v.id)
              ],
              [
                "name",
                Decco.optionToJson(Decco.stringToJson, v.name)
              ],
              [
                "description",
                Decco.optionToJson(Decco.stringToJson, v.description)
              ],
              [
                "image",
                Decco.optionToJson(t_encode$6, v.image)
              ],
              [
                "link",
                Decco.optionToJson(Decco.stringToJson, v.link)
              ],
              [
                "productsCount",
                Decco.optionToJson(Decco.intToJson, v.productsCount)
              ],
              [
                "defaultSortOrder",
                Decco.optionToJson(t_encode$5, v.defaultSortOrder)
              ],
              [
                "firstImportedAt",
                Decco.optionToJson(Decco.floatToJson, v.firstImportedAt)
              ],
              [
                "lastImportedAt",
                Decco.optionToJson(Decco.floatToJson, v.lastImportedAt)
              ],
              [
                "lastModifiedAt",
                Decco.optionToJson(Decco.floatToJson, v.lastModifiedAt)
              ]
            ]);
}

function t_decode$31(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var id = Curry._1(StoreId$ApiTypes.CategoryId.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "id"), null));
  if (id.TAG === /* Ok */0) {
    var name = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "name"), null));
    if (name.TAG === /* Ok */0) {
      var description = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "description"), null));
      if (description.TAG === /* Ok */0) {
        var image = Decco.optionFromJson(t_decode$6, Belt_Option.getWithDefault(Js_dict.get(dict$1, "image"), null));
        if (image.TAG === /* Ok */0) {
          var link = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "link"), null));
          if (link.TAG === /* Ok */0) {
            var productsCount = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "productsCount"), null));
            if (productsCount.TAG === /* Ok */0) {
              var defaultSortOrder = Decco.optionFromJson(t_decode$5, Belt_Option.getWithDefault(Js_dict.get(dict$1, "defaultSortOrder"), null));
              if (defaultSortOrder.TAG === /* Ok */0) {
                var firstImportedAt = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "firstImportedAt"), null));
                if (firstImportedAt.TAG === /* Ok */0) {
                  var lastImportedAt = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "lastImportedAt"), null));
                  if (lastImportedAt.TAG === /* Ok */0) {
                    var lastModifiedAt = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "lastModifiedAt"), null));
                    if (lastModifiedAt.TAG === /* Ok */0) {
                      return {
                              TAG: /* Ok */0,
                              _0: {
                                id: id._0,
                                name: name._0,
                                description: description._0,
                                image: image._0,
                                link: link._0,
                                productsCount: productsCount._0,
                                defaultSortOrder: defaultSortOrder._0,
                                firstImportedAt: firstImportedAt._0,
                                lastImportedAt: lastImportedAt._0,
                                lastModifiedAt: lastModifiedAt._0
                              }
                            };
                    }
                    var e = lastModifiedAt._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".lastModifiedAt" + e.path,
                              message: e.message,
                              value: e.value
                            }
                          };
                  }
                  var e$1 = lastImportedAt._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".lastImportedAt" + e$1.path,
                            message: e$1.message,
                            value: e$1.value
                          }
                        };
                }
                var e$2 = firstImportedAt._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".firstImportedAt" + e$2.path,
                          message: e$2.message,
                          value: e$2.value
                        }
                      };
              }
              var e$3 = defaultSortOrder._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".defaultSortOrder" + e$3.path,
                        message: e$3.message,
                        value: e$3.value
                      }
                    };
            }
            var e$4 = productsCount._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".productsCount" + e$4.path,
                      message: e$4.message,
                      value: e$4.value
                    }
                  };
          }
          var e$5 = link._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".link" + e$5.path,
                    message: e$5.message,
                    value: e$5.value
                  }
                };
        }
        var e$6 = image._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".image" + e$6.path,
                  message: e$6.message,
                  value: e$6.value
                }
              };
      }
      var e$7 = description._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".description" + e$7.path,
                message: e$7.message,
                value: e$7.value
              }
            };
    }
    var e$8 = name._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".name" + e$8.path,
              message: e$8.message,
              value: e$8.value
            }
          };
  }
  var e$9 = id._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".id" + e$9.path,
            message: e$9.message,
            value: e$9.value
          }
        };
}

var codec$9 = [
  t_encode$31,
  t_decode$31
];

function t_encode$32(v) {
  return Js_dict.fromArray([
              [
                "isComplete",
                Decco.boolToJson(v.isComplete)
              ],
              [
                "count",
                Decco.intToJson(v.count)
              ],
              [
                "lastCompletedAt",
                Decco.optionToJson(Decco.floatToJson, v.lastCompletedAt)
              ],
              [
                "hasSomeCreatedAtTimes",
                Decco.boolToJson(v.hasSomeCreatedAtTimes)
              ],
              [
                "hasSomeBestSellerRanks",
                Decco.boolToJson(v.hasSomeBestSellerRanks)
              ],
              [
                "hasSomeFeaturedRanks",
                Decco.boolToJson(v.hasSomeFeaturedRanks)
              ],
              [
                "hasSomeDiscounts",
                Decco.boolToJson(v.hasSomeDiscounts)
              ],
              [
                "hasSomeDiscountsInStock",
                Decco.boolToJson(v.hasSomeDiscountsInStock)
              ],
              [
                "deletedAt",
                Decco.optionToJson(Decco.floatToJson, v.deletedAt)
              ]
            ]);
}

function t_decode$32(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var isComplete = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "isComplete"), null));
  if (isComplete.TAG === /* Ok */0) {
    var count = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "count"), null));
    if (count.TAG === /* Ok */0) {
      var lastCompletedAt = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "lastCompletedAt"), null));
      if (lastCompletedAt.TAG === /* Ok */0) {
        var hasSomeCreatedAtTimes = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict$1, "hasSomeCreatedAtTimes"), Decco.boolFromJson), {
              TAG: /* Ok */0,
              _0: false
            });
        if (hasSomeCreatedAtTimes.TAG === /* Ok */0) {
          var hasSomeBestSellerRanks = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict$1, "hasSomeBestSellerRanks"), Decco.boolFromJson), {
                TAG: /* Ok */0,
                _0: false
              });
          if (hasSomeBestSellerRanks.TAG === /* Ok */0) {
            var hasSomeFeaturedRanks = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict$1, "hasSomeFeaturedRanks"), Decco.boolFromJson), {
                  TAG: /* Ok */0,
                  _0: false
                });
            if (hasSomeFeaturedRanks.TAG === /* Ok */0) {
              var hasSomeDiscounts = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict$1, "hasSomeDiscounts"), Decco.boolFromJson), {
                    TAG: /* Ok */0,
                    _0: false
                  });
              if (hasSomeDiscounts.TAG === /* Ok */0) {
                var hasSomeDiscountsInStock = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict$1, "hasSomeDiscountsInStock"), Decco.boolFromJson), {
                      TAG: /* Ok */0,
                      _0: false
                    });
                if (hasSomeDiscountsInStock.TAG === /* Ok */0) {
                  var deletedAt = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "deletedAt"), null));
                  if (deletedAt.TAG === /* Ok */0) {
                    return {
                            TAG: /* Ok */0,
                            _0: {
                              isComplete: isComplete._0,
                              count: count._0,
                              lastCompletedAt: lastCompletedAt._0,
                              hasSomeCreatedAtTimes: hasSomeCreatedAtTimes._0,
                              hasSomeBestSellerRanks: hasSomeBestSellerRanks._0,
                              hasSomeFeaturedRanks: hasSomeFeaturedRanks._0,
                              hasSomeDiscounts: hasSomeDiscounts._0,
                              hasSomeDiscountsInStock: hasSomeDiscountsInStock._0,
                              deletedAt: deletedAt._0
                            }
                          };
                  }
                  var e = deletedAt._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".deletedAt" + e.path,
                            message: e.message,
                            value: e.value
                          }
                        };
                }
                var e$1 = hasSomeDiscountsInStock._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".hasSomeDiscountsInStock" + e$1.path,
                          message: e$1.message,
                          value: e$1.value
                        }
                      };
              }
              var e$2 = hasSomeDiscounts._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".hasSomeDiscounts" + e$2.path,
                        message: e$2.message,
                        value: e$2.value
                      }
                    };
            }
            var e$3 = hasSomeFeaturedRanks._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".hasSomeFeaturedRanks" + e$3.path,
                      message: e$3.message,
                      value: e$3.value
                    }
                  };
          }
          var e$4 = hasSomeBestSellerRanks._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".hasSomeBestSellerRanks" + e$4.path,
                    message: e$4.message,
                    value: e$4.value
                  }
                };
        }
        var e$5 = hasSomeCreatedAtTimes._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".hasSomeCreatedAtTimes" + e$5.path,
                  message: e$5.message,
                  value: e$5.value
                }
              };
      }
      var e$6 = lastCompletedAt._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".lastCompletedAt" + e$6.path,
                message: e$6.message,
                value: e$6.value
              }
            };
    }
    var e$7 = count._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".count" + e$7.path,
              message: e$7.message,
              value: e$7.value
            }
          };
  }
  var e$8 = isComplete._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".isComplete" + e$8.path,
            message: e$8.message,
            value: e$8.value
          }
        };
}

var codec$10 = [
  t_encode$32,
  t_decode$32
];

var SyncStatus = {
  t_encode: t_encode$32,
  t_decode: t_decode$32,
  codec: codec$10,
  toJson: t_encode$32,
  fromJson: t_decode$32
};

var Category = {
  t_encode: t_encode$31,
  t_decode: t_decode$31,
  codec: codec$9,
  toJson: t_encode$31,
  fromJson: t_decode$31,
  SyncStatus: SyncStatus
};

function t_encode$33(v) {
  return Decco.arrayToJson(t_encode$31, v);
}

function t_decode$33(v) {
  return Decco.arrayFromJson(t_decode$31, v);
}

var codec$11 = [
  t_encode$33,
  t_decode$33
];

function t_encode$34(v) {
  return Js_dict.fromArray([
              [
                "isComplete",
                Decco.boolToJson(v.isComplete)
              ],
              [
                "count",
                Decco.intToJson(v.count)
              ],
              [
                "lastCompletedAt",
                Decco.optionToJson(Decco.floatToJson, v.lastCompletedAt)
              ]
            ]);
}

function t_decode$34(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var isComplete = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "isComplete"), null));
  if (isComplete.TAG === /* Ok */0) {
    var count = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "count"), null));
    if (count.TAG === /* Ok */0) {
      var lastCompletedAt = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "lastCompletedAt"), null));
      if (lastCompletedAt.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  isComplete: isComplete._0,
                  count: count._0,
                  lastCompletedAt: lastCompletedAt._0
                }
              };
      }
      var e = lastCompletedAt._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".lastCompletedAt" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = count._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".count" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = isComplete._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".isComplete" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

var codec$12 = [
  t_encode$34,
  t_decode$34
];

var SyncStatus$1 = {
  t_encode: t_encode$34,
  t_decode: t_decode$34,
  codec: codec$12,
  toJson: t_encode$34,
  fromJson: t_decode$34
};

function t_encode$35(v) {
  return Js_dict.fromArray([
              [
                "current",
                Decco.optionToJson((function (param) {
                        return Decco.arrayToJson((function (param) {
                                      return Decco.arrayToJson(StoreId$ApiTypes.CategoryId.t_encode, param);
                                    }), param);
                      }), v.current)
              ],
              [
                "pending",
                Decco.arrayToJson((function (param) {
                        return Decco.arrayToJson(StoreId$ApiTypes.CategoryId.t_encode, param);
                      }), v.pending)
              ],
              [
                "nextPage",
                Decco.optionToJson(Decco.stringToJson, v.nextPage)
              ],
              [
                "lastCompletedAt",
                Decco.optionToJson(Decco.floatToJson, v.lastCompletedAt)
              ]
            ]);
}

function t_decode$35(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var current = Decco.optionFromJson((function (param) {
          return Decco.arrayFromJson((function (param) {
                        return Decco.arrayFromJson(StoreId$ApiTypes.CategoryId.t_decode, param);
                      }), param);
        }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "current"), null));
  if (current.TAG === /* Ok */0) {
    var pending = Decco.arrayFromJson((function (param) {
            return Decco.arrayFromJson(StoreId$ApiTypes.CategoryId.t_decode, param);
          }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "pending"), null));
    if (pending.TAG === /* Ok */0) {
      var nextPage = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "nextPage"), null));
      if (nextPage.TAG === /* Ok */0) {
        var lastCompletedAt = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "lastCompletedAt"), null));
        if (lastCompletedAt.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: {
                    current: current._0,
                    pending: pending._0,
                    nextPage: nextPage._0,
                    lastCompletedAt: lastCompletedAt._0
                  }
                };
        }
        var e = lastCompletedAt._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".lastCompletedAt" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = nextPage._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".nextPage" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = pending._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".pending" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = current._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".current" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

var initial_pending = [];

var initial = {
  current: undefined,
  pending: initial_pending,
  nextPage: undefined,
  lastCompletedAt: undefined
};

var codec$13 = [
  t_encode$35,
  t_decode$35
];

var Sync = {
  t_encode: t_encode$35,
  t_decode: t_decode$35,
  initial: initial,
  codec: codec$13,
  toJson: t_encode$35,
  fromJson: t_decode$35
};

function t_encode$36(v) {
  return Js_dict.fromArray([
              [
                "page",
                Curry._1(PageMeta$ApiTypes.t_encode, v.page)
              ],
              [
                "syncStatus",
                Decco.optionToJson(t_encode$34, v.syncStatus)
              ],
              [
                "categories",
                Decco.arrayToJson(t_encode$31, v.categories)
              ]
            ]);
}

function t_decode$36(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var page = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict$1, "page"), PageMeta$ApiTypes.t_decode), {
        TAG: /* Ok */0,
        _0: PageMeta$ApiTypes.$$default
      });
  if (page.TAG === /* Ok */0) {
    var syncStatus = Decco.optionFromJson(t_decode$34, Belt_Option.getWithDefault(Js_dict.get(dict$1, "syncStatus"), null));
    if (syncStatus.TAG === /* Ok */0) {
      var categories = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict$1, "categories"), t_decode$33), {
            TAG: /* Ok */0,
            _0: []
          });
      if (categories.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  page: page._0,
                  syncStatus: syncStatus._0,
                  categories: categories._0
                }
              };
      }
      var e = categories._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".categories" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = syncStatus._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".syncStatus" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = page._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".page" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

var codec$14 = [
  t_encode$36,
  t_decode$36
];

var Page$2 = {
  t_encode: t_encode$36,
  t_decode: t_decode$36,
  codec: codec$14,
  toJson: t_encode$36,
  fromJson: t_decode$36
};

var Categories = {
  t_encode: t_encode$33,
  t_decode: t_decode$33,
  toJson: t_encode$33,
  fromJson: t_decode$33,
  codec: codec$11,
  SyncStatus: SyncStatus$1,
  Sync: Sync,
  Page: Page$2
};

function t_encode$37(v) {
  return Decco.arrayToJson(t_encode$22, v);
}

function t_decode$37(v) {
  return Decco.arrayFromJson(t_decode$22, v);
}

var codec$15 = [
  t_encode$37,
  t_decode$37
];

function t_encode$38(v) {
  return Js_dict.fromArray([
              [
                "page",
                Curry._1(PageMeta$ApiTypes.t_encode, v.page)
              ],
              [
                "products",
                Decco.arrayToJson(t_encode$22, v.products)
              ],
              [
                "availableFilters",
                Decco.optionToJson(t_encode$29, v.availableFilters)
              ],
              [
                "counts",
                Decco.optionToJson(t_encode$30, v.counts)
              ],
              [
                "total",
                Decco.optionToJson(Decco.intToJson, v.total)
              ],
              [
                "syncStatus",
                Decco.optionToJson(t_encode$32, v.syncStatus)
              ],
              [
                "defaultSortOrder",
                Decco.optionToJson(t_encode$5, v.defaultSortOrder)
              ],
              [
                "availableSorts",
                Decco.optionToJson((function (param) {
                        return Decco.arrayToJson(t_encode$5, param);
                      }), v.availableSorts)
              ]
            ]);
}

function t_decode$38(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var page = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict$1, "page"), PageMeta$ApiTypes.t_decode), {
        TAG: /* Ok */0,
        _0: PageMeta$ApiTypes.$$default
      });
  if (page.TAG === /* Ok */0) {
    var products = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict$1, "products"), t_decode$37), {
          TAG: /* Ok */0,
          _0: []
        });
    if (products.TAG === /* Ok */0) {
      var availableFilters = Decco.optionFromJson(t_decode$29, Belt_Option.getWithDefault(Js_dict.get(dict$1, "availableFilters"), null));
      if (availableFilters.TAG === /* Ok */0) {
        var counts = Decco.optionFromJson(t_decode$30, Belt_Option.getWithDefault(Js_dict.get(dict$1, "counts"), null));
        if (counts.TAG === /* Ok */0) {
          var total = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
          if (total.TAG === /* Ok */0) {
            var syncStatus = Decco.optionFromJson(t_decode$32, Belt_Option.getWithDefault(Js_dict.get(dict$1, "syncStatus"), null));
            if (syncStatus.TAG === /* Ok */0) {
              var defaultSortOrder = Decco.optionFromJson(t_decode$5, Belt_Option.getWithDefault(Js_dict.get(dict$1, "defaultSortOrder"), null));
              if (defaultSortOrder.TAG === /* Ok */0) {
                var availableSorts = Decco.optionFromJson((function (param) {
                        return Decco.arrayFromJson(t_decode$5, param);
                      }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "availableSorts"), null));
                if (availableSorts.TAG === /* Ok */0) {
                  return {
                          TAG: /* Ok */0,
                          _0: {
                            page: page._0,
                            products: products._0,
                            availableFilters: availableFilters._0,
                            counts: counts._0,
                            total: total._0,
                            syncStatus: syncStatus._0,
                            defaultSortOrder: defaultSortOrder._0,
                            availableSorts: availableSorts._0
                          }
                        };
                }
                var e = availableSorts._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".availableSorts" + e.path,
                          message: e.message,
                          value: e.value
                        }
                      };
              }
              var e$1 = defaultSortOrder._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".defaultSortOrder" + e$1.path,
                        message: e$1.message,
                        value: e$1.value
                      }
                    };
            }
            var e$2 = syncStatus._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".syncStatus" + e$2.path,
                      message: e$2.message,
                      value: e$2.value
                    }
                  };
          }
          var e$3 = total._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".total" + e$3.path,
                    message: e$3.message,
                    value: e$3.value
                  }
                };
        }
        var e$4 = counts._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".counts" + e$4.path,
                  message: e$4.message,
                  value: e$4.value
                }
              };
      }
      var e$5 = availableFilters._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".availableFilters" + e$5.path,
                message: e$5.message,
                value: e$5.value
              }
            };
    }
    var e$6 = products._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".products" + e$6.path,
              message: e$6.message,
              value: e$6.value
            }
          };
  }
  var e$7 = page._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".page" + e$7.path,
            message: e$7.message,
            value: e$7.value
          }
        };
}

var codec$16 = [
  t_encode$38,
  t_decode$38
];

var empty_page = {
  previous: undefined,
  next: undefined,
  offset: undefined,
  limit: undefined
};

var empty_products = [];

var empty$2 = {
  page: empty_page,
  products: empty_products,
  availableFilters: undefined,
  counts: undefined,
  total: undefined,
  syncStatus: undefined,
  defaultSortOrder: undefined,
  availableSorts: undefined
};

var Page$3 = {
  t_encode: t_encode$38,
  t_decode: t_decode$38,
  codec: codec$16,
  toJson: t_encode$38,
  fromJson: t_decode$38,
  empty: empty$2
};

function t_encode$39(v) {
  return Js_dict.fromArray([
              [
                "page",
                Curry._1(PageMeta$ApiTypes.t_encode, v.page)
              ],
              [
                "products",
                Decco.arrayToJson(t_encode$22, v.products)
              ],
              [
                "filters",
                Decco.optionToJson(t_encode$28, v.filters)
              ],
              [
                "total",
                Decco.optionToJson(Decco.intToJson, v.total)
              ],
              [
                "syncStatus",
                Decco.optionToJson(t_encode$32, v.syncStatus)
              ],
              [
                "defaultSortOrder",
                Decco.optionToJson(t_encode$5, v.defaultSortOrder)
              ],
              [
                "availableSorts",
                Decco.optionToJson((function (param) {
                        return Decco.arrayToJson(t_encode$5, param);
                      }), v.availableSorts)
              ]
            ]);
}

function t_decode$39(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var page = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict$1, "page"), PageMeta$ApiTypes.t_decode), {
        TAG: /* Ok */0,
        _0: PageMeta$ApiTypes.$$default
      });
  if (page.TAG === /* Ok */0) {
    var products = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(dict$1, "products"), t_decode$37), {
          TAG: /* Ok */0,
          _0: []
        });
    if (products.TAG === /* Ok */0) {
      var filters = Decco.optionFromJson(t_decode$28, Belt_Option.getWithDefault(Js_dict.get(dict$1, "filters"), null));
      if (filters.TAG === /* Ok */0) {
        var total = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
        if (total.TAG === /* Ok */0) {
          var syncStatus = Decco.optionFromJson(t_decode$32, Belt_Option.getWithDefault(Js_dict.get(dict$1, "syncStatus"), null));
          if (syncStatus.TAG === /* Ok */0) {
            var defaultSortOrder = Decco.optionFromJson(t_decode$5, Belt_Option.getWithDefault(Js_dict.get(dict$1, "defaultSortOrder"), null));
            if (defaultSortOrder.TAG === /* Ok */0) {
              var availableSorts = Decco.optionFromJson((function (param) {
                      return Decco.arrayFromJson(t_decode$5, param);
                    }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "availableSorts"), null));
              if (availableSorts.TAG === /* Ok */0) {
                return {
                        TAG: /* Ok */0,
                        _0: {
                          page: page._0,
                          products: products._0,
                          filters: filters._0,
                          total: total._0,
                          syncStatus: syncStatus._0,
                          defaultSortOrder: defaultSortOrder._0,
                          availableSorts: availableSorts._0
                        }
                      };
              }
              var e = availableSorts._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".availableSorts" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = defaultSortOrder._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".defaultSortOrder" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = syncStatus._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".syncStatus" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = total._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".total" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = filters._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".filters" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = products._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".products" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = page._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".page" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

var codec$17 = [
  t_encode$39,
  t_decode$39
];

var empty_page$1 = {
  previous: undefined,
  next: undefined,
  offset: undefined,
  limit: undefined
};

var empty_products$1 = [];

var empty$3 = {
  page: empty_page$1,
  products: empty_products$1,
  filters: undefined,
  total: undefined,
  syncStatus: undefined,
  defaultSortOrder: undefined,
  availableSorts: undefined
};

var Page2 = {
  t_encode: t_encode$39,
  t_decode: t_decode$39,
  codec: codec$17,
  toJson: t_encode$39,
  fromJson: t_decode$39,
  empty: empty$3
};

var Products = {
  Sort: undefined,
  t_encode: t_encode$37,
  t_decode: t_decode$37,
  codec: codec$15,
  toJson: t_encode$37,
  fromJson: t_decode$37,
  Page: Page$3,
  Page2: Page2
};

function kind_encode(v) {
  switch (v) {
    case /* MultiLineTextField */0 :
        return ["MultiLineTextField"];
    case /* SingleLineTextField */1 :
        return ["SingleLineTextField"];
    case /* DecimalField */2 :
        return ["DecimalField"];
    case /* IntegerField */3 :
        return ["IntegerField"];
    case /* Dimension */4 :
        return ["Dimension"];
    case /* Date */5 :
        return ["Date"];
    case /* DateTime */6 :
        return ["DateTime"];
    case /* Color */7 :
        return ["Color"];
    case /* Boolean */8 :
        return ["Boolean"];
    case /* Json */9 :
        return ["Json"];
    case /* Money */10 :
        return ["Money"];
    case /* Volume */11 :
        return ["Volume"];
    case /* Weight */12 :
        return ["Weight"];
    case /* Url */13 :
        return ["Url"];
    case /* Rating */14 :
        return ["Rating"];
    case /* FileReference */15 :
        return ["FileReference"];
    case /* ListColor */16 :
        return ["ListColor"];
    case /* ListDimension */17 :
        return ["ListDimension"];
    case /* ListLink */18 :
        return ["ListLink"];
    case /* ListRating */19 :
        return ["ListRating"];
    case /* ListSingleLineTextField */20 :
        return ["ListSingleLineTextField"];
    case /* ListUrl */21 :
        return ["ListUrl"];
    case /* ListVolume */22 :
        return ["ListVolume"];
    case /* ListWeight */23 :
        return ["ListWeight"];
    case /* ListNumberDecimal */24 :
        return ["ListNumberDecimal"];
    case /* ListNumberInteger */25 :
        return ["ListNumberInteger"];
    case /* ListProductReference */26 :
        return ["ListProductReference"];
    case /* Unsupported */27 :
        return ["Unsupported"];
    
  }
}

function kind_decode(v) {
  var jsonArr = Js_json.classify(v);
  if (typeof jsonArr === "number") {
    return Decco.error(undefined, "Not a variant", v);
  }
  if (jsonArr.TAG !== /* JSONArray */3) {
    return Decco.error(undefined, "Not a variant", v);
  }
  var jsonArr$1 = jsonArr._0;
  if (jsonArr$1.length === 0) {
    return Decco.error(undefined, "Expected variant, found empty array", v);
  }
  var tagged = Js_array.map(Js_json.classify, jsonArr$1);
  var match = Belt_Array.getExn(tagged, 0);
  if (typeof match !== "number" && match.TAG === /* JSONString */0) {
    switch (match._0) {
      case "Boolean" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* Boolean */8
                  };
          }
      case "Color" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* Color */7
                  };
          }
      case "Date" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* Date */5
                  };
          }
      case "DateTime" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* DateTime */6
                  };
          }
      case "DecimalField" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* DecimalField */2
                  };
          }
      case "Dimension" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* Dimension */4
                  };
          }
      case "FileReference" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* FileReference */15
                  };
          }
      case "IntegerField" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* IntegerField */3
                  };
          }
      case "Json" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* Json */9
                  };
          }
      case "ListColor" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* ListColor */16
                  };
          }
      case "ListDimension" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* ListDimension */17
                  };
          }
      case "ListLink" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* ListLink */18
                  };
          }
      case "ListNumberDecimal" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* ListNumberDecimal */24
                  };
          }
      case "ListNumberInteger" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* ListNumberInteger */25
                  };
          }
      case "ListProductReference" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* ListProductReference */26
                  };
          }
      case "ListRating" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* ListRating */19
                  };
          }
      case "ListSingleLineTextField" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* ListSingleLineTextField */20
                  };
          }
      case "ListUrl" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* ListUrl */21
                  };
          }
      case "ListVolume" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* ListVolume */22
                  };
          }
      case "ListWeight" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* ListWeight */23
                  };
          }
      case "Money" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* Money */10
                  };
          }
      case "MultiLineTextField" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* MultiLineTextField */0
                  };
          }
      case "Rating" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* Rating */14
                  };
          }
      case "SingleLineTextField" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* SingleLineTextField */1
                  };
          }
      case "Unsupported" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* Unsupported */27
                  };
          }
      case "Url" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* Url */13
                  };
          }
      case "Volume" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* Volume */11
                  };
          }
      case "Weight" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: /* Weight */12
                  };
          }
      default:
        
    }
  }
  return Decco.error(undefined, "Invalid variant constructor", Belt_Array.getExn(jsonArr$1, 0));
}

function t_encode$40(v) {
  return Js_dict.fromArray([
              [
                "key",
                Decco.stringToJson(v.key)
              ],
              [
                "kind",
                kind_encode(v.kind)
              ]
            ]);
}

function t_decode$40(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var key = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "key"), null));
  if (key.TAG === /* Ok */0) {
    var kind = kind_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "kind"), null));
    if (kind.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                key: key._0,
                kind: kind._0
              }
            };
    }
    var e = kind._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".kind" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = key._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".key" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var codec$18 = [
  t_encode$40,
  t_decode$40
];

var MetadataField = {
  kind_encode: kind_encode,
  kind_decode: kind_decode,
  t_encode: t_encode$40,
  t_decode: t_decode$40,
  codec: codec$18,
  toJson: t_encode$40,
  fromJson: t_decode$40
};

function t_encode$41(v) {
  return Js_dict.fromArray([
              [
                "page",
                Curry._1(PageMeta$ApiTypes.t_encode, v.page)
              ],
              [
                "fields",
                Decco.arrayToJson(t_encode$40, v.fields)
              ]
            ]);
}

function t_decode$41(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var page = Curry._1(PageMeta$ApiTypes.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "page"), null));
  if (page.TAG === /* Ok */0) {
    var fields = Decco.arrayFromJson(t_decode$40, Belt_Option.getWithDefault(Js_dict.get(dict$1, "fields"), null));
    if (fields.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                page: page._0,
                fields: fields._0
              }
            };
    }
    var e = fields._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".fields" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = page._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".page" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var codec$19 = [
  t_encode$41,
  t_decode$41
];

var Page$4 = {
  t_encode: t_encode$41,
  t_decode: t_decode$41,
  codec: codec$19,
  toJson: t_encode$41,
  fromJson: t_decode$41
};

var MetadataFields = {
  Page: Page$4
};

var toJson = t_encode$2;

var fromJson = t_decode$2;

export {
  ReviewDataSource ,
  embeddedApp_encode ,
  embeddedApp_decode ,
  Status ,
  currentShopifyScopes ,
  t_encode$2 as t_encode,
  t_decode$2 as t_decode,
  codec ,
  toJson ,
  fromJson ,
  Public ,
  Patch ,
  ProductSortOrder ,
  $$Image ,
  VideoSource ,
  Video ,
  Media ,
  $$MediaList ,
  $$Audio ,
  ProductReviewSummary ,
  Money ,
  PhysicalQuantity ,
  Rating ,
  Link ,
  Metadata ,
  Product ,
  CategoryProductEntry ,
  CategoryProductEntries ,
  Facets ,
  ProductFilters2 ,
  ProductFilters ,
  Category ,
  Categories ,
  Products ,
  MetadataField ,
  MetadataFields ,
}
/* StoreId-ApiTypes Not a pure module */

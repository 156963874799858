// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json__Decode from "../../../../../rescript-json/lib/es6_global/src/Json__Decode.bs.js";
import * as Json__Encode from "../../../../../rescript-json/lib/es6_global/src/Json__Encode.bs.js";
import * as DeccoAdapter$ApiTypes from "../utils/DeccoAdapter.bs.js";

function toJson(param) {
  return Json__Encode.object([
              [
                "label",
                Json__Encode.string(param.label)
              ],
              [
                "url",
                Json__Encode.string(param.url)
              ]
            ]);
}

var fromJson = Json__Decode.map2(Json__Decode.field("label", Json__Decode.string), Json__Decode.field("url", Json__Decode.string), (function (label, url) {
        return {
                label: label,
                url: url
              };
      }));

var include = DeccoAdapter$ApiTypes.Make({
      toJson: toJson,
      fromJson: fromJson
    });

var t_encode = include.t_encode;

var t_decode = include.t_decode;

var CallToAction_codec = include.codec;

var CallToAction = {
  toJson: toJson,
  fromJson: fromJson,
  t_encode: t_encode,
  t_decode: t_decode,
  codec: CallToAction_codec
};

var callToAction_encode = t_encode;

var callToAction_decode = t_decode;

export {
  CallToAction ,
  callToAction_encode ,
  callToAction_decode ,
}
/* fromJson Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as SWR from "../../../../node_modules/rescript-swr/lib/es6_global/src/SWR.bs.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Category$Showside from "../types/Category.bs.js";
function getCategoryById(smartnavApiHost, id) {
  var containsConfigSubstr = smartnavApiHost + "/api/get_config?";
  var containsCategoriesSubstr = smartnavApiHost + "/api/get_config_nodes?";
  var swrKeys = Belt_Array.keep(Array.from(SWR.Cache.cache.keys()), function (key) {
    if (key.indexOf(containsConfigSubstr) === 0) {
      return true;
    } else {
      return key.indexOf(containsCategoriesSubstr) === 0;
    }
  });
  var _i = 0;
  while (true) {
    var i = _i;
    var key = Belt_Array.get(swrKeys, i);
    var category = Belt_Option.flatMap(key, function (key) {
      try {
        var containsCategories = key.indexOf(containsCategoriesSubstr) === 0;
        if (containsCategories) {
          var page = SWR.Cache.cache.get(key);
          return Category$Showside.getCategoryById(page.categories, id);
        }
        var containsConfig = key.indexOf(containsConfigSubstr) === 0;
        if (!containsConfig) {
          return;
        }
        var config = SWR.Cache.cache.get(key);
        return Category$Showside.getCategoryById(config.categories, id);
      } catch (e) {
        return;
      }
    });
    if (category !== undefined) {
      return category;
    }
    if (i >= swrKeys.length) {
      return;
    }
    _i = i + 1 | 0;
    continue;
  }
  ;
}
function getProductById(smartnavApiHost, currency, country, id) {
  var containsArraySubstr = [smartnavApiHost + "/api/get_products?"];
  var containsPageSubstr = [smartnavApiHost + "/api/get_products_by_category2?", smartnavApiHost + "/api/get_products_by_category3?", smartnavApiHost + "/api/get_related_products?"];
  var keyStartsWithSubstr = function (key) {
    if (Belt_Array.some(containsArraySubstr, function (str) {
      return key.indexOf(str) === 0;
    })) {
      return true;
    } else {
      return Belt_Array.some(containsPageSubstr, function (str) {
        return key.indexOf(str) === 0;
      });
    }
  };
  var swrKeys = Belt_Array.keep(Array.from(SWR.Cache.cache.keys()), function (key) {
    if (keyStartsWithSubstr(key)) {
      if (Belt_Option.mapWithDefault(country, true, function (country) {
        return key.includes("country=" + country);
      }) || Belt_Option.mapWithDefault(currency, true, function (currency) {
        return key.includes("currency=" + currency);
      })) {
        return true;
      } else if (country === undefined) {
        return currency === undefined;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
  var _i = 0;
  while (true) {
    var i = _i;
    var key = Belt_Array.get(swrKeys, i);
    var product = Belt_Option.flatMap(key, function (key) {
      try {
        var containsArrays = Belt_Array.some(containsArraySubstr, function (str) {
          return key.indexOf(str) === 0;
        });
        if (containsArrays) {
          var products = SWR.Cache.cache.get(key);
          return Belt_Array.getBy(Belt_Option.getWithDefault(products, []), function (product) {
            return product.id === id;
          });
        }
        var containsPage = Belt_Array.some(containsPageSubstr, function (str) {
          return key.indexOf(str) === 0;
        });
        if (!containsPage) {
          return;
        }
        var page = SWR.Cache.cache.get(key);
        return Belt_Array.getBy(Belt_Option.mapWithDefault(page, [], function (page) {
          return page.products;
        }), function (product) {
          return product.id === id;
        });
      } catch (e) {
        return;
      }
    });
    if (product !== undefined) {
      return product;
    }
    if (i >= swrKeys.length) {
      return;
    }
    _i = i + 1 | 0;
    continue;
  }
  ;
}
function getProductsById(smartnavApiHost, currency, country, productIds) {
  return Belt_Array.keepMap(productIds, function (productId) {
    return getProductById(smartnavApiHost, currency, country, productId);
  });
}
export { getCategoryById, getProductById, getProductsById };
/* SWR Not a pure module */
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
function getSessionStorageKey(key) {
  return "smartnav-picture-in-picture__" + window.location.origin + "__" + key;
}
function getFromSessionStorage(key) {
  try {
    return Belt_Option.flatMap(Caml_option.null_to_opt(sessionStorage.getItem(getSessionStorageKey(key))), function (value) {
      if (value === "") {
        return;
      } else {
        return value;
      }
    });
  } catch (exn) {
    return;
  }
}
function setToSessionStorage(key, value) {
  try {
    sessionStorage.setItem(getSessionStorageKey(key), value);
    return;
  } catch (exn) {
    return;
  }
}
function storeLastCategory(route) {
  var categoryId;
  if (typeof route === "number") {
    categoryId = undefined;
  } else {
    switch (route.TAG | 0) {
      case /* ListPage */1:
        var listPageType = route._0.listPageType;
        categoryId = typeof listPageType === "number" || listPageType.TAG !== /* Category */0 ? undefined : listPageType._0;
        break;
      case /* PDP */2:
        var match = route._0;
        var productCategoryId = match.productCategoryId;
        var categoryId$1 = match.categoryId;
        categoryId = productCategoryId !== undefined ? productCategoryId : categoryId$1 !== undefined ? categoryId$1 : undefined;
        break;
      default:
        categoryId = undefined;
    }
  }
  if (categoryId !== undefined) {
    return setToSessionStorage("category", categoryId);
  }
}
function clearLastCategory(param) {
  setToSessionStorage("category", "");
}
export { getSessionStorageKey, getFromSessionStorage, setToSessionStorage, storeLastCategory, clearLastCategory };
/* No side effect */
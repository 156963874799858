// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json__Decode from "../../../../rescript-json/lib/es6_global/src/Json__Decode.bs.js";
import * as Json__Encode from "../../../../rescript-json/lib/es6_global/src/Json__Encode.bs.js";
import * as DeccoAdapter$ApiTypes from "./utils/DeccoAdapter.bs.js";

function Make($star) {
  var toString = function (id) {
    return id;
  };
  var fromString = function (id) {
    return id;
  };
  var include = DeccoAdapter$ApiTypes.Make({
        toJson: Json__Encode.string,
        fromJson: Json__Decode.string
      });
  return {
          toString: toString,
          fromString: fromString,
          toJson: Json__Encode.string,
          fromJson: Json__Decode.string,
          t_encode: include.t_encode,
          t_decode: include.t_decode,
          codec: include.codec
        };
}

export {
  Make ,
}
/* Json__Decode Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../rescript/lib/es6/curry.js";
import * as Json__Decode from "../../../../../rescript-json/lib/es6_global/src/Json__Decode.bs.js";
import * as Json__Encode from "../../../../../rescript-json/lib/es6_global/src/Json__Encode.bs.js";
import * as Generic$ApiTypes from "../utils/Generic.bs.js";
import * as DeccoAdapter$ApiTypes from "../utils/DeccoAdapter.bs.js";
import * as Tree_LinkCodecs$ApiTypes from "./Tree_LinkCodecs.bs.js";
import * as Tree_LayoutCodecs$ApiTypes from "./Tree_LayoutCodecs.bs.js";
import * as QuickViewPinCodecs$ApiTypes from "../QuickViewPinCodecs.bs.js";
import * as Tree_CardShapeCodecs$ApiTypes from "./Tree_CardShapeCodecs.bs.js";
import * as Tree_TaggedProductsCodecs$ApiTypes from "./Tree_TaggedProductsCodecs.bs.js";

function node_encode(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, [
              [
                "id",
                Curry._1(Generic$ApiTypes.Encode.string, param.id)
              ],
              [
                "image",
                Generic$ApiTypes.Encode.nullable(param.image, Generic$ApiTypes.Encode.string)
              ],
              [
                "imageWidth",
                Generic$ApiTypes.Encode.nullable(param.imageWidth, Generic$ApiTypes.Encode.$$int)
              ],
              [
                "imageHeight",
                Generic$ApiTypes.Encode.nullable(param.imageHeight, Generic$ApiTypes.Encode.$$int)
              ],
              [
                "imageHotspots",
                Generic$ApiTypes.Encode.nullable(param.imageHotspots, (function (__x) {
                        return Curry._2(Generic$ApiTypes.Encode.array, __x, QuickViewPinCodecs$ApiTypes.toJson);
                      }))
              ],
              [
                "video",
                Generic$ApiTypes.Encode.nullable(param.video, Generic$ApiTypes.Encode.string)
              ],
              [
                "layout",
                Generic$ApiTypes.Encode.nullable(param.layout, Tree_LayoutCodecs$ApiTypes.Layout.toJson)
              ],
              [
                "cardShape",
                Generic$ApiTypes.Encode.nullable(param.cardShape, Tree_CardShapeCodecs$ApiTypes.CardShape.toJson)
              ],
              [
                "cardSize",
                Generic$ApiTypes.Encode.nullable(param.cardSize, Generic$ApiTypes.Encode.string)
              ],
              [
                "link",
                Generic$ApiTypes.Encode.nullable(param.link, Tree_LinkCodecs$ApiTypes.Link.toJson)
              ],
              [
                "sliderRows",
                Generic$ApiTypes.Encode.nullable(param.sliderRows, Generic$ApiTypes.Encode.$$int)
              ],
              [
                "gridMaxRows",
                Generic$ApiTypes.Encode.nullable(param.gridMaxRows, Generic$ApiTypes.Encode.$$int)
              ]
            ]);
}

var node_decode = Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._1(Generic$ApiTypes.Decode.succeed, (function (id, image, imageWidth, imageHeight, imageHotspots, video, layout, cardShape, cardSize, link, sliderRows, gridMaxRows, googleFeedIds) {
                                                            return {
                                                                    id: id,
                                                                    image: image,
                                                                    imageWidth: imageWidth,
                                                                    imageHeight: imageHeight,
                                                                    imageHotspots: imageHotspots,
                                                                    video: video,
                                                                    layout: layout,
                                                                    cardShape: cardShape,
                                                                    cardSize: cardSize,
                                                                    link: link,
                                                                    sliderRows: sliderRows,
                                                                    gridMaxRows: gridMaxRows,
                                                                    googleFeedIds: googleFeedIds
                                                                  };
                                                          })), Generic$ApiTypes.Decode.fieldWithDefault("id", Generic$ApiTypes.Decode.string, "")), Curry._2(Generic$ApiTypes.Decode.field, "image", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.string))), Curry._2(Generic$ApiTypes.Decode.field, "imageWidth", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.$$int))), Curry._2(Generic$ApiTypes.Decode.field, "imageHeight", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.$$int))), Curry._2(Generic$ApiTypes.Decode.field, "imageHotspots", Curry._1(Generic$ApiTypes.Decode.option, Curry._1(Generic$ApiTypes.Decode.array, QuickViewPinCodecs$ApiTypes.fromJson)))), Curry._2(Generic$ApiTypes.Decode.field, "video", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.string))), Curry._2(Generic$ApiTypes.Decode.field, "layout", Curry._1(Generic$ApiTypes.Decode.option, Tree_LayoutCodecs$ApiTypes.Layout.fromJson))), Curry._2(Generic$ApiTypes.Decode.field, "cardShape", Curry._1(Generic$ApiTypes.Decode.option, Tree_CardShapeCodecs$ApiTypes.CardShape.fromJson))), Curry._2(Generic$ApiTypes.Decode.field, "cardSize", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.string))), Generic$ApiTypes.Decode.fieldWithDefault("link", Curry._1(Generic$ApiTypes.Decode.option, Tree_LinkCodecs$ApiTypes.Link.fromJson), undefined)), Generic$ApiTypes.Decode.fieldWithDefault("sliderRows", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.$$int), undefined)), Generic$ApiTypes.Decode.fieldWithDefault("gridMaxRows", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.$$int), undefined)), Generic$ApiTypes.Decode.fieldWithDefault("googleFeedIds", Curry._1(Generic$ApiTypes.Decode.option, Tree_TaggedProductsCodecs$ApiTypes.TaggedProducts.fromJson), undefined));

var fromJson = Json__Decode.map(Json__Decode.field("mobile", node_decode), (function (mobile) {
        return {
                mobile: mobile
              };
      }));

function toJson(param) {
  return Json__Encode.object([[
                "mobile",
                node_encode(param.mobile)
              ]]);
}

var include = DeccoAdapter$ApiTypes.Make({
      toJson: toJson,
      fromJson: fromJson
    });

var NodeBreakpoint_empty = {
  mobile: {
    id: "",
    image: undefined,
    imageWidth: undefined,
    imageHeight: undefined,
    imageHotspots: undefined,
    video: undefined,
    layout: undefined,
    cardShape: undefined,
    cardSize: undefined,
    link: undefined,
    sliderRows: undefined,
    gridMaxRows: undefined,
    googleFeedIds: undefined
  }
};

var NodeBreakpoint_t_encode = include.t_encode;

var NodeBreakpoint_t_decode = include.t_decode;

var NodeBreakpoint_codec = include.codec;

var NodeBreakpoint = {
  node_encode: node_encode,
  node_decode: node_decode,
  fromJson: fromJson,
  toJson: toJson,
  empty: NodeBreakpoint_empty,
  t_encode: NodeBreakpoint_t_encode,
  t_decode: NodeBreakpoint_t_decode,
  codec: NodeBreakpoint_codec
};

export {
  NodeBreakpoint ,
}
/* node_decode Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

function toString($$event) {
  if (typeof $$event !== "object") {
    if ($$event === "open") {
      return "open";
    } else if ($$event === "slider:category") {
      return "slider:category";
    } else if ($$event === "Clicked Secondary Button") {
      return "Clicked Secondary Button";
    } else if ($$event === "Clicked Hide Products Button") {
      return "Clicked Hide Products Button";
    } else if ($$event === "page view") {
      return "page_view";
    } else if ($$event === "mkrbio:link_clicked") {
      return "mkrbio:link_clicked";
    } else if ($$event === "Clicked PDP Carousel Close Button") {
      return "Clicked PDP Carousel Close Button";
    } else if ($$event === "Opened") {
      return "Opened";
    } else if ($$event === "loaded") {
      return "loadaed";
    } else if ($$event === "quickview:open") {
      return "quickview:open";
    } else if ($$event === "Clicked Media Carousel Close Button") {
      return "Clicked Media Carousel Close Button";
    } else if ($$event === "product details:image select") {
      return "product details:image select";
    } else if ($$event === "swipe down drawer") {
      return "swipe down drawer";
    } else if ($$event === "product details:description") {
      return "product details:description";
    } else if ($$event === "Clicked CTA Button") {
      return "Clicked CTA Button";
    } else if ($$event === "slider:promo title") {
      return "slider:promo title";
    } else if ($$event === "mkrbio:cta_clicked") {
      return "mkrbio:cta_clicked";
    } else if ($$event === "product details:add to cart") {
      return "product details:add to cart";
    } else if ($$event === "product details:other products") {
      return "product details:other products";
    } else if ($$event === "Clicked PDP Photo") {
      return "Clicked PDP Photo";
    } else if ($$event === "open top category") {
      return "open top category";
    } else if ($$event === "product details:open") {
      return "product details:open";
    } else if ($$event === "slider:title") {
      return "slider:title";
    } else if ($$event === "Swiped Products Drawer Down To Bottom") {
      return "Swiped Products Drawer Down To Bottom";
    } else if ($$event === "Clicked Product Pins Tips On") {
      return "Clicked Product Pins Tips On";
    } else if ($$event === "slider:item") {
      return "slider:item";
    } else if ($$event === "Clicked Product Stack Thumbnail") {
      return "Clicked Product Stack Thumbnail";
    } else if ($$event === "Clicked Product Pin") {
      return "Clicked Product Pin";
    } else if ($$event === "slider:see all link") {
      return "slider:see all link";
    } else if ($$event === "Clicked Product Pins Tips Off") {
      return "Clicked Product Pins Tips Off";
    } else if ($$event === "open link") {
      return "open link";
    } else if ($$event === "Clicked Related Product Card") {
      return "Clicked Related Product Card";
    } else if ($$event === "Clicked Product Variant") {
      return "Clicked Product Variant";
    } else if ($$event === "Clicked In-Media Product Card") {
      return "Clicked In-Media Product Card";
    } else if ($$event === "Swiped Products Drawer Up To Top") {
      return "Swiped Products Drawer Up To Top";
    } else if ($$event === "exit click") {
      return "exit click";
    } else if ($$event === "open index") {
      return "open index";
    } else if ($$event === "timespent") {
      return "time spent";
    } else if ($$event === "menu:link clicked") {
      return "menu:link clicked";
    } else if ($$event === "Clicked View Products Button") {
      return "Clicked View Products Button";
    } else if ($$event === "checkout") {
      return "checkout";
    } else if ($$event === "swipe up drawer") {
      return "swipe up drawer";
    } else if ($$event === "sort expanded") {
      return "sort expanded";
    } else if ($$event === "product details:swatch") {
      return "product details:swatch";
    } else if ($$event === "menu:open") {
      return "menu:open";
    } else if ($$event === "product details:image scroll") {
      return "product details:image scroll";
    } else if ($$event === "back button") {
      return "back button";
    } else if ($$event === "slider:promo") {
      return "slider:promo";
    } else if ($$event === "top category nav") {
      return "top category nav";
    } else if ($$event === "filter expanded") {
      return "filter expanded";
    } else if ($$event === "mkrbio:made_with_maker") {
      return "mkrbio:made_with_maker";
    } else {
      return "Closed";
    }
  }
  var variant = $$event.NAME;
  if (variant === "Clicked Product Card") {
    return "Clicked " + $$event.VAL + " Product Card";
  } else if (variant === "Clicked PDP Section Closed") {
    return "Clicked PDP " + $$event.VAL + " Section Closed";
  } else if (variant === "sec") {
    return "" + $$event.VAL + " sec";
  } else if (variant === "filter applied") {
    return "filter applied";
  } else if (variant === "sort applied") {
    return "sort applied";
  } else if (variant === "clicked action") {
    return "clicked action " + $$event.VAL + "";
  } else {
    return "Clicked PDP " + $$event.VAL + " Section Open";
  }
}
function isNonInteractive($$event) {
  if (typeof $$event === "object") {
    return $$event.NAME === "sec";
  } else if ($$event === "product details:image scroll" || $$event === "menu:open" || $$event === "timespent" || $$event === "loaded" || $$event === "Opened" || $$event === "page view") {
    return true;
  } else {
    return $$event === "open";
  }
}
export { toString, isNonInteractive };
/* No side effect */
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../rescript/lib/es6/curry.js";
import * as Json__Decode from "../../../../../rescript-json/lib/es6_global/src/Json__Decode.bs.js";
import * as Json__Encode from "../../../../../rescript-json/lib/es6_global/src/Json__Encode.bs.js";
import * as Generic$ApiTypes from "../utils/Generic.bs.js";
import * as DeccoAdapter$ApiTypes from "../utils/DeccoAdapter.bs.js";

var partial_arg = [
  [
    "Default",
    /* Default */0
  ],
  [
    "Slider",
    /* Slider */1
  ],
  [
    "Grid",
    /* Grid */2
  ],
  [
    "AdvancedGrid",
    /* AdvancedGrid */3
  ],
  [
    "AdvancedSlider",
    /* AdvancedSlider */4
  ]
];

function labels_encode(param) {
  return Generic$ApiTypes.Encode.simpleVariant(partial_arg, param);
}

var labels_decode = Generic$ApiTypes.Decode.simpleVariant([
      [
        "Default",
        /* Default */0
      ],
      [
        "Slider",
        /* Slider */1
      ],
      [
        "Grid",
        /* Grid */2
      ],
      [
        "AdvancedGrid",
        /* AdvancedGrid */3
      ],
      [
        "AdvancedSlider",
        /* AdvancedSlider */4
      ]
    ]);

var cellContent_fromJson = Generic$ApiTypes.Decode.simpleVariant([
      [
        "Slot",
        /* Slot */0
      ],
      [
        "Whitespace",
        /* Whitespace */1
      ]
    ]);

var partial_arg$1 = [
  [
    "Slot",
    /* Slot */0
  ],
  [
    "Whitespace",
    /* Whitespace */1
  ]
];

function cellContent_toJson(param) {
  return Generic$ApiTypes.Encode.simpleVariant(partial_arg$1, param);
}

function cell_toJson(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, [
              [
                "colSpan",
                Curry._1(Generic$ApiTypes.Encode.$$int, param.colSpan)
              ],
              [
                "rowSpan",
                Curry._1(Generic$ApiTypes.Encode.$$int, param.rowSpan)
              ],
              [
                "content",
                Curry._1(cellContent_toJson, param.content)
              ]
            ]);
}

var cell_fromJson = Curry._4(Generic$ApiTypes.Decode.map3, Curry._2(Generic$ApiTypes.Decode.field, "colSpan", Generic$ApiTypes.Decode.$$int), Curry._2(Generic$ApiTypes.Decode.field, "rowSpan", Generic$ApiTypes.Decode.$$int), Curry._2(Generic$ApiTypes.Decode.field, "content", cellContent_fromJson), (function (colSpan, rowSpan, content) {
        return {
                colSpan: colSpan,
                rowSpan: rowSpan,
                content: content
              };
      }));

function toJson(__x) {
  return Curry._2(Generic$ApiTypes.Encode.array, __x, (function (__x) {
                return Curry._2(Generic$ApiTypes.Encode.array, __x, cell_toJson);
              }));
}

var fromJson = Curry._1(Generic$ApiTypes.Decode.array, Curry._1(Generic$ApiTypes.Decode.array, cell_fromJson));

var GridPattern = {
  cellContent_fromJson: cellContent_fromJson,
  cellContent_toJson: cellContent_toJson,
  cell_toJson: cell_toJson,
  cell_fromJson: cell_fromJson,
  toJson: toJson,
  fromJson: fromJson
};

function gridOptions_encode(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, [
              [
                "columns",
                Generic$ApiTypes.Encode.nullable(param.columns, Generic$ApiTypes.Encode.$$int)
              ],
              [
                "gap",
                Generic$ApiTypes.Encode.nullable(param.gap, Generic$ApiTypes.Encode.$$int)
              ],
              [
                "maxWidth",
                Generic$ApiTypes.Encode.nullable(param.maxWidth, Generic$ApiTypes.Encode.$$int)
              ],
              [
                "padding",
                Generic$ApiTypes.Encode.nullable(param.padding, Generic$ApiTypes.Encode.$$int)
              ]
            ]);
}

var gridOptions_decode = Curry._5(Generic$ApiTypes.Decode.map4, Curry._2(Generic$ApiTypes.Decode.field, "columns", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.$$int)), Curry._2(Generic$ApiTypes.Decode.field, "gap", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.$$int)), Curry._2(Generic$ApiTypes.Decode.field, "maxWidth", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.$$int)), Curry._2(Generic$ApiTypes.Decode.field, "padding", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.$$int)), (function (cols, gap, maxWidth, padding) {
        return {
                columns: cols,
                gap: gap,
                maxWidth: maxWidth,
                padding: padding
              };
      }));

var partial_arg$2 = [
  [
    "EffectSlide",
    /* EffectSlide */0
  ],
  [
    "EffectFade",
    /* EffectFade */1
  ]
];

function sliderEffect_encode(param) {
  return Generic$ApiTypes.Encode.simpleVariant(partial_arg$2, param);
}

var sliderEffect_decode = Generic$ApiTypes.Decode.simpleVariant([
      [
        "EffectSlide",
        /* EffectSlide */0
      ],
      [
        "EffectFade",
        /* EffectFade */1
      ]
    ]);

var partial_arg$3 = [
  [
    "NavAuto",
    /* NavAuto */0
  ],
  [
    "NavScrollbar",
    /* NavScrollbar */2
  ],
  [
    "NavArrows",
    /* NavArrows */1
  ],
  [
    "NavDots",
    /* NavDots */3
  ],
  [
    "NavNone",
    /* NavNone */4
  ]
];

function sliderNav_encode(param) {
  return Generic$ApiTypes.Encode.simpleVariant(partial_arg$3, param);
}

var sliderNav_decode = Generic$ApiTypes.Decode.simpleVariant([
      [
        "NavAuto",
        /* NavAuto */0
      ],
      [
        "NavScrollbar",
        /* NavScrollbar */2
      ],
      [
        "NavArrows",
        /* NavArrows */1
      ],
      [
        "NavDots",
        /* NavDots */3
      ],
      [
        "NavNone",
        /* NavNone */4
      ]
    ]);

var partial_arg$4 = [
  [
    "TransitionEase",
    /* TransitionEase */0
  ],
  [
    "TransitionLinear",
    /* TransitionLinear */1
  ],
  [
    "TransitionEaseIn",
    /* TransitionEaseIn */2
  ],
  [
    "TransitionEaseOut",
    /* TransitionEaseOut */3
  ],
  [
    "TransitionEaseInOut",
    /* TransitionEaseInOut */4
  ]
];

function transition_encode(param) {
  return Generic$ApiTypes.Encode.simpleVariant(partial_arg$4, param);
}

var transition_decode = Generic$ApiTypes.Decode.simpleVariant([
      [
        "TransitionEase",
        /* TransitionEase */0
      ],
      [
        "TransitionLinear",
        /* TransitionLinear */1
      ],
      [
        "TransitionEaseIn",
        /* TransitionEaseIn */2
      ],
      [
        "TransitionEaseOut",
        /* TransitionEaseOut */3
      ],
      [
        "TransitionEaseInOut",
        /* TransitionEaseInOut */4
      ]
    ]);

function sliderOptions_encode(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, [
              [
                "columns",
                Generic$ApiTypes.Encode.nullable(param.columns, Generic$ApiTypes.Encode.$$float)
              ],
              [
                "gap",
                Generic$ApiTypes.Encode.nullable(param.gap, Generic$ApiTypes.Encode.$$int)
              ],
              [
                "maxWidth",
                Generic$ApiTypes.Encode.nullable(param.maxWidth, Generic$ApiTypes.Encode.$$int)
              ],
              [
                "padding",
                Generic$ApiTypes.Encode.nullable(param.padding, Generic$ApiTypes.Encode.$$int)
              ],
              [
                "effect",
                Curry._1(sliderEffect_encode, param.effect)
              ],
              [
                "nav",
                Curry._1(sliderNav_encode, param.nav)
              ],
              [
                "autoplay",
                Curry._1(Generic$ApiTypes.Encode.bool, param.autoplay)
              ],
              [
                "transition",
                Curry._1(transition_encode, param.transition)
              ],
              [
                "transitionSpeed",
                Curry._1(Generic$ApiTypes.Encode.$$int, param.transitionSpeed)
              ],
              [
                "autoplayDelay",
                Curry._1(Generic$ApiTypes.Encode.$$int, param.autoplayDelay)
              ]
            ]);
}

var sliderOptions_decode = Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._1(Generic$ApiTypes.Decode.succeed, (function (columns, gap, maxWidth, padding, effect, nav, transition, transitionSpeed, autoplay, autoplayDelay, loop) {
                                                    return {
                                                            columns: columns,
                                                            gap: gap,
                                                            maxWidth: maxWidth,
                                                            padding: padding,
                                                            effect: effect,
                                                            nav: nav,
                                                            loop: loop,
                                                            autoplay: autoplay,
                                                            transition: transition,
                                                            transitionSpeed: transitionSpeed,
                                                            autoplayDelay: autoplayDelay
                                                          };
                                                  })), Curry._2(Generic$ApiTypes.Decode.field, "columns", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.$$float))), Curry._2(Generic$ApiTypes.Decode.field, "gap", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.$$int))), Curry._2(Generic$ApiTypes.Decode.field, "maxWidth", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.$$int))), Curry._2(Generic$ApiTypes.Decode.field, "padding", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.$$int))), Curry._2(Generic$ApiTypes.Decode.field, "effect", sliderEffect_decode)), Generic$ApiTypes.Decode.fieldWithDefault("nav", sliderNav_decode, /* NavAuto */0)), Generic$ApiTypes.Decode.fieldWithDefault("transition", transition_decode, /* TransitionEaseOut */3)), Generic$ApiTypes.Decode.fieldWithDefault("transitionSpeed", Generic$ApiTypes.Decode.$$int, 300)), Generic$ApiTypes.Decode.fieldWithDefault("autoplay", Generic$ApiTypes.Decode.bool, false)), Generic$ApiTypes.Decode.fieldWithDefault("autoplayDelay", Generic$ApiTypes.Decode.$$int, 3000)), Generic$ApiTypes.Decode.fieldWithDefault("loop", Generic$ApiTypes.Decode.bool, false));

function toJson$1(layout) {
  if (typeof layout !== "number") {
    if (layout.TAG === /* AdvancedGrid */0) {
      return Json__Encode.string("AdvancedGrid" + Json__Encode.encode(gridOptions_encode(layout._0), 0));
    } else {
      return Json__Encode.string("AdvancedSlider" + Json__Encode.encode(sliderOptions_encode(layout._0), 0));
    }
  }
  switch (layout) {
    case /* Default */0 :
        return Json__Encode.string("default");
    case /* Slider */1 :
        return Json__Encode.string("slider");
    case /* Grid */2 :
        return Json__Encode.string("grid");
    
  }
}

var advancedGrid_decode = Generic$ApiTypes.Decode.stringifiedPayloadVariant("AdvancedGrid", gridOptions_decode, (function (payload) {
        return {
                TAG: /* AdvancedGrid */0,
                _0: payload
              };
      }));

var advancedSlider_decode = Generic$ApiTypes.Decode.stringifiedPayloadVariant("AdvancedSlider", sliderOptions_decode, (function (payload) {
        return {
                TAG: /* AdvancedSlider */1,
                _0: payload
              };
      }));

var fromJson$1 = Curry._2(Generic$ApiTypes.Decode.oneOf, advancedGrid_decode, [
      advancedSlider_decode,
      Curry._2(Generic$ApiTypes.Decode.map, Generic$ApiTypes.Decode.exactString("grid"), (function (param) {
              return /* Grid */2;
            })),
      Curry._2(Generic$ApiTypes.Decode.map, Generic$ApiTypes.Decode.exactString("slider"), (function (param) {
              return /* Slider */1;
            })),
      Curry._2(Generic$ApiTypes.Decode.map, Generic$ApiTypes.Decode.exactString("default"), (function (param) {
              return /* Default */0;
            }))
    ]);

var include = DeccoAdapter$ApiTypes.Make({
      toJson: toJson$1,
      fromJson: fromJson$1
    });

var Layout_t_encode = include.t_encode;

var Layout_t_decode = include.t_decode;

var Layout_codec = include.codec;

var Layout = {
  labels_encode: labels_encode,
  labels_decode: labels_decode,
  abstract_encode: Json__Encode.string,
  abstract_decode: Json__Decode.string,
  GridPattern: GridPattern,
  gridOptions_encode: gridOptions_encode,
  gridOptions_decode: gridOptions_decode,
  sliderEffect_encode: sliderEffect_encode,
  sliderEffect_decode: sliderEffect_decode,
  sliderNav_encode: sliderNav_encode,
  sliderNav_decode: sliderNav_decode,
  transition_encode: transition_encode,
  transition_decode: transition_decode,
  sliderOptions_encode: sliderOptions_encode,
  sliderOptions_decode: sliderOptions_decode,
  toJson: toJson$1,
  advancedGrid_decode: advancedGrid_decode,
  advancedSlider_decode: advancedSlider_decode,
  fromJson: fromJson$1,
  t_encode: Layout_t_encode,
  t_decode: Layout_t_decode,
  codec: Layout_codec
};

export {
  Layout ,
}
/* labels_decode Not a pure module */

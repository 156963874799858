// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../rescript/lib/es6/curry.js";
import * as Generic$ApiTypes from "../utils/Generic.bs.js";
import * as DeccoAdapter$ApiTypes from "../utils/DeccoAdapter.bs.js";
import * as MkrBio_CallToActionCodecs$ApiTypes from "./MkrBio_CallToActionCodecs.bs.js";
import * as MkrBio_SocialNetworkCodecs$ApiTypes from "./MkrBio_SocialNetworkCodecs.bs.js";
import * as MkrBio_SocialNetworkLinkCodecs$ApiTypes from "./MkrBio_SocialNetworkLinkCodecs.bs.js";
import * as MkrBio_SocialNetworkConnectionCodecs$ApiTypes from "./MkrBio_SocialNetworkConnectionCodecs.bs.js";

function toJson(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, [
              [
                "logoUrl",
                Curry._1(Generic$ApiTypes.Encode.string, param.logoUrl)
              ],
              [
                "name",
                Generic$ApiTypes.Encode.nullable(param.name, Generic$ApiTypes.Encode.string)
              ],
              [
                "description",
                Generic$ApiTypes.Encode.nullable(param.description, Generic$ApiTypes.Encode.string)
              ],
              [
                "callToAction",
                Generic$ApiTypes.Encode.nullable(param.callToAction, MkrBio_CallToActionCodecs$ApiTypes.CallToAction.toJson)
              ],
              [
                "socialNetworksLinks",
                Curry._2(Generic$ApiTypes.Encode.array, param.socialNetworksLinks, MkrBio_SocialNetworkLinkCodecs$ApiTypes.SocialNetworkLink.toJson)
              ],
              [
                "socialNetworkConnections",
                Curry._2(Generic$ApiTypes.Encode.array, param.socialNetworkConnections, MkrBio_SocialNetworkConnectionCodecs$ApiTypes.SocialNetworkConnection.toJson)
              ]
            ]);
}

var fromJson = Curry._7(Generic$ApiTypes.Decode.map6, Curry._2(Generic$ApiTypes.Decode.field, "logoUrl", Generic$ApiTypes.Decode.string), Curry._2(Generic$ApiTypes.Decode.field, "name", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.string)), Curry._2(Generic$ApiTypes.Decode.field, "description", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.string)), Curry._2(Generic$ApiTypes.Decode.field, "callToAction", Curry._1(Generic$ApiTypes.Decode.option, MkrBio_CallToActionCodecs$ApiTypes.CallToAction.fromJson)), Generic$ApiTypes.Decode.fieldWithDefault("socialNetworksLinks", Curry._1(Generic$ApiTypes.Decode.array, MkrBio_SocialNetworkLinkCodecs$ApiTypes.SocialNetworkLink.fromJson), []), Generic$ApiTypes.Decode.fieldWithDefault("socialNetworkConnections", Curry._1(Generic$ApiTypes.Decode.array, MkrBio_SocialNetworkConnectionCodecs$ApiTypes.SocialNetworkConnection.fromJson), []), (function (logoUrl, name, description, callToAction, socialNetworksLinks, socialNetworkConnections) {
        return {
                logoUrl: logoUrl,
                name: name,
                description: description,
                callToAction: callToAction,
                socialNetworksLinks: socialNetworksLinks,
                socialNetworkConnections: socialNetworkConnections
              };
      }));

var include = DeccoAdapter$ApiTypes.Make({
      toJson: toJson,
      fromJson: fromJson
    });

var SocialNetwork = MkrBio_SocialNetworkCodecs$ApiTypes.SocialNetwork;

var socialNetwork_encode = MkrBio_SocialNetworkCodecs$ApiTypes.socialNetwork_encode;

var socialNetwork_decode = MkrBio_SocialNetworkCodecs$ApiTypes.socialNetwork_decode;

var SocialNetworkConnection = MkrBio_SocialNetworkConnectionCodecs$ApiTypes.SocialNetworkConnection;

var socialNetworkConnection_encode = MkrBio_SocialNetworkConnectionCodecs$ApiTypes.socialNetworkConnection_encode;

var socialNetworkConnection_decode = MkrBio_SocialNetworkConnectionCodecs$ApiTypes.socialNetworkConnection_decode;

var SocialNetworkLink = MkrBio_SocialNetworkLinkCodecs$ApiTypes.SocialNetworkLink;

var socialNetworkLink_encode = MkrBio_SocialNetworkLinkCodecs$ApiTypes.socialNetworkLink_encode;

var socialNetworkLink_decode = MkrBio_SocialNetworkLinkCodecs$ApiTypes.socialNetworkLink_decode;

var CallToAction = MkrBio_CallToActionCodecs$ApiTypes.CallToAction;

var callToAction_encode = MkrBio_CallToActionCodecs$ApiTypes.callToAction_encode;

var callToAction_decode = MkrBio_CallToActionCodecs$ApiTypes.callToAction_decode;

var t_encode = include.t_encode;

var t_decode = include.t_decode;

var codec = include.codec;

export {
  SocialNetwork ,
  socialNetwork_encode ,
  socialNetwork_decode ,
  SocialNetworkConnection ,
  socialNetworkConnection_encode ,
  socialNetworkConnection_decode ,
  SocialNetworkLink ,
  socialNetworkLink_encode ,
  socialNetworkLink_decode ,
  CallToAction ,
  callToAction_encode ,
  callToAction_decode ,
  toJson ,
  fromJson ,
  t_encode ,
  t_decode ,
  codec ,
}
/* fromJson Not a pure module */

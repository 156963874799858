// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../rescript/lib/es6/curry.js";
import * as Generic$ApiTypes from "./utils/Generic.bs.js";
import * as DeccoAdapter$ApiTypes from "./utils/DeccoAdapter.bs.js";

function toJson(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, [
              [
                "previous",
                Generic$ApiTypes.Encode.nullable(param.previous, Generic$ApiTypes.Encode.string)
              ],
              [
                "next",
                Generic$ApiTypes.Encode.nullable(param.next, Generic$ApiTypes.Encode.string)
              ],
              [
                "offset",
                Generic$ApiTypes.Encode.nullable(param.offset, Generic$ApiTypes.Encode.$$int)
              ],
              [
                "limit",
                Generic$ApiTypes.Encode.nullable(param.limit, Generic$ApiTypes.Encode.$$int)
              ]
            ]);
}

var fromJson = Curry._5(Generic$ApiTypes.Decode.map4, Curry._2(Generic$ApiTypes.Decode.field, "previous", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.string)), Curry._2(Generic$ApiTypes.Decode.field, "next", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.string)), Generic$ApiTypes.Decode.fieldWithDefault("offset", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.$$int), undefined), Generic$ApiTypes.Decode.fieldWithDefault("limit", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.$$int), undefined), (function (previous, next, offset, limit) {
        return {
                previous: previous,
                next: next,
                offset: offset,
                limit: limit
              };
      }));

var include = DeccoAdapter$ApiTypes.Make({
      toJson: toJson,
      fromJson: fromJson
    });

var $$default = {
  previous: undefined,
  next: undefined,
  offset: undefined,
  limit: undefined
};

var t_encode = include.t_encode;

var t_decode = include.t_decode;

var codec = include.codec;

export {
  toJson ,
  fromJson ,
  $$default ,
  $$default as default,
  t_encode ,
  t_decode ,
  codec ,
}
/* fromJson Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../rescript/lib/es6/curry.js";
import * as Belt_Option from "../../../../../rescript/lib/es6/belt_Option.js";
import * as Json__Encode from "../../../../../rescript-json/lib/es6_global/src/Json__Encode.bs.js";
import * as Generic$ApiTypes from "../utils/Generic.bs.js";
import * as DeccoAdapter$ApiTypes from "../utils/DeccoAdapter.bs.js";

var partial_arg = [
  [
    "Labels",
    /* Labels */0
  ],
  [
    "PillsWithLabel",
    /* PillsWithLabel */1
  ],
  [
    "PillsWithIcon",
    /* PillsWithIcon */2
  ],
  [
    "PillsWithRoundedIcon",
    /* PillsWithRoundedIcon */3
  ]
];

function layout_encode(param) {
  return Generic$ApiTypes.Encode.simpleVariant(partial_arg, param);
}

var layout_decode = Generic$ApiTypes.Decode.simpleVariant([
      [
        "Labels",
        /* Labels */0
      ],
      [
        "PillsWithLabel",
        /* PillsWithLabel */1
      ],
      [
        "PillsWithIcon",
        /* PillsWithIcon */2
      ],
      [
        "PillsWithRoundedIcon",
        /* PillsWithRoundedIcon */3
      ]
    ]);

var partial_arg$1 = [
  [
    "Small",
    /* Small */0
  ],
  [
    "Medium",
    /* Medium */1
  ],
  [
    "Large",
    /* Large */2
  ]
];

function size_encode(param) {
  return Generic$ApiTypes.Encode.simpleVariant(partial_arg$1, param);
}

var size_decode = Generic$ApiTypes.Decode.simpleVariant([
      [
        "Small",
        /* Small */0
      ],
      [
        "Medium",
        /* Medium */1
      ],
      [
        "Large",
        /* Large */2
      ]
    ]);

var partial_arg$2 = [
  [
    "Top",
    /* Top */0
  ],
  [
    "Bottom",
    /* Bottom */1
  ]
];

function position_encode(param) {
  return Generic$ApiTypes.Encode.simpleVariant(partial_arg$2, param);
}

var position_decode = Generic$ApiTypes.Decode.simpleVariant([
      [
        "Top",
        /* Top */0
      ],
      [
        "Bottom",
        /* Bottom */1
      ]
    ]);

var partial_arg$3 = [
  [
    "Left",
    /* Left */0
  ],
  [
    "Center",
    /* Center */1
  ],
  [
    "Right",
    /* Right */2
  ]
];

function align_encode(param) {
  return Generic$ApiTypes.Encode.simpleVariant(partial_arg$3, param);
}

var align_decode = Generic$ApiTypes.Decode.simpleVariant([
      [
        "Left",
        /* Left */0
      ],
      [
        "Center",
        /* Center */1
      ],
      [
        "Right",
        /* Right */2
      ]
    ]);

function toJson(param) {
  return Json__Encode.object([
              [
                "layout",
                Curry._1(layout_encode, param.layout)
              ],
              [
                "size",
                Curry._1(size_encode, param.size)
              ],
              [
                "position",
                Curry._1(position_encode, param.position)
              ],
              [
                "align",
                Curry._1(align_encode, param.align)
              ]
            ]);
}

var fromJson = Curry._5(Generic$ApiTypes.Decode.map4, Curry._2(Generic$ApiTypes.Decode.field, "layout", Curry._1(Generic$ApiTypes.Decode.option, layout_decode)), Curry._2(Generic$ApiTypes.Decode.field, "size", Curry._1(Generic$ApiTypes.Decode.option, size_decode)), Curry._2(Generic$ApiTypes.Decode.field, "position", Curry._1(Generic$ApiTypes.Decode.option, position_decode)), Curry._2(Generic$ApiTypes.Decode.field, "align", Curry._1(Generic$ApiTypes.Decode.option, align_decode)), (function (layout, size, position, align) {
        return {
                layout: Belt_Option.getWithDefault(layout, /* Labels */0),
                size: Belt_Option.getWithDefault(size, /* Medium */1),
                position: Belt_Option.getWithDefault(position, /* Top */0),
                align: Belt_Option.getWithDefault(align, /* Left */0)
              };
      }));

var include = DeccoAdapter$ApiTypes.Make({
      toJson: toJson,
      fromJson: fromJson
    });

var CategoryTabSettings_t_encode = include.t_encode;

var CategoryTabSettings_t_decode = include.t_decode;

var CategoryTabSettings_codec = include.codec;

var CategoryTabSettings = {
  layout_encode: layout_encode,
  layout_decode: layout_decode,
  size_encode: size_encode,
  size_decode: size_decode,
  position_encode: position_encode,
  position_decode: position_decode,
  align_encode: align_encode,
  align_decode: align_decode,
  toJson: toJson,
  fromJson: fromJson,
  t_encode: CategoryTabSettings_t_encode,
  t_decode: CategoryTabSettings_t_decode,
  codec: CategoryTabSettings_codec
};

export {
  CategoryTabSettings ,
}
/* layout_decode Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Namespace$Showside from "../types/Namespace.bs.js";
function emptyUrlTransformer(param, param$1) {}
var context = React.createContext({
  namespace: undefined,
  urlTransformer: undefined,
  setUrlTransformer: function (param) {}
});
var provider = context.Provider;
var provider$1 = context.Provider;
function LocationConfig$Provider(Props) {
  var namespace = Props.namespace;
  var children = Props.children;
  var match = React.useState(function () {});
  return React.createElement(provider$1, {
    value: {
      namespace: namespace,
      urlTransformer: match[0],
      setUrlTransformer: match[1]
    },
    children: children
  });
}
LocationConfig$Provider.displayName = "LocationConfig.Provider";
var Provider = {
  provider: provider$1,
  make: LocationConfig$Provider
};
function use(param) {
  return React.useContext(context);
}
function useNamespaceAsString(param) {
  return Namespace$Showside.toString(Belt_Option.getWithDefault(React.useContext(context).namespace, Namespace$Showside.empty));
}
export { emptyUrlTransformer, context, provider, Provider, use, useNamespaceAsString };
/* context Not a pure module */
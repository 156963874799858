// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_string from "../../../node_modules/rescript/lib/es6/js_string.js";
import * as Belt_Float from "../../../node_modules/rescript/lib/es6/belt_Float.js";
import * as Pervasives from "../../../node_modules/rescript/lib/es6/pervasives.js";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_format from "../../../node_modules/rescript/lib/es6/caml_format.js";
import * as Caml_option from "../../../node_modules/rescript/lib/es6/caml_option.js";
function make(mediaId, mediaParentId, albumId, panelClosed, whichList, productId, relatedMediaProductIds, relatedMediaCategoryId, variantId, photoZoom, time, param) {
  return {
    mediaId: mediaId,
    mediaParentId: mediaParentId,
    albumId: albumId,
    panelClosed: panelClosed,
    whichList: whichList,
    productId: productId,
    relatedMediaProductIds: relatedMediaProductIds,
    relatedMediaCategoryId: relatedMediaCategoryId,
    variantId: variantId,
    photoZoom: photoZoom,
    time: time
  };
}
var empty = make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
function noneIfEmpty(value) {
  return Belt_Option.flatMap(value, function (value) {
    if (value === "") {
      return;
    } else {
      return value;
    }
  });
}
function fromDict(params) {
  var mediaId = noneIfEmpty(Js_dict.get(params, "QVMediaId"));
  var mediaParentId = noneIfEmpty(Js_dict.get(params, "QVMediaParentId"));
  var albumId = noneIfEmpty(Js_dict.get(params, "QVAlbumId"));
  var panelClosed = Belt_Option.map(Js_dict.get(params, "QVPanelClosed"), function (value) {
    return Belt_Option.isSome(Js_string.match_(/true|t|1|ok|yes|y/i, value));
  });
  var whichList = noneIfEmpty(Js_dict.get(params, "QVWhichList"));
  var productId = noneIfEmpty(Js_dict.get(params, "QVProductId"));
  var relatedMediaProductIds = Belt_Option.map(Js_dict.get(params, "QVRelatedMediaProductIds"), function (ids) {
    return ids.split(",");
  });
  var relatedMediaCategoryId = noneIfEmpty(Js_dict.get(params, "QVRelatedMediaCategoryId"));
  var variantId = noneIfEmpty(Js_dict.get(params, "QVVariantId"));
  var photoZoom = Belt_Option.flatMap(Js_dict.get(params, "QVPhotoZoom"), function (value) {
    var isInt = Belt_Option.isSome(Js_string.match_(/^([0-9]+)$/i, value));
    if (isInt) {
      return Caml_format.int_of_string(value);
    }
  });
  var time = Belt_Option.flatMap(Js_dict.get(params, "QVTime"), function (value) {
    var isFloat = Belt_Option.isSome(Caml_option.null_to_opt(value.match(/^([0-9\.]+)$/i)));
    if (isFloat) {
      return Belt_Float.fromString(value);
    }
  });
  return {
    mediaId: mediaId,
    mediaParentId: mediaParentId,
    albumId: albumId,
    panelClosed: panelClosed,
    whichList: whichList,
    productId: productId,
    relatedMediaProductIds: relatedMediaProductIds,
    relatedMediaCategoryId: relatedMediaCategoryId,
    variantId: variantId,
    photoZoom: photoZoom,
    time: time
  };
}
function toDict(state) {
  return Js_dict.fromArray([["QVMediaId", Belt_Option.getWithDefault(state.mediaId, "")], ["QVMediaParentId", Belt_Option.getWithDefault(state.mediaParentId, "")], ["QVAlbumId", Belt_Option.getWithDefault(state.albumId, "")], ["QVPanelClosed", Belt_Option.mapWithDefault(state.panelClosed, "", Pervasives.string_of_bool)], ["QVWhichList", Belt_Option.getWithDefault(state.whichList, "")], ["QVProductId", Belt_Option.getWithDefault(state.productId, "")], ["QVRelatedMediaProductIds", Belt_Option.getWithDefault(state.relatedMediaProductIds, []).join(",")], ["QVRelatedMediaCategoryId", Belt_Option.getWithDefault(state.relatedMediaCategoryId, "")], ["QVVariantId", Belt_Option.getWithDefault(state.variantId, "")], ["QVPhotoZoom", Belt_Option.mapWithDefault(state.photoZoom, "", function (prim) {
    return String(prim);
  })], ["QVTime", Belt_Option.mapWithDefault(state.time, "", function (prim) {
    return String(prim);
  })]]);
}
function toString(state) {
  return ["QVMediaId" + Belt_Option.getWithDefault(state.mediaId, ""), "QVMediaParentId" + Belt_Option.getWithDefault(state.mediaParentId, ""), "QVAlbumId" + Belt_Option.getWithDefault(state.albumId, ""), "QVPanelClosed" + Belt_Option.mapWithDefault(state.panelClosed, "", Pervasives.string_of_bool), "QVWhichList" + Belt_Option.getWithDefault(state.whichList, ""), "QVProductId" + Belt_Option.getWithDefault(state.productId, ""), "QVRelatedMediaProductIds" + Belt_Option.getWithDefault(state.relatedMediaProductIds, []).join(","), "QVRelatedMediaCategoryId" + Belt_Option.getWithDefault(state.relatedMediaCategoryId, ""), "QVVariantId" + Belt_Option.getWithDefault(state.variantId, ""), "QVPhotoZoom" + Belt_Option.mapWithDefault(state.photoZoom, "", function (prim) {
    return String(prim);
  }), "QVTime" + Belt_Option.mapWithDefault(state.time, "", function (prim) {
    return String(prim);
  })].join("|");
}
function sanitize($$location) {
  return {
    mediaId: $$location.mediaId,
    mediaParentId: $$location.mediaParentId,
    albumId: $$location.albumId,
    panelClosed: undefined,
    whichList: $$location.whichList,
    productId: $$location.productId,
    relatedMediaProductIds: $$location.relatedMediaProductIds,
    relatedMediaCategoryId: $$location.relatedMediaCategoryId,
    variantId: $$location.variantId,
    photoZoom: undefined,
    time: $$location.time
  };
}
export { make, empty, noneIfEmpty, fromDict, toDict, toString, sanitize };
/* empty Not a pure module */
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as SWR from "../../../../node_modules/rescript-swr/lib/es6_global/src/SWR.bs.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../../node_modules/rescript/lib/es6/js_json.js";
import * as Caml_obj from "../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "../../../../node_modules/rescript/lib/es6/belt_Result.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Json__Decode from "../../../../node_modules/rescript-json/lib/es6_global/src/Json__Decode.bs.js";
import * as UseEnv$Showside from "../hook/UseEnv.bs.js";
import * as Js_null_undefined from "../../../../node_modules/rescript/lib/es6/js_null_undefined.js";
import * as Location$Showside from "../utils/Location.bs.js";
import * as FetchUtils$Showside from "../utils/FetchUtils.bs.js";
import * as Breakpoint2$Showside from "../utils/Breakpoint2.bs.js";
import * as ReactContext$Showside from "../utils/ReactContext.bs.js";
import * as Configuration$Showside from "../types/Configuration.bs.js";
import * as CategoryFinder$Showside from "../types/CategoryFinder.bs.js";
import * as RouterProvider$Showside from "./RouterProvider.bs.js";
import * as ViewportContext$Showside from "./ViewportContext.bs.js";
import * as LegacyShowside_Configuration$ApiTypes from "../../../../node_modules/api-types/lib/es6_global/src/LegacyShowside/LegacyShowside_Configuration.bs.js";
import * as LegacyShowside_SettingsCodecs$ApiTypes from "../../../../node_modules/api-types/lib/es6_global/src/LegacyShowside/LegacyShowside_SettingsCodecs.bs.js";
import * as LegacyShowside_SettingsDecoders$ApiTypes from "../../../../node_modules/api-types/lib/es6_global/src/LegacyShowside/LegacyShowside_SettingsDecoders.bs.js";
var context = React.createContext(undefined);
var provider = context.Provider;
var importConfigurationDecoder = function () {
  return import('../types/ConfigurationDecoder.bs.js').then(m => m.default);
};
function useFetchConfig(url) {
  Curry._1(importConfigurationDecoder, undefined);
  var match = SWR.useSWR(undefined, undefined, undefined, false, Js_null_undefined.fromOption(url), function (url) {
    return FetchUtils$Showside.$$fetch(undefined, undefined, undefined, undefined, FetchUtils$Showside.Server.ignoreNotFoundError, async function (json) {
      var decoder = await Curry._1(importConfigurationDecoder, undefined);
      return Curry._1(decoder, json);
    }, url);
  });
  return [match.error, match.data];
}
function applyOptionsToSettings(settings, options) {
  var dict = Belt_Option.getWithDefault(Js_json.decodeObject(LegacyShowside_SettingsCodecs$ApiTypes.toJson(settings)), {});
  var withAppliedOptions = Belt_Array.reduce(Js_dict.entries(options), dict, function (acc, param) {
    acc[param[0]] = param[1];
    return acc;
  });
  try {
    return Belt_Result.getExn(Json__Decode.decodeValue(withAppliedOptions, LegacyShowside_SettingsDecoders$ApiTypes.fromJson));
  } catch (exn) {
    return settings;
  }
}
function applyOptions(config) {
  var options;
  try {
    options = Belt_Option.flatMap(Belt_Option.map(Belt_Option.map(Caml_option.nullable_to_opt(new URL(window.location.href).searchParams.get("QNOptions")), Location$Showside.decodePostMessageData), function (prim) {
      return JSON.parse(prim);
    }), Js_json.decodeObject);
  } catch (exn) {
    options = undefined;
  }
  if (options === undefined) {
    return config;
  }
  try {
    return {
      id: config.id,
      categories: config.categories,
      settings: applyOptionsToSettings(config.settings, Caml_option.valFromOption(options)),
      transitiveStoreId: config.transitiveStoreId,
      transitiveAccount: config.transitiveAccount,
      transitiveStore: config.transitiveStore,
      accountId: config.accountId,
      lastModifiedAt: config.lastModifiedAt
    };
  } catch (exn$1) {
    return config;
  }
}
function ConfigurationProvider(Props) {
  var configId = Props.configId;
  var children = Props.children;
  var config = Props.config;
  var enableTrimmingTreeByReferrer = Props.enableTrimmingTreeByReferrer;
  var match = ViewportContext$Showside.use(undefined);
  var match$1 = React.useState(function () {});
  var setBreakpointOverride = match$1[1];
  var breakpoint = Belt_Option.getWithDefault(match$1[0], match.breakpoint);
  var match$2 = UseEnv$Showside.use(undefined);
  var smartnavApiHost = match$2.smartnavApiHost;
  var match$3 = RouterProvider$Showside.useRouter(undefined);
  var previewMode = match$3.previewMode;
  var $$location = window.location;
  var trimTreeByReferrer = React.useMemo(function () {
    var previewLink = new URL(window.location.href).searchParams.get("maker_preview_contextual_nav");
    if (enableTrimmingTreeByReferrer) {
      return Belt_Option.getWithDefault(previewLink == null ? undefined : Caml_option.some(previewLink), encodeURIComponent($$location.origin + $$location.pathname));
    }
  }, []);
  var live = previewMode !== undefined ? typeof previewMode === "number" ? previewMode !== 0 : false : true;
  var backup = previewMode !== undefined && typeof previewMode !== "number" ? previewMode._0.toString() : undefined;
  var url = Belt_Option.map(configId, function (configId) {
    return Configuration$Showside.makeApiUrl(smartnavApiHost, live, backup, configId, Breakpoint2$Showside.toString(breakpoint), trimTreeByReferrer, undefined);
  });
  var match$4 = useFetchConfig(url);
  var data = match$4[1];
  var getLastModifiedNodeTimestamp = function (config, $$default) {
    var largestTimestamp = {
      contents: $$default
    };
    var maybeUpdateLargestTimestamp = function (category) {
      var current = category.lastModifiedAt;
      var comparison = Belt_Option.getWithDefault(current, 0.0) > Belt_Option.getWithDefault(largestTimestamp.contents, 0.0);
      if (comparison) {
        largestTimestamp.contents = current;
      }
      Belt_Array.forEach(CategoryFinder$Showside.getChildren(category), maybeUpdateLargestTimestamp);
    };
    Belt_Array.forEach(Belt_Option.mapWithDefault(data, [], function (config) {
      return config.categories;
    }), maybeUpdateLargestTimestamp);
    return largestTimestamp.contents;
  };
  var getLastModifiedAt = function (config) {
    var $$default = config.lastModifiedAt;
    if (previewMode === 0) {
      return Belt_Option.getWithDefault(getLastModifiedNodeTimestamp(config, $$default), $$default);
    } else {
      return $$default;
    }
  };
  var config$1 = config !== undefined ? config : data;
  var config$2 = Belt_Option.map(config$1, function (config) {
    var newrecord = Caml_obj.obj_dup(config.settings);
    return {
      id: config.id,
      categories: config.categories,
      settings: (newrecord.gaTrackingId = Belt_Option.mapWithDefault(config.settings.gaTrackingId, Belt_Option.flatMap(config.transitiveAccount, function (a) {
        return a.gaMeasurementId;
      }), function (id) {
        return id;
      }), newrecord),
      transitiveStoreId: config.transitiveStoreId,
      transitiveAccount: config.transitiveAccount,
      transitiveStore: config.transitiveStore,
      accountId: config.accountId,
      lastModifiedAt: getLastModifiedAt(config)
    };
  });
  var config$3 = Belt_Option.map(config$2, applyOptions);
  var render = function (param) {
    if (config$3 !== undefined) {
      return React.createElement(provider, {
        value: {
          config: config$3,
          setBreakpointOverride: setBreakpointOverride
        },
        children: children
      });
    } else {
      return null;
    }
  };
  var memoized = React.useMemo(function () {
    return render(undefined);
  }, [Belt_Option.mapWithDefault(config$3, 0.0, function (dto) {
    return dto.lastModifiedAt;
  }), breakpoint]);
  if (configId !== undefined) {
    return memoized;
  } else {
    return render(undefined);
  }
}
ConfigurationProvider.displayName = "ConfigurationProvider";
function use(param) {
  return ReactContext$Showside.useWithDefault("ConfigurationProvider.use", "ConfigurationProvider", {
    config: LegacyShowside_Configuration$ApiTypes.empty,
    setBreakpointOverride: function (param) {}
  }, context);
}
var initialValue;
var make = ConfigurationProvider;
export { initialValue, context, provider, importConfigurationDecoder, useFetchConfig, applyOptionsToSettings, applyOptions, make, use };
/* context Not a pure module */
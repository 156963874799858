// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../rescript/lib/es6/curry.js";
import * as Belt_Array from "../../../../../rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../../rescript/lib/es6/belt_Option.js";
import * as Generic$ApiTypes from "../utils/Generic.bs.js";
import * as DeccoAdapter$ApiTypes from "../utils/DeccoAdapter.bs.js";

function propertyToJson(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, [
              [
                "property",
                Curry._1(Generic$ApiTypes.Encode.string, param.property)
              ],
              [
                "value",
                param.value
              ]
            ]);
}

function overrideToJson(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, [
              [
                "configId",
                Curry._1(Generic$ApiTypes.Encode.string, param.configId)
              ],
              [
                "properties",
                Curry._2(Generic$ApiTypes.Encode.array, param.properties, propertyToJson)
              ]
            ]);
}

function toJson(__x) {
  return Curry._2(Generic$ApiTypes.Encode.array, __x, overrideToJson);
}

var Encode = {
  propertyToJson: propertyToJson,
  overrideToJson: overrideToJson,
  toJson: toJson
};

var propertyFromJson = Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._1(Generic$ApiTypes.Decode.succeed, (function (property, value) {
                return {
                        property: property,
                        value: value
                      };
              })), Curry._2(Generic$ApiTypes.Decode.field, "property", Generic$ApiTypes.Decode.string)), Curry._2(Generic$ApiTypes.Decode.field, "value", Generic$ApiTypes.Decode.value));

var overrideFromJson = Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._1(Generic$ApiTypes.Decode.succeed, (function (configId, properties) {
                return {
                        configId: configId,
                        properties: properties
                      };
              })), Curry._2(Generic$ApiTypes.Decode.field, "configId", Generic$ApiTypes.Decode.string)), Curry._2(Generic$ApiTypes.Decode.field, "properties", Curry._1(Generic$ApiTypes.Decode.array, propertyFromJson)));

var fromJson = Curry._1(Generic$ApiTypes.Decode.array, overrideFromJson);

var Decode = {
  propertyFromJson: propertyFromJson,
  overrideFromJson: overrideFromJson,
  fromJson: fromJson
};

var include = DeccoAdapter$ApiTypes.Make({
      toJson: toJson,
      fromJson: fromJson
    });

function getByConfigId(overrides, configId) {
  return Belt_Array.getBy(overrides, (function (override) {
                return override.configId === configId;
              }));
}

function getByConfigIdAndProperty(overrides, configId, property) {
  return Belt_Array.getBy(Belt_Option.mapWithDefault(getByConfigId(overrides, configId), [], (function (override) {
                    return override.properties;
                  })), (function (item) {
                return item.property === property;
              }));
}

var Overrides_t_encode = include.t_encode;

var Overrides_t_decode = include.t_decode;

var Overrides_codec = include.codec;

var Overrides = {
  Encode: Encode,
  Decode: Decode,
  toJson: toJson,
  fromJson: fromJson,
  t_encode: Overrides_t_encode,
  t_decode: Overrides_t_decode,
  codec: Overrides_codec,
  getByConfigId: getByConfigId,
  getByConfigIdAndProperty: getByConfigIdAndProperty
};

export {
  Overrides ,
}
/* propertyFromJson Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
var context = React.createContext({
  isInline: false,
  accountId: undefined,
  trackers: undefined,
  onAddToCart: undefined,
  onOpenLink: undefined
});
var provider = context.Provider;
function ExternalIntegrationProvider(Props) {
  var value = Props.value;
  var children = Props.children;
  return React.createElement(provider, {
    value: value,
    children: children
  });
}
ExternalIntegrationProvider.displayName = "ExternalIntegrationProvider";
function use(param) {
  return React.useContext(context);
}
var make = ExternalIntegrationProvider;
export { context, provider, make, use };
/* context Not a pure module */
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Smartbar$Showside from "./utils/Smartbar.bs.js";
function NavEnabledTracker(Props) {
  var embedType = Props.embedType;
  var onChange = Props.onChange;
  var isEnabled = Smartbar$Showside.useSmartBarEnabled(embedType);
  React.useEffect(function () {
    Curry._1(onChange, isEnabled);
  }, [isEnabled]);
  return null;
}
NavEnabledTracker.displayName = "NavEnabledTracker";
var make = NavEnabledTracker;
export { make };
/* react Not a pure module */
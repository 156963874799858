// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Belt_Int from "../../../../node_modules/rescript/lib/es6/belt_Int.js";
import * as Caml_obj from "../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Belt_SortArray from "../../../../node_modules/rescript/lib/es6/belt_SortArray.js";
import * as Settings$Showside from "../types/Settings.bs.js";
import * as Throttle$Showside from "../utils/Throttle.bs.js";
import * as WebapiUtils$Showside from "../utils/WebapiUtils.bs.js";
import * as ResizeObserver$Showside from "../utils/ResizeObserver.bs.js";
import * as RouterProvider$Showside from "./RouterProvider.bs.js";
import * as ViewportContext$Showside from "./ViewportContext.bs.js";
import * as MutationObserver$Showside from "../utils/MutationObserver.bs.js";
import * as EntrypointContext$Showside from "./EntrypointContext.bs.js";
import * as RefByQuerySelector$Showside from "../utils/RefByQuerySelector.bs.js";
import * as IntersectionObserver$Showside from "../utils/IntersectionObserver.bs.js";
import * as ConfigurationProvider$Showside from "./ConfigurationProvider.bs.js";
var empty_containerRef = React.createRef();
var empty = {
  value: 0,
  containerTop: 0,
  containerSize: undefined,
  containerRef: empty_containerRef,
  isEffective: false
};
var context = React.createContext(empty);
var provider = context.Provider;
function useStickySiteHeader(ref) {
  var getStickyAncestor = function (_el) {
    while (true) {
      var el = _el;
      var position = window.getComputedStyle(el).position;
      if (position === "sticky" || position === "fixed") {
        return Caml_option.some(el);
      }
      var el$1 = el.parentElement;
      if (el$1 == null) {
        return;
      }
      _el = el$1;
      continue;
    }
    ;
  };
  var getStickyElement = function (param) {
    var el = ref.current;
    if (el == null) {
      return null;
    }
    var el$1 = getStickyAncestor(el);
    if (el$1 !== undefined) {
      return Caml_option.valFromOption(el$1);
    } else {
      return null;
    }
  };
  var match = React.useState(function () {
    return getStickyElement(undefined);
  });
  var setStickyElement = match[1];
  var stickyElement = match[0];
  var stickyElementRef = React.useMemo(function () {
    return {
      current: stickyElement
    };
  }, [stickyElement]);
  var updateStickyElement = function (param) {
    Curry._1(setStickyElement, function (param) {
      return getStickyElement(undefined);
    });
  };
  React.useEffect(function () {
    Curry._1(setStickyElement, function (param) {
      return getStickyElement(undefined);
    });
  }, []);
  MutationObserver$Showside.use({
    attributes: true,
    characterData: false,
    childList: false,
    subtree: false
  }, ref, updateStickyElement);
  return stickyElementRef;
}
function useSiteHeaderSize(ref) {
  var el = ref.current;
  var computedStyles = !(el == null) ? Caml_option.some(window.getComputedStyle(el)) : undefined;
  var isSticky = computedStyles !== undefined ? Caml_option.valFromOption(computedStyles).position === "sticky" : false;
  var cssTop = computedStyles !== undefined ? Belt_Int.fromString(Caml_option.valFromOption(computedStyles).top) : undefined;
  var cssTransformTop = computedStyles !== undefined ? Belt_Option.flatMap(WebapiUtils$Showside.StyleDeclaration.fromCssStyleDeclatation(Caml_option.valFromOption(computedStyles)).transform, function (transform) {
    return Belt_Option.map(transform.matrix, function (param) {
      return param[5];
    });
  }) : undefined;
  var size = ResizeObserver$Showside.useResizeObserver(undefined, ref);
  var match = Throttle$Showside.use(30, true, function (param) {
    return Date.now();
  });
  var setTimestamp = match[1];
  var timeoutId = React.useRef(undefined);
  var height = Belt_Option.map(size, function (size) {
    return size.height;
  });
  var top = Belt_Option.map(size, function (size) {
    return size.top;
  });
  var top$1 = isSticky && cssTop !== undefined ? Belt_Option.mapWithDefault(cssTransformTop, cssTop, function (cssTransformTop) {
    return cssTop + cssTransformTop | 0;
  }) : top;
  var initialTop = React.useRef(top$1);
  React.useEffect(function () {
    var match = initialTop.current;
    if (match !== undefined) {} else {
      initialTop.current = top$1;
    }
  }, [top$1]);
  var top$2 = Belt_Option.flatMap(top$1, function (top) {
    return Belt_Option.map(initialTop.current, function (initialTop) {
      return Math.min(top, initialTop);
    });
  });
  var $$clearTimeout$1 = function (param) {
    Belt_Option.forEach(timeoutId.current, function (timeoutId) {
      clearTimeout(timeoutId);
    });
  };
  var forceThrottledRerender = function (param) {
    var el = ref.current;
    if (el == null) {
      return;
    }
    $$clearTimeout$1(undefined);
    var computedStyles = WebapiUtils$Showside.StyleDeclaration.fromCssStyleDeclatation(window.getComputedStyle(el));
    var transitionDuration = computedStyles.transitionDuration;
    var transitionDelay = computedStyles.transitionDelay;
    var timeoutDelay = (transitionDuration + transitionDelay + 0.1) * 1000 | 0;
    timeoutId.current = Caml_option.some(setTimeout(function (param) {
      Curry._1(setTimestamp, function (param) {
        return Date.now();
      });
    }, timeoutDelay));
  };
  MutationObserver$Showside.use({
    attributes: true,
    characterData: false,
    childList: false,
    subtree: false
  }, ref, forceThrottledRerender);
  IntersectionObserver$Showside.use(ref, undefined, undefined, undefined, [0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0], undefined, 0, undefined, undefined);
  RouterProvider$Showside.useRouter(undefined);
  return [height, top$2];
}
function StickyHeaderOffsetProvider$Selector(Props) {
  var selector = Props.selector;
  var callback = Props.callback;
  var originalRef = RefByQuerySelector$Showside.use(undefined, selector);
  var stickyRef = useStickySiteHeader(originalRef);
  var getRef = function (param) {
    var match = stickyRef.current;
    var match$1 = originalRef.current;
    if (match == null) {
      if (match$1 == null) {
        return null;
      } else {
        return match$1;
      }
    } else {
      return match;
    }
  };
  var ref = React.useRef(getRef(undefined));
  React.useEffect(function () {
    ref.current = getRef(undefined);
  });
  var addUpOptions = function (a, b) {
    return Belt_Array.reduce(Belt_Array.map([a, b], function (v) {
      return Belt_Option.getWithDefault(v, -1);
    }), 0, function (acc, next) {
      return acc + next | 0;
    });
  };
  var match = useSiteHeaderSize(ref);
  var top = match[1];
  var height = match[0];
  var value = addUpOptions(height, top);
  var overrideRef = React.useMemo(function () {
    var el = originalRef.current;
    if (!(el == null) && value === 0) {
      return {
        current: el
      };
    } else {
      return {
        current: null
      };
    }
  }, [value]);
  var match$1 = useSiteHeaderSize(overrideRef);
  var value$1 = addUpOptions(match$1[0], match$1[1]);
  var overrideValue = value$1 <= 0 ? undefined : value$1;
  var value$2 = Belt_Option.getWithDefault(overrideValue, value);
  React.useEffect(function () {
    Curry._2(callback, selector, {
      value: value$2,
      containerTop: 0,
      containerSize: undefined,
      containerRef: empty_containerRef,
      isEffective: Belt_Option.isSome(Caml_option.nullable_to_opt(stickyRef.current))
    });
    return function (param) {
      Curry._2(callback, selector, empty);
    };
  }, [height, top, value$2]);
  return null;
}
StickyHeaderOffsetProvider$Selector.displayName = "StickyHeaderOffsetProvider.Selector";
var Selector = {
  useStickySiteHeader: useStickySiteHeader,
  useSiteHeaderSize: useSiteHeaderSize,
  make: StickyHeaderOffsetProvider$Selector
};
function StickyHeaderOffsetProvider(Props) {
  var attributes = Props.attributes;
  var className = Props.className;
  var children = Props.children;
  var containerRef = React.useRef(null);
  var initialContainerTop = React.useRef(undefined);
  var containerSize = ResizeObserver$Showside.useResizeObserver(undefined, containerRef);
  var containerTop = Belt_Option.map(containerSize, function (size) {
    return size.top;
  });
  React.useEffect(function () {
    var match = initialContainerTop.current;
    if (match !== undefined || containerTop === undefined) {} else {
      initialContainerTop.current = containerTop;
    }
  }, [containerTop]);
  var match = React.useState(function () {
    return {};
  });
  var setDict = match[1];
  var dict = match[0];
  var match$1 = ConfigurationProvider$Showside.use(undefined).config;
  var selector = Belt_Option.orElse(Belt_Option.flatMap(attributes, function (attr) {
    return attr.navMenuSiteHeaderTarget;
  }), Settings$Showside.getSiteHeaderCSSSelector(match$1.settings, match$1.transitiveAccount));
  var selectors = Belt_Option.mapWithDefault(selector, [], function (__x) {
    return __x.split(",");
  }).map(function (prim) {
    return prim.trim();
  });
  var callback = React.useCallback(function (selector) {
    return function (value) {
      var previousValue = Js_dict.get(dict, selector);
      return Curry._1(setDict, function (dict) {
        return Js_dict.fromArray(Js_dict.entries(dict).filter(function (param) {
          return param[0] !== selector;
        }).concat(value !== undefined ? [[selector, value]] : previousValue !== undefined ? [[selector, previousValue]] : []));
      });
    };
  }, [dict, setDict]);
  var selectorElements = selectors.map(function (selector, i) {
    return React.createElement(StickyHeaderOffsetProvider$Selector, {
      selector: selector,
      callback: callback,
      key: "" + selector + "-" + String(i) + ""
    });
  });
  var ctxValue = React.useMemo(function () {
    return {
      value: 0,
      containerTop: Belt_Option.getWithDefault(containerTop, 0),
      containerSize: containerSize,
      containerRef: containerRef,
      isEffective: false
    };
  }, [containerTop, containerSize]);
  var ctxValue$1 = React.useMemo(function () {
    return Belt_Option.mapWithDefault(Belt_Array.get(Belt_SortArray.stableSortBy(Js_dict.values(dict), function (a, b) {
      if (Caml_obj.greaterthan(b, a)) {
        return 1;
      } else {
        return -1;
      }
    }), 0), ctxValue, function (t) {
      return {
        value: Math.max(t.value, 0),
        containerTop: ctxValue.containerTop,
        containerSize: ctxValue.containerSize,
        containerRef: ctxValue.containerRef,
        isEffective: t.isEffective
      };
    });
  }, [dict, containerTop, containerSize]);
  var tmp = {
    ref: containerRef
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  var children$1 = React.createElement("div", tmp, children, selectorElements);
  return React.createElement(provider, {
    value: ctxValue$1,
    children: children$1
  });
}
StickyHeaderOffsetProvider.displayName = "StickyHeaderOffsetProvider";
function use(param) {
  return React.useContext(context);
}
function useContainerTop(param) {
  var match = EntrypointContext$Showside.use(undefined);
  var ctx = React.useContext(context);
  var nextValue = match.embedType === /* Inline */1 ? ctx.containerTop - ctx.value | 0 : 0;
  var result = React.useRef(nextValue);
  React.useEffect(function () {
    result.current = nextValue > result.current && nextValue > 0 ? nextValue : result.current;
  }, [nextValue]);
  return result.current;
}
function useEffective(param) {
  var ctx = React.useContext(context);
  if (ctx.isEffective) {
    return ctx.value;
  } else {
    return 0;
  }
}
function useLenient(param) {
  var match = ConfigurationProvider$Showside.use(undefined).config;
  var value = React.useContext(context).value;
  if (match.settings.siteHeaderRespect) {
    return value;
  } else {
    return 0;
  }
}
function useStrict(param) {
  return React.useContext(context).value;
}
function useAdjustedWindowHeight(param) {
  var match = ViewportContext$Showside.use(undefined);
  var stickyHeaderOffset = useLenient(undefined);
  return match.windowSize.height - stickyHeaderOffset | 0;
}
var make = StickyHeaderOffsetProvider;
export { empty, context, provider, Selector, make, use, useContainerTop, useEffective, useLenient, useStrict, useAdjustedWindowHeight };
/* empty Not a pure module */
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../rescript/lib/es6/curry.js";
import * as Generic$ApiTypes from "../utils/Generic.bs.js";
import * as DeccoAdapter$ApiTypes from "../utils/DeccoAdapter.bs.js";

function toJson(v) {
  switch (v.TAG | 0) {
    case /* Instagram */0 :
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "Instagram", Generic$ApiTypes.Encode.string);
    case /* Tiktok */1 :
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "Tiktok", Generic$ApiTypes.Encode.string);
    case /* Twitter */2 :
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "Twitter", Generic$ApiTypes.Encode.string);
    case /* Facebook */3 :
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "Facebook", Generic$ApiTypes.Encode.string);
    case /* Youtube */4 :
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "Youtube", Generic$ApiTypes.Encode.string);
    
  }
}

var fromJson = Curry._2(Generic$ApiTypes.Decode.oneOf, Generic$ApiTypes.Decode.deccoVariantWithPayload("Instagram", Generic$ApiTypes.Decode.string, (function (value) {
            return {
                    TAG: /* Instagram */0,
                    _0: value
                  };
          })), [
      Generic$ApiTypes.Decode.deccoVariantWithPayload("Tiktok", Generic$ApiTypes.Decode.string, (function (value) {
              return {
                      TAG: /* Tiktok */1,
                      _0: value
                    };
            })),
      Generic$ApiTypes.Decode.deccoVariantWithPayload("Twitter", Generic$ApiTypes.Decode.string, (function (value) {
              return {
                      TAG: /* Twitter */2,
                      _0: value
                    };
            })),
      Generic$ApiTypes.Decode.deccoVariantWithPayload("Facebook", Generic$ApiTypes.Decode.string, (function (value) {
              return {
                      TAG: /* Facebook */3,
                      _0: value
                    };
            })),
      Generic$ApiTypes.Decode.deccoVariantWithPayload("Youtube", Generic$ApiTypes.Decode.string, (function (value) {
              return {
                      TAG: /* Youtube */4,
                      _0: value
                    };
            }))
    ]);

var include = DeccoAdapter$ApiTypes.Make({
      toJson: toJson,
      fromJson: fromJson
    });

var t_encode = include.t_encode;

var t_decode = include.t_decode;

var SocialNetworkLink_codec = include.codec;

var SocialNetworkLink = {
  toJson: toJson,
  fromJson: fromJson,
  t_encode: t_encode,
  t_decode: t_decode,
  codec: SocialNetworkLink_codec
};

var socialNetworkLink_encode = t_encode;

var socialNetworkLink_decode = t_decode;

export {
  SocialNetworkLink ,
  socialNetworkLink_encode ,
  socialNetworkLink_decode ,
}
/* fromJson Not a pure module */

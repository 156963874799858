// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../rescript/lib/es6/curry.js";
import * as Json__Encode from "../../../../../rescript-json/lib/es6_global/src/Json__Encode.bs.js";
import * as Generic$ApiTypes from "../utils/Generic.bs.js";
import * as MkrBioCodecs$ApiTypes from "../MkrBio/MkrBioCodecs.bs.js";
import * as CustomActionId$ApiTypes from "../CustomActionId.bs.js";
import * as SizeGuideCodecs$ApiTypes from "../SizeGuideCodecs.bs.js";
import * as CTAButtonsCodecs$ApiTypes from "../CTAButtonsCodecs.bs.js";
import * as CustomActionCodecs$ApiTypes from "../CustomActionCodecs.bs.js";
import * as LegacyShowside_StylingCodecs$ApiTypes from "./LegacyShowside_StylingCodecs.bs.js";
import * as LegacyShowside_CategoryCodecs$ApiTypes from "./LegacyShowside_CategoryCodecs.bs.js";
import * as LegacyShowside_BasicTypesCodecs$ApiTypes from "./LegacyShowside_BasicTypesCodecs.bs.js";
import * as LegacyShowside_SettingsDecoders$ApiTypes from "./LegacyShowside_SettingsDecoders.bs.js";

var partial_arg = [
  [
    "Large",
    /* Large */0
  ],
  [
    "Medium",
    /* Medium */1
  ],
  [
    "Small",
    /* Small */2
  ],
  [
    "FullWidth",
    /* FullWidth */3
  ]
];

function toJson(param) {
  return Generic$ApiTypes.Encode.simpleVariant(partial_arg, param);
}

var fromJson = LegacyShowside_SettingsDecoders$ApiTypes.PanelSize.fromJson;

var PanelSize = {
  toJson: toJson,
  fromJson: fromJson
};

var partial_arg$1 = [
  [
    "Left",
    /* Left */0
  ],
  [
    "Right",
    /* Right */1
  ]
];

function toJson$1(param) {
  return Generic$ApiTypes.Encode.simpleVariant(partial_arg$1, param);
}

var fromJson$1 = LegacyShowside_SettingsDecoders$ApiTypes.PanelPosition.fromJson;

var PanelPosition = {
  toJson: toJson$1,
  fromJson: fromJson$1
};

var partial_arg$2 = [
  [
    "FirstVariant",
    /* FirstVariant */0
  ],
  [
    "HighestPriceVariant",
    /* HighestPriceVariant */1
  ],
  [
    "LowestPriceVariant",
    /* LowestPriceVariant */2
  ],
  [
    "HighestDiscountVariant",
    /* HighestDiscountVariant */3
  ]
];

function toJson$2(param) {
  return Generic$ApiTypes.Encode.simpleVariant(partial_arg$2, param);
}

var variantSelector_fromJson = LegacyShowside_SettingsDecoders$ApiTypes.VariantSelector.fromJson;

var VariantSelector = {
  toJson: toJson$2,
  variantSelector_fromJson: variantSelector_fromJson
};

var partial_arg$3 = [
  [
    "Old",
    /* Old */0
  ],
  [
    "Horizontal",
    /* Horizontal */1
  ],
  [
    "Vertical",
    /* Vertical */2
  ]
];

function toJson$3(param) {
  return Generic$ApiTypes.Encode.simpleVariant(partial_arg$3, param);
}

var fromJson$2 = LegacyShowside_SettingsDecoders$ApiTypes.DesktopPDPLayout.fromJson;

var DesktopPDPLayout = {
  toJson: toJson$3,
  fromJson: fromJson$2
};

var partial_arg$4 = [
  [
    "Left",
    /* Left */0
  ],
  [
    "Center",
    /* Center */1
  ]
];

function toJson$4(param) {
  return Generic$ApiTypes.Encode.simpleVariant(partial_arg$4, param);
}

var fromJson$3 = LegacyShowside_SettingsDecoders$ApiTypes.TitleDescriptionPosition.fromJson;

var TitleDescriptionPosition = {
  toJson: toJson$4,
  fromJson: fromJson$3
};

var partial_arg$5 = [
  [
    "Ua",
    /* Ua */0
  ],
  [
    "Gtag",
    /* Gtag */1
  ]
];

function toJson$5(param) {
  return Generic$ApiTypes.Encode.simpleVariant(partial_arg$5, param);
}

var fromJson$4 = LegacyShowside_SettingsDecoders$ApiTypes.GaVersion.fromJson;

var GaVersion = {
  toJson: toJson$5,
  fromJson: fromJson$4
};

function addToCartProps_toJson(param) {
  return Json__Encode.object([
              [
                "textColor",
                Json__Encode.string(param.textColor)
              ],
              [
                "bgColor",
                Json__Encode.string(param.bgColor)
              ],
              [
                "label",
                Json__Encode.string(param.label)
              ],
              [
                "confirmationMessage",
                Json__Encode.string(param.confirmationMessage)
              ]
            ]);
}

var addToCartProps_fromJson = LegacyShowside_SettingsDecoders$ApiTypes.PDPCTAConfig.addToCartProps_fromJson;

function customActionProps_toJson(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, [
              [
                "id",
                Curry._1(Generic$ApiTypes.Encode.string, param.id)
              ],
              [
                "textColor",
                Curry._1(Generic$ApiTypes.Encode.string, param.textColor)
              ],
              [
                "bgColor",
                Curry._1(Generic$ApiTypes.Encode.string, param.bgColor)
              ],
              [
                "label",
                Curry._1(Generic$ApiTypes.Encode.string, param.label)
              ],
              [
                "confirmationMessage",
                Curry._1(Generic$ApiTypes.Encode.string, param.confirmationMessage)
              ],
              [
                "onClickFn",
                Curry._1(Generic$ApiTypes.Encode.string, param.onClickFn)
              ],
              [
                "renderFn",
                Generic$ApiTypes.Encode.nullable(param.renderFn, Generic$ApiTypes.Encode.string)
              ]
            ]);
}

var customActionProps_fromJson = LegacyShowside_SettingsDecoders$ApiTypes.PDPCTAConfig.customActionProps_fromJson;

function pdpCTAAction_toJson(v) {
  switch (v.TAG | 0) {
    case /* AddToCart */0 :
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "AddToCart", addToCartProps_toJson);
    case /* OpenLink */1 :
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "OpenLink", Generic$ApiTypes.Encode.string);
    case /* CustomAction */2 :
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "CustomAction", customActionProps_toJson);
    
  }
}

var pdpCTAAction_fromJson = LegacyShowside_SettingsDecoders$ApiTypes.PDPCTAConfig.pdpCTAAction_fromJson;

function toJson$6(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, [
              [
                "primary",
                pdpCTAAction_toJson(param.primary)
              ],
              [
                "secondary",
                Generic$ApiTypes.Encode.nullable(param.secondary, pdpCTAAction_toJson)
              ],
              [
                "tertiary",
                Generic$ApiTypes.Encode.nullable(param.tertiary, pdpCTAAction_toJson)
              ]
            ]);
}

var fromJson$5 = LegacyShowside_SettingsDecoders$ApiTypes.PDPCTAConfig.fromJson;

var PDPCTAConfig = {
  addToCartProps_toJson: addToCartProps_toJson,
  addToCartProps_fromJson: addToCartProps_fromJson,
  customActionProps_toJson: customActionProps_toJson,
  customActionProps_fromJson: customActionProps_fromJson,
  pdpCTAAction_toJson: pdpCTAAction_toJson,
  pdpCTAAction_fromJson: pdpCTAAction_fromJson,
  toJson: toJson$6,
  fromJson: fromJson$5
};

function toJson$7(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, [
              [
                "panelSize",
                Curry._1(toJson, param.panelSize)
              ],
              [
                "panelPosition",
                Curry._1(toJson$1, param.panelPosition)
              ],
              [
                "backdrop",
                Curry._1(Generic$ApiTypes.Encode.bool, param.backdrop)
              ],
              [
                "backdropBlur",
                Curry._1(Generic$ApiTypes.Encode.bool, param.backdropBlur)
              ],
              [
                "backdropColor",
                Curry._1(Generic$ApiTypes.Encode.string, param.backdropColor)
              ],
              [
                "categoryLayout",
                LegacyShowside_BasicTypesCodecs$ApiTypes.smartbarCategoryLayout_toJson(param.categoryLayout)
              ],
              [
                "categorySize",
                LegacyShowside_BasicTypesCodecs$ApiTypes.smartbarCategorySize_toJson(param.categorySize)
              ],
              [
                "nestedCategoryLayout",
                LegacyShowside_BasicTypesCodecs$ApiTypes.smartbarCategoryLayout_toJson(param.nestedCategoryLayout)
              ],
              [
                "nestedCategorySize",
                LegacyShowside_BasicTypesCodecs$ApiTypes.smartbarCategorySize_toJson(param.nestedCategorySize)
              ],
              [
                "checkoutLink",
                Generic$ApiTypes.Encode.nullable(param.checkoutLink, Generic$ApiTypes.Encode.string)
              ],
              [
                "checkoutCustomAction",
                Generic$ApiTypes.Encode.nullable(param.checkoutCustomAction, CustomActionId$ApiTypes.toJson)
              ],
              [
                "currencyPickerEnabled",
                Curry._1(Generic$ApiTypes.Encode.bool, param.currencyPickerEnabled)
              ],
              [
                "gridDensityZoomEnabled",
                Curry._1(Generic$ApiTypes.Encode.bool, param.gridDensityZoomEnabled)
              ],
              [
                "filtersEnabled",
                Curry._1(Generic$ApiTypes.Encode.bool, param.filtersEnabled)
              ],
              [
                "filtersLabelOrder",
                Curry._2(Generic$ApiTypes.Encode.array, param.filtersLabelOrder, Generic$ApiTypes.Encode.string)
              ],
              [
                "linksAsSidebar",
                Curry._1(Generic$ApiTypes.Encode.bool, param.linksAsSidebar)
              ],
              [
                "sidebarDesktopDefault",
                Curry._1(Generic$ApiTypes.Encode.bool, param.sidebarDesktopDefault)
              ],
              [
                "sidebarMobileDefault",
                Curry._1(Generic$ApiTypes.Encode.bool, param.sidebarMobileDefault)
              ],
              [
                "backButton",
                Curry._1(Generic$ApiTypes.Encode.bool, param.backButton)
              ],
              [
                "smartbarEnabled",
                Curry._1(Generic$ApiTypes.Encode.bool, param.smartbarEnabled)
              ],
              [
                "zIndexInt",
                Curry._1(Generic$ApiTypes.Encode.$$int, param.zIndexInt)
              ],
              [
                "zIndex",
                Curry._1(Generic$ApiTypes.Encode.string, param.zIndex)
              ],
              [
                "categoryCardShape",
                LegacyShowside_BasicTypesCodecs$ApiTypes.cardShape_toJson(param.categoryCardShape)
              ],
              [
                "categoryCardFitToContainer",
                Curry._1(Generic$ApiTypes.Encode.bool, param.categoryCardFitToContainer)
              ],
              [
                "categoryCardTitleLineTruncate",
                Curry._1(Generic$ApiTypes.Encode.$$int, param.categoryCardTitleLineTruncate)
              ],
              [
                "productCardTitleLineTruncate",
                Curry._1(Generic$ApiTypes.Encode.$$int, param.productCardTitleLineTruncate)
              ],
              [
                "productCardFitToContainer",
                Curry._1(Generic$ApiTypes.Encode.bool, param.productCardFitToContainer)
              ],
              [
                "productCardShape",
                LegacyShowside_BasicTypesCodecs$ApiTypes.cardShape_toJson(param.productCardShape)
              ],
              [
                "productCardSize",
                Curry._1(LegacyShowside_CategoryCodecs$ApiTypes.CardSize.toJson, param.productCardSize)
              ],
              [
                "productCardAddToCart",
                Curry._1(Generic$ApiTypes.Encode.bool, param.productCardAddToCart)
              ],
              [
                "pdpCTAConfig",
                toJson$6(param.pdpCTAConfig)
              ],
              [
                "pdpAddToCartLabel",
                Curry._1(Generic$ApiTypes.Encode.string, param.pdpAddToCartLabel)
              ],
              [
                "pdpAddToCartConfirmation",
                Curry._1(Generic$ApiTypes.Encode.string, param.pdpAddToCartConfirmation)
              ],
              [
                "pdpPrimaryCustomAction",
                Generic$ApiTypes.Encode.nullable(param.pdpPrimaryCustomAction, CustomActionCodecs$ApiTypes.Button.toJson)
              ],
              [
                "pdpSecondaryCustomAction",
                Generic$ApiTypes.Encode.nullable(param.pdpSecondaryCustomAction, CustomActionCodecs$ApiTypes.Button.toJson)
              ],
              [
                "pdpTertiaryCustomAction",
                Generic$ApiTypes.Encode.nullable(param.pdpTertiaryCustomAction, CustomActionCodecs$ApiTypes.Button.toJson)
              ],
              [
                "pdpGalleryCustomAction",
                Generic$ApiTypes.Encode.nullable(param.pdpGalleryCustomAction, CustomActionCodecs$ApiTypes.Button.toJson)
              ],
              [
                "pdpSizeGuide",
                Generic$ApiTypes.Encode.nullable(param.pdpSizeGuide, SizeGuideCodecs$ApiTypes.toJson)
              ],
              [
                "pdpCTAButtons",
                CTAButtonsCodecs$ApiTypes.toJson(param.pdpCTAButtons)
              ],
              [
                "pdpOpenLinkLabel",
                Curry._1(Generic$ApiTypes.Encode.string, param.pdpOpenLinkLabel)
              ],
              [
                "pdpCTAButtonBgColor",
                Curry._1(Generic$ApiTypes.Encode.string, param.pdpCTAButtonBgColor)
              ],
              [
                "pdpCTAButtonTextColor",
                Curry._1(Generic$ApiTypes.Encode.string, param.pdpCTAButtonTextColor)
              ],
              [
                "pdpGalleryBGBlur",
                Curry._1(Generic$ApiTypes.Encode.bool, param.pdpGalleryBGBlur)
              ],
              [
                "pdpGalleryBGColor",
                Curry._1(Generic$ApiTypes.Encode.string, param.pdpGalleryBGColor)
              ],
              [
                "pdpPricePrefixText",
                Generic$ApiTypes.Encode.nullable(param.pdpPricePrefixText, Generic$ApiTypes.Encode.string)
              ],
              [
                "pdpPriceSuffixText",
                Generic$ApiTypes.Encode.nullable(param.pdpPriceSuffixText, Generic$ApiTypes.Encode.string)
              ],
              [
                "pdpPromoText",
                Generic$ApiTypes.Encode.nullable(param.pdpPromoText, Generic$ApiTypes.Encode.string)
              ],
              [
                "shipping",
                Generic$ApiTypes.Encode.nullable(param.shipping, Generic$ApiTypes.Encode.string)
              ],
              [
                "shippingShort",
                Generic$ApiTypes.Encode.nullable(param.shippingShort, Generic$ApiTypes.Encode.string)
              ],
              [
                "arrival",
                Generic$ApiTypes.Encode.nullable(param.arrival, Generic$ApiTypes.Encode.string)
              ],
              [
                "hideOutOfStock",
                Curry._1(Generic$ApiTypes.Encode.bool, param.hideOutOfStock)
              ],
              [
                "videoLoop",
                Curry._1(Generic$ApiTypes.Encode.bool, param.videoLoop)
              ],
              [
                "videoAutoPlay",
                Curry._1(Generic$ApiTypes.Encode.bool, param.videoAutoPlay)
              ],
              [
                "videoMute",
                Curry._1(Generic$ApiTypes.Encode.bool, param.videoMute)
              ],
              [
                "videoControls",
                Curry._1(Generic$ApiTypes.Encode.bool, param.videoControls)
              ],
              [
                "openLinkLabel",
                Curry._1(Generic$ApiTypes.Encode.string, param.openLinkLabel)
              ],
              [
                "openLinkInNewTab",
                Curry._1(Generic$ApiTypes.Encode.bool, param.openLinkInNewTab)
              ],
              [
                "openProductLink",
                Curry._1(Generic$ApiTypes.Encode.bool, param.openProductLink)
              ],
              [
                "outOfStockLabel",
                Curry._1(Generic$ApiTypes.Encode.string, param.outOfStockLabel)
              ],
              [
                "indexNode",
                Generic$ApiTypes.Encode.nullable(param.indexNode, LegacyShowside_CategoryCodecs$ApiTypes.toJson)
              ],
              [
                "quickviewCarousel",
                Curry._1(Generic$ApiTypes.Encode.bool, param.quickviewCarousel)
              ],
              [
                "quickviewAutoplay",
                Curry._1(Generic$ApiTypes.Encode.bool, param.quickviewAutoplay)
              ],
              [
                "quickviewPictureInPicture",
                Curry._1(Generic$ApiTypes.Encode.bool, param.quickviewPictureInPicture)
              ],
              [
                "siteHeaderCSSSelector",
                Generic$ApiTypes.Encode.nullable(param.siteHeaderCSSSelector, Generic$ApiTypes.Encode.string)
              ],
              [
                "siteHeaderRespect",
                Curry._1(Generic$ApiTypes.Encode.bool, param.siteHeaderRespect)
              ],
              [
                "displayVariantBy",
                Curry._1(toJson$2, param.displayVariantBy)
              ],
              [
                "newMobilePDP",
                Curry._1(Generic$ApiTypes.Encode.bool, param.newMobilePDP)
              ],
              [
                "desktopPDPLayout",
                Curry._1(toJson$3, param.desktopPDPLayout)
              ],
              [
                "newMobilePDPCube",
                Curry._1(Generic$ApiTypes.Encode.bool, param.newMobilePDPCube)
              ],
              [
                "newMobilePDPCover",
                Curry._1(Generic$ApiTypes.Encode.bool, param.newMobilePDPCover)
              ],
              [
                "quantityPicker",
                Curry._1(Generic$ApiTypes.Encode.bool, param.quantityPicker)
              ],
              [
                "styling",
                Generic$ApiTypes.Encode.nullable(param.styling, LegacyShowside_StylingCodecs$ApiTypes.toJson)
              ],
              [
                "mkrBio",
                Generic$ApiTypes.Encode.nullable(param.mkrBio, MkrBioCodecs$ApiTypes.toJson)
              ],
              [
                "cart",
                Curry._1(Generic$ApiTypes.Encode.bool, param.cart)
              ],
              [
                "gaTrackingId",
                Generic$ApiTypes.Encode.nullable(param.gaTrackingId, Generic$ApiTypes.Encode.string)
              ],
              [
                "gaVersion",
                Curry._1(toJson$5, param.gaVersion)
              ],
              [
                "contextualL1s",
                Curry._1(Generic$ApiTypes.Encode.bool, param.contextualL1s)
              ],
              [
                "contextualInline",
                Curry._1(Generic$ApiTypes.Encode.bool, param.contextualInline)
              ],
              [
                "contextualMenuDesktop",
                Curry._1(Generic$ApiTypes.Encode.bool, param.contextualMenuDesktop)
              ],
              [
                "contextualMenuMobile",
                Curry._1(Generic$ApiTypes.Encode.bool, param.contextualMenuMobile)
              ],
              [
                "autoHideBottombar",
                Curry._1(Generic$ApiTypes.Encode.bool, param.autoHideBottombar)
              ],
              [
                "expandVariantsInProductsListing",
                Generic$ApiTypes.Encode.nullable(param.expandVariantsInProductsListing, Generic$ApiTypes.Encode.bool)
              ],
              [
                "favoritesEnabled",
                Curry._1(Generic$ApiTypes.Encode.bool, param.favoritesEnabled)
              ],
              [
                "historyEnabled",
                Curry._1(Generic$ApiTypes.Encode.bool, param.historyEnabled)
              ],
              [
                "plpTitleDescriptionEnabled",
                Curry._1(Generic$ApiTypes.Encode.bool, param.plpTitleDescriptionEnabled)
              ],
              [
                "plpTitleDescriptionPosition",
                Curry._1(toJson$4, param.plpTitleDescriptionPosition)
              ],
              [
                "seoDynamicLinks",
                Curry._1(Generic$ApiTypes.Encode.bool, param.seoDynamicLinks)
              ]
            ]);
}

var Breakpoint2;

var fromJson$6 = LegacyShowside_SettingsDecoders$ApiTypes.fromJson;

export {
  Breakpoint2 ,
  PanelSize ,
  PanelPosition ,
  VariantSelector ,
  DesktopPDPLayout ,
  TitleDescriptionPosition ,
  GaVersion ,
  PDPCTAConfig ,
  toJson$7 as toJson,
  fromJson$6 as fromJson,
}
/* Json__Encode Not a pure module */

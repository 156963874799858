// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../rescript/lib/es6/curry.js";
import * as Belt_Option from "../../../../rescript/lib/es6/belt_Option.js";
import * as Json__Encode from "../../../../rescript-json/lib/es6_global/src/Json__Encode.bs.js";
import * as Generic$ApiTypes from "./utils/Generic.bs.js";
import * as DeccoAdapter$ApiTypes from "./utils/DeccoAdapter.bs.js";

var videosPayload_decode = Curry._3(Generic$ApiTypes.Decode.map2, Curry._2(Generic$ApiTypes.Decode.field, "id", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.string)), Curry._2(Generic$ApiTypes.Decode.field, "isPinned", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.bool)), (function (id, isPinned) {
        return {
                id: Belt_Option.getWithDefault(id, ""),
                isPinned: Belt_Option.getWithDefault(isPinned, false)
              };
      }));

function videosPayload_encode(payload) {
  return Json__Encode.object([
              [
                "id",
                Json__Encode.string(payload.id)
              ],
              [
                "isPinned",
                Json__Encode.bool(payload.isPinned)
              ]
            ]);
}

var videosWithPayload_decode = Curry._3(Generic$ApiTypes.Decode.map2, Generic$ApiTypes.Decode.exactVariantChoice("Videos"), Curry._2(Generic$ApiTypes.Decode.index, 1, videosPayload_decode), (function (param, payload) {
        return /* Videos */{
                _0: payload
              };
      }));

function toJson(contentType) {
  return Generic$ApiTypes.Encode.deccoVariantWithPayload(contentType._0, "Videos", videosPayload_encode);
}

var include = DeccoAdapter$ApiTypes.Make({
      toJson: toJson,
      fromJson: videosWithPayload_decode
    });

var ContentType_t_encode = include.t_encode;

var ContentType_t_decode = include.t_decode;

var ContentType_codec = include.codec;

var ContentType = {
  videosPayload_decode: videosPayload_decode,
  videosPayload_encode: videosPayload_encode,
  videosWithPayload_decode: videosWithPayload_decode,
  fromJson: videosWithPayload_decode,
  toJson: toJson,
  t_encode: ContentType_t_encode,
  t_decode: ContentType_t_decode,
  codec: ContentType_codec
};

export {
  ContentType ,
}
/* videosPayload_decode Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../rescript/lib/es6/curry.js";
import * as Belt_Option from "../../../../rescript/lib/es6/belt_Option.js";
import * as Json__Encode from "../../../../rescript-json/lib/es6_global/src/Json__Encode.bs.js";
import * as Generic$ApiTypes from "./utils/Generic.bs.js";
import * as DeccoAdapter$ApiTypes from "./utils/DeccoAdapter.bs.js";

var reelsPayload_decode = Curry._6(Generic$ApiTypes.Decode.map5, Curry._2(Generic$ApiTypes.Decode.field, "id", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.string)), Curry._2(Generic$ApiTypes.Decode.field, "isPinned", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.bool)), Curry._2(Generic$ApiTypes.Decode.field, "isSharedToFeed", Generic$ApiTypes.Decode.bool), Curry._2(Generic$ApiTypes.Decode.field, "isScraped", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.bool)), Curry._2(Generic$ApiTypes.Decode.field, "isCollaborated", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.bool)), (function (id, isPinned, isSharedToFeed, isScraped, isCollaborated) {
        return {
                id: Belt_Option.getWithDefault(id, ""),
                isPinned: Belt_Option.getWithDefault(isPinned, false),
                isSharedToFeed: isSharedToFeed,
                isScraped: Belt_Option.getWithDefault(isScraped, false),
                isCollaborated: Belt_Option.getWithDefault(isCollaborated, false)
              };
      }));

function reelsPayload_encode(payload) {
  return Json__Encode.object([
              [
                "id",
                Json__Encode.string(payload.id)
              ],
              [
                "isPinned",
                Json__Encode.bool(payload.isPinned)
              ],
              [
                "isSharedToFeed",
                Json__Encode.bool(payload.isSharedToFeed)
              ],
              [
                "isScraped",
                Json__Encode.bool(payload.isScraped)
              ],
              [
                "isCollaborated",
                Json__Encode.bool(payload.isCollaborated)
              ]
            ]);
}

var postsPayload_decode = Curry._5(Generic$ApiTypes.Decode.map4, Curry._2(Generic$ApiTypes.Decode.field, "id", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.string)), Curry._2(Generic$ApiTypes.Decode.field, "isPinned", Generic$ApiTypes.Decode.bool), Curry._2(Generic$ApiTypes.Decode.field, "isScraped", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.bool)), Curry._2(Generic$ApiTypes.Decode.field, "isCollaborated", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.bool)), (function (id, isPinned, isScraped, isCollaborated) {
        return {
                id: Belt_Option.getWithDefault(id, ""),
                isPinned: isPinned,
                isScraped: Belt_Option.getWithDefault(isScraped, false),
                isCollaborated: Belt_Option.getWithDefault(isCollaborated, false)
              };
      }));

function postsPayload_encode(payload) {
  return Json__Encode.object([
              [
                "id",
                Json__Encode.string(payload.id)
              ],
              [
                "isPinned",
                Json__Encode.bool(payload.isPinned)
              ],
              [
                "isScraped",
                Json__Encode.bool(payload.isScraped)
              ],
              [
                "isCollaborated",
                Json__Encode.bool(payload.isCollaborated)
              ]
            ]);
}

var reelsWithPayload_decode = Curry._3(Generic$ApiTypes.Decode.map2, Generic$ApiTypes.Decode.exactVariantChoice("Reels"), Curry._2(Generic$ApiTypes.Decode.index, 1, reelsPayload_decode), (function (param, payload) {
        return {
                TAG: /* Reels */1,
                _0: payload
              };
      }));

var instagramContentType_v2_decode = Curry._2(Generic$ApiTypes.Decode.oneOf, Curry._2(Generic$ApiTypes.Decode.andThen, Generic$ApiTypes.Decode.exactVariantChoice("Posts"), (function (param) {
            return Curry._1(Generic$ApiTypes.Decode.succeed, {
                        TAG: /* Posts */0,
                        _0: {
                          id: "",
                          isPinned: false,
                          isScraped: false,
                          isCollaborated: false
                        }
                      });
          })), [reelsWithPayload_decode]);

var postsWithPayload_decode = Curry._3(Generic$ApiTypes.Decode.map2, Generic$ApiTypes.Decode.exactVariantChoice("Posts"), Curry._2(Generic$ApiTypes.Decode.index, 1, postsPayload_decode), (function (param, payload) {
        return {
                TAG: /* Posts */0,
                _0: payload
              };
      }));

var instagramContentType_v1_decode = Curry._2(Generic$ApiTypes.Decode.oneOf, Curry._2(Generic$ApiTypes.Decode.andThen, Generic$ApiTypes.Decode.exactVariantChoice("Posts"), (function (param) {
            return Curry._1(Generic$ApiTypes.Decode.succeed, {
                        TAG: /* Posts */0,
                        _0: {
                          id: "",
                          isPinned: false,
                          isScraped: false,
                          isCollaborated: false
                        }
                      });
          })), [Curry._2(Generic$ApiTypes.Decode.andThen, Generic$ApiTypes.Decode.exactVariantChoice("Reels"), (function (param) {
              return Curry._1(Generic$ApiTypes.Decode.succeed, {
                          TAG: /* Reels */1,
                          _0: {
                            id: "",
                            isPinned: false,
                            isSharedToFeed: true,
                            isScraped: false,
                            isCollaborated: false
                          }
                        });
            }))]);

var fromJson = Curry._2(Generic$ApiTypes.Decode.oneOf, postsWithPayload_decode, [reelsWithPayload_decode]);

var fromJson$1 = Curry._2(Generic$ApiTypes.Decode.oneOf, fromJson, [
      instagramContentType_v2_decode,
      instagramContentType_v1_decode
    ]);

function toJson(contentType) {
  if (contentType.TAG === /* Posts */0) {
    return Generic$ApiTypes.Encode.deccoVariantWithPayload(contentType._0, "Posts", postsPayload_encode);
  } else {
    return Generic$ApiTypes.Encode.deccoVariantWithPayload(contentType._0, "Reels", reelsPayload_encode);
  }
}

var include = DeccoAdapter$ApiTypes.Make({
      toJson: toJson,
      fromJson: fromJson$1
    });

var ContentType_t_encode = include.t_encode;

var ContentType_t_decode = include.t_decode;

var ContentType_codec = include.codec;

var ContentType = {
  reelsPayload_decode: reelsPayload_decode,
  reelsPayload_encode: reelsPayload_encode,
  postsPayload_decode: postsPayload_decode,
  postsPayload_encode: postsPayload_encode,
  reelsWithPayload_decode: reelsWithPayload_decode,
  instagramContentType_v2_decode: instagramContentType_v2_decode,
  postsWithPayload_decode: postsWithPayload_decode,
  instagramContentType_v1_decode: instagramContentType_v1_decode,
  fromJson: fromJson$1,
  toJson: toJson,
  t_encode: ContentType_t_encode,
  t_decode: ContentType_t_decode,
  codec: ContentType_codec
};

export {
  ContentType ,
}
/* reelsPayload_decode Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../rescript/lib/es6/curry.js";
import * as Belt_Option from "../../../../rescript/lib/es6/belt_Option.js";
import * as Generic$ApiTypes from "./utils/Generic.bs.js";
import * as DeccoAdapter$ApiTypes from "./utils/DeccoAdapter.bs.js";

function subset_encode(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, [
              [
                "disable",
                Curry._1(Generic$ApiTypes.Encode.bool, param.disable)
              ],
              [
                "image",
                Generic$ApiTypes.Encode.nullable(param.image, Generic$ApiTypes.Encode.string)
              ],
              [
                "smallImage",
                Generic$ApiTypes.Encode.nullable(param.smallImage, Generic$ApiTypes.Encode.string)
              ],
              [
                "link",
                Generic$ApiTypes.Encode.nullable(param.link, Generic$ApiTypes.Encode.string)
              ],
              [
                "customScript",
                Generic$ApiTypes.Encode.nullable(param.customScript, Generic$ApiTypes.Encode.string)
              ]
            ]);
}

var subset_decode = Curry._6(Generic$ApiTypes.Decode.map5, Generic$ApiTypes.Decode.fieldWithDefault("disable", Generic$ApiTypes.Decode.bool, false), Curry._2(Generic$ApiTypes.Decode.field, "image", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.string)), Curry._2(Generic$ApiTypes.Decode.field, "smallImage", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.string)), Curry._2(Generic$ApiTypes.Decode.field, "link", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.string)), Curry._2(Generic$ApiTypes.Decode.field, "customScript", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.string)), (function (disable, image, smallImage, link, customScript) {
        return {
                disable: disable,
                image: image,
                smallImage: smallImage,
                link: link,
                customScript: customScript
              };
      }));

function toJson(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, [
              [
                "label",
                Curry._1(Generic$ApiTypes.Encode.string, param.label)
              ],
              [
                "image",
                Generic$ApiTypes.Encode.nullable(param.image, Generic$ApiTypes.Encode.string)
              ],
              [
                "smallImage",
                Generic$ApiTypes.Encode.nullable(param.smallImage, Generic$ApiTypes.Encode.string)
              ],
              [
                "link",
                Generic$ApiTypes.Encode.nullable(param.link, Generic$ApiTypes.Encode.string)
              ],
              [
                "overrides",
                Generic$ApiTypes.Encode.nullable(param.overrides, (function (__x) {
                        return Generic$ApiTypes.Encode.dictWithEncoder(__x, subset_encode);
                      }))
              ],
              [
                "excludeCategoryIds",
                Curry._2(Generic$ApiTypes.Encode.array, param.excludeCategoryIds, Generic$ApiTypes.Encode.string)
              ],
              [
                "customScript",
                Generic$ApiTypes.Encode.nullable(param.customScript, Generic$ApiTypes.Encode.string)
              ]
            ]);
}

var fromJson = Curry._8(Generic$ApiTypes.Decode.map7, Generic$ApiTypes.Decode.fieldWithDefault("label", Generic$ApiTypes.Decode.string, "Size Guide"), Curry._2(Generic$ApiTypes.Decode.field, "image", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.string)), Curry._2(Generic$ApiTypes.Decode.field, "smallImage", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.string)), Curry._2(Generic$ApiTypes.Decode.field, "link", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.string)), Curry._2(Generic$ApiTypes.Decode.field, "overrides", Curry._1(Generic$ApiTypes.Decode.option, Curry._1(Generic$ApiTypes.Decode.dict, subset_decode))), Generic$ApiTypes.Decode.fieldWithDefault("excludeCategoryIds", Curry._1(Generic$ApiTypes.Decode.array, Generic$ApiTypes.Decode.string), []), Curry._2(Generic$ApiTypes.Decode.field, "customScript", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.string)), (function (label, image, smallImage, link, overrides, excludeCategoryIds, customScript) {
        return {
                label: label,
                image: image,
                smallImage: smallImage,
                link: link,
                overrides: overrides,
                excludeCategoryIds: excludeCategoryIds,
                customScript: customScript
              };
      }));

var empty_excludeCategoryIds = [];

var empty = {
  label: "Size Guide",
  image: undefined,
  smallImage: undefined,
  link: undefined,
  overrides: undefined,
  excludeCategoryIds: empty_excludeCategoryIds,
  customScript: undefined
};

function toSubset(t) {
  var disable = Belt_Option.isNone(t.image) && Belt_Option.isNone(t.smallImage) && Belt_Option.isNone(t.link);
  return {
          disable: disable,
          image: t.image,
          smallImage: t.smallImage,
          link: t.link,
          customScript: t.customScript
        };
}

function fromSubset(templateOpt, subset) {
  var template = templateOpt !== undefined ? templateOpt : empty;
  return {
          label: template.label,
          image: subset.image,
          smallImage: subset.smallImage,
          link: subset.link,
          overrides: template.overrides,
          excludeCategoryIds: template.excludeCategoryIds,
          customScript: subset.customScript
        };
}

var include = DeccoAdapter$ApiTypes.Make({
      toJson: toJson,
      fromJson: fromJson
    });

var emptySubset = {
  disable: false,
  image: undefined,
  smallImage: undefined,
  link: undefined,
  customScript: undefined
};

var t_encode = include.t_encode;

var t_decode = include.t_decode;

var codec = include.codec;

export {
  subset_encode ,
  subset_decode ,
  emptySubset ,
  toJson ,
  fromJson ,
  empty ,
  toSubset ,
  fromSubset ,
  t_encode ,
  t_decode ,
  codec ,
}
/* subset_decode Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
function tap(data, fn) {
  Curry._1(fn, data);
  return data;
}
function log2(data, msg) {
  console.log(msg, data);
  return data;
}
function log3(data, msg0, msg1) {
  var fn = function (param) {
    console.log(msg0, msg1, param);
  };
  fn(data);
  return data;
}
function log4(data, msg0, msg1, mdg2) {
  var fn = function (param) {
    console.log(msg0, msg1, mdg2, param);
  };
  fn(data);
  return data;
}
export { tap, log2, log3, log4 };
/* No side effect */
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
function useWithDefault(hookNameOpt, providerNameOpt, $$default, ctx) {
  var hookName = hookNameOpt !== undefined ? hookNameOpt : "React.useContext";
  var providerName = providerNameOpt !== undefined ? providerNameOpt : "React.Context.provider";
  var ctx$1 = React.useContext(ctx);
  if (ctx$1 !== undefined) {
    return Caml_option.valFromOption(ctx$1);
  } else {
    console.warn("" + hookName + " was called without initializing the provider. Ensure the component is wrapped in <" + providerName + ">.");
    return $$default;
  }
}
export { useWithDefault };
/* react Not a pure module */
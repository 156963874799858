// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../rescript/lib/es6/curry.js";
import * as Js_json from "../../../../rescript/lib/es6/js_json.js";
import * as Belt_Result from "../../../../rescript/lib/es6/belt_Result.js";
import * as Caml_option from "../../../../rescript/lib/es6/caml_option.js";

function falseableEncode(encoder, opt) {
  if (opt !== undefined) {
    return Curry._1(encoder, Caml_option.valFromOption(opt));
  } else {
    return false;
  }
}

function falseableDecode(decoder, json) {
  var match = Js_json.decodeBoolean(json);
  if (match !== undefined && !match) {
    return {
            TAG: /* Ok */0,
            _0: undefined
          };
  }
  var __x = Curry._1(decoder, json);
  return Belt_Result.map(__x, (function (v) {
                return Caml_option.some(v);
              }));
}

var falseable = [
  falseableEncode,
  falseableDecode
];

function magicDecode(j) {
  return {
          TAG: /* Ok */0,
          _0: j
        };
}

function magic_0(prim) {
  return prim;
}

var magic = [
  magic_0,
  magicDecode
];

export {
  falseableEncode ,
  falseableDecode ,
  falseable ,
  magicDecode ,
  magic ,
}
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_obj from "../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Webapi__Dom__Element from "../../../../node_modules/rescript-webapi/lib/es6_global/src/Webapi/Dom/Webapi__Dom__Element.bs.js";
function AdditionalCSS(Props) {
  var isNavBeingHiddenWithDisplayNone = Belt_Array.some(Belt_Array.map(Belt_Array.map(Array.prototype.slice.call(document.querySelectorAll("#maker-smartnav, #maker-smartnav-inline, #maker-smartnav-launcher, #maker-smartnav-section")), Webapi__Dom__Element.ofNode), function (node) {
    return Belt_Option.map(node, function (node) {
      return window.getComputedStyle(node).display;
    });
  }), function (display) {
    return Caml_obj.equal(display, "none");
  });
  if (isNavBeingHiddenWithDisplayNone) {
    return null;
  } else {
    return React.createElement("style", undefined, "html[lang='en'].QVTW__body-no-scroll body,\n        html.QVTW__body-no-scroll,\n        html[lang='en'].QVTW__body-no-scroll body.production,\n        html.QVTW__body-no-scroll,\n        html.QVTW__body-no-scroll body {\n          overflow: clip !important;\n          overflow-x: clip !important;\n          overflow-y: clip !important;\n          touch-action: none;\n        }\n\n        html.QVTW__body-no-scroll .js-maker-embed[data-sticky] iframe {\n          display: none !important;\n        }");
  }
}
AdditionalCSS.displayName = "AdditionalCSS";
var make = AdditionalCSS;
export { make };
/* react Not a pure module */
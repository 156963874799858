// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../rescript/lib/es6/curry.js";
import * as Generic$ApiTypes from "../utils/Generic.bs.js";
import * as Tree_LayoutCodecs$ApiTypes from "../Tree/Tree_LayoutCodecs.bs.js";

var gridColumns_fromJson = Generic$ApiTypes.Decode.simpleVariant([
      [
        "OneColumnGrid",
        /* OneColumnGrid */0
      ],
      [
        "TwoColumnGrid",
        /* TwoColumnGrid */1
      ],
      [
        "ThreeColumnGrid",
        /* ThreeColumnGrid */2
      ],
      [
        "FourColumnGrid",
        /* FourColumnGrid */3
      ],
      [
        "FiveColumnGrid",
        /* FiveColumnGrid */4
      ],
      [
        "SixColumnGrid",
        /* SixColumnGrid */5
      ],
      [
        "SevenColumnGrid",
        /* SevenColumnGrid */6
      ],
      [
        "EightColumnGrid",
        /* EightColumnGrid */7
      ],
      [
        "TwelveColumnGrid",
        /* TwelveColumnGrid */8
      ]
    ]);

var pixelDecoder = Generic$ApiTypes.Decode.deccoVariantWithPayload("Pixels", Generic$ApiTypes.Decode.$$int, (function (i) {
        return /* Pixels */{
                _0: i
              };
      }));

var layoutGap_fromJson = Curry._2(Generic$ApiTypes.Decode.oneOf, pixelDecoder, [Generic$ApiTypes.Decode.simpleVariant([
            [
              "Equal",
              /* Equal */0
            ],
            [
              "Half",
              /* Half */1
            ],
            [
              "Double",
              /* Double */2
            ]
          ])]);

var sliderEffect_fromJson = Generic$ApiTypes.Decode.simpleVariant([
      [
        "EffectSlide",
        /* EffectSlide */0
      ],
      [
        "EffectFade",
        /* EffectFade */1
      ]
    ]);

var sliderNav_fromJson = Generic$ApiTypes.Decode.simpleVariant([
      [
        "NavAuto",
        /* NavAuto */0
      ],
      [
        "NavArrows",
        /* NavArrows */1
      ],
      [
        "NavScrollbar",
        /* NavScrollbar */2
      ],
      [
        "NavDots",
        /* NavDots */3
      ],
      [
        "NavNone",
        /* NavNone */4
      ]
    ]);

var transition_fromJson = Generic$ApiTypes.Decode.simpleVariant([
      [
        "TransitionEase",
        /* TransitionEase */0
      ],
      [
        "TransitionLinear",
        /* TransitionLinear */1
      ],
      [
        "TransitionEaseIn",
        /* TransitionEaseIn */2
      ],
      [
        "TransitionEaseOut",
        /* TransitionEaseOut */3
      ],
      [
        "TransitionEaseInOut",
        /* TransitionEaseInOut */4
      ]
    ]);

var iconsWithLabelDecoder = Generic$ApiTypes.Decode.deccoVariantWithPayload("IconsWithLabel", Curry._2(Generic$ApiTypes.Decode.map, Curry._2(Generic$ApiTypes.Decode.field, "lineClamp", Generic$ApiTypes.Decode.$$int), (function (lineClamp) {
            return /* IconsWithLabel */{
                    lineClamp: lineClamp
                  };
          })), (function (v) {
        return v;
      }));

var smartbarCategoryLayout_fromJson = Curry._2(Generic$ApiTypes.Decode.oneOf, iconsWithLabelDecoder, [Generic$ApiTypes.Decode.simpleVariant([
            [
              "Labels",
              /* Labels */0
            ],
            [
              "IconsOnly",
              /* IconsOnly */1
            ],
            [
              "PillsWithLabel",
              /* PillsWithLabel */2
            ],
            [
              "PillsWithIcon",
              /* PillsWithIcon */3
            ],
            [
              "PillsWithRoundedIcon",
              /* PillsWithRoundedIcon */4
            ]
          ])]);

var smartbarCategorySize_fromJson = Generic$ApiTypes.Decode.simpleVariant([
      [
        "Small",
        /* Small */0
      ],
      [
        "Medium",
        /* Medium */1
      ],
      [
        "Large",
        /* Large */2
      ]
    ]);

var customDecoder = Generic$ApiTypes.Decode.deccoVariantWithPayload("Custom", Curry._2(Generic$ApiTypes.Decode.tuple2, Generic$ApiTypes.Decode.$$int, Generic$ApiTypes.Decode.$$int), (function (param) {
        return /* Custom */{
                _0: param[0],
                _1: param[1]
              };
      }));

var cardShape_fromJson = Curry._2(Generic$ApiTypes.Decode.oneOf, customDecoder, [Generic$ApiTypes.Decode.simpleVariant([
            [
              "Circle",
              /* Circle */0
            ],
            [
              "Square",
              /* Square */1
            ],
            [
              "Landscape",
              /* Landscape */2
            ],
            [
              "Portrait",
              /* Portrait */3
            ],
            [
              "Panorama",
              /* Panorama */4
            ],
            [
              "Wide",
              /* Wide */5
            ],
            [
              "Tall",
              /* Tall */6
            ]
          ])]);

var gridPayload_fromJson = Curry._7(Generic$ApiTypes.Decode.map6, Curry._2(Generic$ApiTypes.Decode.field, "columns", gridColumns_fromJson), Generic$ApiTypes.Decode.fieldWithDefault("gap", layoutGap_fromJson, /* Equal */0), Generic$ApiTypes.Decode.fieldWithDefault("maxRows", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.$$int), undefined), Generic$ApiTypes.Decode.fieldWithDefault("maxWidth", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.$$int), undefined), Generic$ApiTypes.Decode.fieldWithDefault("padding", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.$$int), undefined), Generic$ApiTypes.Decode.fieldWithDefault("pattern", Curry._1(Generic$ApiTypes.Decode.option, Tree_LayoutCodecs$ApiTypes.Layout.GridPattern.fromJson), undefined), (function (columns, gap, maxRows, maxWidth, padding, pattern) {
        return {
                columns: columns,
                gap: gap,
                maxRows: maxRows,
                maxWidth: maxWidth,
                padding: padding,
                pattern: pattern
              };
      }));

var sliderPayload_fromJson = Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._1(Generic$ApiTypes.Decode.succeed, (function (slidesPerView, gap, maxWidth, padding, rows, effect, nav, transition, transitionSpeed, autoplay, autoplayDelay, loop) {
                                                        return {
                                                                slidesPerView: slidesPerView,
                                                                gap: gap,
                                                                maxWidth: maxWidth,
                                                                padding: padding,
                                                                rows: rows,
                                                                effect: effect,
                                                                nav: nav,
                                                                transition: transition,
                                                                transitionSpeed: transitionSpeed,
                                                                autoplay: autoplay,
                                                                autoplayDelay: autoplayDelay,
                                                                loop: loop
                                                              };
                                                      })), Curry._2(Generic$ApiTypes.Decode.field, "slidesPerView", Generic$ApiTypes.Decode.$$float)), Generic$ApiTypes.Decode.fieldWithDefault("gap", layoutGap_fromJson, /* Equal */0)), Generic$ApiTypes.Decode.fieldWithDefault("maxWidth", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.$$int), undefined)), Generic$ApiTypes.Decode.fieldWithDefault("padding", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.$$int), undefined)), Generic$ApiTypes.Decode.fieldWithDefault("rows", Generic$ApiTypes.Decode.$$int, 1)), Generic$ApiTypes.Decode.fieldWithDefault("effect", sliderEffect_fromJson, /* EffectSlide */0)), Generic$ApiTypes.Decode.fieldWithDefault("nav", sliderNav_fromJson, /* NavAuto */0)), Generic$ApiTypes.Decode.fieldWithDefault("transition", transition_fromJson, /* TransitionEaseOut */3)), Generic$ApiTypes.Decode.fieldWithDefault("transitionSpeed", Generic$ApiTypes.Decode.$$int, 300)), Generic$ApiTypes.Decode.fieldWithDefault("autoplay", Generic$ApiTypes.Decode.bool, false)), Generic$ApiTypes.Decode.fieldWithDefault("autoplayDelay", Generic$ApiTypes.Decode.$$int, 3000)), Generic$ApiTypes.Decode.fieldWithDefault("loop", Generic$ApiTypes.Decode.bool, false));

export {
  gridColumns_fromJson ,
  layoutGap_fromJson ,
  sliderEffect_fromJson ,
  sliderNav_fromJson ,
  transition_fromJson ,
  smartbarCategoryLayout_fromJson ,
  smartbarCategorySize_fromJson ,
  cardShape_fromJson ,
  gridPayload_fromJson ,
  sliderPayload_fromJson ,
}
/* gridColumns_fromJson Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import UseKey from "@rooks/use-key";
function use(target, metaOpt, ctrlOpt, activeOpt, allowRepeatOpt, preventDefaultOpt, stopPropagationOpt, key, fn) {
  var meta = metaOpt !== undefined ? metaOpt : false;
  var ctrl = ctrlOpt !== undefined ? ctrlOpt : false;
  var active = activeOpt !== undefined ? activeOpt : true;
  var allowRepeat = allowRepeatOpt !== undefined ? allowRepeatOpt : false;
  var preventDefault = preventDefaultOpt !== undefined ? preventDefaultOpt : false;
  var stopPropagation = stopPropagationOpt !== undefined ? stopPropagationOpt : false;
  UseKey(key, function (e) {
    if ((allowRepeat || !e.repeat) && (!meta || e.metaKey) && (!ctrl || e.ctrlKey)) {
      Curry._1(fn, undefined);
      if (preventDefault) {
        e.preventDefault();
      }
      if (stopPropagation) {
        e.stopPropagation();
        return;
      } else {
        return;
      }
    }
  }, {
    eventTypes: ["keydown"],
    target: target,
    when: active
  });
}
export { use };
/* @rooks/use-key Not a pure module */
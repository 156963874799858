// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Float from "../../../../node_modules/rescript/lib/es6/belt_Float.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Category$Showside from "../types/Category.bs.js";
function resolveNoneAsNA(value) {
  return Belt_Option.getWithDefault(value, "NA");
}
function track(eventName, category, product, variant, isMobile, productsOpt, productCount, param) {
  var device = isMobile ? "Mobile" : "Desktop";
  var $$event;
  if (eventName === "category_view") {
    $$event = category !== undefined ? {
      NAME: "category_view",
      VAL: {
        category_name: category.title,
        category_id: category.id,
        URL: Belt_Option.getWithDefault(Category$Showside.getCategoryLink(category), "NA"),
        device: device,
        platform: "Maker"
      }
    } : undefined;
  } else if (product !== undefined && variant !== undefined) {
    var price = variant.price;
    var tmp;
    tmp = typeof price === "number" ? 0.0 : price.TAG === /* Regular */0 ? Belt_Option.getWithDefault(Belt_Float.fromString(price._0), 0.0) : Belt_Option.getWithDefault(Belt_Float.fromString(price._0.price), 0.0);
    var price$1 = variant.price;
    var tmp$1;
    tmp$1 = typeof price$1 === "number" ? 0.0 : price$1.TAG === /* Regular */0 ? Belt_Option.getWithDefault(Belt_Float.fromString(price$1._0), 0.0) : Belt_Option.getWithDefault(Belt_Float.fromString(price$1._0.regularPrice), 0.0);
    var match = variant.media;
    var tmp$2;
    switch (match.TAG | 0) {
      case /* Image */0:
        tmp$2 = match._0.url;
        break;
      case /* Video */1:
        tmp$2 = match._0.posterUrl;
        break;
      case /* IframeEmbed */2:
        tmp$2 = undefined;
        break;
    }
    $$event = {
      NAME: "product_view",
      VAL: {
        product_name: product.name,
        sku: Belt_Option.getWithDefault(variant.sku, "NA"),
        product_id: variant.id,
        mrp: tmp,
        special_price: tmp$1,
        currency: product.currency,
        discount: 0.0,
        product_url: Belt_Option.getWithDefault(Belt_Option.orElse(variant.link, product.link), "NA"),
        image_url: Belt_Option.getWithDefault(tmp$2, "NA"),
        category: Belt_Option.getWithDefault(Belt_Option.map(category, function (category) {
          return category.title;
        }), "NA"),
        device: device,
        platform: "Maker",
        sub_category: Belt_Option.getWithDefault(undefined, "NA"),
        user_id: Belt_Option.getWithDefault(undefined, "NA"),
        country: Belt_Option.getWithDefault(undefined, "NA"),
        country_code: Belt_Option.getWithDefault(undefined, "NA"),
        country_id: Belt_Option.getWithDefault(undefined, "NA"),
        brand: Belt_Option.getWithDefault(undefined, "NA")
      }
    };
  } else {
    $$event = undefined;
  }
  var clevertap = window.clevertap;
  if (clevertap === undefined) {
    return;
  }
  if ($$event === undefined) {
    return;
  }
  var clevertap$1 = Caml_option.valFromOption(clevertap);
  if ($$event.NAME === "category_view") {
    var details = $$event.VAL;
    return Belt_Option.forEach(clevertap$1.event, function ($$event) {
      $$event.push("category_view", details);
    });
  }
  var details$1 = $$event.VAL;
  Belt_Option.forEach(clevertap$1.event, function ($$event) {
    $$event.push("product_view", details$1);
  });
}
export { resolveNoneAsNA, track };
/* Category-Showside Not a pure module */
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "../../../../../rescript/lib/es6/caml_option.js";

function useSafeTimeout(debugOpt, param) {
  var debug = debugOpt !== undefined ? debugOpt : false;
  var ref = React.useRef(undefined);
  var $$clearTimeout$1 = function (param) {
    var id = ref.current;
    if (id === undefined) {
      return ;
    }
    var id$1 = Caml_option.valFromOption(id);
    if (debug) {
      console.log("clearing safe timeout ", id$1);
    }
    clearTimeout(id$1);
  };
  var $$setTimeout$1 = function (callback, timeoutMs) {
    $$clearTimeout$1(undefined);
    ref.current = Caml_option.some(setTimeout((function (param) {
                Curry._1(callback, undefined);
              }), timeoutMs));
    if (debug) {
      console.log("starting safe timeout ", ref.current, timeoutMs);
      return ;
    }
    
  };
  React.useEffect((function () {
          return (function (param) {
                    if (debug) {
                      console.log("clearing safe timeout ", ref.current);
                    }
                    $$clearTimeout$1(undefined);
                  });
        }), []);
  return React.useMemo((function () {
                return {
                        setTimeout: $$setTimeout$1,
                        clearTimeout: $$clearTimeout$1
                      };
              }), [
              ref.current,
              debug
            ]);
}

export {
  useSafeTimeout ,
}
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as UseSafeTimeout from "../../../../node_modules/@makerinc/use-safe-timeout/lib/es6_global/src/UseSafeTimeout.bs.js";
var timeSpent = {
  contents: 0
};
var openState = {
  contents: /* Closed */0
};
function use(config, isOpen, track) {
  var timeSpentEvents = React.useRef([]);
  var match = UseSafeTimeout.useSafeTimeout(undefined, undefined);
  var $$clearTimeout = match.clearTimeout;
  var $$setTimeout = match.setTimeout;
  var getTimeSpent = function (openAt) {
    return Math.round((Date.now() - openAt) / 1000) | 0;
  };
  var timeSpentPolling = function (timeSpent, openAt) {
    var time = timeSpent + getTimeSpent(openAt) | 0;
    var newEvents = Belt_Array.keep(Belt_Array.keep([30, 60, 90, 120], function (seconds) {
      return Belt_Array.some(timeSpentEvents.current, function (val) {
        return val === seconds;
      }) === false;
    }), function (seconds) {
      return time >= seconds;
    });
    Belt_Array.forEach(newEvents, function (seconds) {
      Curry.app(track, [{
        NAME: "sec",
        VAL: String(seconds)
      }, false, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined]);
    });
    timeSpentEvents.current = Belt_Array.concat(timeSpentEvents.current, newEvents);
    $$setTimeout(function (param) {
      timeSpentPolling(timeSpent, openAt);
    }, 1000);
  };
  React.useEffect(function () {
    var match = openState.contents;
    if (match) {
      if (isOpen) {} else {
        timeSpent.contents = timeSpent.contents + getTimeSpent(match._0) | 0;
        openState.contents = /* Closed */0;
      }
    } else if (isOpen) {
      var openAt = Date.now();
      openState.contents = /* Open */{
        _0: openAt
      };
      timeSpentPolling(timeSpent.contents, openAt);
    }
    return function (param) {
      Curry._1($$clearTimeout, undefined);
    };
  }, [isOpen]);
  React.useEffect(function () {
    var handleBeforeUnload = function (param) {
      var openAt = openState.contents;
      var time = openAt ? timeSpent.contents + getTimeSpent(openAt._0) | 0 : timeSpent.contents;
      if (time > 0) {
        return Curry.app(track, ["timespent", undefined, undefined, undefined, undefined, undefined, time, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined]);
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("pagehide", handleBeforeUnload);
    return function (param) {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("pagehide", handleBeforeUnload);
    };
  }, []);
}
export { timeSpent, openState, use };
/* react Not a pure module */
// Generated by ReScript, PLEASE EDIT WITH CARE

var config = {
  apiKey: "AIzaSyBeEYbHQ1kD7JW3eXTj7z_Ko-ilIDfotmw",
  authDomain: "smart-nav-analytics.firebaseapp.com",
  projectId: "smart-nav-analytics",
  storageBucket: "smart-nav-analytics.appspot.com",
  messagingSenderId: "340297097320",
  appId: "1:340297097320:web:6979368a4b6cd0e86b1bdd",
  measurementId: "G-PXQYP4VJE2"
};
export { config };
/* No side effect */
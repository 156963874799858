// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../rescript/lib/es6/curry.js";
import * as Json__Decode from "../../../../../rescript-json/lib/es6_global/src/Json__Decode.bs.js";
import * as Json__Encode from "../../../../../rescript-json/lib/es6_global/src/Json__Encode.bs.js";
import * as DeccoAdapter$ApiTypes from "../utils/DeccoAdapter.bs.js";
import * as MkrBio_SocialNetworkCodecs$ApiTypes from "./MkrBio_SocialNetworkCodecs.bs.js";

function toJson(param) {
  return Json__Encode.object([
              [
                "handle",
                Json__Encode.string(param.handle)
              ],
              [
                "showPostDescription",
                Json__Encode.bool(param.showPostDescription)
              ],
              [
                "showLikes",
                Json__Encode.bool(param.showLikes)
              ],
              [
                "showViews",
                Json__Encode.bool(param.showViews)
              ],
              [
                "numOfPosts",
                Json__Encode.$$int(param.numOfPosts)
              ],
              [
                "socialNetwork",
                Curry._1(MkrBio_SocialNetworkCodecs$ApiTypes.SocialNetwork.toJson, param.socialNetwork)
              ]
            ]);
}

var fromJson = Json__Decode.map6(Json__Decode.field("handle", Json__Decode.string), Json__Decode.field("showPostDescription", Json__Decode.bool), Json__Decode.field("showLikes", Json__Decode.bool), Json__Decode.field("showViews", Json__Decode.bool), Json__Decode.field("numOfPosts", Json__Decode.$$int), Json__Decode.field("socialNetwork", MkrBio_SocialNetworkCodecs$ApiTypes.SocialNetwork.fromJson), (function (handle, showPostDescription, showLikes, showViews, numOfPosts, socialNetwork) {
        return {
                handle: handle,
                showPostDescription: showPostDescription,
                showLikes: showLikes,
                showViews: showViews,
                numOfPosts: numOfPosts,
                socialNetwork: socialNetwork
              };
      }));

var include = DeccoAdapter$ApiTypes.Make({
      toJson: toJson,
      fromJson: fromJson
    });

var t_encode = include.t_encode;

var t_decode = include.t_decode;

var SocialNetworkConnection_codec = include.codec;

var SocialNetworkConnection = {
  toJson: toJson,
  fromJson: fromJson,
  t_encode: t_encode,
  t_decode: t_decode,
  codec: SocialNetworkConnection_codec
};

var socialNetworkConnection_encode = t_encode;

var socialNetworkConnection_decode = t_decode;

export {
  SocialNetworkConnection ,
  socialNetworkConnection_encode ,
  socialNetworkConnection_decode ,
}
/* fromJson Not a pure module */

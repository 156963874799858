// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Generic$ApiTypes from "../utils/Generic.bs.js";
import * as DeccoAdapter$ApiTypes from "../utils/DeccoAdapter.bs.js";

var partial_arg = [
  [
    "Instagram",
    /* Instagram */0
  ],
  [
    "Tiktok",
    /* Tiktok */1
  ],
  [
    "Twitter",
    /* Twitter */2
  ],
  [
    "Facebook",
    /* Facebook */3
  ],
  [
    "Youtube",
    /* Youtube */4
  ]
];

function toJson(param) {
  return Generic$ApiTypes.Encode.simpleVariant(partial_arg, param);
}

var fromJson = Generic$ApiTypes.Decode.simpleVariant([
      [
        "Instagram",
        /* Instagram */0
      ],
      [
        "Tiktok",
        /* Tiktok */1
      ],
      [
        "Twitter",
        /* Twitter */2
      ],
      [
        "Facebook",
        /* Facebook */3
      ],
      [
        "Youtube",
        /* Youtube */4
      ]
    ]);

var include = DeccoAdapter$ApiTypes.Make({
      toJson: toJson,
      fromJson: fromJson
    });

var t_encode = include.t_encode;

var t_decode = include.t_decode;

var SocialNetwork_codec = include.codec;

var SocialNetwork = {
  toJson: toJson,
  fromJson: fromJson,
  t_encode: t_encode,
  t_decode: t_decode,
  codec: SocialNetwork_codec
};

var socialNetwork_encode = t_encode;

var socialNetwork_decode = t_decode;

export {
  SocialNetwork ,
  socialNetwork_encode ,
  socialNetwork_decode ,
}
/* fromJson Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import loadable from "@loadable/component";
let component = loadable(() => import('./LauncherApp.bs.js'));
;
function LauncherApp_Lazy(Props) {
  return React.createElement(component, Props);
}
LauncherApp_Lazy.displayName = "LauncherApp_Lazy";
var make = LauncherApp_Lazy;
export { make };
/*  Not a pure module */
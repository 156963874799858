// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as URLUtils$Showside from "./URLUtils.bs.js";
import * as EventTypes$Showside from "../types/EventTypes.bs.js";
import * as FirebaseConfig$Showside from "./FirebaseConfig.bs.js";
var jsonStringifyExn = function jsonStringify(input) {
  return JSON.stringify(input);
};
var initScript = function () {
  window.dataLayer = window.dataLayer || [];
  window.gtag = function () {
    dataLayer.push(arguments);
  };
};
function sendEvent(account, callback, $$event, properties, param) {
  var match = window.gtag;
  if (match !== undefined) {} else {
    Curry._1(initScript, undefined);
  }
  var match$1 = Belt_Option.mapWithDefault(account, false, function (a) {
    return a.eventsPostmessage;
  });
  var match$2 = window.gtag;
  var match$3 = window.gtag;
  if (!match$1) {
    if (callback !== undefined) {
      if (match$2 !== undefined) {
        return match$2("event", $$event, properties, Caml_option.valFromOption(callback));
      } else {
        return;
      }
    } else if (match$3 !== undefined) {
      return match$3("event", $$event, properties);
    } else {
      return;
    }
  }
  try {
    var data = jsonStringifyExn({
      smartnav: true,
      event: $$event,
      ecommerce: properties
    });
    window.postMessage(data, "*");
    return;
  } catch (exn) {
    return;
  }
}
function track(account, gaTrackingId, $$event, category, action, label, value, param) {
  sendEvent(account, undefined, action, {
    event_category: category,
    event_label: label,
    value: value,
    non_interaction: EventTypes$Showside.isNonInteractive($$event),
    send_to: gaTrackingId
  }, undefined);
}
function insertScript(id) {
  Curry._1(initScript, undefined);
  var script = document.createElement("script");
  script.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=" + id);
  script.setAttribute("async", "true");
  Belt_Option.forEach(Caml_option.nullable_to_opt(document.querySelector("head")), function (param) {
    param.appendChild(script);
  });
}
function init(gaTrackingId, checkoutLink, sendPageViewOpt, account) {
  var sendPageView = sendPageViewOpt !== undefined ? sendPageViewOpt : false;
  var domain = Belt_Option.mapWithDefault(Belt_Option.map(Belt_Option.flatMap(checkoutLink, URLUtils$Showside.maybeMakeUrl), function (prim) {
    return prim.hostname;
  }), [], function (domain) {
    return [domain];
  });
  var match = window.gtag;
  if (match !== undefined || gaTrackingId === undefined) {} else {
    insertScript(gaTrackingId);
  }
  var match$1 = window.gtag;
  if (match$1 !== undefined && gaTrackingId !== undefined) {
    return match$1("config", gaTrackingId, {
      send_page_view: sendPageView,
      linker: checkoutLink !== undefined ? {
        domains: Belt_Array.concat(["mkr.bio"], domain)
      } : undefined
    });
  }
}
var clientIdRef = {
  contents: undefined
};
function getGtagProperty(gaTrackingId, name) {
  return new Promise(function (resolve, reject) {
    var match = window.smartnavOriginalGtag;
    var match$1 = window.gtag;
    var gtag;
    if (match !== undefined) {
      gtag = match;
    } else {
      if (match$1 === undefined) {
        return reject(null);
      }
      gtag = match$1;
    }
    gtag("get", Belt_Option.getWithDefault(gaTrackingId, FirebaseConfig$Showside.config.measurementId), name, function (result) {
      resolve(result == null ? undefined : Caml_option.some(result));
    });
  });
}
function getClientId(gaTrackingId, gaVersion, checkoutLink, account) {
  var value = clientIdRef.contents;
  if (value !== undefined) {
    return Promise.resolve(value);
  } else {
    init(gaTrackingId, checkoutLink, undefined, account);
    return Promise.all([getGtagProperty(gaTrackingId, "client_id"), getGtagProperty(gaTrackingId, "session_id")]).then(function (param) {
      var sessionId = param[1];
      var clientId = param[0];
      clientIdRef.contents = clientId !== undefined && sessionId !== undefined ? [clientId, sessionId] : undefined;
      return clientIdRef.contents;
    });
  }
}
var initAnalyticsJs = function initAnalyticsJs() {
  (function (i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r;
    i[r] = i[r] || function () {
      (i[r].q = i[r].q || []).push(arguments);
    }, i[r].l = 1 * new Date();
    a = s.createElement(o), m = s.getElementsByTagName(o)[0];
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
  })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
};
function getLinkerParam(gaTrackingId, gaVersion, checkoutLink, account, param) {
  var glBridge = Belt_Option.flatMap(Belt_Option.map(window.google_tag_data == null ? undefined : Caml_option.some(window.google_tag_data), function (prim) {
    return prim.glBridge;
  }), function (prim) {
    if (prim == null) {
      return;
    } else {
      return Caml_option.some(prim);
    }
  });
  var retry = function (param) {
    return new Promise(function (resolve, reject) {
      setTimeout(function (param) {
        getLinkerParam(gaTrackingId, gaVersion, checkoutLink, account, undefined).then(function (result) {
          resolve(result);
        });
      }, 42);
    });
  };
  var match = window.GoogleAnalyticsObject;
  if (account === undefined) {
    return Promise.resolve(undefined);
  }
  if (glBridge === undefined) {
    if (match !== undefined) {
      return retry(undefined);
    } else {
      Curry._1(initAnalyticsJs, undefined);
      return retry(undefined);
    }
  }
  var glBridge$1 = Caml_option.valFromOption(glBridge);
  return getClientId(gaTrackingId, Caml_option.some(gaVersion), checkoutLink, account).then(function (result) {
    if (result === undefined) {
      return;
    }
    var sessionId = result[1];
    var clientId = result[0];
    var value = Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Array.map(Belt_Option.getWithDefault(Caml_option.nullable_to_opt(document.cookie), "").split(";"), function (prim) {
      return prim.trim();
    }), function (value) {
      var regex = new RegExp("_ga_(.*)=GS1.1.(" + sessionId + "(.*))");
      var matches = Belt_Option.getWithDefault(Caml_option.null_to_opt(value.match(regex)), []);
      var key = Belt_Array.get(matches, 1);
      var value$1 = Belt_Array.get(matches, 2);
      if (key === undefined) {
        return;
      }
      var key$1 = Caml_option.valFromOption(key);
      if (key$1 === undefined) {
        return;
      }
      if (value$1 === undefined) {
        return;
      }
      var value$2 = Caml_option.valFromOption(value$1);
      if (value$2 !== undefined) {
        return [key$1, value$2];
      }
    }), 0), function (param) {
      return Caml_option.nullable_to_opt(glBridge$1.generate(Js_dict.fromArray([["_ga", clientId], ["_ga_" + param[0], param[1]]])));
    });
    if (value !== undefined) {
      return ["_gl", value];
    }
  });
}
var Linker = {
  clientIdRef: clientIdRef,
  getGtagProperty: getGtagProperty,
  getClientId: getClientId,
  initAnalyticsJs: initAnalyticsJs,
  getLinkerParam: getLinkerParam
};
var Gtag = {
  jsonStringifyExn: jsonStringifyExn,
  initScript: initScript,
  sendEvent: sendEvent,
  track: track,
  insertScript: insertScript,
  init: init,
  Linker: Linker
};
function getLinkerParam$1(gaTrackingId, gaVersion, checkoutLink, account, param) {
  if (gaTrackingId !== undefined) {
    return getLinkerParam(gaTrackingId, gaVersion, checkoutLink, account, undefined);
  } else {
    return Promise.resolve(undefined);
  }
}
function track$1(account, gaTrackingId, $$event, category, action, label, value, param) {
  track(account, gaTrackingId, $$event, category, action, label, value, undefined);
}
function init$1(gaTrackingId, gaVersion, checkoutLink, sendPageView, account) {
  if (gaTrackingId !== undefined) {
    init(gaTrackingId, checkoutLink, sendPageView, account);
    return;
  }
}
export { Gtag, getLinkerParam$1 as getLinkerParam, track$1 as track, init$1 as init };
/* No side effect */
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../rescript/lib/es6/curry.js";
import * as Generic$ApiTypes from "./utils/Generic.bs.js";
import * as DeccoAdapter$ApiTypes from "./utils/DeccoAdapter.bs.js";
import * as CustomActionId$ApiTypes from "./CustomActionId.bs.js";

function toJson(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, [
              [
                "id",
                Curry._1(CustomActionId$ApiTypes.toJson, param.id)
              ],
              [
                "name",
                Curry._1(Generic$ApiTypes.Encode.string, param.name)
              ],
              [
                "onClickFn",
                Generic$ApiTypes.Encode.nullable(param.onClickFn, Generic$ApiTypes.Encode.string)
              ],
              [
                "renderFn",
                Generic$ApiTypes.Encode.nullable(param.renderFn, Generic$ApiTypes.Encode.string)
              ],
              [
                "addToCart",
                Curry._1(Generic$ApiTypes.Encode.bool, param.addToCart)
              ]
            ]);
}

var fromJson = Curry._6(Generic$ApiTypes.Decode.map5, Curry._2(Generic$ApiTypes.Decode.field, "id", CustomActionId$ApiTypes.fromJson), Curry._2(Generic$ApiTypes.Decode.field, "name", Generic$ApiTypes.Decode.string), Curry._2(Generic$ApiTypes.Decode.field, "onClickFn", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.string)), Curry._2(Generic$ApiTypes.Decode.field, "renderFn", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.string)), Curry._2(Generic$ApiTypes.Decode.field, "addToCart", Generic$ApiTypes.Decode.bool), (function (id, name, onClickFn, renderFn, addToCart) {
        return {
                id: id,
                name: name,
                onClickFn: onClickFn,
                renderFn: renderFn,
                addToCart: addToCart
              };
      }));

var include = DeccoAdapter$ApiTypes.Make({
      toJson: toJson,
      fromJson: fromJson
    });

function toJson$1(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, [
              [
                "id",
                Generic$ApiTypes.Encode.nullable(param.id, CustomActionId$ApiTypes.toJson)
              ],
              [
                "textColor",
                Generic$ApiTypes.Encode.nullable(param.textColor, Generic$ApiTypes.Encode.string)
              ],
              [
                "bgColor",
                Generic$ApiTypes.Encode.nullable(param.bgColor, Generic$ApiTypes.Encode.string)
              ],
              [
                "label",
                Generic$ApiTypes.Encode.nullable(param.label, Generic$ApiTypes.Encode.string)
              ],
              [
                "confirmation",
                Generic$ApiTypes.Encode.nullable(param.confirmation, Generic$ApiTypes.Encode.string)
              ]
            ]);
}

var fromJson$1 = Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._1(Generic$ApiTypes.Decode.succeed, (function (id, textColor, bgColor, label, confirmation) {
                            return {
                                    id: id,
                                    textColor: textColor,
                                    bgColor: bgColor,
                                    label: label,
                                    confirmation: confirmation
                                  };
                          })), Curry._2(Generic$ApiTypes.Decode.field, "id", Curry._1(Generic$ApiTypes.Decode.option, CustomActionId$ApiTypes.fromJson))), Generic$ApiTypes.Decode.fieldWithDefault("textColor", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.string), undefined)), Generic$ApiTypes.Decode.fieldWithDefault("bgColor", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.string), undefined)), Generic$ApiTypes.Decode.fieldWithDefault("label", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.string), undefined)), Generic$ApiTypes.Decode.fieldWithDefault("confirmation", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.string), undefined));

var include$1 = DeccoAdapter$ApiTypes.Make({
      toJson: toJson$1,
      fromJson: fromJson$1
    });

var Button_defaults = {
  id: undefined,
  textColor: "#ffffff",
  bgColor: "#000000",
  label: "Add to Cart",
  confirmation: "Added to cart!"
};

var Button_t_encode = include$1.t_encode;

var Button_t_decode = include$1.t_decode;

var Button_codec = include$1.codec;

var Button = {
  toJson: toJson$1,
  fromJson: fromJson$1,
  defaults: Button_defaults,
  t_encode: Button_t_encode,
  t_decode: Button_t_decode,
  codec: Button_codec
};

var t_encode = include.t_encode;

var t_decode = include.t_decode;

var codec = include.codec;

export {
  toJson ,
  fromJson ,
  t_encode ,
  t_decode ,
  codec ,
  Button ,
}
/* fromJson Not a pure module */

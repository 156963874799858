// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../rescript/lib/es6/curry.js";
import * as Generic$ApiTypes from "../utils/Generic.bs.js";
import * as Tree_LayoutCodecs$ApiTypes from "../Tree/Tree_LayoutCodecs.bs.js";
import * as LegacyShowside_BasicTypesDecoders$ApiTypes from "./LegacyShowside_BasicTypesDecoders.bs.js";

var partial_arg = [
  [
    "OneColumnGrid",
    /* OneColumnGrid */0
  ],
  [
    "TwoColumnGrid",
    /* TwoColumnGrid */1
  ],
  [
    "ThreeColumnGrid",
    /* ThreeColumnGrid */2
  ],
  [
    "FourColumnGrid",
    /* FourColumnGrid */3
  ],
  [
    "FiveColumnGrid",
    /* FiveColumnGrid */4
  ],
  [
    "SixColumnGrid",
    /* SixColumnGrid */5
  ],
  [
    "SevenColumnGrid",
    /* SevenColumnGrid */6
  ],
  [
    "EightColumnGrid",
    /* EightColumnGrid */7
  ],
  [
    "TwelveColumnGrid",
    /* TwelveColumnGrid */8
  ]
];

function gridColumns_toJson(param) {
  return Generic$ApiTypes.Encode.simpleVariant(partial_arg, param);
}

function layoutGap_toJson(v) {
  if (typeof v !== "number") {
    return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "Pixels", Generic$ApiTypes.Encode.$$int);
  }
  switch (v) {
    case /* Equal */0 :
        return Curry._2(Generic$ApiTypes.Encode.array, ["Equal"], Generic$ApiTypes.Encode.string);
    case /* Half */1 :
        return Curry._2(Generic$ApiTypes.Encode.array, ["Half"], Generic$ApiTypes.Encode.string);
    case /* Double */2 :
        return Curry._2(Generic$ApiTypes.Encode.array, ["Double"], Generic$ApiTypes.Encode.string);
    
  }
}

var partial_arg$1 = [
  [
    "EffectSlide",
    /* EffectSlide */0
  ],
  [
    "EffectFade",
    /* EffectFade */1
  ]
];

function sliderEffect_toJson(param) {
  return Generic$ApiTypes.Encode.simpleVariant(partial_arg$1, param);
}

var partial_arg$2 = [
  [
    "NavAuto",
    /* NavAuto */0
  ],
  [
    "NavScrollbar",
    /* NavScrollbar */2
  ],
  [
    "NavArrows",
    /* NavArrows */1
  ],
  [
    "NavDots",
    /* NavDots */3
  ],
  [
    "NavNone",
    /* NavNone */4
  ]
];

function sliderNav_toJson(param) {
  return Generic$ApiTypes.Encode.simpleVariant(partial_arg$2, param);
}

var partial_arg$3 = [
  [
    "TransitionEase",
    /* TransitionEase */0
  ],
  [
    "TransitionLinear",
    /* TransitionLinear */1
  ],
  [
    "TransitionEaseIn",
    /* TransitionEaseIn */2
  ],
  [
    "TransitionEaseOut",
    /* TransitionEaseOut */3
  ],
  [
    "TransitionEaseInOut",
    /* TransitionEaseInOut */4
  ]
];

function transition_toJson(param) {
  return Generic$ApiTypes.Encode.simpleVariant(partial_arg$3, param);
}

function smartbarCategoryLayout_toJson(v) {
  if (typeof v !== "number") {
    return Generic$ApiTypes.Encode.deccoVariantWithPayload({
                lineClamp: v.lineClamp
              }, "IconsWithLabel", (function (payload) {
                  return Curry._1(Generic$ApiTypes.Encode.object, [[
                                "lineClamp",
                                Curry._1(Generic$ApiTypes.Encode.$$int, payload.lineClamp)
                              ]]);
                }));
  }
  switch (v) {
    case /* Labels */0 :
        return Curry._2(Generic$ApiTypes.Encode.array, ["Labels"], Generic$ApiTypes.Encode.string);
    case /* IconsOnly */1 :
        return Curry._2(Generic$ApiTypes.Encode.array, ["IconsOnly"], Generic$ApiTypes.Encode.string);
    case /* PillsWithLabel */2 :
        return Curry._2(Generic$ApiTypes.Encode.array, ["PillsWithLabel"], Generic$ApiTypes.Encode.string);
    case /* PillsWithIcon */3 :
        return Curry._2(Generic$ApiTypes.Encode.array, ["PillsWithIcon"], Generic$ApiTypes.Encode.string);
    case /* PillsWithRoundedIcon */4 :
        return Curry._2(Generic$ApiTypes.Encode.array, ["PillsWithRoundedIcon"], Generic$ApiTypes.Encode.string);
    
  }
}

var partial_arg$4 = [
  [
    "Small",
    /* Small */0
  ],
  [
    "Medium",
    /* Medium */1
  ],
  [
    "Large",
    /* Large */2
  ]
];

function smartbarCategorySize_toJson(param) {
  return Generic$ApiTypes.Encode.simpleVariant(partial_arg$4, param);
}

function cardShape_toJson(v) {
  if (typeof v !== "number") {
    return Generic$ApiTypes.Encode.deccoVariantWithPayload([
                v._0,
                v._1
              ], "Custom", (function (__x) {
                  return Curry._2(Generic$ApiTypes.Encode.array, __x, Generic$ApiTypes.Encode.$$int);
                }));
  }
  switch (v) {
    case /* Circle */0 :
        return Curry._2(Generic$ApiTypes.Encode.array, ["Circle"], Generic$ApiTypes.Encode.string);
    case /* Square */1 :
        return Curry._2(Generic$ApiTypes.Encode.array, ["Square"], Generic$ApiTypes.Encode.string);
    case /* Landscape */2 :
        return Curry._2(Generic$ApiTypes.Encode.array, ["Landscape"], Generic$ApiTypes.Encode.string);
    case /* Portrait */3 :
        return Curry._2(Generic$ApiTypes.Encode.array, ["Portrait"], Generic$ApiTypes.Encode.string);
    case /* Panorama */4 :
        return Curry._2(Generic$ApiTypes.Encode.array, ["Panorama"], Generic$ApiTypes.Encode.string);
    case /* Wide */5 :
        return Curry._2(Generic$ApiTypes.Encode.array, ["Wide"], Generic$ApiTypes.Encode.string);
    case /* Tall */6 :
        return Curry._2(Generic$ApiTypes.Encode.array, ["Tall"], Generic$ApiTypes.Encode.string);
    
  }
}

function gridPayload_toJson(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(/* Equal */0, [[
                                    "columns",
                                    gridColumns_toJson(param.columns)
                                  ]], layoutGap_toJson, [
                                  "gap",
                                  param.gap
                                ]), (function (__x) {
                                return Generic$ApiTypes.Encode.nullable(__x, Generic$ApiTypes.Encode.$$int);
                              }), [
                              "maxRows",
                              param.maxRows
                            ]), (function (__x) {
                            return Generic$ApiTypes.Encode.nullable(__x, Generic$ApiTypes.Encode.$$int);
                          }), [
                          "maxWidth",
                          param.maxWidth
                        ]), (function (__x) {
                        return Generic$ApiTypes.Encode.nullable(__x, Generic$ApiTypes.Encode.$$int);
                      }), [
                      "padding",
                      param.padding
                    ]), (function (__x) {
                    return Generic$ApiTypes.Encode.nullable(__x, Tree_LayoutCodecs$ApiTypes.Layout.GridPattern.toJson);
                  }), [
                  "pattern",
                  param.pattern
                ]));
}

function sliderPayload_toJson(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, Generic$ApiTypes.Encode.addIf(false, Generic$ApiTypes.Encode.addIf(false, Generic$ApiTypes.Encode.addIf(3000, Generic$ApiTypes.Encode.addIf(300, Generic$ApiTypes.Encode.addIf(/* TransitionEaseOut */3, Generic$ApiTypes.Encode.addIf(/* NavAuto */0, Generic$ApiTypes.Encode.addIf(/* EffectSlide */0, Generic$ApiTypes.Encode.addIf(1, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(/* Equal */0, [[
                                                            "slidesPerView",
                                                            Curry._1(Generic$ApiTypes.Encode.$$float, param.slidesPerView)
                                                          ]], layoutGap_toJson, [
                                                          "gap",
                                                          param.gap
                                                        ]), (function (__x) {
                                                        return Generic$ApiTypes.Encode.nullable(__x, Generic$ApiTypes.Encode.$$int);
                                                      }), [
                                                      "maxWidth",
                                                      param.maxWidth
                                                    ]), (function (__x) {
                                                    return Generic$ApiTypes.Encode.nullable(__x, Generic$ApiTypes.Encode.$$int);
                                                  }), [
                                                  "padding",
                                                  param.padding
                                                ]), Generic$ApiTypes.Encode.$$int, [
                                              "rows",
                                              param.rows
                                            ]), sliderEffect_toJson, [
                                          "effect",
                                          param.effect
                                        ]), sliderNav_toJson, [
                                      "nav",
                                      param.nav
                                    ]), transition_toJson, [
                                  "transition",
                                  param.transition
                                ]), Generic$ApiTypes.Encode.$$int, [
                              "transitionSpeed",
                              param.transitionSpeed
                            ]), Generic$ApiTypes.Encode.$$int, [
                          "autoplayDelay",
                          param.autoplayDelay
                        ]), Generic$ApiTypes.Encode.bool, [
                      "autoplay",
                      param.autoplay
                    ]), Generic$ApiTypes.Encode.bool, [
                  "loop",
                  param.loop
                ]));
}

var gridColumns_fromJson = LegacyShowside_BasicTypesDecoders$ApiTypes.gridColumns_fromJson;

var sliderEffect_fromJson = LegacyShowside_BasicTypesDecoders$ApiTypes.sliderEffect_fromJson;

var sliderNav_fromJson = LegacyShowside_BasicTypesDecoders$ApiTypes.sliderNav_fromJson;

var transition_fromJson = LegacyShowside_BasicTypesDecoders$ApiTypes.transition_fromJson;

var layoutGap_fromJson = LegacyShowside_BasicTypesDecoders$ApiTypes.layoutGap_fromJson;

var smartbarCategoryLayout_fromJson = LegacyShowside_BasicTypesDecoders$ApiTypes.smartbarCategoryLayout_fromJson;

var smartbarCategorySize_fromJson = LegacyShowside_BasicTypesDecoders$ApiTypes.smartbarCategorySize_fromJson;

var cardShape_fromJson = LegacyShowside_BasicTypesDecoders$ApiTypes.cardShape_fromJson;

var gridPayload_fromJson = LegacyShowside_BasicTypesDecoders$ApiTypes.gridPayload_fromJson;

var sliderPayload_fromJson = LegacyShowside_BasicTypesDecoders$ApiTypes.sliderPayload_fromJson;

export {
  gridColumns_toJson ,
  gridColumns_fromJson ,
  layoutGap_toJson ,
  sliderEffect_toJson ,
  sliderEffect_fromJson ,
  sliderNav_toJson ,
  sliderNav_fromJson ,
  transition_toJson ,
  transition_fromJson ,
  layoutGap_fromJson ,
  smartbarCategoryLayout_toJson ,
  smartbarCategoryLayout_fromJson ,
  smartbarCategorySize_toJson ,
  smartbarCategorySize_fromJson ,
  cardShape_toJson ,
  cardShape_fromJson ,
  gridPayload_toJson ,
  gridPayload_fromJson ,
  sliderPayload_toJson ,
  sliderPayload_fromJson ,
}
/* Generic-ApiTypes Not a pure module */

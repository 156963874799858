// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../rescript/lib/es6/curry.js";
import * as Generic$ApiTypes from "../utils/Generic.bs.js";
import * as DeccoAdapter$ApiTypes from "../utils/DeccoAdapter.bs.js";

var fromJson = Generic$ApiTypes.Decode.simpleVariant([
      [
        "ChildrenFirst",
        /* ChildrenFirst */0
      ],
      [
        "ProductsFirst",
        /* ProductsFirst */1
      ]
    ]);

function toJson(v) {
  if (v) {
    return Curry._2(Generic$ApiTypes.Encode.array, ["ProductsFirst"], Generic$ApiTypes.Encode.string);
  } else {
    return Curry._2(Generic$ApiTypes.Encode.array, ["ChildrenFirst"], Generic$ApiTypes.Encode.string);
  }
}

var include = DeccoAdapter$ApiTypes.Make({
      toJson: toJson,
      fromJson: fromJson
    });

var DisplayPriority_t_encode = include.t_encode;

var DisplayPriority_t_decode = include.t_decode;

var DisplayPriority_codec = include.codec;

var DisplayPriority = {
  fromJson: fromJson,
  toJson: toJson,
  t_encode: DisplayPriority_t_encode,
  t_decode: DisplayPriority_t_decode,
  codec: DisplayPriority_codec
};

export {
  DisplayPriority ,
}
/* fromJson Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as StringNaturalCompare from "string-natural-compare";
function compare(a, b) {
  try {
    return StringNaturalCompare.default(a, b);
  } catch (exn) {
    return StringNaturalCompare(a, b);
  }
}
export { compare };
/* string-natural-compare Not a pure module */
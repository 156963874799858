// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "../../../../node_modules/rescript/lib/es6/caml.js";
import * as React from "react";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as UseBreakpoint$Showside from "../hook/UseBreakpoint.bs.js";
import * as UseWindowSize$Showside from "../hook/UseWindowSize.bs.js";
var initialValue = {
  isMobile: true,
  breakpoint: /* Sm */1,
  windowSize: {
    width: 1,
    height: 1
  }
};
var context = React.createContext(initialValue);
var provider = context.Provider;
function ViewportContext$Provider(Props) {
  var value = Props.value;
  var children = Props.children;
  var match = UseWindowSize$Showside.useWindowSize(true, 100, undefined);
  var width = Caml.int_max(match[0], 1);
  var height = Caml.int_max(match[1], 1);
  var match$1 = UseBreakpoint$Showside.use(width);
  var breakpoint = match$1.breakpoint;
  var isMobile = match$1.isMobile;
  var value$1 = React.useMemo(function () {
    return Belt_Option.getWithDefault(value, {
      isMobile: isMobile,
      breakpoint: breakpoint,
      windowSize: {
        width: width,
        height: height
      }
    });
  }, [breakpoint, isMobile, value, width, height]);
  return React.createElement(provider, {
    value: value$1,
    children: children
  });
}
ViewportContext$Provider.displayName = "ViewportContext.Provider";
var Provider = {
  provider: provider,
  make: ViewportContext$Provider
};
function use(param) {
  return React.useContext(context);
}
export { initialValue, context, Provider, use };
/* context Not a pure module */
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Tap$Showside from "./Tap.bs.js";
import * as Js_null_undefined from "../../../../node_modules/rescript/lib/es6/js_null_undefined.js";
function use(node, selector) {
  var getElementByQuerySelector = function (selector) {
    if (node !== undefined) {
      return Caml_option.nullable_to_opt(Caml_option.valFromOption(node).querySelector(selector.trim()));
    } else {
      return Caml_option.nullable_to_opt(document.querySelector(selector.trim()));
    }
  };
  var makeNullable = function (param) {
    if (selector !== undefined) {
      var selectors = selector.split(",");
      return Js_null_undefined.fromOption(Tap$Showside.tap(Belt_Array.get(Belt_Array.keepMap(Belt_Array.map(selectors, getElementByQuerySelector), function (x) {
        return x;
      }), 0), function (i) {
        if (i === undefined) {
          console.warn("Warning: RefByQuerySelector could not find any elements with the given selector.", selector);
          return;
        }
      }));
    } else {
      return null;
    }
  };
  var ref = React.useRef(makeNullable(undefined));
  React.useEffect(function () {
    ref.current = makeNullable(undefined);
    return function (param) {
      ref.current = null;
    };
  });
  return ref;
}
export { use };
/* react Not a pure module */
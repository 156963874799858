// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as HotKeys$Showside from "../utils/HotKeys.bs.js";
import * as RouterProvider$Showside from "../context/RouterProvider.bs.js";
import * as UseRouteManipulation$Showside from "../hook/UseRouteManipulation.bs.js";
function EscapeKeyController(Props) {
  var embedType = Props.embedType;
  var match = RouterProvider$Showside.useRouter(undefined);
  var route = match.route;
  var match$1 = UseRouteManipulation$Showside.useRouteManipulation(undefined);
  var back = match$1.back;
  var back$1 = function (param) {
    if (embedType !== 1) {
      if (embedType !== 0) {
        return;
      } else {
        return Curry._1(back, undefined);
      }
    } else if (typeof route === "number" || route.TAG !== /* Home */0) {
      return Curry._1(back, undefined);
    } else {
      return;
    }
  };
  HotKeys$Showside.use(undefined, undefined, undefined, undefined, undefined, undefined, undefined, "Escape", back$1);
  return null;
}
EscapeKeyController.displayName = "EscapeKeyController";
var make = EscapeKeyController;
export { make };
/* HotKeys-Showside Not a pure module */
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../rescript/lib/es6/curry.js";
import * as Json__Decode from "../../../../../rescript-json/lib/es6_global/src/Json__Decode.bs.js";
import * as Generic$ApiTypes from "../utils/Generic.bs.js";
import * as DeccoAdapter$ApiTypes from "../utils/DeccoAdapter.bs.js";
import * as CustomActionId$ApiTypes from "../CustomActionId.bs.js";
import * as Store_ProductSortOrderCodecs$ApiTypes from "../Store/Store_ProductSortOrderCodecs.bs.js";

function t_encode(v) {
  var categoryLink_toJson = function (param) {
    return Curry._1(Generic$ApiTypes.Encode.object, [
                [
                  "value",
                  Generic$ApiTypes.Encode.nullable(param.value, Generic$ApiTypes.Encode.string)
                ],
                [
                  "categoryId",
                  Curry._1(Generic$ApiTypes.Encode.string, param.categoryId)
                ]
              ]);
  };
  var productLink_toJson = function (param) {
    return Curry._1(Generic$ApiTypes.Encode.object, [
                [
                  "value",
                  Generic$ApiTypes.Encode.nullable(param.value, Generic$ApiTypes.Encode.string)
                ],
                [
                  "productId",
                  Curry._1(Generic$ApiTypes.Encode.string, param.productId)
                ],
                [
                  "variantId",
                  Generic$ApiTypes.Encode.nullable(param.variantId, Generic$ApiTypes.Encode.string)
                ]
              ]);
  };
  var storeCollectionLink_toJson = function (param) {
    return Curry._1(Generic$ApiTypes.Encode.object, [
                [
                  "value",
                  Generic$ApiTypes.Encode.nullable(param.value, Generic$ApiTypes.Encode.string)
                ],
                [
                  "categoryId",
                  Curry._1(Generic$ApiTypes.Encode.string, param.categoryId)
                ],
                [
                  "sortBy",
                  Store_ProductSortOrderCodecs$ApiTypes.ProductSortOrder.toJson(param.sortBy)
                ]
              ]);
  };
  var customActionLink_toJson = function (param) {
    return Curry._1(Generic$ApiTypes.Encode.object, [[
                  "customActionId",
                  Curry._1(CustomActionId$ApiTypes.toJson, param.customActionId)
                ]]);
  };
  if (typeof v === "number") {
    return Generic$ApiTypes.Encode.simpleVariant([[
                  "NotLinked",
                  /* NotLinked */0
                ]], /* NotLinked */0);
  }
  switch (v.TAG | 0) {
    case /* UrlLink */0 :
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "UrlLink", Generic$ApiTypes.Encode.string);
    case /* CategoryLink */1 :
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "CategoryLink", categoryLink_toJson);
    case /* ProductLink */2 :
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "ProductLink", productLink_toJson);
    case /* StoreCategoryLink */3 :
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "StoreCategoryLink", storeCollectionLink_toJson);
    case /* CustomActionLink */4 :
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "CustomActionLink", customActionLink_toJson);
    case /* UnknownLink */5 :
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "UnknownLink", Generic$ApiTypes.Encode.string);
    
  }
}

var categoryLink_fromJson = Curry._3(Generic$ApiTypes.Decode.map2, Curry._2(Generic$ApiTypes.Decode.field, "value", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.string)), Curry._2(Generic$ApiTypes.Decode.field, "categoryId", Generic$ApiTypes.Decode.string), (function (value, categoryId) {
        return {
                value: value,
                categoryId: categoryId
              };
      }));

var productLink_fromJson = Curry._4(Generic$ApiTypes.Decode.map3, Curry._2(Generic$ApiTypes.Decode.field, "value", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.string)), Curry._2(Generic$ApiTypes.Decode.field, "productId", Generic$ApiTypes.Decode.string), Curry._2(Generic$ApiTypes.Decode.field, "variantId", Curry._1(Generic$ApiTypes.Decode.option, Generic$ApiTypes.Decode.string)), (function (value, productId, variantId) {
        return {
                value: value,
                productId: productId,
                variantId: variantId
              };
      }));

var storeCategoryLink_fromJson = Curry._4(Generic$ApiTypes.Decode.map3, Curry._2(Generic$ApiTypes.Decode.field, "value", Curry._1(Generic$ApiTypes.Decode.nullable, Generic$ApiTypes.Decode.string)), Curry._2(Generic$ApiTypes.Decode.field, "categoryId", Generic$ApiTypes.Decode.string), Generic$ApiTypes.Decode.fieldWithDefault("sortBy", Store_ProductSortOrderCodecs$ApiTypes.ProductSortOrder.fromJson, /* Default */0), (function (value, categoryId, sortBy) {
        return {
                value: value,
                categoryId: categoryId,
                sortBy: sortBy
              };
      }));

var customActionLink_fromJson = Curry._2(Generic$ApiTypes.Decode.map, Curry._2(Generic$ApiTypes.Decode.field, "customActionId", CustomActionId$ApiTypes.fromJson), (function (customActionId) {
        return {
                customActionId: customActionId
              };
      }));

var t_decode = Curry._2(Generic$ApiTypes.Decode.oneOf, Generic$ApiTypes.Decode.deccoVariantWithPayload("UnknownLink", Generic$ApiTypes.Decode.string, (function (str) {
            return {
                    TAG: /* UnknownLink */5,
                    _0: str
                  };
          })), [
      Generic$ApiTypes.Decode.deccoVariantWithPayload("UrlLink", Generic$ApiTypes.Decode.string, (function (str) {
              return {
                      TAG: /* UrlLink */0,
                      _0: str
                    };
            })),
      Generic$ApiTypes.Decode.deccoVariantWithPayload("CategoryLink", categoryLink_fromJson, (function (str) {
              return {
                      TAG: /* CategoryLink */1,
                      _0: str
                    };
            })),
      Generic$ApiTypes.Decode.deccoVariantWithPayload("ProductLink", productLink_fromJson, (function (str) {
              return {
                      TAG: /* ProductLink */2,
                      _0: str
                    };
            })),
      Generic$ApiTypes.Decode.deccoVariantWithPayload("StoreCategoryLink", storeCategoryLink_fromJson, (function (str) {
              return {
                      TAG: /* StoreCategoryLink */3,
                      _0: str
                    };
            })),
      Generic$ApiTypes.Decode.deccoVariantWithPayload("CustomActionLink", customActionLink_fromJson, (function (str) {
              return {
                      TAG: /* CustomActionLink */4,
                      _0: str
                    };
            })),
      Generic$ApiTypes.Decode.simpleVariant([[
              "NotLinked",
              /* NotLinked */0
            ]])
    ]);

var t_v1_decode = Curry._2(Generic$ApiTypes.Decode.andThen, Generic$ApiTypes.Decode.$$default(Generic$ApiTypes.Decode.string, ""), (function (link) {
        return Curry._1(Generic$ApiTypes.Decode.succeed, {
                    TAG: /* UnknownLink */5,
                    _0: link
                  });
      }));

var t_decode$1 = Json__Decode.oneOf(t_decode, [t_v1_decode]);

var include = DeccoAdapter$ApiTypes.Make({
      toJson: t_encode,
      fromJson: t_decode$1
    });

var Link_t_encode = include.t_encode;

var Link_t_decode = include.t_decode;

var Link_codec = include.codec;

var Link = {
  t_v1_decode: t_v1_decode,
  toJson: t_encode,
  fromJson: t_decode$1,
  t_encode: Link_t_encode,
  t_decode: Link_t_decode,
  codec: Link_codec
};

export {
  Link ,
}
/* categoryLink_fromJson Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../rescript/lib/es6/curry.js";
import * as Json__Decode from "../../../../../rescript-json/lib/es6_global/src/Json__Decode.bs.js";
import * as Facets$ApiTypes from "../Facets.bs.js";
import * as Generic$ApiTypes from "../utils/Generic.bs.js";
import * as CustomActionId$ApiTypes from "../CustomActionId.bs.js";
import * as QuickViewPinCodecs$ApiTypes from "../QuickViewPinCodecs.bs.js";
import * as Tree_TaggedMediaCodecs$ApiTypes from "../Tree/Tree_TaggedMediaCodecs.bs.js";
import * as Tree_TaggedProductsCodecs$ApiTypes from "../Tree/Tree_TaggedProductsCodecs.bs.js";
import * as Tree_DisplayPriorityCodecs$ApiTypes from "../Tree/Tree_DisplayPriorityCodecs.bs.js";
import * as Store_ProductSortOrderCodecs$ApiTypes from "../Store/Store_ProductSortOrderCodecs.bs.js";
import * as Tree_CategoryTabSettingsCodecs$ApiTypes from "../Tree/Tree_CategoryTabSettingsCodecs.bs.js";
import * as Tree_SocialNetworkSourceCodecs$ApiTypes from "../Tree/Tree_SocialNetworkSourceCodecs.bs.js";
import * as LegacyShowside_BasicTypesCodecs$ApiTypes from "./LegacyShowside_BasicTypesCodecs.bs.js";
import * as LegacyShowside_CategoryDecoders$ApiTypes from "./LegacyShowside_CategoryDecoders.bs.js";

var partial_arg = [
  [
    "Xxxs",
    /* Xxxs */0
  ],
  [
    "Xxs",
    /* Xxs */1
  ],
  [
    "Xs",
    /* Xs */2
  ],
  [
    "Sm",
    /* Sm */3
  ],
  [
    "Md",
    /* Md */4
  ],
  [
    "Lg",
    /* Lg */5
  ],
  [
    "Xl",
    /* Xl */6
  ],
  [
    "Xxl",
    /* Xxl */7
  ],
  [
    "Xxxl",
    /* Xxxl */8
  ],
  [
    "Xxxxl",
    /* Xxxxl */9
  ],
  [
    "Xxxxxl",
    /* Xxxxxl */10
  ]
];

function toJson(param) {
  return Generic$ApiTypes.Encode.simpleVariant(partial_arg, param);
}

var fromJson = LegacyShowside_CategoryDecoders$ApiTypes.CardSize.fromJson;

var CardSize = {
  toJson: toJson,
  fromJson: fromJson
};

function toJson$1(v) {
  var categoryLink_toJson = function (param) {
    return Curry._1(Generic$ApiTypes.Encode.object, Generic$ApiTypes.Encode.addIf(undefined, [[
                      "categoryId",
                      Curry._1(Generic$ApiTypes.Encode.string, param.categoryId)
                    ]], (function (__x) {
                      return Generic$ApiTypes.Encode.nullable(__x, Facets$ApiTypes.t_encode);
                    }), [
                    "filters",
                    param.filters
                  ]));
  };
  var productLink_toJson = function (param) {
    return Curry._1(Generic$ApiTypes.Encode.object, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(undefined, [[
                          "productId",
                          Curry._1(Generic$ApiTypes.Encode.string, param.productId)
                        ]], (function (__x) {
                          return Generic$ApiTypes.Encode.nullable(__x, Generic$ApiTypes.Encode.string);
                        }), [
                        "variantId",
                        param.variantId
                      ]), (function (__x) {
                      return Generic$ApiTypes.Encode.nullable(__x, Generic$ApiTypes.Encode.string);
                    }), [
                    "productCategoryId",
                    param.productCategoryId
                  ]));
  };
  var storeCategoryLink_toJson = function (param) {
    return Curry._1(Generic$ApiTypes.Encode.object, [
                [
                  "categoryId",
                  Curry._1(Generic$ApiTypes.Encode.string, param.categoryId)
                ],
                [
                  "sortBy",
                  Store_ProductSortOrderCodecs$ApiTypes.ProductSortOrder.toJson(param.sortBy)
                ],
                [
                  "count",
                  Curry._1(Generic$ApiTypes.Encode.$$int, param.count)
                ],
                [
                  "relatedMediaCount",
                  Generic$ApiTypes.Encode.nullable(param.relatedMediaCount, Generic$ApiTypes.Encode.$$int)
                ]
              ]);
  };
  var customActionLink_toJson = function (param) {
    return Curry._1(Generic$ApiTypes.Encode.object, [
                [
                  "customActionId",
                  Curry._1(CustomActionId$ApiTypes.toJson, param.customActionId)
                ],
                [
                  "code",
                  Curry._1(Generic$ApiTypes.Encode.string, param.code)
                ],
                [
                  "name",
                  Curry._1(Generic$ApiTypes.Encode.string, param.name)
                ]
              ]);
  };
  switch (v.TAG | 0) {
    case /* URL */0 :
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "URL", Generic$ApiTypes.Encode.string);
    case /* Category */1 :
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "Category", categoryLink_toJson);
    case /* Product */2 :
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "Product", productLink_toJson);
    case /* StoreCategory */3 :
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "StoreCategory", storeCategoryLink_toJson);
    case /* CustomAction */4 :
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "CustomAction", customActionLink_toJson);
    
  }
}

var fromJson$1 = LegacyShowside_CategoryDecoders$ApiTypes.Link.fromJson;

var Link = {
  toJson: toJson$1,
  fromJson: fromJson$1
};

function videoCard_toJson(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(/* Square */1, Generic$ApiTypes.Encode.addIf(/* Lg */5, [[
                                        "videoUrl",
                                        Curry._1(Generic$ApiTypes.Encode.string, param.videoUrl)
                                      ]], toJson, [
                                      "size",
                                      param.size
                                    ]), LegacyShowside_BasicTypesCodecs$ApiTypes.cardShape_toJson, [
                                  "shape",
                                  param.shape
                                ]), (function (__x) {
                                return Generic$ApiTypes.Encode.nullable(__x, toJson$1);
                              }), [
                              "link",
                              param.link
                            ]), (function (__x) {
                            return Generic$ApiTypes.Encode.nullable(__x, Generic$ApiTypes.Encode.$$int);
                          }), [
                          "width",
                          param.width
                        ]), (function (__x) {
                        return Generic$ApiTypes.Encode.nullable(__x, Generic$ApiTypes.Encode.$$int);
                      }), [
                      "height",
                      param.height
                    ]), (function (__x) {
                    return Generic$ApiTypes.Encode.nullable(__x, Generic$ApiTypes.Encode.string);
                  }), [
                  "fallbackImageUrl",
                  param.fallbackImageUrl
                ]));
}

var videoCard_fromJson = LegacyShowside_CategoryDecoders$ApiTypes.CategoryCard.videoCard_fromJson;

function imageCard_toJson(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(/* Square */1, Generic$ApiTypes.Encode.addIf(/* Lg */5, [[
                                    "imageUrl",
                                    Curry._1(Generic$ApiTypes.Encode.string, param.imageUrl)
                                  ]], toJson, [
                                  "size",
                                  param.size
                                ]), LegacyShowside_BasicTypesCodecs$ApiTypes.cardShape_toJson, [
                              "shape",
                              param.shape
                            ]), (function (__x) {
                            return Generic$ApiTypes.Encode.nullable(__x, toJson$1);
                          }), [
                          "link",
                          param.link
                        ]), (function (__x) {
                        return Generic$ApiTypes.Encode.nullable(__x, Generic$ApiTypes.Encode.$$int);
                      }), [
                      "width",
                      param.width
                    ]), (function (__x) {
                    return Generic$ApiTypes.Encode.nullable(__x, Generic$ApiTypes.Encode.$$int);
                  }), [
                  "height",
                  param.height
                ]));
}

var imageCard_fromJson = LegacyShowside_CategoryDecoders$ApiTypes.CategoryCard.imageCard_fromJson;

function imageLinkCard_toJson(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, Generic$ApiTypes.Encode.addIf(/* Square */1, Generic$ApiTypes.Encode.addIf(/* Lg */5, [
                      [
                        "link",
                        toJson$1(param.link)
                      ],
                      [
                        "imageUrl",
                        Curry._1(Generic$ApiTypes.Encode.string, param.imageUrl)
                      ]
                    ], toJson, [
                      "size",
                      param.size
                    ]), LegacyShowside_BasicTypesCodecs$ApiTypes.cardShape_toJson, [
                  "shape",
                  param.shape
                ]));
}

var imageLinkCard_fromJson = LegacyShowside_CategoryDecoders$ApiTypes.CategoryCard.imageLinkCard_fromJson;

function videoLinkCard_toJson(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(/* Square */1, Generic$ApiTypes.Encode.addIf(/* Lg */5, [
                          [
                            "link",
                            toJson$1(param.link)
                          ],
                          [
                            "videoUrl",
                            Curry._1(Generic$ApiTypes.Encode.string, param.videoUrl)
                          ]
                        ], toJson, [
                          "size",
                          param.size
                        ]), LegacyShowside_BasicTypesCodecs$ApiTypes.cardShape_toJson, [
                      "shape",
                      param.shape
                    ]), (function (__x) {
                    return Generic$ApiTypes.Encode.nullable(__x, Generic$ApiTypes.Encode.string);
                  }), [
                  "fallbackImageUrl",
                  param.fallbackImageUrl
                ]));
}

var videoLinkCard_fromJson = LegacyShowside_CategoryDecoders$ApiTypes.CategoryCard.videoLinkCard_fromJson;

function medialessCard_toJson(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, Generic$ApiTypes.Encode.addIf(/* Square */1, Generic$ApiTypes.Encode.addIf(/* Lg */5, [[
                        "link",
                        Generic$ApiTypes.Encode.nullable(param.link, toJson$1)
                      ]], toJson, [
                      "size",
                      param.size
                    ]), LegacyShowside_BasicTypesCodecs$ApiTypes.cardShape_toJson, [
                  "shape",
                  param.shape
                ]));
}

var medialessCard_fromJson = LegacyShowside_CategoryDecoders$ApiTypes.CategoryCard.medialessCard_fromJson;

function medialessLinkCard_toJson(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, Generic$ApiTypes.Encode.addIf(/* Square */1, Generic$ApiTypes.Encode.addIf(/* Lg */5, [[
                        "link",
                        toJson$1(param.link)
                      ]], toJson, [
                      "size",
                      param.size
                    ]), LegacyShowside_BasicTypesCodecs$ApiTypes.cardShape_toJson, [
                  "shape",
                  param.shape
                ]));
}

var medialessLinkCard_fromJson = LegacyShowside_CategoryDecoders$ApiTypes.CategoryCard.medialessLinkCard_fromJson;

function imageDisplayCard_toJson(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, Generic$ApiTypes.Encode.addIf(/* Square */1, Generic$ApiTypes.Encode.addIf(/* Lg */5, [[
                        "imageUrl",
                        Curry._1(Generic$ApiTypes.Encode.string, param.imageUrl)
                      ]], toJson, [
                      "size",
                      param.size
                    ]), LegacyShowside_BasicTypesCodecs$ApiTypes.cardShape_toJson, [
                  "shape",
                  param.shape
                ]));
}

var imageDisplayCard_fromJson = LegacyShowside_CategoryDecoders$ApiTypes.CategoryCard.imageDisplayCard_fromJson;

function videoDisplayCard_toJson(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, Generic$ApiTypes.Encode.addIf(/* Square */1, Generic$ApiTypes.Encode.addIf(/* Lg */5, [
                      [
                        "videoUrl",
                        Curry._1(Generic$ApiTypes.Encode.string, param.videoUrl)
                      ],
                      [
                        "fallbackImageUrl",
                        Generic$ApiTypes.Encode.nullable(param.fallbackImageUrl, Generic$ApiTypes.Encode.string)
                      ]
                    ], toJson, [
                      "size",
                      param.size
                    ]), LegacyShowside_BasicTypesCodecs$ApiTypes.cardShape_toJson, [
                  "shape",
                  param.shape
                ]));
}

var videoDisplayCard_fromJson = LegacyShowside_CategoryDecoders$ApiTypes.CategoryCard.videoDisplayCard_fromJson;

function toJson$2(v) {
  switch (v.TAG | 0) {
    case /* ImageLinkCard */0 :
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "ImageLinkCard", imageLinkCard_toJson);
    case /* VideoLinkCard */1 :
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "VideoLinkCard", videoLinkCard_toJson);
    case /* ImageCard */2 :
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "ImageCard", imageCard_toJson);
    case /* VideoCard */3 :
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "VideoCard", videoCard_toJson);
    case /* MedialessCard */4 :
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "MedialessCard", medialessCard_toJson);
    case /* MedialessLinkCard */5 :
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "MedialessLinkCard", medialessLinkCard_toJson);
    case /* ImageDisplayCard */6 :
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "ImageDisplayCard", imageDisplayCard_toJson);
    case /* VideoDisplayCard */7 :
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "VideoDisplayCard", videoDisplayCard_toJson);
    
  }
}

var fromJson$2 = LegacyShowside_CategoryDecoders$ApiTypes.CategoryCard.fromJson;

var CategoryCard = {
  videoCard_toJson: videoCard_toJson,
  videoCard_fromJson: videoCard_fromJson,
  imageCard_toJson: imageCard_toJson,
  imageCard_fromJson: imageCard_fromJson,
  imageLinkCard_toJson: imageLinkCard_toJson,
  imageLinkCard_fromJson: imageLinkCard_fromJson,
  videoLinkCard_toJson: videoLinkCard_toJson,
  videoLinkCard_fromJson: videoLinkCard_fromJson,
  medialessCard_toJson: medialessCard_toJson,
  medialessCard_fromJson: medialessCard_fromJson,
  medialessLinkCard_toJson: medialessLinkCard_toJson,
  medialessLinkCard_fromJson: medialessLinkCard_fromJson,
  imageDisplayCard_toJson: imageDisplayCard_toJson,
  imageDisplayCard_fromJson: imageDisplayCard_fromJson,
  videoDisplayCard_toJson: videoDisplayCard_toJson,
  videoDisplayCard_fromJson: videoDisplayCard_fromJson,
  toJson: toJson$2,
  fromJson: fromJson$2
};

function storeCategoryConnection_toJson(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, [
              [
                "categoryId",
                Curry._1(Generic$ApiTypes.Encode.string, param.categoryId)
              ],
              [
                "sortBy",
                Store_ProductSortOrderCodecs$ApiTypes.ProductSortOrder.toJson(param.sortBy)
              ],
              [
                "filterBy",
                Generic$ApiTypes.Encode.nullable(param.filterBy, (function (__x) {
                        return Curry._2(Generic$ApiTypes.Encode.array, __x, Generic$ApiTypes.Encode.string);
                      }))
              ],
              [
                "count",
                Curry._1(Generic$ApiTypes.Encode.$$int, param.count)
              ],
              [
                "relatedMediaCount",
                Generic$ApiTypes.Encode.nullable(param.relatedMediaCount, Generic$ApiTypes.Encode.$$int)
              ]
            ]);
}

var storeCategoryConnection_fromJson = LegacyShowside_CategoryDecoders$ApiTypes.ExternalConnection.storeCategoryConnection_fromJson;

function configEmbedConnection_toJson(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, [[
                "configId",
                Curry._1(Generic$ApiTypes.Encode.string, param.configId)
              ]]);
}

var configEmbedConnection_fromJson = LegacyShowside_CategoryDecoders$ApiTypes.ExternalConnection.configEmbedConnection_fromJson;

function toJson$3(source) {
  if (typeof source !== "number") {
    if (source.TAG === /* StoreCategory */0) {
      return Generic$ApiTypes.Encode.deccoVariantWithPayload(source._0, "StoreCategory", storeCategoryConnection_toJson);
    } else {
      return Generic$ApiTypes.Encode.deccoVariantWithPayload(source._0, "ConfigEmbed", configEmbedConnection_toJson);
    }
  }
  switch (source) {
    case /* History */0 :
        return Generic$ApiTypes.Encode.simpleVariant([[
                      "History",
                      /* History */0
                    ]], /* History */0);
    case /* Favorites */1 :
        return Generic$ApiTypes.Encode.simpleVariant([[
                      "Favorites",
                      /* Favorites */1
                    ]], /* Favorites */1);
    case /* StoreCart */2 :
        return Generic$ApiTypes.Encode.simpleVariant([[
                      "StoreCart",
                      /* StoreCart */2
                    ]], /* StoreCart */2);
    
  }
}

var fromJson$3 = LegacyShowside_CategoryDecoders$ApiTypes.ExternalConnection.fromJson;

var t_v1_fromJson = LegacyShowside_CategoryDecoders$ApiTypes.ExternalConnection.t_v1_fromJson;

var fromJson$4 = Json__Decode.oneOf(fromJson$3, [t_v1_fromJson]);

var ExternalConnection = {
  storeCategoryConnection_toJson: storeCategoryConnection_toJson,
  storeCategoryConnection_fromJson: storeCategoryConnection_fromJson,
  configEmbedConnection_toJson: configEmbedConnection_toJson,
  configEmbedConnection_fromJson: configEmbedConnection_fromJson,
  toJson: toJson$3,
  t_v1_fromJson: t_v1_fromJson,
  fromJson: fromJson$4
};

function layout_toJson(v) {
  if (typeof v === "number") {
    return Generic$ApiTypes.Encode.simpleVariant([[
                  "NoLayout",
                  /* NoLayout */0
                ]], /* NoLayout */0);
  }
  switch (v.TAG | 0) {
    case /* SubCategoriesSlider */0 :
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "SubCategoriesSlider", LegacyShowside_BasicTypesCodecs$ApiTypes.sliderPayload_toJson);
    case /* SubCategoriesSliderWithHeader */1 :
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "SubCategoriesSliderWithHeader", LegacyShowside_BasicTypesCodecs$ApiTypes.sliderPayload_toJson);
    case /* SubCategoriesGrid */2 :
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "SubCategoriesGrid", LegacyShowside_BasicTypesCodecs$ApiTypes.gridPayload_toJson);
    case /* ProductGrid */3 :
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "ProductGrid", LegacyShowside_BasicTypesCodecs$ApiTypes.gridPayload_toJson);
    case /* ProductSlider */4 :
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "ProductSlider", LegacyShowside_BasicTypesCodecs$ApiTypes.sliderPayload_toJson);
    
  }
}

function linkCategory_toJson(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, [[
                "link",
                Curry._1(Generic$ApiTypes.Encode.string, param.link)
              ]]);
}

function toJson$4(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(/* ChildrenFirst */0, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(/* NoLayout */0, Generic$ApiTypes.Encode.addIf(/* NoLayout */0, Generic$ApiTypes.Encode.addIf(/* NoLayout */0, Generic$ApiTypes.Encode.addIf(true, Generic$ApiTypes.Encode.addIf(true, Generic$ApiTypes.Encode.addIf(true, Generic$ApiTypes.Encode.addIf(true, Generic$ApiTypes.Encode.addIf(true, Generic$ApiTypes.Encode.addIf(true, Generic$ApiTypes.Encode.addIf(true, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf("", [
                                                                                  [
                                                                                    "id",
                                                                                    Curry._1(Generic$ApiTypes.Encode.string, param.id)
                                                                                  ],
                                                                                  [
                                                                                    "card",
                                                                                    toJson$2(param.card)
                                                                                  ],
                                                                                  [
                                                                                    "categoryType",
                                                                                    categoryType_toJson(param.categoryType)
                                                                                  ]
                                                                                ], Generic$ApiTypes.Encode.string, [
                                                                                  "title",
                                                                                  param.title
                                                                                ]), (function (__x) {
                                                                                return Generic$ApiTypes.Encode.nullable(__x, Generic$ApiTypes.Encode.string);
                                                                              }), [
                                                                              "description",
                                                                              param.description
                                                                            ]), Generic$ApiTypes.Encode.bool, [
                                                                          "showTitle",
                                                                          param.showTitle
                                                                        ]), Generic$ApiTypes.Encode.bool, [
                                                                      "showDescription",
                                                                      param.showDescription
                                                                    ]), Generic$ApiTypes.Encode.bool, [
                                                                  "visible",
                                                                  param.visible
                                                                ]), Generic$ApiTypes.Encode.bool, [
                                                              "visibleInMenuBar",
                                                              param.visibleInMenuBar
                                                            ]), Generic$ApiTypes.Encode.bool, [
                                                          "visibleInSectionList",
                                                          param.visibleInSectionList
                                                        ]), Generic$ApiTypes.Encode.bool, [
                                                      "visibleInParent",
                                                      param.visibleInParent
                                                    ]), Generic$ApiTypes.Encode.bool, [
                                                  "visibleInHeaderSlider",
                                                  param.visibleInHeaderSlider
                                                ]), layout_toJson, [
                                              "listPageActiveLayout",
                                              param.listPageActiveLayout
                                            ]), layout_toJson, [
                                          "userSelectedLayout",
                                          param.userSelectedLayout
                                        ]), layout_toJson, [
                                      "forcedPLPLayout",
                                      param.forcedPLPLayout
                                    ]), (function (__x) {
                                    return Generic$ApiTypes.Encode.nullable(__x, Tree_CategoryTabSettingsCodecs$ApiTypes.CategoryTabSettings.toJson);
                                  }), [
                                  "tabbedSubCategories",
                                  param.tabbedSubCategories
                                ]), (function (__x) {
                                return Generic$ApiTypes.Encode.nullable(__x, Tree_SocialNetworkSourceCodecs$ApiTypes.SocialNetworkSource.toJson);
                              }), [
                              "socialNetworkSource",
                              param.socialNetworkSource
                            ]), Tree_DisplayPriorityCodecs$ApiTypes.DisplayPriority.toJson, [
                          "displayPriority",
                          param.displayPriority
                        ]), (function (__x) {
                        return Generic$ApiTypes.Encode.nullable(__x, Generic$ApiTypes.Encode.bool);
                      }), [
                      "expandVariantsInProductsListing",
                      param.expandVariantsInProductsListing
                    ]), (function (__x) {
                    return Generic$ApiTypes.Encode.nullable(__x, Generic$ApiTypes.Encode.$$float);
                  }), [
                  "lastModifiedAt",
                  param.lastModifiedAt
                ]));
}

function category_toJson(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf([], Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(0, Generic$ApiTypes.Encode.addIf(undefined, [[
                                                    "children",
                                                    Curry._2(Generic$ApiTypes.Encode.array, param.children, toJson$4)
                                                  ]], (function (__x) {
                                                    return Generic$ApiTypes.Encode.nullable(__x, Generic$ApiTypes.Encode.string);
                                                  }), [
                                                  "loadMoreChildren",
                                                  param.loadMoreChildren
                                                ]), Generic$ApiTypes.Encode.$$int, [
                                              "totalChildren",
                                              param.totalChildren
                                            ]), (function (__x) {
                                            return Generic$ApiTypes.Encode.nullable(__x, toJson$2);
                                          }), [
                                          "firstCard",
                                          param.firstCard
                                        ]), (function (__x) {
                                        return Generic$ApiTypes.Encode.nullable(__x, toJson$3);
                                      }), [
                                      "externalConnection",
                                      param.externalConnection
                                    ]), (function (__x) {
                                    return Generic$ApiTypes.Encode.nullable(__x, Generic$ApiTypes.Encode.string);
                                  }), [
                                  "link",
                                  param.link
                                ]), Tree_TaggedProductsCodecs$ApiTypes.TaggedProducts.toJson, [
                              "products",
                              param.products
                            ]), (function (__x) {
                            return Generic$ApiTypes.Encode.nullable(__x, taggedMedia_toJson);
                          }), [
                          "taggedMedia",
                          param.taggedMedia
                        ]), (function (__x) {
                        return Generic$ApiTypes.Encode.nullable(__x, Generic$ApiTypes.Encode.$$int);
                      }), [
                      "imageHeight",
                      param.imageHeight
                    ]), (function (__x) {
                    return Generic$ApiTypes.Encode.nullable(__x, Generic$ApiTypes.Encode.$$int);
                  }), [
                  "imageWidth",
                  param.imageWidth
                ]));
}

function quickviewCategory_toJson(param) {
  var products = param.products;
  return Curry._1(Generic$ApiTypes.Encode.object, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf([], Generic$ApiTypes.Encode.addIf([], Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(false, Generic$ApiTypes.Encode.addIf(false, Generic$ApiTypes.Encode.addIf(0, Generic$ApiTypes.Encode.addIf(undefined, [[
                                                                "children",
                                                                Curry._2(Generic$ApiTypes.Encode.array, param.children, toJson$4)
                                                              ]], (function (__x) {
                                                                return Generic$ApiTypes.Encode.nullable(__x, Generic$ApiTypes.Encode.string);
                                                              }), [
                                                              "loadMoreChildren",
                                                              param.loadMoreChildren
                                                            ]), Generic$ApiTypes.Encode.$$int, [
                                                          "totalChildren",
                                                          param.totalChildren
                                                        ]), Generic$ApiTypes.Encode.bool, [
                                                      "isStories",
                                                      param.isStories
                                                    ]), Generic$ApiTypes.Encode.bool, [
                                                  "isPinned",
                                                  param.isPinned
                                                ]), (function (__x) {
                                                return Generic$ApiTypes.Encode.nullable(__x, toJson$2);
                                              }), [
                                              "firstCard",
                                              param.firstCard
                                            ]), (function (__x) {
                                            return Generic$ApiTypes.Encode.nullable(__x, toJson$3);
                                          }), [
                                          "externalConnection",
                                          param.externalConnection
                                        ]), (function (__x) {
                                        return Generic$ApiTypes.Encode.nullable(__x, Generic$ApiTypes.Encode.string);
                                      }), [
                                      "link",
                                      param.link
                                    ]), Tree_TaggedProductsCodecs$ApiTypes.TaggedProducts.toJson, [
                                  "products",
                                  products
                                ]), Tree_TaggedProductsCodecs$ApiTypes.TaggedProducts.toJson, [
                              "products",
                              products
                            ]), (function (value) {
                            return Generic$ApiTypes.Encode.nullable(value, (function (__x) {
                                          return Curry._2(Generic$ApiTypes.Encode.array, __x, QuickViewPinCodecs$ApiTypes.toJson);
                                        }));
                          }), [
                          "imageHotspots",
                          param.imageHotspots
                        ]), (function (__x) {
                        return Generic$ApiTypes.Encode.nullable(__x, Generic$ApiTypes.Encode.$$int);
                      }), [
                      "imageWidth",
                      param.imageWidth
                    ]), (function (__x) {
                    return Generic$ApiTypes.Encode.nullable(__x, Generic$ApiTypes.Encode.$$int);
                  }), [
                  "imageHeight",
                  param.imageHeight
                ]));
}

function indexCategory_toJson(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, Generic$ApiTypes.Encode.addIf(undefined, [], (function (__x) {
                    return Generic$ApiTypes.Encode.nullable(__x, toJson$3);
                  }), [
                  "externalConnection",
                  param.externalConnection
                ]));
}

function configEmbedCategory_toJson(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf([], Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(0, Generic$ApiTypes.Encode.addIf(undefined, [
                                                  [
                                                    "configId",
                                                    Curry._1(Generic$ApiTypes.Encode.string, param.configId)
                                                  ],
                                                  [
                                                    "children",
                                                    Curry._2(Generic$ApiTypes.Encode.array, param.children, toJson$4)
                                                  ]
                                                ], (function (__x) {
                                                    return Generic$ApiTypes.Encode.nullable(__x, Generic$ApiTypes.Encode.string);
                                                  }), [
                                                  "loadMoreChildren",
                                                  param.loadMoreChildren
                                                ]), Generic$ApiTypes.Encode.$$int, [
                                              "totalChildren",
                                              param.totalChildren
                                            ]), (function (__x) {
                                            return Generic$ApiTypes.Encode.nullable(__x, toJson$2);
                                          }), [
                                          "firstCard",
                                          param.firstCard
                                        ]), (function (__x) {
                                        return Generic$ApiTypes.Encode.nullable(__x, Generic$ApiTypes.Encode.string);
                                      }), [
                                      "link",
                                      param.link
                                    ]), Tree_TaggedProductsCodecs$ApiTypes.TaggedProducts.toJson, [
                                  "products",
                                  param.products
                                ]), (function (__x) {
                                return Generic$ApiTypes.Encode.nullable(__x, taggedMedia_toJson);
                              }), [
                              "taggedMedia",
                              param.taggedMedia
                            ]), (function (__x) {
                            return Generic$ApiTypes.Encode.nullable(__x, (function (__x) {
                                          return Curry._2(Generic$ApiTypes.Encode.array, __x, QuickViewPinCodecs$ApiTypes.toJson);
                                        }));
                          }), [
                          "imageHotspots",
                          param.imageHotspots
                        ]), (function (__x) {
                        return Generic$ApiTypes.Encode.nullable(__x, Generic$ApiTypes.Encode.$$int);
                      }), [
                      "imageWidth",
                      param.imageWidth
                    ]), (function (__x) {
                    return Generic$ApiTypes.Encode.nullable(__x, Generic$ApiTypes.Encode.$$int);
                  }), [
                  "imageHeight",
                  param.imageHeight
                ]));
}

function taggedMediaSource_toJson(source) {
  return Generic$ApiTypes.Encode.deccoVariantWithPayload(source._0, "Manual", (function (__x) {
                return Curry._2(Generic$ApiTypes.Encode.array, __x, toJson$4);
              }));
}

function taggedMedia_toJson(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(undefined, Generic$ApiTypes.Encode.addIf(/* InlineDisplay */0, [[
                                    "source",
                                    taggedMediaSource_toJson(param.source)
                                  ]], Tree_TaggedMediaCodecs$ApiTypes.TaggedMedia.displayType_toJson, [
                                  "displayType",
                                  param.displayType
                                ]), (function (__x) {
                                return Generic$ApiTypes.Encode.nullable(__x, Generic$ApiTypes.Encode.$$int);
                              }), [
                              "cardWidth",
                              param.cardWidth
                            ]), (function (__x) {
                            return Generic$ApiTypes.Encode.nullable(__x, Generic$ApiTypes.Encode.$$int);
                          }), [
                          "cardHeight",
                          param.cardHeight
                        ]), (function (__x) {
                        return Generic$ApiTypes.Encode.nullable(__x, Generic$ApiTypes.Encode.$$int);
                      }), [
                      "startRowOffset",
                      param.startRowOffset
                    ]), (function (__x) {
                    return Generic$ApiTypes.Encode.nullable(__x, Generic$ApiTypes.Encode.$$float);
                  }), [
                  "slidesPerView",
                  param.slidesPerView
                ]));
}

function categoryType_toJson(v) {
  if (typeof v === "number") {
    return Generic$ApiTypes.Encode.simpleVariant([[
                  "Page",
                  /* Page */0
                ]], v);
  }
  switch (v.TAG | 0) {
    case /* Link */0 :
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "Link", linkCategory_toJson);
    case /* Standard */1 :
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "Standard", category_toJson);
    case /* QuickView */2 :
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "QuickView", quickviewCategory_toJson);
    case /* Index */3 :
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "Index", indexCategory_toJson);
    case /* ConfigEmbed */4 :
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(v._0, "ConfigEmbed", configEmbedCategory_toJson);
    
  }
}

function toJson$5(v) {
  return Curry._1(Generic$ApiTypes.Encode.object, [
              [
                "categories",
                Curry._2(Generic$ApiTypes.Encode.array, v.categories, toJson$4)
              ],
              [
                "previous",
                Generic$ApiTypes.Encode.nullable(v.previous, Generic$ApiTypes.Encode.string)
              ],
              [
                "next",
                Generic$ApiTypes.Encode.nullable(v.next, Generic$ApiTypes.Encode.string)
              ],
              [
                "offset",
                Curry._1(Generic$ApiTypes.Encode.$$int, v.offset)
              ],
              [
                "total",
                Curry._1(Generic$ApiTypes.Encode.$$int, v.total)
              ]
            ]);
}

var fromJson$5 = LegacyShowside_CategoryDecoders$ApiTypes.Page.fromJson;

var Page = {
  toJson: toJson$5,
  fromJson: fromJson$5
};

var layout_fromJson = LegacyShowside_CategoryDecoders$ApiTypes.layout_fromJson;

var linkCategory_fromJson = LegacyShowside_CategoryDecoders$ApiTypes.linkCategory_fromJson;

var fromJson$6 = LegacyShowside_CategoryDecoders$ApiTypes.fromJson;

var category_fromJson = LegacyShowside_CategoryDecoders$ApiTypes.category_fromJson;

var taggedMediaSource_fromJson = LegacyShowside_CategoryDecoders$ApiTypes.taggedMediaSource_fromJson;

var taggedMedia_fromJson = LegacyShowside_CategoryDecoders$ApiTypes.taggedMedia_fromJson;

var quickviewCategory_fromJson = LegacyShowside_CategoryDecoders$ApiTypes.quickviewCategory_fromJson;

var indexCategory_fromJson = LegacyShowside_CategoryDecoders$ApiTypes.indexCategory_fromJson;

var configEmbedCategory_fromJson = LegacyShowside_CategoryDecoders$ApiTypes.configEmbedCategory_fromJson;

var categoryType_fromJson = LegacyShowside_CategoryDecoders$ApiTypes.categoryType_fromJson;

export {
  CardSize ,
  Link ,
  CategoryCard ,
  ExternalConnection ,
  layout_toJson ,
  layout_fromJson ,
  linkCategory_toJson ,
  linkCategory_fromJson ,
  toJson$4 as toJson,
  fromJson$6 as fromJson,
  category_toJson ,
  category_fromJson ,
  taggedMediaSource_toJson ,
  taggedMediaSource_fromJson ,
  taggedMedia_toJson ,
  taggedMedia_fromJson ,
  quickviewCategory_toJson ,
  quickviewCategory_fromJson ,
  indexCategory_toJson ,
  indexCategory_fromJson ,
  configEmbedCategory_toJson ,
  configEmbedCategory_fromJson ,
  categoryType_toJson ,
  categoryType_fromJson ,
  Page ,
}
/* fromJson Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../rescript/lib/es6/curry.js";
import * as Json__Decode from "../../../../../rescript-json/lib/es6_global/src/Json__Decode.bs.js";
import * as Json__Encode from "../../../../../rescript-json/lib/es6_global/src/Json__Encode.bs.js";
import * as Generic$ApiTypes from "../utils/Generic.bs.js";
import * as DeccoAdapter$ApiTypes from "../utils/DeccoAdapter.bs.js";

var partial_arg = [
  [
    "Default",
    /* Default */0
  ],
  [
    "Landscape",
    /* Landscape */1
  ],
  [
    "Portrait",
    /* Portrait */2
  ],
  [
    "Panorama",
    /* Panorama */3
  ],
  [
    "Wide",
    /* Wide */4
  ],
  [
    "Tall",
    /* Tall */5
  ],
  [
    "Square",
    /* Square */6
  ],
  [
    "Circle",
    /* Circle */7
  ],
  [
    "Custom",
    /* Custom */8
  ]
];

function labels_encode(param) {
  return Generic$ApiTypes.Encode.simpleVariant(partial_arg, param);
}

var labels_decode = Generic$ApiTypes.Decode.simpleVariant([
      [
        "Default",
        /* Default */0
      ],
      [
        "Landscape",
        /* Landscape */1
      ],
      [
        "Portrait",
        /* Portrait */2
      ],
      [
        "Panorama",
        /* Panorama */3
      ],
      [
        "Wide",
        /* Wide */4
      ],
      [
        "Tall",
        /* Tall */5
      ],
      [
        "Square",
        /* Square */6
      ],
      [
        "Circle",
        /* Circle */7
      ],
      [
        "Custom",
        /* Custom */8
      ]
    ]);

function custom_encode(c) {
  return Json__Encode.object([
              [
                "width",
                Json__Encode.$$int(c.width)
              ],
              [
                "height",
                Json__Encode.$$int(c.height)
              ]
            ]);
}

var custom_decode = Json__Decode.map2(Json__Decode.field("width", Json__Decode.$$int), Json__Decode.field("height", Json__Decode.$$int), (function (width, height) {
        return {
                width: width,
                height: height
              };
      }));

function toJson(cardShape) {
  if (typeof cardShape !== "number") {
    return Curry._1(Generic$ApiTypes.Encode.string, "Custom" + Curry._2(Generic$ApiTypes.Encode.encode, custom_encode(cardShape._0), 0));
  }
  switch (cardShape) {
    case /* Default */0 :
        return Curry._1(Generic$ApiTypes.Encode.string, "default");
    case /* Landscape */1 :
        return Curry._1(Generic$ApiTypes.Encode.string, "landscape");
    case /* Portrait */2 :
        return Curry._1(Generic$ApiTypes.Encode.string, "portrait");
    case /* Panorama */3 :
        return Curry._1(Generic$ApiTypes.Encode.string, "panorama");
    case /* Wide */4 :
        return Curry._1(Generic$ApiTypes.Encode.string, "wide");
    case /* Tall */5 :
        return Curry._1(Generic$ApiTypes.Encode.string, "tall");
    case /* Square */6 :
        return Curry._1(Generic$ApiTypes.Encode.string, "square");
    case /* Circle */7 :
        return Curry._1(Generic$ApiTypes.Encode.string, "circle");
    
  }
}

var customDecode = Generic$ApiTypes.Decode.stringifiedPayloadVariant("Custom", custom_decode, (function (payload) {
        return /* Custom */{
                _0: payload
              };
      }));

var fromJson = Curry._2(Generic$ApiTypes.Decode.oneOf, customDecode, [
      Curry._2(Generic$ApiTypes.Decode.map, Generic$ApiTypes.Decode.exactString("default"), (function (param) {
              return /* Default */0;
            })),
      Curry._2(Generic$ApiTypes.Decode.map, Generic$ApiTypes.Decode.exactString("landscape"), (function (param) {
              return /* Landscape */1;
            })),
      Curry._2(Generic$ApiTypes.Decode.map, Generic$ApiTypes.Decode.exactString("portrait"), (function (param) {
              return /* Portrait */2;
            })),
      Curry._2(Generic$ApiTypes.Decode.map, Generic$ApiTypes.Decode.exactString("panorama"), (function (param) {
              return /* Panorama */3;
            })),
      Curry._2(Generic$ApiTypes.Decode.map, Generic$ApiTypes.Decode.exactString("wide"), (function (param) {
              return /* Wide */4;
            })),
      Curry._2(Generic$ApiTypes.Decode.map, Generic$ApiTypes.Decode.exactString("tall"), (function (param) {
              return /* Tall */5;
            })),
      Curry._2(Generic$ApiTypes.Decode.map, Generic$ApiTypes.Decode.exactString("square"), (function (param) {
              return /* Square */6;
            })),
      Curry._2(Generic$ApiTypes.Decode.map, Generic$ApiTypes.Decode.exactString("circle"), (function (param) {
              return /* Circle */7;
            }))
    ]);

var include = DeccoAdapter$ApiTypes.Make({
      toJson: toJson,
      fromJson: fromJson
    });

var CardShape_t_encode = include.t_encode;

var CardShape_t_decode = include.t_decode;

var CardShape_codec = include.codec;

var CardShape = {
  labels_encode: labels_encode,
  labels_decode: labels_decode,
  abstract_encode: Json__Encode.string,
  abstract_decode: Json__Decode.string,
  custom_encode: custom_encode,
  custom_decode: custom_decode,
  toJson: toJson,
  fromJson: fromJson,
  t_encode: CardShape_t_encode,
  t_decode: CardShape_t_decode,
  codec: CardShape_codec
};

export {
  CardShape ,
}
/* labels_decode Not a pure module */

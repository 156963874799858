// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Caml_obj from "../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as BasicTypes$Showside from "./BasicTypes.bs.js";
import * as UniqueArray$Showside from "../utils/UniqueArray.bs.js";
import * as CategoryFinder$Showside from "./CategoryFinder.bs.js";
import * as CustomActionId$ApiTypes from "../../../../node_modules/api-types/lib/es6_global/src/CustomActionId.bs.js";
import * as FiltersConfig2$Showside from "./FiltersConfig2.bs.js";
function getColumns(layout) {
  if (typeof layout === "number") {
    return /* FiveColumnGrid */4;
  }
  switch (layout.TAG | 0) {
    case /* SubCategoriesGrid */2:
    case /* ProductGrid */3:
      return layout._0.columns;
    case /* SubCategoriesSlider */0:
    case /* SubCategoriesSliderWithHeader */1:
    case /* ProductSlider */4:
      return BasicTypes$Showside.GridColumns.fromInt(layout._0.slidesPerView | 0);
  }
}
function getMaxWidth(layout) {
  if (typeof layout === "number") {
    return;
  }
  switch (layout.TAG | 0) {
    case /* SubCategoriesGrid */2:
    case /* ProductGrid */3:
      return layout._0.maxWidth;
    case /* SubCategoriesSlider */0:
    case /* SubCategoriesSliderWithHeader */1:
    case /* ProductSlider */4:
      return layout._0.maxWidth;
  }
}
function getPadding(layout) {
  if (typeof layout === "number") {
    return;
  }
  switch (layout.TAG | 0) {
    case /* SubCategoriesGrid */2:
    case /* ProductGrid */3:
      return layout._0.padding;
    case /* SubCategoriesSlider */0:
    case /* SubCategoriesSliderWithHeader */1:
    case /* ProductSlider */4:
      return layout._0.padding;
  }
}
function getGap(layout) {
  if (typeof layout === "number") {
    return /* Equal */0;
  }
  switch (layout.TAG | 0) {
    case /* SubCategoriesSlider */0:
    case /* SubCategoriesSliderWithHeader */1:
    case /* SubCategoriesGrid */2:
    case /* ProductGrid */3:
    case /* ProductSlider */4:
      return layout._0.gap;
  }
}
function getMaxRows(layout) {
  if (typeof layout === "number") {
    return;
  }
  switch (layout.TAG | 0) {
    case /* SubCategoriesGrid */2:
    case /* ProductGrid */3:
      return layout._0.maxRows;
    default:
      return;
  }
}
var Layout = {
  getColumns: getColumns,
  getMaxWidth: getMaxWidth,
  getPadding: getPadding,
  getGap: getGap,
  getMaxRows: getMaxRows
};
var TabbedSubCategories = {};
function isNotEmpty(link) {
  switch (link.TAG | 0) {
    case /* URL */0:
      return link._0.trim() !== "";
    case /* Product */2:
      return link._0.productId.trim() !== "";
    case /* Category */1:
    case /* StoreCategory */3:
      return link._0.categoryId.trim() !== "";
    case /* CustomAction */4:
      return Curry._1(CustomActionId$ApiTypes.toString, link._0.customActionId) !== "";
  }
}
function getURL(link) {
  if (link.TAG === /* URL */0) {
    return link._0;
  }
}
function getCardLink(category) {
  var match = category.card;
  switch (match.TAG | 0) {
    case /* ImageLinkCard */0:
    case /* VideoLinkCard */1:
    case /* MedialessLinkCard */5:
      break;
    default:
      return;
  }
  return Belt_Option.keep(match._0.link, isNotEmpty);
}
function getCardFallbackLink(category) {
  var match = category.card;
  switch (match.TAG | 0) {
    case /* ImageCard */2:
    case /* VideoCard */3:
    case /* MedialessCard */4:
      return match._0.link;
    case /* ImageDisplayCard */6:
    case /* VideoDisplayCard */7:
      return;
    default:
      return match._0.link;
  }
}
function isStoreCategoryLink(category) {
  var match = getCardFallbackLink(category);
  if (match !== undefined && match.TAG === /* StoreCategory */3) {
    return true;
  } else {
    return false;
  }
}
var Link = {
  isNotEmpty: isNotEmpty,
  getURL: getURL,
  getCardLink: getCardLink,
  getCardFallbackLink: getCardFallbackLink,
  isStoreCategoryLink: isStoreCategoryLink
};
function getSlidesPerView(cardSize) {
  switch (cardSize) {
    case /* Xxxs */0:
      return 8.2;
    case /* Xxs */1:
      return 7.2;
    case /* Xs */2:
      return 6.2;
    case /* Sm */3:
      return 4.4;
    case /* Md */4:
      return 3.2;
    case /* Lg */5:
      return 2.2;
    case /* Xl */6:
      return 1.8;
    case /* Xxl */7:
      return 1.4;
    case /* Xxxl */8:
      return 1.2;
    case /* Xxxxl */9:
      return 1.05;
    case /* Xxxxxl */10:
      return 1.0;
  }
}
var CardSize = {
  getSlidesPerView: getSlidesPerView
};
function getShapeWithinRange(shape, range, $$default) {
  var isInRange = Belt_Option.isSome(Belt_Option.flatMap(range, function (range) {
    return Belt_Array.getBy(range, function (shape0) {
      return Caml_obj.equal(shape0, shape);
    });
  }));
  if (range !== undefined && !isInRange) {
    return $$default;
  } else {
    return shape;
  }
}
function getCardShape(defaultOpt, range, card) {
  var $$default = defaultOpt !== undefined ? defaultOpt : /* Square */1;
  switch (card.TAG | 0) {
    case /* ImageLinkCard */0:
    case /* VideoLinkCard */1:
      return getShapeWithinRange(card._0.shape, range, $$default);
    default:
      return getShapeWithinRange(card._0.shape, range, $$default);
  }
}
function getCardSize($$default, card) {
  switch (card.TAG | 0) {
    case /* ImageLinkCard */0:
    case /* VideoLinkCard */1:
      return card._0.size;
    default:
      return card._0.size;
  }
}
function setShape(card, shape) {
  switch (card.TAG | 0) {
    case /* ImageLinkCard */0:
      var rest = card._0;
      return {
        TAG: /* ImageLinkCard */0,
        _0: {
          link: rest.link,
          imageUrl: rest.imageUrl,
          size: rest.size,
          shape: shape
        }
      };
    case /* VideoLinkCard */1:
      var rest$1 = card._0;
      return {
        TAG: /* VideoLinkCard */1,
        _0: {
          link: rest$1.link,
          videoUrl: rest$1.videoUrl,
          size: rest$1.size,
          shape: shape,
          fallbackImageUrl: rest$1.fallbackImageUrl
        }
      };
    case /* ImageCard */2:
      var rest$2 = card._0;
      return {
        TAG: /* ImageCard */2,
        _0: {
          link: rest$2.link,
          size: rest$2.size,
          shape: shape,
          imageUrl: rest$2.imageUrl,
          width: rest$2.width,
          height: rest$2.height
        }
      };
    case /* VideoCard */3:
      var rest$3 = card._0;
      return {
        TAG: /* VideoCard */3,
        _0: {
          link: rest$3.link,
          size: rest$3.size,
          shape: shape,
          videoUrl: rest$3.videoUrl,
          width: rest$3.width,
          height: rest$3.height,
          fallbackImageUrl: rest$3.fallbackImageUrl
        }
      };
    case /* MedialessCard */4:
      var rest$4 = card._0;
      return {
        TAG: /* MedialessCard */4,
        _0: {
          link: rest$4.link,
          size: rest$4.size,
          shape: shape
        }
      };
    case /* MedialessLinkCard */5:
      var rest$5 = card._0;
      return {
        TAG: /* MedialessLinkCard */5,
        _0: {
          link: rest$5.link,
          size: rest$5.size,
          shape: shape
        }
      };
    case /* ImageDisplayCard */6:
      var rest$6 = card._0;
      return {
        TAG: /* ImageDisplayCard */6,
        _0: {
          imageUrl: rest$6.imageUrl,
          size: rest$6.size,
          shape: shape
        }
      };
    case /* VideoDisplayCard */7:
      var rest$7 = card._0;
      return {
        TAG: /* VideoDisplayCard */7,
        _0: {
          videoUrl: rest$7.videoUrl,
          size: rest$7.size,
          shape: shape,
          fallbackImageUrl: rest$7.fallbackImageUrl
        }
      };
  }
}
var CategoryCard = {
  getShapeWithinRange: getShapeWithinRange,
  getCardShape: getCardShape,
  getCardSize: getCardSize,
  setShape: setShape
};
function setChildren(category, children) {
  var newrecord = Caml_obj.obj_dup(category);
  var data = category.categoryType;
  var tmp;
  if (typeof data === "number") {
    tmp = data;
  } else {
    switch (data.TAG | 0) {
      case /* Standard */1:
        var data$1 = data._0;
        tmp = {
          TAG: /* Standard */1,
          _0: {
            firstCard: data$1.firstCard,
            externalConnection: data$1.externalConnection,
            children: children,
            loadMoreChildren: data$1.loadMoreChildren,
            totalChildren: data$1.totalChildren,
            link: data$1.link,
            products: data$1.products,
            taggedMedia: data$1.taggedMedia,
            imageHotspots: data$1.imageHotspots,
            imageWidth: data$1.imageWidth,
            imageHeight: data$1.imageHeight
          }
        };
        break;
      case /* QuickView */2:
        var data$2 = data._0;
        tmp = {
          TAG: /* QuickView */2,
          _0: {
            isStories: data$2.isStories,
            isPinned: data$2.isPinned,
            firstCard: data$2.firstCard,
            externalConnection: data$2.externalConnection,
            children: children,
            loadMoreChildren: data$2.loadMoreChildren,
            totalChildren: data$2.totalChildren,
            link: data$2.link,
            products: data$2.products,
            imageHotspots: data$2.imageHotspots,
            imageWidth: data$2.imageWidth,
            imageHeight: data$2.imageHeight
          }
        };
        break;
      default:
        tmp = data;
    }
  }
  newrecord.categoryType = tmp;
  return newrecord;
}
function setShowTitle(category, showTitle) {
  var newrecord = Caml_obj.obj_dup(category);
  newrecord.showTitle = showTitle;
  return newrecord;
}
function setCardShape(category, shape) {
  var newrecord = Caml_obj.obj_dup(category);
  var data = category.card;
  var tmp;
  switch (data.TAG | 0) {
    case /* ImageLinkCard */0:
      var data$1 = data._0;
      tmp = {
        TAG: /* ImageLinkCard */0,
        _0: {
          link: data$1.link,
          imageUrl: data$1.imageUrl,
          size: data$1.size,
          shape: shape
        }
      };
      break;
    case /* VideoLinkCard */1:
      var data$2 = data._0;
      tmp = {
        TAG: /* VideoLinkCard */1,
        _0: {
          link: data$2.link,
          videoUrl: data$2.videoUrl,
          size: data$2.size,
          shape: shape,
          fallbackImageUrl: data$2.fallbackImageUrl
        }
      };
      break;
    case /* ImageCard */2:
      var data$3 = data._0;
      tmp = {
        TAG: /* ImageCard */2,
        _0: {
          link: data$3.link,
          size: data$3.size,
          shape: shape,
          imageUrl: data$3.imageUrl,
          width: data$3.width,
          height: data$3.height
        }
      };
      break;
    case /* VideoCard */3:
      var data$4 = data._0;
      tmp = {
        TAG: /* VideoCard */3,
        _0: {
          link: data$4.link,
          size: data$4.size,
          shape: shape,
          videoUrl: data$4.videoUrl,
          width: data$4.width,
          height: data$4.height,
          fallbackImageUrl: data$4.fallbackImageUrl
        }
      };
      break;
    case /* MedialessCard */4:
      var data$5 = data._0;
      tmp = {
        TAG: /* MedialessCard */4,
        _0: {
          link: data$5.link,
          size: data$5.size,
          shape: shape
        }
      };
      break;
    case /* MedialessLinkCard */5:
      var data$6 = data._0;
      tmp = {
        TAG: /* MedialessLinkCard */5,
        _0: {
          link: data$6.link,
          size: data$6.size,
          shape: shape
        }
      };
      break;
    case /* ImageDisplayCard */6:
      var data$7 = data._0;
      tmp = {
        TAG: /* ImageDisplayCard */6,
        _0: {
          imageUrl: data$7.imageUrl,
          size: data$7.size,
          shape: shape
        }
      };
      break;
    case /* VideoDisplayCard */7:
      var data$8 = data._0;
      tmp = {
        TAG: /* VideoDisplayCard */7,
        _0: {
          videoUrl: data$8.videoUrl,
          size: data$8.size,
          shape: shape,
          fallbackImageUrl: data$8.fallbackImageUrl
        }
      };
      break;
  }
  newrecord.card = tmp;
  return newrecord;
}
function setTabbedSubCategories(category, tabbedSubCategories) {
  var newrecord = Caml_obj.obj_dup(category);
  newrecord.tabbedSubCategories = tabbedSubCategories;
  return newrecord;
}
function isInstagramNode(category) {
  var match = category.socialNetworkSource;
  if (match !== undefined && match.TAG === /* Instagram */0) {
    return true;
  } else {
    return false;
  }
}
function isTiktokNode(category) {
  var match = category.socialNetworkSource;
  if (match !== undefined && match.TAG !== /* Instagram */0) {
    return true;
  } else {
    return false;
  }
}
function getInstagramFeed(category) {
  return Belt_Array.getBy(CategoryFinder$Showside.getChildren(category), function (node) {
    if (node.title.toLowerCase() === "feed") {
      return isInstagramNode(node);
    } else {
      return false;
    }
  });
}
function isInstagramRoot(category, parentCategory) {
  if (isInstagramNode(category) && Belt_Option.mapWithDefault(parentCategory, true, function (parent) {
    return Belt_Option.isNone(parent.socialNetworkSource);
  })) {
    return Belt_Option.isSome(getInstagramFeed(category));
  } else {
    return false;
  }
}
function getTiktokFeed(category) {
  return Belt_Array.getBy(CategoryFinder$Showside.getChildren(category), function (node) {
    if (node.title.toLowerCase() === "videos") {
      return isTiktokNode(node);
    } else {
      return false;
    }
  });
}
function isTiktokRoot(category, parentCategory) {
  if (isTiktokNode(category) && Belt_Option.mapWithDefault(parentCategory, true, function (parent) {
    return Belt_Option.isNone(parent.socialNetworkSource);
  })) {
    return Belt_Option.isSome(getTiktokFeed(category));
  } else {
    return false;
  }
}
function isQuickView(category) {
  var match = category.categoryType;
  if (typeof match === "number" || match.TAG !== /* QuickView */2) {
    return false;
  } else {
    return true;
  }
}
function isVideoCard(card) {
  switch (card.TAG | 0) {
    case /* VideoLinkCard */1:
    case /* VideoCard */3:
    case /* VideoDisplayCard */7:
      return true;
    default:
      return false;
  }
}
function getImageHotspots(category) {
  var match = category.categoryType;
  if (typeof match === "number") {
    return;
  }
  switch (match.TAG | 0) {
    case /* Standard */1:
      return match._0.imageHotspots;
    case /* QuickView */2:
      return match._0.imageHotspots;
    default:
      return;
  }
}
function hasAnyItems(category) {
  var match = category.categoryType;
  var externalConnection;
  var children;
  var products;
  if (typeof match === "number") {
    return true;
  }
  switch (match.TAG | 0) {
    case /* Standard */1:
      var match$1 = match._0;
      externalConnection = match$1.externalConnection;
      children = match$1.children;
      products = match$1.products;
      break;
    case /* QuickView */2:
      var match$2 = match._0;
      externalConnection = match$2.externalConnection;
      children = match$2.children;
      products = match$2.products;
      break;
    case /* Link */0:
    case /* Index */3:
      return false;
    case /* ConfigEmbed */4:
      return true;
  }
  if (children.length !== 0 || externalConnection !== undefined) {
    return true;
  } else {
    return products.length !== 0;
  }
}
function hasProducts(category) {
  var match = category.categoryType;
  var externalConnection;
  var products;
  if (typeof match === "number") {
    return false;
  }
  switch (match.TAG | 0) {
    case /* Standard */1:
      var match$1 = match._0;
      externalConnection = match$1.externalConnection;
      products = match$1.products;
      break;
    case /* QuickView */2:
      var match$2 = match._0;
      externalConnection = match$2.externalConnection;
      products = match$2.products;
      break;
    case /* Index */3:
      return Belt_Option.isSome(match._0.externalConnection);
    default:
      return false;
  }
  if (Belt_Option.isSome(externalConnection)) {
    return true;
  } else {
    return products.length !== 0;
  }
}
function getProducts(category) {
  var match = category.categoryType;
  if (typeof match === "number") {
    return [];
  }
  switch (match.TAG | 0) {
    case /* Standard */1:
      return match._0.products;
    case /* QuickView */2:
      return match._0.products;
    default:
      return [];
  }
}
function getProductIds(category) {
  return Belt_Array.map(getProducts(category), function (param) {
    return param.productId;
  });
}
function getConnection(category) {
  var match = getCardLink(category);
  var otherwise;
  if (match !== undefined && match.TAG === /* StoreCategory */3) {
    var match$1 = match._0;
    otherwise = {
      TAG: /* StoreCategory */0,
      _0: {
        categoryId: match$1.categoryId,
        sortBy: /* Default */0,
        filterBy: undefined,
        count: match$1.count,
        relatedMediaCount: undefined
      }
    };
  } else {
    otherwise = undefined;
  }
  var match$2 = category.categoryType;
  if (typeof match$2 === "number") {
    return otherwise;
  }
  switch (match$2.TAG | 0) {
    case /* Standard */1:
      return Belt_Option.orElse(match$2._0.externalConnection, otherwise);
    case /* QuickView */2:
      return Belt_Option.orElse(match$2._0.externalConnection, otherwise);
    case /* Index */3:
      return Belt_Option.orElse(match$2._0.externalConnection, otherwise);
    default:
      return otherwise;
  }
}
function getStoreCategoryConnection(category) {
  var match = getConnection(category);
  if (match !== undefined && !(typeof match === "number" || match.TAG !== /* StoreCategory */0)) {
    return match._0;
  }
}
function isConnectedToProductSource(category) {
  var match = getConnection(category);
  if (match !== undefined && (typeof match === "number" || match.TAG !== /* ConfigEmbed */1)) {
    return true;
  } else {
    return false;
  }
}
function isConnectedToStoreCategory(category) {
  var match = getConnection(category);
  if (match !== undefined && !(typeof match === "number" || match.TAG !== /* StoreCategory */0)) {
    return true;
  } else {
    return false;
  }
}
function getFilterBy(connection, account) {
  return Belt_Option.map(Belt_Option.flatMap(account, function (account) {
    return account.filtersConfig2;
  }), function (config) {
    return FiltersConfig2$Showside.getFacetsByKVPair(config, Belt_Option.getWithDefault(connection.filterBy, []));
  });
}
var ExternalConnection = {
  getConnection: getConnection,
  getStoreCategoryConnection: getStoreCategoryConnection,
  isConnectedToProductSource: isConnectedToProductSource,
  isConnectedToStoreCategory: isConnectedToStoreCategory,
  getFilterBy: getFilterBy
};
function isQuickViewStoriesEnabled(category) {
  var match = category.categoryType;
  if (typeof match === "number" || match.TAG !== /* QuickView */2) {
    return false;
  } else {
    return match._0.isStories;
  }
}
function getQuickViewAlbumMediaId(category) {
  var match = category.categoryType;
  if (typeof match === "number" || match.TAG !== /* QuickView */2) {
    return;
  } else {
    return Belt_Option.map(Belt_Array.get(match._0.children, 0), function (child) {
      return child.id;
    });
  }
}
function getFirstCard(category) {
  var match = category.categoryType;
  if (typeof match === "number") {
    return;
  }
  switch (match.TAG | 0) {
    case /* Standard */1:
      return match._0.firstCard;
    case /* QuickView */2:
      return match._0.firstCard;
    default:
      return;
  }
}
function getCategoryImage(category) {
  var match = category.card;
  switch (match.TAG | 0) {
    case /* ImageLinkCard */0:
      return match._0.imageUrl;
    case /* VideoLinkCard */1:
      return Belt_Option.getWithDefault(match._0.fallbackImageUrl, "");
    case /* ImageCard */2:
      return match._0.imageUrl;
    case /* VideoCard */3:
      return Belt_Option.getWithDefault(match._0.fallbackImageUrl, "");
    case /* MedialessCard */4:
    case /* MedialessLinkCard */5:
      return "";
    case /* ImageDisplayCard */6:
      return match._0.imageUrl;
    case /* VideoDisplayCard */7:
      return Belt_Option.getWithDefault(match._0.fallbackImageUrl, "");
  }
}
function getCategoryImageSize(category) {
  var match = category.categoryType;
  if (typeof match === "number") {
    return [undefined, undefined];
  }
  switch (match.TAG | 0) {
    case /* Standard */1:
      var match$1 = match._0;
      return [match$1.imageWidth, match$1.imageHeight];
    case /* QuickView */2:
      var match$2 = match._0;
      return [match$2.imageWidth, match$2.imageHeight];
    default:
      return [undefined, undefined];
  }
}
function getCategoryVideo(category) {
  var match = category.card;
  switch (match.TAG | 0) {
    case /* VideoLinkCard */1:
      return match._0.videoUrl;
    case /* VideoCard */3:
      return match._0.videoUrl;
    case /* VideoDisplayCard */7:
      return match._0.videoUrl;
    default:
      return;
  }
}
function getCategoryCardLinkUrl(fallbackOpt, category) {
  var fallback = fallbackOpt !== undefined ? fallbackOpt : false;
  var match = fallback ? getCardFallbackLink(category) : getCardLink(category);
  if (match !== undefined && match.TAG === /* URL */0) {
    return match._0;
  }
}
function hasSubCategories(category) {
  return Belt_Array.keep(CategoryFinder$Showside.getChildren(category), function (c) {
    return c.visible;
  }).length !== 0;
}
function getSensibleSiblings(fallbackEmptyOpt, isForcingPLPOpt, categories, category) {
  var fallbackEmpty = fallbackEmptyOpt !== undefined ? fallbackEmptyOpt : false;
  var categoryId = Belt_Option.getWithDefault(Belt_Option.map(category, function (cat) {
    return cat.id;
  }), "");
  var parent = CategoryFinder$Showside.getCategoryParentById(categories, categoryId);
  var hasSubCategories$1 = Belt_Option.getWithDefault(Belt_Option.map(category, hasSubCategories), false);
  var isBanner = Belt_Option.getWithDefault(Belt_Option.map(category, isQuickView), false);
  var parent$1 = Caml_obj.notequal(Belt_Array.get(Belt_Option.mapWithDefault(category, [], CategoryFinder$Showside.getChildren), 0), Belt_Array.get(categories, 0)) ? parent : undefined;
  var category$1 = !isBanner && hasSubCategories$1 ? category : undefined;
  var category$2 = Belt_Option.mapWithDefault(category$1, 0, function (category) {
    return Belt_Array.keep(CategoryFinder$Showside.getChildren(category), function (param) {
      return param.visibleInMenuBar;
    }).length;
  }) > 0 ? category$1 : undefined;
  var category$3;
  if (category$2 !== undefined) {
    category$3 = category$2;
  } else {
    if (parent$1 === undefined) {
      if (fallbackEmpty) {
        return [];
      } else {
        return categories;
      }
    }
    category$3 = parent$1;
  }
  return Belt_Array.concat(Belt_Option.mapWithDefault(getStoreCategoryConnection(category$3), [], function (param) {
    if (category$3.displayPriority !== /* ProductsFirst */1) {
      return [];
    }
    var newrecord = Caml_obj.obj_dup(category$3);
    return [(newrecord.title = "All", newrecord)];
  }), CategoryFinder$Showside.getChildren(category$3));
}
function isIndexNode(category) {
  var match = category.categoryType;
  if (typeof match === "number" || match.TAG !== /* Index */3) {
    return false;
  } else {
    return true;
  }
}
function isLinkCategory(category) {
  var match = category.categoryType;
  if (typeof match === "number" || match.TAG !== /* Link */0) {
    return false;
  } else {
    return true;
  }
}
function getCategoryLink(category) {
  var match = category.categoryType;
  if (typeof match === "number") {
    return;
  }
  switch (match.TAG | 0) {
    case /* Link */0:
      return match._0.link;
    case /* Standard */1:
      var link = match._0.link;
      if (link !== undefined) {
        return link;
      } else {
        return;
      }
    case /* QuickView */2:
      var link$1 = match._0.link;
      if (link$1 !== undefined) {
        return link$1;
      } else {
        return;
      }
    default:
      return;
  }
}
function isPLP(category) {
  var match = category.listPageActiveLayout;
  if (typeof match === "number" || match.TAG !== /* ProductGrid */3) {
    return false;
  } else {
    return true;
  }
}
function hasMedialessCard(category) {
  var match = category.card;
  switch (match.TAG | 0) {
    case /* MedialessCard */4:
    case /* MedialessLinkCard */5:
      return true;
    default:
      return false;
  }
}
function determineProductGridLayoutWithCardSize(min, max, cardSize) {
  var column;
  switch (cardSize) {
    case /* Xxxs */0:
    case /* Xxs */1:
      column = /* SevenColumnGrid */6;
      break;
    case /* Xs */2:
      column = /* SixColumnGrid */5;
      break;
    case /* Sm */3:
      column = /* FourColumnGrid */3;
      break;
    case /* Md */4:
      column = /* ThreeColumnGrid */2;
      break;
    case /* Lg */5:
    case /* Xl */6:
    case /* Xxl */7:
      column = /* TwoColumnGrid */1;
      break;
    case /* Xxxl */8:
    case /* Xxxxl */9:
    case /* Xxxxxl */10:
      column = /* OneColumnGrid */0;
      break;
  }
  var column$1 = min !== undefined && column < min ? min : column;
  if (max !== undefined && column$1 > max) {
    return max;
  } else {
    return column$1;
  }
}
function getMinimumAllowedColumns(_cardShape) {
  while (true) {
    var cardShape = _cardShape;
    if (typeof cardShape === "number") {
      if (!(cardShape === 5 || cardShape === 4)) {
        return /* TwoColumnGrid */1;
      } else {
        return;
      }
    }
    _cardShape = BasicTypes$Showside.CardShape.toCardShapeWithResolvedCustom(/* Custom */{
      _0: {
        width: cardShape._0,
        height: cardShape._1
      }
    }, /* Square */6);
    continue;
  }
  ;
}
function calculateGridColumns(card) {
  var cardSize = getCardSize(undefined, card);
  var minimumAllowedColumns = getMinimumAllowedColumns(getCardShape(undefined, undefined, card));
  return determineProductGridLayoutWithCardSize(minimumAllowedColumns, undefined, cardSize);
}
function dedupById(__x) {
  return UniqueArray$Showside.getUnique(__x, function (category) {
    return category.id;
  });
}
function dedupByMediaSource(__x) {
  return UniqueArray$Showside.getUnique(__x, function (category) {
    var match = category.card;
    switch (match.TAG | 0) {
      case /* ImageLinkCard */0:
        return match._0.imageUrl;
      case /* VideoLinkCard */1:
        return match._0.videoUrl;
      case /* ImageCard */2:
        return match._0.imageUrl;
      case /* VideoCard */3:
        return match._0.videoUrl;
      case /* MedialessCard */4:
      case /* MedialessLinkCard */5:
        return category.id;
      case /* ImageDisplayCard */6:
        return match._0.imageUrl;
      case /* VideoDisplayCard */7:
        return match._0.videoUrl;
    }
  });
}
var TaggedMedia = CategoryFinder$Showside.TaggedMedia;
var swapCategoryTypeWithQuickView = CategoryFinder$Showside.swapCategoryTypeWithQuickView;
var index = CategoryFinder$Showside.index;
var findCategoriesBy = CategoryFinder$Showside.findCategoriesBy;
var findCategoryBy = CategoryFinder$Showside.findCategoryBy;
var keepMapCategories = CategoryFinder$Showside.keepMapCategories;
var getCategoryPathById = CategoryFinder$Showside.getCategoryPathById;
var getCategoryById = CategoryFinder$Showside.getCategoryById;
var getCategoryParentById = CategoryFinder$Showside.getCategoryParentById;
var getChildren = CategoryFinder$Showside.getChildren;
var getCategorySiblings = CategoryFinder$Showside.getCategorySiblings;
export { TaggedMedia, swapCategoryTypeWithQuickView, index, findCategoriesBy, findCategoryBy, keepMapCategories, getCategoryPathById, getCategoryById, getCategoryParentById, getChildren, getCategorySiblings, Layout, TabbedSubCategories, Link, CardSize, CategoryCard, setChildren, setShowTitle, setCardShape, setTabbedSubCategories, isInstagramNode, isTiktokNode, getInstagramFeed, isInstagramRoot, getTiktokFeed, isTiktokRoot, isQuickView, isVideoCard, getImageHotspots, hasAnyItems, hasProducts, getProducts, getProductIds, ExternalConnection, isQuickViewStoriesEnabled, getQuickViewAlbumMediaId, getFirstCard, getCategoryImage, getCategoryImageSize, getCategoryVideo, getCategoryCardLinkUrl, hasSubCategories, getSensibleSiblings, isIndexNode, isLinkCategory, getCategoryLink, isPLP, hasMedialessCard, determineProductGridLayoutWithCardSize, getMinimumAllowedColumns, calculateGridColumns, dedupById, dedupByMediaSource };
/* CategoryFinder-Showside Not a pure module */